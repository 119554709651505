import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class ElectricityQuote implements ApiResource {
    id = 0

    supplier: string
    term: string
    standingCharge: string
    annualCost: string
    uplift: number
    customerFacing: boolean
    updeAllWithUplift: string
    updeDayWithUplift: string
    updeNightWithUplift: string
    updeWeekdayWithUplift: string
    updeEveningWeekendWithUplift: string
    contractStartDate: Date
    contractEndDate: Date
    providerId: number
    pricingListId: number
    profileClass: string
    meterType: string
    type: string
    minConsumption: number
    maxConsumption: number
    pesArea: string
    ref: string
    updeSc: string
    updeAll: string
    updeDay: string
    updeNight: string
    updeWeekday: string
    updeEveningWeekend: string
    endOfCurve: string
    capacityCharge: string
    name: string
    providerType: string
    providerStatus: string
    image: string
    file: string
}

@Injectable({
    providedIn: "root",
})
export class ElectricityQuoteSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): ElectricityQuote {
        const quote = new ElectricityQuote()
        quote.id = json.id

        quote.supplier = json.supplier
        quote.term = json.term
        quote.standingCharge = json.standing_charge
        quote.annualCost = json.annual_cost
        quote.uplift = json.uplift
        quote.customerFacing = json.customer_facing
        quote.updeAllWithUplift = json.upde_all_with_uplift
        quote.updeDayWithUplift = json.upde_day_with_uplift
        quote.updeNightWithUplift = json.upde_night_with_uplift
        quote.updeWeekdayWithUplift = json.upde_weekday_with_uplift
        quote.updeEveningWeekendWithUplift = json.upde_evening_weekend_with_uplift
        quote.contractStartDate = new Date(json.contract_start_date)
        quote.contractEndDate = new Date(json.contract_end_date)
        quote.providerId = json.provider_id
        quote.pricingListId = json.pricing_list_id
        quote.profileClass = json.profile_class
        quote.meterType = json.meter_type
        quote.type = json.type
        quote.minConsumption = json.min_consumption
        quote.maxConsumption = json.max_consumption
        quote.pesArea = json.pes_area
        quote.ref = json.ref
        quote.updeSc = json.upde_sc
        quote.updeAll = json.upde_all
        quote.updeDay = json.upde_day
        quote.updeNight = json.upde_night
        quote.updeWeekday = json.upde_weekday
        quote.updeEveningWeekend = json.upde_evening_weekend
        quote.endOfCurve = json.end_of_curve
        quote.capacityCharge = json.capacity_charge
        quote.name = json.name
        quote.providerType = json.provider_type
        quote.providerStatus = json.provider_status
        quote.image = json.image
        quote.file = json.file

        return quote
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(quote: ElectricityQuote): any {
        return {
            id: quote.id,
        }
    }
}
