import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-close",
    template: `<svg
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{
        'width': width + 'px',
        'height': height + 'px',
        'cursor': cursor,
    }"
        viewBox="0 0 6.305 6.303">
        <g id="Group_1044" data-name="Group 1044" transform="translate(-1519.395 -64.949)">
            <path
                id="Path_680"
                data-name="Path 680"
                d="M1520.284,71.252h-.013a.521.521,0,0,0-.043-.05q-.39-.393-.782-.784c-.016-.015-.03-.031-.051-.053l2.274-2.273-2.259-2.259.884-.884,2.269,2.269,2.262-2.262.824.825c.016.015.034.028.051.043v.012a.213.213,0,0,0-.023.019l-2.211,2.211c-.011.011-.02.024-.032.036l.037.039,2.2,2.2c.009.009.019.016.028.024v.012c-.011.009-.023.016-.033.026l-.816.815a.411.411,0,0,0-.025.033h-.012c-.007-.008-.013-.016-.02-.023q-1.1-1.105-2.21-2.21a.379.379,0,0,0-.037-.032c-.014.014-.027.024-.038.036l-2.2,2.2C1520.3,71.233,1520.292,71.243,1520.284,71.252Z"
                fill="currentColor" />
        </g>
    </svg>`,
})
export class CloseComponent {
    @Input() width: number = 11
    @Input() height: number = 11
    @Input() cursor: string = "pointer"
}
