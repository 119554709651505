import {
    CustomerNotificationCaseComment,
    CustomerNotificationCaseCommentSerializer,
} from "./customer-notification-case-comment"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"

export class CustomerNotificationCase {
    id: number = 0
    ticketNo: string
    assignedUserId: string
    parentId: string
    ticketpriorities: string
    productId: string
    ticketseverities: string
    ticketcategories: string
    updateLog: string
    hours: string
    days: string
    createdAt: string
    modifiedAt: string
    fromPortal: string
    modifiedby: string
    ticketTitle: string
    description: string
    solution: string
    contactId: string
    source: string
    starred: string
    tags: string
    cfCustomerId: string
    cfCustomersId: string
    helpdeskSlaName: string
    helpdeskSlaRemain: string
    helpdeskSlaRemainH: string
    helpdeskSlaStatus: string
    helpdeskSlaElapsed: string
    helpdeskSlaElapsedH: string
    cf1139: string
    comments: CustomerNotificationCaseComment[]
}

@Injectable({
    providedIn: "root",
})
export class CustomerNotificationCaseSerializer implements ApiResourceSerializer {
    constructor(private customerNotificationCaseCommentSerializer: CustomerNotificationCaseCommentSerializer) {}

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fromJson(json: any): CustomerNotificationCase {
        const item = new CustomerNotificationCase()
        item.id = json.id
        item.ticketNo = json.ticket_no
        item.assignedUserId = json.assigned_user_id
        item.parentId = json.parent_id
        item.ticketpriorities = json.ticketpriorities
        item.productId = json.product_id
        item.ticketseverities = json.ticketseverities
        item.ticketcategories = json.ticketcategories
        item.updateLog = json.update_log
        item.hours = json.hours
        item.days = json.days
        item.createdAt = json.created_at
        item.modifiedAt = json.modified_at
        item.fromPortal = json.from_portal
        item.modifiedby = json.modifiedby
        item.ticketTitle = json.ticket_title
        item.description = json.description
        item.solution = json.solution
        item.contactId = json.contact_id
        item.source = json.source
        item.starred = json.starred
        item.tags = json.tags
        item.cfCustomerId = json.cf_customer_id
        item.cfCustomersId = json.cf_customers_id
        item.helpdeskSlaName = json.helpdesk_sla_name
        item.helpdeskSlaRemain = json.helpdesk_sla_remain
        item.helpdeskSlaRemainH = json.helpdesk_sla_remain_h
        item.helpdeskSlaStatus = json.helpdesk_sla_status
        item.helpdeskSlaElapsed = json.helpdesk_sla_elapsed
        item.helpdeskSlaElapsedH = json.helpdesk_sla_elapsed_h
        item.cf1139 = json.cf_1139
        item.comments =
            json.comments !== undefined && json.comments !== null
                ? json.comments.map(im => this.customerNotificationCaseCommentSerializer.fromJson(im))
                : []
        return item
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(item: CustomerNotificationCase): any {
        return {
            id: item.id,
        }
    }
}
