const ActionTypes = {
    /**
     * Http action type
     */
    setRefreshTokenInterceptor: "HTTP_REFRESH_TOKEN_INTERCEPTOR",
    setHttpLogout: "HTTP_LOG_OUT",
    setHttpRequestObserve: "HTTP_REQUEST_OBSERVE",
    setHttpRequestInProgress: "HTTP_REQUEST_IN_PROGRESS",
    setHttpRequestInitialize: "HTTP_REQUEST_INITIALIZE",
    setHttpRequestClearTimer: "HTTP_REQUEST_CLEAR_TIMER",

    /**
     * Page action types
     */
    setPageAnimation: "PAGE_SET_ANIMATION",
    setPageInitialize: "PAGE_SET_PAGE_INITIALIZE",
    setPageRestart: "PAGE_SET_RESTART_PAGE",
    setPageLoadingFrom: "PAGE_SET_PAGE_LOADING_FROM",
    setResetPageLoadingFrom: "PAGE_SET_RESET_PAGE_LOADING_FROM",

    setAuthUser: "AUTH_SET_AUTH_USER",
    getAuthUser: "AUTH_GET_AUTH_USER",
    setDashboardData: "DASHBOARD_SET_DATA",
    setDashboardCustomerId: "DASHBOARD_SET_CUSTOMER_ID",
    setDashboardPartnerId: "DASHBOARD_SET_PARTNER_ID",
    setDashboardCurrentCustomer: "DASHBOARD_SET_CURRENT_CUSTOMER",
    setDashboardEditingMode: "DASHBOARD_SET_EDITING_MODE",
    setDashboardCurrentUser: "DASHBOARD_SET_CURRENT_USER",
    setDashboardCurrentPartner: "DASHBOARD_SET_CURRENT_PARTNER",

    /**
     * Header action types
     */
    setHeaderInitialize: "APP_HEADER_INITIALIZE",
    setHeaderLogout: "APP_HEADER_LOGOUT",
    setHeaderLoadedCustomer: "APP_HEADER_LOADED_CUSTOMER",
    setHeaderLoadedNotification: "APP_HEADER_LOADED_NOTIFICATION",
    setHeaderLoadedSwitchableLocation: "APP_HEADER_LOADED_SWITCHABLE_LOCATION",
    setHeaderLoadedInbox: "APP_HEADER_LOADED_INBOX",

    /**
     * Loading
     */
    setShowAppLoadingGlobal: "APP_LOADING_GLOBAL",
    setHideAppLoadingGlobal: "APP_HIDE_LOADING_GLOBAL",
    setShowAppLoadingHeader: "APP_LOADING_HEADER",
    setHideAppLoadingHeader: "APP_HIDE_LOADING_HEADER",
    setShowAppLoadingComponent: "APP_SHOW_LOADING_COMPONENT",
    setHideAppLoadingComponent: "APP_HIDE_LOADING_COMPONENT",

    /**
     * Dashboard widget action types
     */
    setDashboardTotalCountMonthlyWidget: "DASHBOARD_SET_TOTAL_COUNT_MONTHLY_WIDGET",
    setDashboardAverageRateWidget: "DASHBOARD_SET_AVERAGE_RAGE_WIDGET",
    setDashboardCardCountDailyWidget: "DASHBOARD_SET_CARD_COUNT_DAILY_WIDGET",
    setDashboardCardCountMonthlyWidget: "DASHBOARD_SET_CARD_COUNT_MONTHLY_WIDGET",
    setDashboardCardTypeWidget: "DASHBOARD_SET_CARD_TYPE_WIDGET",
    setDashboardDashboardTypesWidget: "DASHBOARD_SET_TYPES_WIDGET",
    setDashboardChargeBackWidget: "DASHBOARD_SET_CHARGE_BACK_WIDGET",
    setDashboardPaymentWidget: "DASHBOARD_SET_PAYMENT_WIDGET",
    setDashboardTotalAmountDailyWidget: "DASHBOARD_SET_TOTAL_AMOUNT_DAILY_WIDGET",
    setDashboardTotalAmountMonthlyWidget: "DASHBOARD_SET_TOTAL_AMOUNT_MONTHLY_WIDGET",
    setDashboardTotalAtvDailyWidget: "DASHBOARD_SET_TOTAL_ATV_DAILY_WIDGET",
    setDashboardTotalAtvMonthlyWidget: "DASHBOARD_SET_TOTAL_ATV_MONTHLY_WIDGET",
    setDashboardTerminalCountDailyWidget: "DASHBOARD_SET_TERMINAL_COUNT_DAILY_WIDGET",
    setDashboardTransactionValueWidget: "DASHBOARD_SET_TRANSACTION_VALUE_WIDGET",
    setDashboardTransactionWidget: "DASHBOARD_SET_TRANSACTION_WIDGET",
    setDashboardRateWidget: "DASHBOARD_SET_RATE_WIDGET",
    setDashboardWidgetUpdateStatus: "DASHBOARD_SET_DASHBOARD_WIDGET_UPDATE_STATUS",
    setRestartDashboard: "DASHBOARD_SET_RESTART_DSAHBOARD",
    setBusinessOverviewDashboard: "DASHBOARD_SET_BUSINESS_OVERVIEW",
    setTerminalsCountLiveMonthlyDashboard: "DASHBOARD_SET_TERMINAL_COUNT_LIVE_MONTH",
    setTerminalsCountLiveDailyDashboard: "DASHBOARD_SET_TERMINAL_COUNT_LIVE_DAILY",
    setTerminalsCountOrderedMonthlyDashboard: "DASHBOARD_SET_TERMINAL_COUNT_ORDERED_MONTH",
    setTerminalsCountOrderedDailyDashboard: "DASHBOARD_SET_TERMINAL_COUNT_ORDERED_DAILY",
    setCommissionsDashboard: "DASHBOARD_SET_COMMISSIONS",
    setQuoteAndApplicationDashboard: "DASHBOARD_SET_QUOTE_AND_APPLICATION",
    setAllBusinessOverviewDashboard: "DASHBOARD_SET_ALL_BUSINESS_OVERVIEW",
    setFinanceCommissionDashboard: "DASHBOARD_SET_FINANCE_COMMISSION",
    setResidualCommissionDashboard: "DASHBOARD_SET_RESIDUAL_COMMISSION",

    /**
     * Transaction date picker type
     */
    setTransactionDatePicker: "TRANSACTION_SET_DATE_PICKER",
    setTransactionDatePickerType: "TRANSACTION_SET_DATE_PICKER_TYPE",
    setTransactionDatePickerExactDate: "TRANSACTION_SET_DATE_PICKER_EXACT_DATE",
    setTransactionDatePickerFromDate: "TRANSACTION_SET_DATE_PICKER_FROM_DATE",
    setTransactionDatePickerToDate: "TRANSACTION_SET_DATE_PICKER_TO_DATE",
    setTransactionDatePickerMaxFromDate: "TRANSACTION_SET_DATE_PICKER_MAX_FROM_DATE",
    setTransactionDatePickerMinToDate: "TRANSACTION_SET_DATE_PICKER_MIN_TO_DATE",

    /**
     * Settlements date picker type
     */
    setSettlementsDatePicker: "TRANSACTION_SETTLEMENT_SET_DATE_PICKER",
    setSettlementsDatePickerType: "TRANSACTION_SETTLEMENT_SET_DATE_PICKER_TYPE",
    setSettlementsDatePickerExactDate: "TRANSACTION_SETTLEMENT_SET_DATE_PICKER_EXACT_DATE",
    setSettlementsDatePickerFromDate: "TRANSACTION_SETTLEMENT_SET_DATE_PICKER_FROM_DATE",
    setSettlementsDatePickerToDate: "TRANSACTION_SETTLEMENT_SET_DATE_PICKER_TO_DATE",

    /**
     * Chargeback date picker type
     */
    setChargebackDatePicker: "TRANSACTION_CHARGEBACK_SET_DATE_PICKER",
    setChargebackDatePickerType: "TRANSACTION_CHARGEBACK_SET_DATE_PICKER_TYPE",
    setChargebackDatePickerExactDate: "TRANSACTION_CHARGEBACK_SET_DATE_PICKER_EXACT_DATE",
    setChargebackDatePickerFromDate: "TRANSACTION_CHARGEBACK_SET_DATE_PICKER_FROM_DATE",
    setChargebackDatePickerToDate: "TRANSACTION_CHARGEBACK_SET_DATE_PICKER_TO_DATE",

    /**
     * End transaction page actions type
     */

    /**
     * Virtual terminal create payment actions type
     */
    setVirtualTerminalAmount: "VIRTUAL_TERMINAL_SET_AMOUNT",
    setVirtualTerminalStep1Info: "VIRTUAL_TERMINAL_SET_STEP_1_INFO",
    setVirtualTerminalStep2Info: "VIRTUAL_TERMINAL_SET_STEP_2_INFO",
    setRestartVirtualTerminal: "VIRTUAL_TERMINAL_SET_RESTART_VIRTUAL_TERMINAL",
    setVirtualTerminalPaymentResponse: "VIRTUAL_TERMINAL_SET_PAYMENT_RESPONSE",
    setVirtualTerminalCurrentStep: "VIRTUAL_TERMINAL_SET_CURRENT_STEP",

    /**
     * Customer registration
     */
    setCustomerRegistrationFormSubmit: "CUSTOMER_REGISTRATION_FORM_SUBMIT",
    setCustomerRegistrationFormSetupInProgress: "CUSTOMER_REGISTRATION_SETUP_IN_PROGRESS",
    setCustomerRegistrationCompleted: "CUSTOMER_REGISTRATION_COMPLETED",
    setCustomerRegistrationReset: "CUSTOMER_REGISTRATION_RESET",
    setCustomerRegistrationInvalidFastPayment: "CUSTOMER_REGISTRATION_INVALID_FAST_PAYMENT",
    setCustomerRegistrationLoadFeeSection: "CUSTOMER_REGISTRATION_LOAD_FEE_SECTION",
    setCustomerRegistrationUpdatePrice: "CUSTOMER_REGISTRATION_UPDATE_PRICE",
    setCustomerRegistrationStandardFees: "CUSTOMER_REGISTRATION_STANDARD_FEES",
    setCustomerRegistrationStandardFeesLoading: "CUSTOMER_REGISTRATION_STANDARD_FEES_LOADING",
    setCustomerRegistrationFormSubmitted: "CUSTOMER_REGISTRATION_STANDARD_FEES",
    setCustomerRegistrationPCIPlusValue: "CUSTOMER_REGISTRATION_PCI_PLUS_VALUE",
    setCustomerRegistrationFasterPaymentValue: "CUSTOMER_REGISTRATION_FASTER_PAYMENT_VALUE",
    setCustomerRegistrationProductSelect: "CUSTOMER_REGISTRATION_PRODUCT_SELECT",
    setCustomerRegistrationTerminalSelect: "CUSTOMER_REGISTRATION_TERMINAL_SELECT",
}

export default ActionTypes
