import { SetDashboardCommissionsWidgetAction } from "../../actions/DashboardActions"
import { WidgetCommissions } from "@appModels/widget-commissions"
import { WidgetCommissionsApiService } from "@appServices/api/widget-commissions-api.service"
import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { AlertService } from "@app/_services/alert.service"
import { EChartsOption } from "echarts"
import HelperVerofy from "@appHelpers/helper-verofy"

export const FetchCommissionsData = createAction("CommissionsAction", props<{ partnerId: number }>())

@Injectable()
export class CommissionsEffects {
    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchCommissionsData),
                exhaustMap(action =>
                    this.widgetCommissionsApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                {
                                    const totalAmountMonthly: WidgetCommissions[] = payload

                                    const bar = []
                                    const data = []

                                    if (totalAmountMonthly[0]) {
                                        const fetchedData = totalAmountMonthly[0]

                                        bar.push("Residual")
                                        data.push({
                                            "name": "Residual",
                                            "value": fetchedData["availableResidualCommission"],
                                        })

                                        bar.push("Finance")
                                        data.push({
                                            "name": "Finance",
                                            "value": fetchedData["availableFinanceCommission"],
                                        })
                                    }

                                    const eChartOption: EChartsOption = {
                                        tooltip: {
                                            trigger: "item",
                                            formatter: a => {
                                                const amount = parseFloat(a.data.value)
                                                // Access the original marker
                                                const originalMarker = a.marker
                                                const color = a.color
                                                const customContent = `${
                                                    a.data.name
                                                }: <span style="color:${color}"><strong>${HelperVerofy.formatCurrency(
                                                    amount,
                                                    "GBP",
                                                    2,
                                                )}</strong></span>`

                                                // Combine the original marker and custom content
                                                return `${originalMarker}${customContent}`
                                            },
                                            textStyle: {
                                                fontSize: 12,
                                            },
                                        },
                                        color: ["#0078fa", "#97c1fe"],
                                        legend: {
                                            selectorPosition: "start",
                                            align: "left",
                                            orient: "horizontal",
                                            top: "2px",
                                            data: bar,
                                        },
                                        toolbox: {
                                            top: 0,
                                            right: 0,
                                            feature: {
                                                saveAsImage: {
                                                    emphasis: {
                                                        iconStyle: {
                                                            textFill: "#307AFF",
                                                            borderColor: "#307AFF",
                                                        },
                                                    },
                                                    title: "Download report",
                                                    name: "Verofy Commissions",
                                                },
                                            },
                                        },
                                        calculable: true,
                                        series: [
                                            {
                                                name: "Value",
                                                type: "pie",
                                                radius: ["40%", "60%"],
                                                avoidLabelOverlap: false,
                                                itemStyle: {
                                                    borderRadius: 5,
                                                    borderColor: "#fff",
                                                    borderWidth: 1,
                                                },
                                                label: {
                                                    show: false,
                                                    position: "center",
                                                },
                                                emphasis: {
                                                    label: {
                                                        show: false,
                                                    },
                                                },
                                                labelLine: {
                                                    show: false,
                                                },
                                                data,
                                            },
                                        ],
                                    }
                                    /* {
                                        legend: {
                                            data: ["Total"],
                                            align: "left",
                                            orient: "horizontal",
                                            top: "10px",
                                            left: "0",
                                        },
                                        color: ["#BDBCBC", "#f3f3f3"],
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                axis: "auto",
                                                type: "none",
                                            },
                                        },
                                        xAxis: {
                                            data: bar,
                                            silent: false,
                                            splitLine: {
                                                show: false,
                                            },
                                        },
                                        yAxis: {},
                                        series: [
                                            {
                                                name: "Total",
                                                type: "bar",
                                                data,
                                                animationDelay: +(idx => idx * 10),
                                            },
                                        ],
                                        animationEasing: "elasticOut",
                                        animationDelayUpdate: +(idx => idx * 5),
                                    } */

                                    return SetDashboardCommissionsWidgetAction({
                                        data: eChartOption,
                                    })
                                }
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private widgetCommissionsApiService: WidgetCommissionsApiService,
    ) {}
}
