/* eslint-disable @typescript-eslint/no-explicit-any */
import { SetRefreshTokenException } from "./../store/actions/AppHttpActions"
import { AppHttpExceptionType } from "@app/store/reducers/AppHttpReducer"
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, throwError } from "rxjs"
import { AuthenticationService } from "@app/_services/authentication.service"
import { catchError, switchMap, filter, take } from "rxjs/operators"
import { LoggingService } from "../_services/logging.service"
import { Store } from "@ngrx/store"
import { AppState } from "@app/store"

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false
    isLogout: boolean
    refreshTokenStatus: AppHttpExceptionType

    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    store: Store<AppState>

    constructor(
        public authService: AuthenticationService,
        public loggingService: LoggingService,
        store: Store<AppState>,
    ) {
        this.store = store
        this.store
            .select(state => state.http.logOut)
            .subscribe(isLogout => {
                this.isLogout = isLogout
            })
        this.store
            .select(state => state.http.refreshTokenStatus)
            .subscribe(status => {
                this.refreshTokenStatus = status
            })
    }

    isOpenURL(request: HttpRequest<any>): boolean {
        return request.url.includes("refresh-token") || request.url.includes("login") || request.url.includes("logout")
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // Don't want to refresh token for some requests like login or refresh token itself
                // - so we verify url and we throw an error if it's the case
                if (this.isOpenURL(request)) {
                    // We do another check to see if refresh token failed
                    // - in this case we want to logout user and to redirect it to login page
                    if (request.url.includes("refresh-token")) {
                        this.loggingService.logDebugInfo("REFRESH TOKEN INTERCEPTOR: Logout")
                        // Logout is processed in server-error interceptor in case of 401 error status
                        this.authService.finishLogout(null, true)
                    }

                    // console.log(error)
                    return throwError(() => error)
                }

                // If error status is different than 401 we want to skip refresh token
                // - so the error can be caught by the server-error interceptor
                if (error.status !== 401) return throwError(() => error)
                // if (error.status !== 401) return throwError(() => new Error("Unauthorized"))

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request))),
                    )
                } else {
                    this.refreshTokenInProgress = true
                    this.loggingService.logDebugInfo("REFRESH TOKEN INTERCEPTOR: Refreshing")

                    if (!this.isLogout && !this.isOpenURL(request)) {
                        this.store.dispatch(SetRefreshTokenException({ httpType: AppHttpExceptionType.HttpRefreshing }))
                    }

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null)

                    // Call auth.refreshAccessToken(this is an Observable that will be returned)
                    return this.authService.refreshAuthToken().pipe(
                        switchMap((token: any) => {
                            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            this.refreshTokenInProgress = false
                            this.refreshTokenSubject.next(token)

                            this.loggingService.logDebugInfo(
                                "REFRESH TOKEN INTERCEPTOR - Adding authentication token" + token,
                            )
                            return next.handle(this.addAuthenticationToken(request))
                        }),
                        catchError((err: any) => {
                            this.refreshTokenInProgress = false

                            this.loggingService.logDebugInfo("REFRESH TOKEN INTERCEPTOR - Catching error logout")
                            this.store.dispatch(
                                SetRefreshTokenException({ httpType: AppHttpExceptionType.HttpRefreshed }),
                            )
                            return throwError(() => err)
                        }),
                    )
                }
            }),
        )
    }

    /**
     * Adds refreshed auth bearer token to the request
     */
    addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // Get access token from Local Storage
        const accessToken = this.authService.getAccessToken()

        // If access token is null this means that user is not logged in
        // - so return the original request
        if (!accessToken) {
            return request
        }

        // Clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: "Bearer " + this.authService.getAccessToken(),
            },
        })
    }
}
