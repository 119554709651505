import { Component, EventEmitter, Input, Output } from "@angular/core"
import { AssociativeArray } from "@appCore/types"
import { Pagination } from "@appModels/pagination"

@Component({
    selector: "app-pagination-controls",
    templateUrl: "./pagination-controls.component.html",
})
export class PaginationControlsComponent {
    options: AssociativeArray<string> = this.getDefaultOptions()
    @Input() pagination: Pagination
    @Output() pageChange = new EventEmitter<AssociativeArray<string>>()
    @Output() perPageChange = new EventEmitter<number>()
    getDefaultOptions(): AssociativeArray<string> {
        return {
            "per_page": "25",
            "page": "1",
            "order_by": "created_at",
            "order_by_direction": "DESC",
        }
    }

    onPageChange(value: number): void {
        this.options["page"] = value.toString()
        this.pageChange.emit(this.options)
    }

    onPerPageChange(value: number): void {
        this.options["per_page"] = value.toString()
        this.perPageChange.emit(value)
        this.onPageChange(parseInt(this.options["page"]))
    }

    onPaginationNextPreviousClick(list: string): void {
        switch (list) {
            case "start": {
                this.options["page"] = "1"
                break
            }
            case "previous": {
                this.options["page"] = this.pagination.currentPage - 1
                break
            }
            case "next": {
                this.options["page"] = this.pagination.currentPage + 1
                break
            }
            case "end": {
                this.options["page"] = this.pagination.lastPage
                break
            }
            default: {
                // this.onResetFilter()
                break
            }
        }
        this.pageChange.emit(this.options)
    }

    createPageRange(total: number): Array<number> {
        const items = []
        for (let i = 1; i <= total; i++) {
            items.push(i)
        }
        return items
    }

    createPerPageRange(): Array<number> {
        return [5, 10, 15, 25, 50, 100]
    }
}
