import { Injectable } from "@angular/core"

@Injectable({
    providedIn: "root",
})
export class LoggingService {
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    logError(message: string, stack: string = ""): void {
        console.log("ERROR: " + message)
    }

    logDebugInfo(message: string): void {
        console.log("DEBUG INFO: " + message)
    }
}
