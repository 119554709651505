import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class Inbox extends ApiResource {
    id: number = 0
    totalMessages: number = 0
    totalReadMessages: number = 0
    totalUnreadMessages: number = 0
    totalArchivedMessages: number = 0
    totalNonArchivedMessages: number = 0
    totalActionRequiredMessages: number = 0
}

@Injectable({
    providedIn: "root",
})
export class InboxSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Inbox {
        const record = new Inbox()
        record.id = json.id
        record.totalMessages = json.total_messages
        record.totalReadMessages = json.total_read_messages
        record.totalUnreadMessages = json.total_unread_messages
        record.totalArchivedMessages = json.total_archived_messages
        record.totalNonArchivedMessages = json.total_non_archived_messages
        record.totalActionRequiredMessages = json.total_action_required_messages
        return record
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(message: Inbox): any {
        return {
            id: message.id,
            total_messages: message.totalMessages,
            total_read_messages: message.totalReadMessages,
            total_unread_messages: message.totalUnreadMessages,
            total_archived_messages: message.totalArchivedMessages,
            total_non_archived_messages: message.totalNonArchivedMessages,
            total_action_required_messages: message.totalActionRequiredMessages,
        }
    }
}
