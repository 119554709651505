import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Store } from "@ngrx/store"
import { AppState } from "@app/store"
import { SetPageAnimation, SetPageRestart } from "@app/store/actions/AppPageActions"
import {
    SetHttpRequestClearTimer,
    SetHttpRequestInProgress,
    SetHttpRequestObserve,
} from "@app/store/actions/AppHttpActions"

@Injectable()
export class AnimateGuard {
    constructor(private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.store.dispatch(SetPageRestart())
        this.store.dispatch(SetHttpRequestObserve({ isObserve: false }))
        this.store.dispatch(SetHttpRequestClearTimer())
        if (route.data.animatePage) {
            this.store.dispatch(SetPageAnimation({ animation: true }))
        }

        const currentUrl = state.url
        // Check is this route has http request observe
        if (
            route.data.observeHttp &&
            currentUrl !== "/login" &&
            currentUrl !== "/login/ios" &&
            currentUrl !== "/login/android" &&
            currentUrl !== "/android/public/after-cloud-customer-registraion" &&
            currentUrl !== "/ios/public/after-cloud-customer-registraion"
        ) {
            this.store.dispatch(SetHttpRequestObserve({ isObserve: true }))
            this.store.dispatch(SetHttpRequestInProgress())
        }

        return true
    }
}
