import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class VatRegistrationInfo extends ApiResource {
    target: {
        name: string
        address: {
            line1: string
            line2: string
            line3: string
            postcode: string
            countryCode: string
        }
        vatNumber: number
    }
    requester: string
    processingDate: string
    consultationNumber: string
}

@Injectable({
    providedIn: "root",
})
export class VatRegistrationInfoSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): VatRegistrationInfo {
        const record = new VatRegistrationInfo()
        record.target = json.target
        record.requester = json.requester
        record.processingDate = json.processingDate
        record.consultationNumber = json.consultationNumber
        return record
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(info: VatRegistrationInfo): any {
        return {
            target: info.target,
            requester: info.requester,
            processingDate: info.processingDate,
            consultationNumber: info.consultationNumber,
        }
    }
}
