import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { AlertService } from "@app/_services/alert.service"
import { SetFinanceCommissionWidgetAction } from "../../actions/DashboardActions"
import { EChartsOption, SeriesOption } from "echarts"
import * as echarts from "echarts"
import { DashboardWidgetColor } from "@app/interfaces/dashboard/dashboardWidgetColor"
import { WidgetAllBusinessOverviewApiService } from "@app/_services/api/widget-all-business-overview-api.service"
import { WidgetAllBusinessOverview } from "@app/_models/widget-all-business-overview"
import HelperVerofy from "@app/_helpers/helper-verofy"
import { DatePipe } from "@angular/common"

export const FetchCommissionFinanaceData = createAction("CommissionFinanaceAction", props<{ partnerId: number }>())

@Injectable()
export class CommissionFinanaceEffect {
    currency: string = "£"
    p_currency: string = "p"
    hasData: boolean = false

    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchCommissionFinanaceData),
                exhaustMap(action =>
                    this.widgetApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                {
                                    const widgetCommissionFinanace: WidgetAllBusinessOverview[] = payload
                                    const datePipe: DatePipe = new DatePipe("en-US")
                                    const barX = []
                                    const financeCommission = []

                                    widgetCommissionFinanace.forEach(record => {
                                        const monthLabel = datePipe.transform(
                                            record.year + "-" + record.month + "-1",
                                            "M.YY",
                                        )

                                        barX.push(monthLabel)
                                        financeCommission.push(record.totalFinanceAmount ?? 0)
                                    })

                                    const hasNoData = widgetCommissionFinanace.every(
                                        item => item.totalFinanceAmount === "0",
                                    )
                                    if (hasNoData) this.hasData = false
                                    else this.hasData = true

                                    const currentSeries: SeriesOption[] = [
                                        {
                                            name: "Finance commission",
                                            type: "line",
                                            data: financeCommission,
                                            animationDelay: +(idx => idx * 10),
                                        },
                                    ]

                                    let barY: number[] = [0]
                                    barY = this.mapAxisY(barY, financeCommission)

                                    let yAxis = {}
                                    if (barY.some(value => value !== 0) && barY.length > 0) {
                                        yAxis = {
                                            max: Math.max(...barY),
                                            min: Math.min(...barY),
                                            splitLine: {
                                                show: false,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === "0")
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                        }
                                    } else {
                                        yAxis = {
                                            boundaryGap: false,
                                            splitLine: {
                                                show: false,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === "0")
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                            data: [0, 10000],
                                        }
                                    }

                                    const eChartOptions: EChartsOption = {
                                        plugins: {
                                            tooltip: {
                                                titleFont: {
                                                    size: 200,
                                                },
                                                bodyFont: {
                                                    size: 150,
                                                },
                                            },
                                        },
                                        title: {
                                            text: this.hasData
                                                ? ""
                                                : "There is currently no commission data to display.",
                                            subtext: "",
                                            left: "center",
                                            top: "42%",
                                            textStyle: {
                                                fontSize: 12,
                                                opacity: 0.5,
                                                fontWeight: 400,
                                            },
                                        },
                                        color: [
                                            DashboardWidgetColor.Real,
                                            DashboardWidgetColor.Refund,
                                            DashboardWidgetColor.Visa,
                                        ],
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                axis: "auto",
                                                type: "none",
                                            },
                                            textStyle: {
                                                fontSize: 12,
                                            },
                                            formatter: function (params) {
                                                // Access the title (name) from the first data point
                                                const title = params[0].name
                                                let tooltipContent = `<strong>${title}</strong> <br/>`

                                                // Loop through the data points to construct the rest of the tooltip content
                                                params.forEach(param => {
                                                    const originalMarker = param.marker
                                                    const name = param.seriesName
                                                    const markerColor = param.color
                                                    const value = param.value

                                                    const formattedValue = HelperVerofy.formatCurrency(
                                                        Number(value),
                                                        name === "Active customers" ? "" : "GBP",
                                                        name === "Active customers" ? 0 : 2,
                                                    )

                                                    tooltipContent +=
                                                        originalMarker +
                                                        `${name}: <span style="color:${markerColor}"><strong>${formattedValue}</strong></span><br/>`
                                                })

                                                return tooltipContent
                                            },
                                        },
                                        toolbox: {
                                            top: 0,
                                            right: 0,
                                            feature: {
                                                saveAsImage: {
                                                    emphasis: {
                                                        iconStyle: {
                                                            textFill: "#307AFF",
                                                            borderColor: "#307AFF",
                                                        },
                                                    },
                                                    title: "Download report",
                                                    name: "Verofy Finance Income",
                                                },
                                            },
                                        },
                                        xAxis: {
                                            data: barX,
                                            silent: false,
                                            splitLine: {
                                                show: true,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                            },
                                            nameTextStyle: {
                                                align: "center",
                                                verticalAlign: "middle",
                                            },
                                        },
                                        yAxis: yAxis,
                                        series: currentSeries,
                                        animationEasing: "elasticOut",
                                        animationDelayUpdate: +(idx => idx * 5),
                                        grid: {
                                            containLabel: true,
                                            left: 0,
                                        },
                                    }

                                    this.setSeriesListeners(currentSeries)

                                    return SetFinanceCommissionWidgetAction({
                                        data: eChartOptions,
                                    })
                                }
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    mapAxisY(output: number[], input: string[]): number[] {
        input.forEach(item => {
            if (item) {
                // Remove currency symbol (£) and comma (,) and then convert to a number
                const numericValue = Number(item.toString().replace("£", "").replace(/,/g, ""))
                if (output.indexOf(numericValue) === -1) {
                    output.push(numericValue)
                }
            }
        })
        return output
    }

    setSeriesListeners(currentSeries: SeriesOption[]): void {
        setTimeout(function () {
            try {
                // Check if the container element exists
                const containerElement = document.getElementById("finance-commission-chart")

                if (containerElement) {
                    // You can initialize your chart and add event listeners here
                    const yourChart = echarts.init(containerElement)

                    // Add event listeners to detect series selection changes
                    yourChart.on("legendselectchanged", (params: { selected: Record<string, boolean> }) => {
                        // Create an array to store the selected series names
                        const selectedSeries = Object.keys(params.selected).filter(
                            seriesName => params.selected[seriesName],
                        )

                        // Check if any series is selected
                        if (selectedSeries.length > 0) {
                            // Calculate the min and max for the selected series
                            const maxValues = selectedSeries.map(seriesName => {
                                const record = currentSeries.find(item => item.name === seriesName)
                                if (record) return Math.max(...(record.data as number[]))
                                else return 0
                            })

                            const minValues = selectedSeries.map(seriesName => {
                                const record = currentSeries.find(item => item.name === seriesName)
                                if (record) return Math.min(...(record.data as number[]))
                                else return 0
                            })

                            // Calculate the overall min and max for the selected series
                            const overallMax = Math.max(...maxValues)
                            const overallMin = Math.min(...minValues)

                            // Update the yAxis
                            yourChart.setOption({
                                yAxis: {
                                    max: overallMax,
                                    min: overallMin,
                                },
                            })
                        }
                    })
                }
            } catch (error) {
                console.error("Error initializing the chart:", error)
            }
        }, 2000)
    }

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private widgetApiService: WidgetAllBusinessOverviewApiService,
    ) {}
}
