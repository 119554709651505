import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class Notification implements ApiResource {
    id: number
    title: string
    message: string
    closed: number
    recipientType: string
    recipientId: number
    createdAt: Date
    updatedAt: Date
}

@Injectable({
    providedIn: "root",
})
export class NotificationSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Notification {
        const object = new Notification()
        object.id = json.id
        object.title = json.title
        object.message = json.message
        object.closed = json.closed
        object.recipientType = json.recipient_type
        object.recipientId = json.recipient_id
        object.createdAt = new Date(json.created_at)
        object.updatedAt = new Date(json.updated_at)
        return object
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(json: Notification): any {
        return {
            id: json.id,
            title: json.title,
            message: json.message,
            closed: json.closed,
            recipient_type: json.recipientType,
            recipient_id: json.recipientId,
            created_at: json.createdAt,
            updated_at: json.updatedAt,
        }
    }
}
