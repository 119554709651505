import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-error-exclamation-icon",
    template: ` <svg xmlns="http://www.w3.org/2000/svg" width="22.598" height="19.239" viewBox="0 0 22.598 19.239">
        <g id="Group_55" data-name="Group 55" transform="translate(-3014.844 -3961.222)">
            <g id="Group_54" data-name="Group 54">
                <g id="Group_53" data-name="Group 53">
                    <g id="Group_51" data-name="Group 51">
                        <path
                            id="Path_81"
                            data-name="Path 81"
                            d="M3035.492,3980.461h-18.7a1.736,1.736,0,0,1-1.588-2.814l9.275-15.353a1.825,1.825,0,0,1,3.325,0l9.275,15.353a1.736,1.736,0,0,1-1.588,2.814Zm-9.349-17.995c-.2,0-.417.172-.6.471l-9.275,15.353a.536.536,0,0,0,.522.926h18.7a.536.536,0,0,0,.523-.926l-9.275-15.353C3026.559,3962.638,3026.341,3962.466,3026.143,3962.466Z"
                            fill="currentColor" />
                    </g>
                    <g id="Group_52" data-name="Group 52">
                        <path
                            id="Path_82"
                            data-name="Path 82"
                            d="M3026.777,3974.48c0,.456-.005.827-.647.825h0c-.642,0-.645-.37-.645-.827l-.505-7.9c0-.457.522-.825,1.163-.825h0c.643,0,1.163.372,1.163.828Z"
                            fill="currentColor" />
                        <path
                            id="Path_83"
                            data-name="Path 83"
                            d="M3027.153,3977.034a1.01,1.01,0,1,1-1.01-1.009A1.011,1.011,0,0,1,3027.153,3977.034Z"
                            fill="currentColor" />
                    </g>
                </g>
            </g>
        </g>
    </svg>`,
})
export class ErrorExclamationIconComponent {}
