
<section class="datePickerComponent">
  <div class="row" style="position: relative;">
    <div class="col-sm-12 ">
      <div [ngClass]="isVTerminalRoute ? 'popupContainerVterminal' : 'popupContainer'" *ngIf="openMenu">
        <menu class="customMenu">
          <button class="btnCloseMenu" (click)="closeMenu()">
            <svg-close></svg-close>
          </button>
          <label class="menuButton" *ngFor="let option of options; let last = last" [class.border-bottom]="!last">
            <span class="menuLabel">{{ option.label }}</span>
            <input type="radio" name="dateOption" class="menu-radio" [checked]="isSelected(option.value)"
                   (click)="onMenuSelection(option.value)">
          </label>
          <!-- Confirm Button -->
          <button class="btn btnPrimary mt-3" (click)="confirmSelection()" style="margin-left: 80px;">Confirm</button>
        </menu>
        <div class="appMl-12">
          <input
            [bsConfig]="{ isAnimated: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'MMMM Do YYYY', showWeekNumbers: false }"
            [maxDate]="maxDateRangeFilterDate" type="text" placement="right"
            class="form-control inputFieldHidden overlay inputFieldvisibility" #dp="bsDaterangepicker" bsDaterangepicker
            [formControl]="filterAdjustPeriod" autocomplete="off">
        </div>
      </div>

    </div>
  </div>


</section>
