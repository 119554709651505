import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class Tax {
    id = 0
    createdAt: Date
    updatedAt: Date
    name: string
    percent: number
    isEnabled: boolean
}

@Injectable({
    providedIn: "root",
})
export class TaxSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Tax {
        const record = new Tax()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.name = json.name
        record.percent = json.percent
        record.isEnabled = json.is_enabled
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: Tax): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            name: record.name,
            percent: record.percent,
            is_enabled: record.isEnabled,
        }
    }
}
