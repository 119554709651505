import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { DeviceDetectorService } from "ngx-device-detector"

@Injectable()
export class DetectPhoneGuard {
    constructor(private router: Router, private deviceDetector: DeviceDetectorService) {}

    canActivate(): boolean {
        // Check is ios or android
        const os = this.deviceDetector.os.toLowerCase()
        const deviceType = this.deviceDetector.deviceType.toLowerCase()

        if (os === "ios" && deviceType === "mobile") {
            // Detect mobile IOS
            this.router.navigateByUrl("/login/ios")
        }

        if (os === "android" && deviceType === "mobile") {
            this.router.navigateByUrl("/login/android")
        }

        return true
    }
}
