import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-calendar-icon",
    template: `
        <svg
            [ngStyle]="{
                'width': width + 'px',
                'height': height + 'px',
                'stroke': color,
                'marginTop': marginTop + 'px'
            }"
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 30 30"
            [ngClass]="{ 'hovered': isHovered }"
            (mouseenter)="onMouseEnter()"
            (mouseleave)="onMouseLeave()">
            <defs>
                <style type="text/css">
                    .cls-1 {
                        fill: none;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 0.73px;
                    }
                </style>
            </defs>
            <rect class="cls-1" x=".77" y="3.37" width="28.47" height="25.55" rx="3.28" ry="3.28" />
            <line class="cls-1" x1=".77" y1="7.96" x2="29.23" y2="7.96" />
            <g>
                <g>
                    <line class="cls-1" x1="4.88" y1="12.06" x2="9.05" y2="12.06" />
                    <line class="cls-1" x1="4.88" y1="15.89" x2="9.05" y2="15.89" />
                    <line class="cls-1" x1="4.88" y1="19.72" x2="9.05" y2="19.72" />
                    <line class="cls-1" x1="4.88" y1="23.54" x2="9.05" y2="23.54" />
                </g>
                <g>
                    <line class="cls-1" x1="12.92" y1="12.06" x2="17.08" y2="12.06" />
                    <line class="cls-1" x1="12.92" y1="15.89" x2="17.08" y2="15.89" />
                    <line class="cls-1" x1="12.92" y1="19.72" x2="17.08" y2="19.72" />
                    <line class="cls-1" x1="12.92" y1="23.54" x2="17.08" y2="23.54" />
                </g>
                <g>
                    <line class="cls-1" x1="20.95" y1="12.06" x2="25.12" y2="12.06" />
                    <line class="cls-1" x1="20.95" y1="15.89" x2="25.12" y2="15.89" />
                    <line class="cls-1" x1="20.95" y1="19.72" x2="25.12" y2="19.72" />
                    <line class="cls-1" x1="20.95" y1="23.54" x2="25.12" y2="23.54" />
                </g>
            </g>
            <g>
                <line class="cls-1" x1="8.14" y1="1.08" x2="8.14" y2="5.46" />
                <line class="cls-1" x1="21.86" y1="1.08" x2="21.86" y2="5.46" />
            </g>
        </svg>
    `,

    styles: [
        `
            .hovered .cls-1 {
                stroke: #307aff;
            }
        `,
    ],
})
export class CalendarIconComponent {
    @Input() width: number = 40
    @Input() height: number = 40
    @Input() color: string = "grey"
    public isHovered: boolean = false
    @Input() marginTop: number = 0

    onMouseEnter(): void {
        this.isHovered = true
    }

    onMouseLeave(): void {
        this.isHovered = false
    }
}
