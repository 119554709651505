import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { State, StateSerializer } from "./state"
import { JsonObject } from "../_core/types"

export class Country implements ApiResource {
    public static DEFAULT_COUNTRY_ID = 234
    public static DEFAULT_NATIONALITY_ID = 234

    id = 0
    code: string
    name: string
    nationalityName: string
    states: State[]
}

@Injectable({
    providedIn: "root",
})
export class CountrySerializer implements ApiResourceSerializer {
    constructor(private stateSerializer: StateSerializer) {}

    fromJson(json: JsonObject): Country {
        const country = new Country()
        country.id = json.id
        country.name = json.name
        country.nationalityName = json.nationality_name
        country.code = json.code
        country.states = json.states !== undefined ? json.states.map(item => this.stateSerializer.fromJson(item)) : null

        return country
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(country: Country): any {
        return {
            id: country.id,
            name: country.name,
            nationality_name: country.nationalityName,
            code: country.code,
            states: country.states !== undefined ? country.states.map(item => this.stateSerializer.toJson(item)) : null,
        }
    }
}
