import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { Country, CountrySerializer } from "@app/_models/country"

export class PartnerLocation {
    id = 0
    createdAt: Date
    updatedAt: Date

    // Legacy variables
    address1: string
    address2: string
    address3: string
    houseNameNumber: string

    // Newly added variables
    addressLine1: string
    addressLine2: string
    addressLine3: string
    houseName: string
    houseNumber: string
    streetName: string

    // Variables
    city: string
    postcode: string
    countryId: number = null
    country: Country
    types: string[]

    actionDelete: boolean
}

@Injectable({
    providedIn: "root",
})
export class PartnerLocationSerializer implements ApiResourceSerializer {
    constructor(private countrySerializer: CountrySerializer) {}

    fromJson(json: JsonObject): PartnerLocation {
        const location = new PartnerLocation()
        location.id = json.id
        location.createdAt = new Date(json.created_at)
        location.updatedAt = new Date(json.updated_at)

        // Legacy variables
        location.address1 = json.address_1
        location.address2 = json.address_2
        location.address3 = json.address_3
        location.houseNameNumber = json.house_name_number

        // Newly added variables
        location.addressLine1 = json.address_line_1
        location.addressLine2 = json.address_line_2
        location.addressLine3 = json.address_line_3
        location.houseName = json.house_name
        location.houseNumber = json.house_number
        location.streetName = json.street_name

        // Variables
        location.city = json.city
        location.postcode = json.postcode
        location.countryId = json.country_id
        location.country = json.country ? this.countrySerializer.fromJson(json.country) : null

        location.types = json.types

        return location
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(location: PartnerLocation): any {
        return {
            id: location.id,

            // Legacy variables
            address_1: location.address1,
            address_2: location.address2,
            address_3: location.address3,
            house_name_number: location.houseNameNumber,

            // Newly added variables
            address_line_1: location.addressLine1,
            address_line_2: location.addressLine2,
            address_line_3: location.addressLine3,
            house_name: location.houseName,
            house_number: location.houseNumber,
            street_name: location.streetName,

            // Variables
            city: location.city,
            postcode: location.postcode,
            country_id: location.countryId,
            country: location.country ? this.countrySerializer.toJson(location.country) : null,

            types: location.types,
        }
    }
}
