/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { JsonObject } from "../_core/types"
import { Currency, CurrencySerializer } from "@app/_models/currency"
import { NgxCurrencyInputMode } from "ngx-currency"
import VerofyHelper from "@app/_helpers/verofy.helper"

export class AuthUserStorage implements ApiResource {
    id: number
    accessToken?: string
    refreshToken?: string
    permissions: Array<number>
    previousLoginAt: Date
    sellerId: number
    currency: Currency
    name: string
    twoFactorAuthRequestId: number
    req2FaMethod: string
    requires2Fa: boolean
    partnersIds: Array<number>
    defaultPartnerId: number
    branding: {
        bgColor: string
        fontColor: string
        actionColor: string
        appAlpha: string
        appBeta: string
        appGamma: string
        appDelta: string
        appIcon: string
        logoUrl: string
        appLogoUrl: string
    }
    isInAdminGroup: boolean
    /**
     * Checks currently logged in record permission.
     */
    hasPermission(permission: number): boolean {
        return this.permissions.indexOf(permission) > -1
    }

    hasAnyOfPermissions(permissions: number[]): boolean {
        let hasAnyPermission = false
        permissions.forEach(permission => {
            if (this.permissions.indexOf(permission) > -1) {
                hasAnyPermission = true
            }
        })

        return hasAnyPermission
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCurrencyMaskConfig(options?: any) {
        const config = {
            align: "left",
            prefix: this.currency?.symbol ?? "£",
            inputMode: NgxCurrencyInputMode.Financial,
            allowNegative: false,
            precision: 2,
            nullable: true,
            thousands: ",",
            decimal: ".",
            min: null,
            max: null,
        }

        if (!options) {
            return config
        }

        // Apply custom options.
        for (const option in options) {
            if (Object.prototype.hasOwnProperty.call(options, option)) {
                config[option] = options[option]
            }
        }

        return config
    }

    formatCurrency(value: number): string {
        if (isNaN(value) || value === null || value === undefined) value = 0
        return VerofyHelper.formatCurrency(isNaN(value) ? 0 : value, this.currency?.code ?? "GBP")
    }
}

@Injectable({
    providedIn: "root",
})
export class AuthUserStorageSerializer implements ApiResourceSerializer {
    constructor(private currencySerializer: CurrencySerializer) {}

    fromJson(json: JsonObject): AuthUserStorage {
        if (json === null) return null
        const record = new AuthUserStorage()
        record.id = json.seller_id // json.id
        record.accessToken = json.access_token
        record.refreshToken = json.refresh_token
        record.previousLoginAt = json.previous_login_at
        record.name = json.name
        record.permissions = json.permissions
        record.currency = json.currency ? this.currencySerializer.fromJson(json.currency) : null
        record.twoFactorAuthRequestId = json.two_factor_auth_request_id
        record.sellerId = json.seller_id
        record.requires2Fa = json.requires_2fa
        record.req2FaMethod = json.req_2fa_method
        record.partnersIds = json.partners_ids
        record.defaultPartnerId = json.default_partner_id
        record.isInAdminGroup = json.is_in_admin_group
        record.branding = json.branding
            ? {
                  bgColor: json.branding?.bg_color,
                  fontColor: json.branding?.font_color,
                  actionColor: json.branding?.action_color,
                  appAlpha: json.branding?.app_alpha,
                  appBeta: json.branding?.app_beta,
                  appGamma: json.branding?.app_gamma,
                  appDelta: json.branding?.app_delta,
                  appIcon: json.branding?.app_icon,
                  logoUrl: json.branding?.logo_url,
                  appLogoUrl: json.branding?.app_logo_url,
              }
            : null

        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: AuthUserStorage): any {
        if (record === null) return null
        return {
            id: record.id,
            access_token: record.accessToken,
            refresh_token: record.refreshToken,
            previous_login_at: record.previousLoginAt,
            name: record.name,
            permissions: record.permissions,
            currency: record.currency ? this.currencySerializer.toJson(record.currency) : null,
            two_factor_auth_request_id: record.twoFactorAuthRequestId,
            seller_id: record.sellerId,
            req_2fa_method: record.req2FaMethod,
            requires_2fa: record.requires2Fa,
            partners_ids: record.partnersIds,
            default_partner_id: record.defaultPartnerId,
            branding: record.branding
                ? {
                      bg_color: record.branding?.bgColor,
                      font_color: record.branding?.fontColor,
                      action_color: record.branding?.actionColor,
                      app_alpha: record.branding?.appAlpha,
                      app_beta: record.branding?.appBeta,
                      app_gamma: record.branding?.appGamma,
                      app_delta: record.branding?.appDelta,
                      app_icon: record.branding?.appIcon,
                      logo_url: record.branding?.logoUrl,
                      app_logo_url: record.branding?.appLogoUrl,
                  }
                : null,
            is_in_admin_group: record.isInAdminGroup,
        }
    }
}
