<section  (mouseenter)="showHoverText = true"
          (mouseleave)="showHoverText = false"
          (click)="handleCopy()"
          class="copyTooltip">
    <span>
        <svg-copy-icon [class.clicked]="isClicked"
                       [isClicked]="isClicked" />
    </span>
  <span class="tooltip-text" [class.visible]="showHoverText">{{ hoverText }}</span>
</section>
