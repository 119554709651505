import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CRFormCalculator {
    id: number
    atv: string
    creditPercentage: string
    debitPercentage: string
}

@Injectable({
    providedIn: "root",
})
export class CRFormCalculatorSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): CRFormCalculator {
        const record = new CRFormCalculator()
        record.id = json.id
        record.atv = json.atv
        record.creditPercentage = json.credit_percentage
        record.debitPercentage = json.debit_percentage
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRFormCalculator): any {
        return {
            id: record.id,
            atv: record.atv,
            credit_percentage: record.creditPercentage,
            debit_percentage: record.debitPercentage,
        }
    }
}
