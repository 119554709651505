<div #requestProgressElement *ngIf="showRequestInProgress" [ngClass]="['httpRequestWrapper',showElement ? 'show' : '', hideElement ? 'hide' : '']">
    <div>
        <div class="httpRequestBody">
            <div class="tortoise">
                <img src="assets/img/tortoise.gif"/>
            </div>
            <p>please have some patience, we are loading the data</p>
        </div>
    </div>
</div>
