import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core"
import { AuthenticationService } from "../../../_services/authentication.service"
import { AlertService } from "../../../_services/alert.service"
import { NavigationEnd, Router } from "@angular/router"
import { AppError } from "../../../_core/app-error"
import { PartnersApiService } from "../../../_services/api/partners.api.service"
import { AppService, Breadcrumbs } from "../../../_services/app.service"
import { SellerPermission } from "../../../_models/seller-permission"
import { AuthUserStorage } from "@app/_models/auth-user-storage"
import { take, takeUntil } from "rxjs/operators"
import { NotificationApiService } from "../../../_services/api/notification.api.service"
import { Observable, Subject } from "rxjs"
import { Notification } from "./../../../_models/notification"
import { Store } from "@ngrx/store"
import { AppState } from "@app/store"
import { SetHideAppLoadingGlobal, SetShowAppLoadingGlobal } from "@app/store/actions/AppLoadingActions"
import { SetHeaderInitialize, SetHeaderLogout } from "@app/store/actions/AppHeaderActions"
import { SetPageLoadingFrom } from "@app/store/actions/AppPageActions"
import { PageLoadingFrom } from "@app/store/reducers/AppPageReducer"
import { Inbox } from "@app/_models/inbox"
import { CustomerCloudMessagesApiService } from "@app/_services/api/customer-cloud-messages-api.service"
import { CurrentSwitchablePartnersService } from "@app/_services/current-switchable-partners.service"
import { CRProductsAndServicesService } from "@app/_services/cr-product-and-services.service"
import { HeaderTimerComponent } from "../header-timer/header-timer.component"
import { LocalStorageService } from "@app/_services/local-storage.service"
import { SwitchablePartner } from "@app/_models/switchable-partner"
import { PartnerStorage } from "@app/_models/partner-storage"
import { AuthUserSignal } from "@app/signals/customerOnboarding/AuthUserSignal"

@Component({
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"],
    selector: "app-header",
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild(HeaderTimerComponent) headerTimerComponent: HeaderTimerComponent

    appLoadingGlobal: boolean = true
    isGlobalLoaderOn: boolean = true
    appLoadingGlobalAnimation: string = "animationFadeIn"
    appLoadingTimer = null
    appLoadingHeader: Observable<boolean> = this.store.select(state => state.loading.loadingHeader)
    initializeHeader: Observable<boolean> = this.store.select(state => state.header.initializeHeader)

    logoutLoading: string = ""

    title: string
    breadcrumbs: Breadcrumbs[]
    notifications: Notification[]
    notClosedNotifications: number
    // currentCustomer: Customer
    inbox: Inbox

    currentUser: AuthUserStorage
    partnerId: number
    sellerId: number

    currentPartner: PartnerStorage
    currentPartnerId: number
    switchablePartners: SwitchablePartner[]
    firstLetter: string = "N/A"
    autoLogout

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Math: any

    isCurrentUserCollapsed: boolean = false
    isNotificationAreaCollapsed: boolean = false
    isPartnerMainCollapsed: boolean = false
    isNotificationPopUpCollapsed: boolean = false

    isLoginPage: boolean = false
    isHomepage: boolean = false
    navigationEndUrl: string

    @ViewChild("dropdownMain") dropdownMain
    @ViewChild("dropdownNotification") dropdownNotification
    @ViewChild("dropdownPartnerMain") dropdownPartnerMain
    @ViewChild("dropdownNotificationPopup") dropdownNotificationPopup
    listenerFn: () => void
    renderer: Renderer2

    SellerPermission = SellerPermission
    private unsubscribe$ = new Subject()

    unauthenticPages: string[] = ["/login", "/pin-reset", "/users/account-activation", "/login/android", "/login/ios"]

    constructor(
        private store: Store<AppState>,
        private appService: AppService,
        private authService: AuthenticationService,
        public router: Router,
        private notificationApiService: NotificationApiService,
        private customerCloudMessagesApiService: CustomerCloudMessagesApiService,
        private alertService: AlertService,
        private partnersApiService: PartnersApiService,
        private currentSwitchablePartnersService: CurrentSwitchablePartnersService,
        private cRProductsAndServicesService: CRProductsAndServicesService,
        private localStorageService: LocalStorageService,
        renderer: Renderer2,
        private cdr: ChangeDetectorRef,
    ) {
        this.Math = Math
        this.renderer = renderer
        this.navigationEndUrl = this.router.url

        this.store.dispatch(SetShowAppLoadingGlobal())

        this.currentSwitchablePartnersService.currentSwitchablePartners
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(switchablePartners => {
                this.switchablePartners = switchablePartners
            })

        this.authService.partnerPortalDataset.pipe(takeUntil(this.unsubscribe$)).subscribe(partnerPortalDataset => {
            if (this.authService.isAuthenticated()) {
                if (partnerPortalDataset) {
                    this.currentUser = partnerPortalDataset.authUser
                    this.currentPartnerId = partnerPortalDataset.partnerId
                    this.currentPartner = partnerPortalDataset.partnerStorage

                    if (this.currentUser) this.sellerId = this.currentUser.sellerId

                    if (this.sellerId && this.currentPartnerId) {
                        this.loadInbox()
                        this.loadNotifications()
                    }

                    this.store.dispatch(SetHideAppLoadingGlobal())
                    this.store.dispatch(SetHeaderInitialize())
                    this.generateDynamicStyle()
                }
            }
            AuthUserSignal.set(partnerPortalDataset)
        })
        console.log("user is +++++++++++++", this.currentUser)

        router.events.subscribe(() => {
            if (this.headerTimerComponent) this.headerTimerComponent.resetCountDown()
        })

        // Update after route
        router.events.subscribe(val => {
            if (this.headerTimerComponent) this.headerTimerComponent.resetCountDown()
            if (val instanceof NavigationEnd) {
                const url = val.url.split("?")[0]
                this.navigationEndUrl = url
                if (this.unauthenticPages.indexOf(url) > -1) {
                    this.store.dispatch(SetHideAppLoadingGlobal())
                    this.store.dispatch(SetHeaderInitialize())
                    this.isLoginPage = true
                } else {
                    this.isLoginPage = false
                }

                if (url === "/" || url === "") {
                    this.isHomepage = true
                } else {
                    this.isHomepage = false
                }

                if (
                    this.authService.isAuthenticated() &&
                    !this.urlContains("customer-registration") &&
                    !this.urlContains("customer-quote") &&
                    this.currentPartnerId
                ) {
                    this.cRProductsAndServicesService.regenerateCurrentProductsAndServices(this.currentPartnerId)
                }
            }
        })
    }

    isCurrentUserAdmin(): boolean {
        return this.currentUser && this.currentUser.isInAdminGroup
    }

    ngOnInit(): void {
        this.store
            .select(state => state.loading.loadingGlobal)
            .subscribe(loadingGlobal => {
                if (this.appLoadingTimer) {
                    clearTimeout(this.appLoadingTimer)
                }
                if (loadingGlobal) {
                    this.appLoadingGlobal = true
                    this.isGlobalLoaderOn = true
                    this.appLoadingGlobalAnimation = "animationFadeIn"
                    this.cdr.detectChanges()
                } else {
                    this.appLoadingGlobal = false
                    this.appLoadingGlobalAnimation = "animationFadeOut"
                    this.appLoadingTimer = setTimeout(() => {
                        this.isGlobalLoaderOn = false
                        this.cdr.detectChanges()
                    }, 1500)
                    this.cdr.detectChanges()
                }
            })

        this.store
            .select(state => state.header.logout)
            .subscribe(isLogout => {
                if (isLogout) {
                    this.logoutLoading = "logout"
                } else {
                    this.logoutLoading = ""
                }
            })

        // Subscribe to the APP title defined by each component
        this.appService.getTitle().subscribe(appTitle => {
            this.title = appTitle
            this.cdr.detectChanges()
        })

        this.appService.getBreadcrumbs().subscribe(breadcrumbs => {
            this.breadcrumbs = breadcrumbs
            this.cdr.detectChanges()
        })

        this.store
            .select(state => state.header.initializeHeader)
            .subscribe(() => {
                this.cdr.detectChanges()
            })

        if (this.headerTimerComponent) this.headerTimerComponent.resetCountDown()
    }

    urlContains(value: string): boolean {
        if (this.navigationEndUrl !== undefined) {
            return this.navigationEndUrl.includes(value)
        }
        return false
    }

    isUrlActive(segment: string): boolean {
        if (segment === "users") {
            if (
                this.navigationEndUrl.includes("/settings") &&
                !this.navigationEndUrl.includes("/settings/user-groups") &&
                !this.navigationEndUrl.includes("/settings/login-history") &&
                !this.navigationEndUrl.includes("/settings/alerts") &&
                !this.navigationEndUrl.includes("/settings/notifications")
            ) {
                return true
            }
        }
        if (segment === "user-groups") {
            if (this.navigationEndUrl.includes("/settings/user-groups")) {
                return true
            }
        }
        return false
    }

    loadInbox(): void {
        this.customerCloudMessagesApiService
            .inbox(this.sellerId, { "partner_id": this.currentPartnerId.toString() })
            .subscribe({
                next: (payload: Inbox) => {
                    this.inbox = payload
                },
                error: (error: AppError) => {
                    this.alertService.userError(error, "We're sorry but an unexpected error occurred!", false, true)
                    throw error
                },
                complete: () => {
                    console.log("HTTP request completed - Inbox")
                },
            })
    }

    loadNotifications(): void {
        this.notificationApiService
            .list(this.sellerId, { "partner_id": this.currentPartnerId.toString() })
            .pipe(take(1))
            .subscribe({
                next: payload => {
                    this.notifications = payload
                    this.notClosedNotifications = this.notifications.filter(item => item.closed !== 1).length
                },
                error: error => {
                    this.alertService.userError(
                        error,
                        "We're sorry but customer notifications were not found!",
                        false,
                        true,
                    )
                    throw error
                },
                complete: () => {
                    console.log("HTTP request completed - Partner notifications")
                },
            })
    }

    setNotificationClosed(notificationId: number): void {
        this.notificationApiService
            .setClosed(
                this.sellerId,
                {
                    "notification_ids": notificationId.toString(),
                },
                this.currentPartnerId,
            )
            .pipe(take(1))
            .subscribe({
                next: payload => {
                    this.notifications = payload
                    this.notClosedNotifications = this.notifications.filter(item => item.closed !== 1).length
                },
                error: error => {
                    this.alertService.userError(
                        error,
                        "We're sorry but setting customer notification as closed failed!",
                        false,
                        true,
                    )
                    throw error
                },
                complete: () => {
                    console.log("HTTP request completed - Customer notification is being closed!")
                },
            })
    }

    setAllNotificationsClosed(): void {
        const notificationIds = this.notifications.map(({ id }) => id)
        this.notificationApiService
            .setClosed(
                this.sellerId,
                {
                    "notification_ids": notificationIds.toString(),
                },
                this.currentPartnerId,
            )
            .pipe(take(1))
            .subscribe({
                next: payload => {
                    this.notifications = payload
                    this.notClosedNotifications = this.notifications.filter(item => item.closed !== 1).length
                },
                error: error => {
                    this.alertService.userError(
                        error,
                        "We're sorry but setting customer notifications as closed failed!",
                        false,
                        true,
                    )
                    throw error
                },
                complete: () => {
                    console.log("HTTP request completed - Customer notifications are being closed!")
                    this.isNotificationAreaCollapsed = !this.isNotificationAreaCollapsed
                },
            })
    }

    getFirstLetter(value: string): string {
        return value.charAt(0).toUpperCase()
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(v => console.log(v))
        this.unsubscribe$.complete()
    }

    closeAllToggles(): void {
        this.isPartnerMainCollapsed = false
        this.isNotificationAreaCollapsed = false
        this.isCurrentUserCollapsed = false
    }

    switchPartner(partnerId: number): void {
        console.log("Switching partners ...")
        this.store.dispatch(SetShowAppLoadingGlobal("Switching partners"))
        this.closeAllToggles()
        this.partnersApiService
            .setDefaultPartner(+partnerId)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.isGlobalLoaderOn = true
                    this.beforePartnerSwitch()
                    this.authService.switchPartner(+partnerId, ["/"])
                },
                error: (error: AppError) => {
                    this.alertService.userError(error, "We're sorry but we're unable to switch partners.", false, true)
                    throw error
                },
            })
    }

    beforePartnerSwitch(): void {
        this.localStorageService.removeSpecificData([
            "cardRatesSets",
            "standardFees",
            "crProductsAndServices",
            "customerRegistration",
            "customerRegistrationHash",
        ])
        if (this.routeContains("customer-registration")) this.router.navigate(["/customers"])
        if (this.routeContains("customer-quote")) this.router.navigate(["/customers"])
    }

    checkPermission(permissionCode: number): boolean {
        return this.currentUser.permissions.indexOf(permissionCode) > -1 ? true : false
    }

    onRouterClick(event: MouseEvent, action: string): void {
        event.preventDefault()
        if (action === "settings") {
            if (this.checkPermission(SellerPermission.ACCESS_USERS)) {
                this.router.navigate(["/settings"])
            } else {
                this.router.navigate(["/notifications"])
            }
        }
    }

    onLogout(): void {
        this.store.dispatch(SetShowAppLoadingGlobal("Logging you out"))
        this.store.dispatch(SetHeaderLogout({ status: true }))
        this.isLoginPage = true
        setTimeout(() => {
            this.authService.logout({
                returnUrl: document.location.pathname,
                preserveDeviceUniqueId: false,
                timerLogout: false,
            })
        }, 1000)
    }

    setLoadingFromHeader(): void {
        console.log("setLoadingFromHeader")
        this.store.dispatch(SetPageLoadingFrom({ loadingFrom: PageLoadingFrom.header }))
    }

    getInitials(): string {
        return Array.from(this.currentUser.name)[0]?.toUpperCase()
    }

    toggle(event: MouseEvent, area: string): void {
        event.preventDefault()
        event.stopPropagation()

        if (area === "notificationArea") {
            this.isNotificationAreaCollapsed = !this.isNotificationAreaCollapsed
            this.listenerFn = this.renderer.listen("document", "click", evt => {
                if (this.dropdownNotification !== undefined) {
                    const notificationDropdown = this.dropdownNotification.nativeElement.contains(evt.target)
                    if (this.isNotificationAreaCollapsed && !notificationDropdown) {
                        this.isNotificationAreaCollapsed = !this.isNotificationAreaCollapsed
                        // Remove listener event
                        this.listenerFn()
                    }
                }
            })
        }
        if (area === "partnerMain") {
            this.isPartnerMainCollapsed = !this.isPartnerMainCollapsed

            this.listenerFn = this.renderer.listen("document", "click", evt => {
                if (this.dropdownPartnerMain !== undefined) {
                    const notificationDropdown = this.dropdownPartnerMain.nativeElement.contains(evt.target)
                    if (this.isPartnerMainCollapsed && !notificationDropdown) {
                        this.isPartnerMainCollapsed = !this.isPartnerMainCollapsed
                        // Remove listener event
                        this.listenerFn()
                    }
                }
            })
        }
        if (area === "notificationPopUp") {
            this.isNotificationPopUpCollapsed = !this.isNotificationPopUpCollapsed

            this.listenerFn = this.renderer.listen("document", "click", evt => {
                if (this.dropdownNotificationPopup !== undefined) {
                    const notificationDropdown = this.dropdownNotificationPopup.nativeElement.contains(evt.target)
                    if (this.isNotificationPopUpCollapsed && !notificationDropdown) {
                        this.isNotificationPopUpCollapsed = !this.isNotificationPopUpCollapsed
                        // Remove listener event
                        this.listenerFn()
                    }
                }
            })
        }
        if (area === "currentUser") {
            this.listenerFn = this.renderer.listen("document", "click", evt => {
                if (this.dropdownMain !== undefined) {
                    const userDropdown = this.dropdownMain.nativeElement.contains(evt.target)
                    if (this.isCurrentUserCollapsed && !userDropdown) {
                        this.isCurrentUserCollapsed = !this.isCurrentUserCollapsed
                        // Remove listener event
                        this.listenerFn()
                    }
                }
            })

            this.isCurrentUserCollapsed = !this.isCurrentUserCollapsed
        }
    }

    generateDynamicStyle(): void {
        const styleContainer = document.createElement("div")
        styleContainer.id = "dynamicHeaderStyle"
        const styleElement = document.createElement("style")
        const fontColor = this.currentUser.branding.fontColor ? this.currentUser.branding.fontColor : "#ffffff"
        const hoverColor = this.currentUser.branding.actionColor ? this.currentUser.branding.actionColor : "#307aff"

        const style = `
            .navbar.navbar-light .container{
                color: ${fontColor};
            }
            .navbar.navbar-light .container a{
                color: ${fontColor};
            }
            .navbar.navbar-light .container a:hover{
                color: ${hoverColor};
            }
            .navbar.navbar-light .container a.active{
                color: ${hoverColor};
            }
            .navbar.navbar-light .container a.active:before{
                background: ${hoverColor};
            }
            .navbar.navbar-light .container .right-logo ul li a, .navbar.navbar-light .container .right-logo ul li span{
                color: ${fontColor};
            }
            .navbar.navbar-light .container .right-logo ul li a:hover svg, .navbar.navbar-light .container .right-logo ul li span:hover svg{
                color: ${hoverColor};
            }
        `
        styleElement.appendChild(document.createTextNode(style))
        styleContainer.appendChild(styleElement)
        document.body.append(styleContainer)
    }

    routeContains(string: string): boolean {
        if (this.router.url.indexOf(string) > -1) {
            return true
        }
        return false
    }
}
