import { Component, Input } from "@angular/core"

@Component({
    selector: "app-copy-clipboard",
    templateUrl: "./copy-clipboard.component.html",
})
export class CopyClipboardComponent {
    @Input({ required: true }) referenceId: string
    hoverText: string = "Copy"
    showHoverText: boolean = false
    isClicked: boolean = false

    handleCopy(): void {
        this.hoverText = "Copied"
        this.showHoverText = true
        this.isClicked = true
        navigator.clipboard
            .writeText(this.referenceId)
            .then(() => {
                this.readClipboard()
            })
            .catch(error => {
                console.error("Error writing to clipboard:", error)
            })

        setTimeout(() => {
            this.showHoverText = false
            this.hoverText = "Copy"
            this.isClicked = false
        }, 3000) // Adjust the duration as needed
    }
    readClipboard(): void {
        navigator.clipboard
            .readText()
            .then(clipText => {
                this.referenceId = clipText || "N/A"
            })
            .catch(error => {
                console.error("Error reading clipboard:", error)
            })
    }
}
