import { CRProductPack, CRProductPackSerializer } from "./cr-product-pack"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRProductsServices, CRProductsServicesSerializer } from "./cr-products-services"
import { CustomerStandardFee, CustomerStandardFeeSerializer } from "@appModels/customer-standard-fee"

export class CRProductsAndPacks {
    id: number = 0
    localTimestamp: Date = new Date()
    products: CRProductsServices[]
    productPacks: CRProductPack[]
    standardFees: CustomerStandardFee[]
}

@Injectable({
    providedIn: "root",
})
export class CRProductsAndPacksSerializer implements ApiResourceSerializer {
    constructor(
        private cRProductPacksSerializer: CRProductPackSerializer,
        private cRProductsServicesSerializer: CRProductsServicesSerializer,
        private customerStandardFeeSerializer: CustomerStandardFeeSerializer,
    ) {}

    fromJson(json: JsonObject): CRProductsAndPacks {
        const record = new CRProductsAndPacks()
        record.id = json.id
        record.localTimestamp = json.local_timestamp ? new Date(json.local_timestamp) : new Date()
        record.products = json.products
            ? json.products.map(item => this.cRProductsServicesSerializer.fromJson(item))
            : []
        record.productPacks = json.product_packs
            ? json.product_packs.map(item => this.cRProductPacksSerializer.fromJson(item))
            : []
        record.standardFees = json.standard_fees
            ? json.standard_fees.map(item => this.customerStandardFeeSerializer.fromJson(item))
            : []
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRProductsAndPacks): any {
        return {
            id: record.id,
            local_timestamp: record.localTimestamp ? record.localTimestamp.toISOString() : new Date().toISOString(),
            products: record.products
                ? record.products.map(item => this.cRProductsServicesSerializer.toJson(item))
                : null,
            product_packs: record.productPacks
                ? record.productPacks.map(item => this.cRProductPacksSerializer.toJson(item))
                : null,
            standard_fees: record.productPacks
                ? record.standardFees.map(item => this.customerStandardFeeSerializer.toJson(item))
                : null,
        }
    }
}
