import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class State {
    id = 0
    code: string
    name: string
}

@Injectable({
    providedIn: "root",
})
export class StateSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): State {
        const state = new State()
        state.id = json.id
        state.name = json.name
        state.code = json.code

        return state
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(state: State): any {
        return {
            id: state.id,
            name: state.name,
        }
    }
}
