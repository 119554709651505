import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { PartnerLocation, PartnerLocationSerializer } from "./partner-location"
import { Seller } from "./seller"
import { SystemFile, SystemFileSerializer } from "./file"
import { Language, LanguageSerializer } from "./language"
import { Currency, CurrencySerializer } from "./currency"
import { JsonObject } from "../_core/types"
import { PartnerMid } from "./partner-mid"

export class Partner implements ApiResource {
    public static COMPANY_TYPE_SALES_COMPANY = 1
    public static COMPANY_TYPE_DEVELOPMENT_COMPANY = 2
    public static COMPANY_TYPE_DEVELOPER = 3
    public static COMPANY_TYPE_OTHER = 99

    id: number
    createdAt: Date
    updatedAt: Date
    deletedAt: Date
    name: string
    slug: string
    companyType: string
    companyName: string
    registrationNumber: string
    vatNumber: string
    accountName: string
    sortCode: string
    accountNumber: string
    iban: string
    havingIban: boolean
    bankName: string
    contactTitle: string
    contactFirstName: string
    contactLastName: string
    contactEmail: string
    contactPhone: string
    contactWebsite: string
    contactDob: Date
    contactGender: string
    signUpEmail: string
    resetPasswordEmail: string
    commissionsPercentageMonthly: string
    commissionsPercentageOneOff: string
    status: number
    logo: SystemFile
    registrationHash: string
    registeredNumber: string

    signUpEmailText: string // helper for a texteditor
    resetPasswordEmailText: string // helper for a texteditor

    languageId: number = null
    language: Language
    currencyId: number = null
    currency: Currency
    locations: PartnerLocation[]

    sellers: Seller[]
    mids: PartnerMid[]
}

@Injectable({
    providedIn: "root",
})
export class PartnerSerializer implements ApiResourceSerializer {
    constructor(
        private partnerLocationSerializer: PartnerLocationSerializer,
        private systemFileSerializer: SystemFileSerializer,
        private languageSerializer: LanguageSerializer,
        private currencySerializer: CurrencySerializer,
    ) {}

    fromJson(json: JsonObject): Partner {
        const record = new Partner()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.deletedAt = new Date(json.deleted_at)
        record.name = json.name
        record.slug = json.slug
        record.companyType = json.company_type
        record.companyName = json.company_name
        record.registrationNumber = json.registration_number
        record.vatNumber = json.vat_number
        record.accountName = json.account_name
        record.sortCode = json.sort_code
        record.accountNumber = json.account_number
        record.iban = json.iban
        record.havingIban = json.iban !== null
        record.bankName = json.bank_name
        record.contactTitle = json.contact_title
        record.contactFirstName = json.contact_first_name
        record.contactLastName = json.contact_last_name
        record.contactEmail = json.contact_email
        record.contactPhone = json.contact_phone
        record.contactWebsite = json.contact_website
        record.contactDob =
            json.contact_dob !== undefined && json.contact_dob !== null ? new Date(json.contact_dob) : null
        record.contactGender = json.contact_gender
        record.commissionsPercentageMonthly = json.commissions_percentage_monthly
        record.commissionsPercentageOneOff = json.commissions_percentage_one_off
        record.status = json.status
        record.logo =
            json.logo !== undefined && json.logo !== null ? this.systemFileSerializer.fromJson(json.logo) : null
        record.registrationHash = json.registration_hash

        record.languageId = json.language !== undefined && json.language !== null ? json.language.id : null
        record.language =
            json.language !== undefined && json.language !== null
                ? this.languageSerializer.fromJson(json.language)
                : null

        record.currencyId = json.currency !== undefined && json.currency !== null ? json.currency.id : null
        record.currency =
            json.currency !== undefined && json.currency !== null
                ? this.currencySerializer.fromJson(json.currency)
                : null

        record.locations =
            json.locations !== undefined
                ? json.locations.map(item => this.partnerLocationSerializer.fromJson(item))
                : []

        record.signUpEmail = json.sign_up_email
        record.signUpEmailText = this.br2nl(json.sign_up_email)

        record.resetPasswordEmail = json.reset_password_email
        record.resetPasswordEmailText = this.br2nl(json.reset_password_email)

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: Partner): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            deleted_at: record.deletedAt,
            name: record.name,
            slug: record.slug,
            company_type: record.companyType,
            company_name: record.companyName,
            registration_number: record.registrationNumber,
            vat_number: record.vatNumber,
            account_name: record.accountName,
            sort_code: record.sortCode,
            account_number: record.accountNumber,
            iban: record.iban,
            bank_name: record.bankName,
            contact_title: record.contactTitle,
            contact_first_name: record.contactFirstName,
            contact_last_name: record.contactLastName,
            contact_email: record.contactEmail,
            contact_phone: record.contactPhone,
            contact_website: record.contactWebsite,
            contact_dob: record.contactDob,
            contact_gender: record.contactGender,
            commissions_percentage_monthly: record.commissionsPercentageMonthly,
            commissions_percentage_one_off: record.commissionsPercentageOneOff,
            status: record.status,
            logo:
                record.logo !== undefined && record.logo !== null
                    ? this.systemFileSerializer.toJson(record.logo)
                    : null,

            registration_hash: record.registrationHash,

            language:
                record.language !== undefined && record.language !== null
                    ? this.languageSerializer.toJson(record.language)
                    : null,

            currency:
                record.currency !== undefined && record.currency !== null
                    ? this.currencySerializer.toJson(record.currency)
                    : null,

            locations: record.locations
                ? record.locations.map(item => this.partnerLocationSerializer.toJson(item))
                : [],

            language_id: record.languageId,
            currency_id: record.currencyId,

            sign_up_email: this.nl2br(record.signUpEmailText, false),
            reset_password_email: this.nl2br(record.resetPasswordEmailText, false),
        }
    }

    br2nl(str: string): string {
        if (typeof str === "undefined" || str === null) {
            return ""
        }
        return str.replace(/<br\s*\/?>/gm, "\n")
    }

    nl2br(str: string, is_xhtml: boolean | undefined): string {
        if (typeof str === "undefined" || str === null) {
            return ""
        }
        const breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>"
        return (str + "").replace(/(?:\r\n|\r|\n)/g, breakTag)
    }
}
