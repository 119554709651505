/**
 * Helper functionality
 *
 */
import { PhoneNumberUtil } from "google-libphonenumber"
import { AssociativeArray } from "../_core/types"
import { phoneNumberObjectInterface } from "@app/_interfaces/phoneNumberObject"

export default class VerofyHelper {
    /**
     * Generate unique ID
     *
     */
    static guid(): string {
        const s4 = (): string =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
    }

    /**
     * Format currency value by currency char - GBP
     */
    static formatCurrency(value: number, currency: string, fixed: number = 2): string {
        if (value === null) {
            return "-"
        }

        let minus = 0
        if (value < 0) {
            value = value * -1
            minus = 1
        }

        value = parseFloat(String(value))
        const valueFormatted = value.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

        if (currency === "GBP") {
            return (minus ? "-" : "") + "£" + valueFormatted
        } else if (currency === "GBP_pence") {
            return (minus ? "-" : "") + valueFormatted + "p"
        } else if (currency === "EUR") {
            return (minus ? "-" : "") + "€" + valueFormatted
        } else if (currency === "USD") {
            return (minus ? "-" : "") + "$" + valueFormatted
        } else if (currency === "percentage") {
            return (minus ? "-" : "") + valueFormatted + "%"
        } else {
            return (minus ? "-" : "") + "" + valueFormatted
        }
    }

    static formatCurrency_Updated(value: number, currency: string, fixed: number = 2): string {
        if (value === null) return "-"

        let minus = 0
        if (value < 0) {
            value = value * -1
            minus = 1
        }

        const parts = value.toString().split(".")
        let beforeDecimal = ""
        let afterDecimal = ""
        let valueFormatted = ""

        if (parts[0]) beforeDecimal = parts[0]
        if (parts[1]) {
            afterDecimal = parts[1]
            valueFormatted = parseFloat(beforeDecimal + "." + afterDecimal).toFixed(fixed)
        } else {
            afterDecimal = parseFloat(parts[0]).toFixed(fixed)
            valueFormatted = afterDecimal
        }

        if (currency === "GBP") {
            return (minus ? "-" : "") + "£" + valueFormatted
        } else if (currency === "GBP_pence") {
            return (minus ? "-" : "") + valueFormatted + "p"
        } else if (currency === "EUR") {
            return (minus ? "-" : "") + "€" + valueFormatted
        } else if (currency === "USD") {
            return (minus ? "-" : "") + "$" + valueFormatted
        } else if (currency === "percentage") {
            return (minus ? "-" : "") + valueFormatted + "%"
        } else {
            return (minus ? "-" : "") + "" + valueFormatted
        }
    }

    /**
     * Converts associative array to array of objects `{label, value}`
     */
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static convertAssociativeArrayToOptions(items: AssociativeArray): { label: string; value: any }[] {
        const options = []
        for (const [key, value] of Object.entries(items)) {
            options.push({
                label: value,
                value: parseInt(key, 2),
            })
        }
        return options
    }

    /**
     * Generates uniques ID for API communication tracking
     */
    static generateMasterRequestId(): string {
        return "_PP" + (new Date().getUTCMilliseconds().toString() + new Date().getTime().toString()).toString()
    }

    /**
     * Pads a raw number with leading characters (zeros by default)
     */
    static padNumberLeft(rawNumber: number, padLength: number, padChar: string = "0"): string {
        const pad = new Array(1 + padLength).join(padChar)
        return (pad + rawNumber).slice(-pad.length)
    }

    /**
     * Returns the current date minus the specific number of months
     */
    static dateMonthsAgo(subMonths = 0): Date {
        const date = new Date()
        date.setMonth(date.getMonth() - subMonths)
        return date
    }

    /**
     * Returns the current date minus the specific number of days
     */
    static dateDaysAgo(subDays = 0): Date {
        const date = new Date()
        date.setMonth(date.getDay() - subDays)
        return date
    }

    /**
     * Returns the current date minus the specific number of years
     */
    static dateYearsAgo(subYears = 0): Date {
        const date = new Date()
        date.setFullYear(date.getFullYear() - subYears)
        return date
    }

    /**
     * Format phone number - get national number
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    static getNationalPhoneNumber(phoneNumber: string): string {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber)
        const formatInOriginalFormat = phoneNumberUtil.formatInOriginalFormat(parse)
        return formatInOriginalFormat
    }

    /**
     * Format phone number - get region code
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    static getRegionCodeOfPhoneNumber(phoneNumber: string): string {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        const countryCode = parse.getCountryCode()
        return phoneNumberUtil.getRegionCodeForCountryCode(countryCode)
    }

    /**
     * Format phone number - get parse boject
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getParseObjectOfPhoneNumber(phoneNumber: string): any {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parseAndKeepRawInput(phoneNumber, "")
        return parse
    }

    /**
     * Format phone number - get formated international phone number
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getFormatedInternationalPhoneNumber(phoneNumber: string): any {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        return phoneNumberUtil.formatOutOfCountryCallingNumber(parse)
    }

    /**
     * Get object for ngx-intl-tel-input initialization
     *
     * @returns
     */
    static getNgxIntlTelInputObject(phoneNumber: string): phoneNumberObjectInterface {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        const countryCode = parse.getCountryCode()

        return {
            number: phoneNumberUtil.formatInOriginalFormat(parse).toString(),
            internationalNumber: phoneNumberUtil.formatOutOfCountryCallingNumber(parse),
            nationalNumber: phoneNumberUtil.getNationalSignificantNumber(parse), // formatInOriginalFormat
            countryCode: phoneNumberUtil.getRegionCodeForCountryCode(countryCode),
            dialCode: "+" + countryCode,
        }
    }

    static formatNumber(value: number | string, action: string, options?: Intl.NumberFormatOptions): number | string {
        if (action === "numberFormat") {
            const currencyFormatter = Intl.NumberFormat("en-GB", options)
            return currencyFormatter.format(Number(value))
        }

        if (action === "reverseNumberFormat") {
            const thousandSeparator = Intl.NumberFormat("en")
                .format(11111)
                .replace(/\p{Number}/gu, "")
            const decimalSeparator = Intl.NumberFormat("en")
                .format(1.1)
                .replace(/\p{Number}/gu, "")

            return parseFloat(
                value
                    .toString()
                    .replace(new RegExp("\\" + thousandSeparator, "g"), "")
                    .replace(new RegExp("\\" + decimalSeparator), "."),
            )
        }

        if (action === "removeCurrency") {
            return value.toString().replace("£", "")
        }

        return value
    }
}
