import { CRRegistration } from "@app/_models/cr-registration"
import { CustomerRegistrationProductsServicesApiService } from "@app/_services/api/customer-registration/customer-registration-products-services.api.service"
import { CRProductsAndPacks, CRProductsAndPacksSerializer } from "@app/_models/cr-products-and-packs"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, take } from "rxjs"
import { LocalStorageService } from "./local-storage.service"
import { AppError } from "@app/_core/app-error"
import { AlertService } from "./alert.service"

@Injectable({
    providedIn: "root",
})
export class CRProductsAndServicesService {
    public crProductsAndServices: Observable<CRProductsAndPacks>
    private crProductsAndServicesSubject: BehaviorSubject<CRProductsAndPacks>

    private static REFRESH_MINUTES = 1

    constructor(
        private customerRegistrationProductsServicesApiService: CustomerRegistrationProductsServicesApiService,
        private cRProductsAndPacksSerializer: CRProductsAndPacksSerializer,
        private localStorageService: LocalStorageService,
        private alertService: AlertService,
    ) {
        this.crProductsAndServicesSubject = new BehaviorSubject<CRProductsAndPacks>(this.getProductsAndServices())
        this.crProductsAndServices = this.crProductsAndServicesSubject.asObservable()
    }

    getProductsAndServices(): CRProductsAndPacks {
        const crProductsAndServices = this.localStorageService.getData("crProductsAndServices")
        if (crProductsAndServices !== null) {
            return this.cRProductsAndPacksSerializer.fromJson(JSON.parse(crProductsAndServices))
        }
        return null
    }

    public get currentProductsAndServicesValue(): CRProductsAndPacks {
        return this.crProductsAndServicesSubject.value
    }

    public setCurrentProductsAndServices(productsAndPacks: CRProductsAndPacks): void {
        this.crProductsAndServicesSubject.next(productsAndPacks)
    }

    subtractMinutes(date, minutes): Date {
        date.setMinutes(date.getMinutes() - minutes)
        return date
    }

    isCurrentProductsAndServicesValid(): boolean {
        /* if (
            this.subtractMinutes(new Date(), CRProductsAndServicesService.REFRESH_MINUTES) >
            this.currentProductsAndServicesValue?.localTimestamp
        ) {
            return false
        } */
        return false
    }

    regenerateCurrentProductsAndServices(partnerId: number): void {
        if (
            this.currentProductsAndServicesValue &&
            this.subtractMinutes(new Date(), CRProductsAndServicesService.REFRESH_MINUTES) >
                this.currentProductsAndServicesValue.localTimestamp
        ) {
            console.log("==> Regenerating default products and services")
            this.loadProductsAndServices(CRRegistration.DEFAULT_TERM_LENGTH, partnerId)
        }
    }

    prepareProductsAndServices(length: number, partnerId: number): Observable<CRProductsAndPacks> {
        return this.customerRegistrationProductsServicesApiService.getDefaultProductsWithPacks(
            {
                "contract_length": length.toString(),
                "partner_id": partnerId ? partnerId.toString() : "",
                "selected_products": "",
            },
            {},
        )
    }

    loadProductsAndServices(length: number, partnerId: number): void {
        this.customerRegistrationProductsServicesApiService
            .getDefaultProductsWithPacks(
                {
                    "contract_length": length.toString(),
                    "partner_id": partnerId ? partnerId.toString() : "",
                    "selected_products": "",
                },
                {},
            )
            .pipe(take(1))
            .subscribe({
                next: (payload: CRProductsAndPacks) => {
                    const json = this.cRProductsAndPacksSerializer.toJson(payload)
                    this.localStorageService.saveData("crProductsAndServices", JSON.stringify(json))
                    this.crProductsAndServicesSubject.next(payload)
                    return payload
                },
                error: (error: AppError) => {
                    this.alertService.userError(
                        error,
                        "We're sorry but the application having some technical difficulties. Please try to reload the page.",
                        false,
                        true,
                    )
                    throw error
                },
            })
    }
}
