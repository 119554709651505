<section class="modalContent">
  <article class="modalHeader">
    <h4 class="modalTitle">{{ title }}</h4>
    <svg-close (click)="dismiss()" />
  </article>
  <article class="modalBody textSize-14">
    <p> {{ message }}</p>
  </article>
  <article class="modalFooter">
    <button type="button" class="btn btnDefault appMa-5" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btnPrimary appMa-5" (click)="accept()">{{ btnOkText }}</button>
  </article>
</section>
