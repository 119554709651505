import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-message-white",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="9.065" height="6.232" viewBox="0 0 9.065 6.232">
        <g id="Group_855" data-name="Group 855" transform="translate(-2660.336 -26.231)">
            <g id="Group_852" data-name="Group 852">
                <g id="Group_851" data-name="Group 851">
                    <path
                        id="Path_579"
                        data-name="Path 579"
                        d="M2668.834,26.231H2660.9a.567.567,0,0,0-.567.567v.322l4.532,1.919,4.533-1.92V26.8A.567.567,0,0,0,2668.834,26.231Z"
                        fill="#fff" />
                </g>
            </g>
            <g id="Group_854" data-name="Group 854">
                <g id="Group_853" data-name="Group 853">
                    <path
                        id="Path_580"
                        data-name="Path 580"
                        d="M2664.979,29.608a.288.288,0,0,1-.221,0l-4.422-1.873V31.9a.567.567,0,0,0,.567.567h7.931a.567.567,0,0,0,.567-.567V27.735Z"
                        fill="#fff" />
                </g>
            </g>
        </g>
    </svg>`,
})
export class MessageWhiteComponent {}
