import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class GasQuote implements ApiResource {
    id = 0

    supplier: string
    term: string
    unitRateWithUplift: string
    contractStartDate: Date
    contractEndDate: Date
    annualCost: number
    uplift: number
    customerFacing: string
    providerId: number
    pricingListId: number
    type: string
    minConsumption: number
    maxConsumption: number
    ldz: string
    exz: string
    ref: string
    sc: string
    unitRate: string
    endOfCurve: string
    capacityCharge: string
    name: string
    providerType: string
    providerStatus: string
    image: string
    file: string
}

@Injectable({
    providedIn: "root",
})
export class GasQuoteSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): GasQuote {
        const quote = new GasQuote()
        quote.id = json.id

        quote.supplier = json.supplier
        quote.term = json.term
        quote.unitRateWithUplift = json.unit_rate_with_uplift
        quote.contractStartDate = new Date(json.contract_start_date)
        quote.contractEndDate = new Date(json.contract_end_date)
        quote.annualCost = json.annual_cost
        quote.uplift = json.uplift
        quote.customerFacing = json.customer_facing
        quote.providerId = json.provider_id
        quote.pricingListId = json.pricing_list_id
        quote.type = json.type
        quote.minConsumption = json.min_consumption
        quote.maxConsumption = json.max_consumption
        quote.ldz = json.ldz
        quote.exz = json.exz
        quote.ref = json.ref
        quote.sc = json.sc
        quote.unitRate = json.unit_rate
        quote.endOfCurve = json.end_of_curve
        quote.capacityCharge = json.capacity_charge
        quote.name = json.name
        quote.providerType = json.provider_type
        quote.providerStatus = json.provider_status
        quote.image = json.image
        quote.file = json.file

        return quote
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(quote: GasQuote): any {
        return {
            id: quote.id,
        }
    }
}
