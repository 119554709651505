import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { SelectOption } from "@appModels/select-option"

export class PartialApproval {
    id: number
    partialApprovalIsActive: boolean
    availableTerms: SelectOption[]
    selectedMessages: SelectOption[]
    enableCNP: boolean
}

@Injectable({
    providedIn: "root",
})
export class CRPartialResetSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): PartialApproval {
        const record = new PartialApproval()
        record.id = 1
        record.partialApprovalIsActive = json.partial_approval_is_active
        record.availableTerms = json.available_terms
        record.selectedMessages = json.selected_messages
        record.enableCNP = json.enable_cnp
        return record
    }

    toJson(record: PartialApproval): JsonObject {
        return {
            partial_approval_is_active: record.partialApprovalIsActive,
            available_terms: record.availableTerms,
            selected_messages: record.selectedMessages,
            enable_cnp: record.enableCNP,
        }
    }
}
