import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ProductPrice, ProductPriceSerializer } from "./product-price"
import { SystemFile, SystemFileSerializer } from "./file"
import { JsonObject } from "../_core/types"
import { PriceRegularity } from "./price-regularity"
import { CRProductCategory, CRProductCategorySerializer } from "./cr-product-category"
import { ProductQuantityType } from "./enums/product-quantity-type"

export interface ProductSetup {
    id: number
    quantity: number
}

export interface ProductMotoSetup {
    id: number
    moto: number
}

export interface ProductGratuitySetup {
    id: number
    gratuity: number
}

export interface ProductIsFreeSetup {
    id: number
    isFree: number
}

export class CRProductsServices {
    id: number = 0
    createdAt: Date
    updatedAt: Date
    name: string
    code: string
    isMotoAvailable: boolean
    isGrauityAvailable: boolean
    isPhysicalHardware: boolean
    description: string
    aditionalInfo: string
    type: number
    isEnabled: boolean
    isAllowedForOrder: boolean
    isIntegrated: boolean
    isCardPresent: boolean
    canBeFinancedExternally: boolean
    isSelected: boolean
    productImage: SystemFile
    productIcon: SystemFile
    pricing: ProductPrice[]
    preservedPricing: ProductPrice[]
    pricingFrom: {
        monthly: number
        oneOff: number
        service: number
    }
    allowedPriceRegularity
    integratedProducts: CRProductsServices[]
    parentProduct: CRProductsServices
    availableAccessories: number[]
    isForFree: boolean
    maxQty: number
    categoryId: number
    category: CRProductCategory
    requiresMid: boolean
    qty = 0
    quantityType: string = ProductQuantityType.QUANTITY_TYPE_NUMBER
    contractLength = 1
    totalPrice = 0
    allowedContractLengths

    // For customer registration logic
    monthlyTotal: number | string = 0
    financeTotal: number | string = 0
    isVisible: boolean = true

    // Local variables
    isProduct: boolean = true
    isProductPack: boolean = false
    productPackId: number

    calculatePrices(): void {
        const self = this
        let totalPrice = 0
        this.pricing.forEach(productPrice => {
            if (productPrice.contractLength === self.contractLength) {
                totalPrice += productPrice.priceBuy
            }
        })
        this.preservedPricing.forEach(productPrice => {
            if (productPrice.contractLength === self.contractLength) {
                totalPrice += productPrice.priceBuy
            }
        })
        this.totalPrice = this.qty * totalPrice

        this.integratedProducts.forEach(intProduct => {
            intProduct.calculatePrices()
            this.totalPrice += intProduct.totalPrice
        })
    }

    isAllowedPriceRegularity(regularity: number): boolean {
        return this.allowedPriceRegularity?.find(item => item.price_regularity === regularity) !== undefined
    }
}

@Injectable({
    providedIn: "root",
})
export class CRProductsServicesSerializer implements ApiResourceSerializer {
    constructor(
        private systemFileSerializer: SystemFileSerializer,
        private productPriceSerializer: ProductPriceSerializer,
        private productCategorySerializer: CRProductCategorySerializer,
    ) {}

    fromJson(json: JsonObject, mode: string = "full"): CRProductsServices {
        const record = new CRProductsServices()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.name = json.name
        record.code = json.code
        record.isMotoAvailable = json.is_moto_available === undefined ? false : json.is_moto_available
        record.isGrauityAvailable = json.is_moto_available === undefined ? false : json.is_moto_available
        record.isPhysicalHardware = json.is_physical_hardware === undefined ? false : json.is_physical_hardware
        record.description = json.description
        record.aditionalInfo = json.aditional_info
        record.quantityType = json.quantity_type
        record.monthlyTotal = json.monthly_total
        record.financeTotal = json.finance_total
        record.type = json.type
        record.isCardPresent = json.is_card_present
        record.isEnabled = json.is_enabled
        record.isAllowedForOrder = json.is_allowed_for_order
        record.canBeFinancedExternally = json.can_be_financed_externally
        record.isIntegrated = json.is_integrated
        record.productImage =
            json.product_image && json.product_image !== ""
                ? this.systemFileSerializer.fromJson(json.product_image)
                : null
        record.productIcon =
            json.product_icon && json.product_icon !== "" ? this.systemFileSerializer.fromJson(json.product_icon) : null
        record.pricing = json.pricing ? json.pricing.map(item => this.productPriceSerializer.fromJson(item)) : []
        record.preservedPricing = json.pricing
            ? json.pricing.map(item => this.productPriceSerializer.fromJson(item))
            : []
        record.pricingFrom = json.pricing_from
            ? {
                  monthly: json.pricing_from.monthly,
                  oneOff: json.pricing_from.one_off,
                  service: json.pricing_from.service,
              }
            : null
        record.requiresMid = json.requires_mid
        record.isForFree = json.is_for_free
        record.maxQty = json.max_qty
        record.categoryId = json.category_id
        record.availableAccessories = json.available_accessories
        record.allowedPriceRegularity = json.allowed_price_regularity

        const monthlyPriceRegularity = json.allowed_price_regularity?.find(
            item => item.price_regularity === PriceRegularity.MONTHLY,
        )
        record.allowedContractLengths = monthlyPriceRegularity ? monthlyPriceRegularity.contract_lengths : []

        if (mode === "full") {
            record.category =
                json.category !== undefined && json.category !== null
                    ? this.productCategorySerializer.fromJson(json.category, "withoutNesting")
                    : null

            record.integratedProducts =
                json.integrated_products !== undefined && json.integrated_products !== null
                    ? json.integrated_products.map(item => {
                          const intProduct = this.fromJson(item, "withoutNesting")
                          intProduct.parentProduct = record
                          return intProduct
                      })
                    : []
        }

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRProductsServices, mode: string = "full"): any {
        const obj = {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            name: record.name,
            code: record.code,
            is_moto_available: record.isMotoAvailable,
            is_grauity_available: true, // record.isGrauityAvailable,
            is_physical_hardware: record.isPhysicalHardware,
            description: record.description,
            aditional_info: record.aditionalInfo,
            monthly_total: record.monthlyTotal,
            finance_total: record.financeTotal,
            quantity_type: record.quantityType,
            type: record.type,
            is_card_present: record.isCardPresent,
            is_enabled: record.isEnabled,
            is_allowed_for_order: record.isAllowedForOrder,
            can_be_financed_externally: record.canBeFinancedExternally,
            is_integrated: record.isIntegrated,

            product_image: record.productImage ? this.systemFileSerializer.toJson(record.productImage) : null,

            product_icon: record.productIcon ? this.systemFileSerializer.toJson(record.productIcon) : null,

            pricing: record.pricing ? record.pricing.map(item => this.productPriceSerializer.toJson(item)) : [],
            preservedPricing: record.pricing
                ? record.pricing.map(item => this.productPriceSerializer.toJson(item))
                : [],

            pricing_from: record.pricingFrom
                ? {
                      monthly: record.pricingFrom.monthly,
                      one_off: record.pricingFrom.oneOff,
                      service: record.pricingFrom.service,
                  }
                : null,

            requires_mid: record.requiresMid,
            is_for_free: record.isForFree,
            max_qty: record.maxQty,
            category_id: record.categoryId,

            available_accessories: record.availableAccessories,
            allowed_price_regularity: record.allowedPriceRegularity,
        }

        if (mode === "full") {
            obj["category"] = record.category
                ? this.productCategorySerializer.toJson(record.category, "withoutNesting")
                : null

            obj["integrated_products"] = record.integratedProducts
                ? record.integratedProducts.map(item => {
                      const intProduct = this.toJson(item, "withoutNesting")
                      intProduct.parentProduct = null
                      return intProduct
                  })
                : []
        }

        return obj
    }
}
