import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class WidgetTransactionsTotalCountDaily extends ApiResource {
    id = 0
    date: number
    totalCount: number
    forecast?: number
}

@Injectable({
    providedIn: "root",
})
export class WidgetTransactionsTotalCountDailySerializer implements ApiResourceSerializer {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): WidgetTransactionsTotalCountDaily {
        const record = new WidgetTransactionsTotalCountDaily()
        record.date = json.date
        record.totalCount = json.total_count
        record.forecast = json.forecast
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: WidgetTransactionsTotalCountDaily): any {
        return {
            date: record.date,
            total_count: record.totalCount,
            forecast: record.forecast,
        }
    }
}
