import { createReducer, on } from "@ngrx/store"
import { CustomerStandardFee } from "@appModels/customer-standard-fee"
import {
    SetCRCardStandardFees,
    SetCRCardStandardFeesLoading,
    SetCRCustomerRegistrationCompleted,
    SetCRCustomerRegistrationSetupInProgress,
    SetCRFormSubmit,
    SetCRInvalidFastPayment,
    SetCRIsProductSelect,
    SetCRIsTerminalSelect,
    SetCRLoadFeeSection,
    SetCRResetCustomerRegistration,
    SetCRSelectedFasterPaymentValue,
    SetCRSelectedPCIPlusValue,
    SetCRUpdatePrice,
} from "@appStore/actions/AppCustomerRegistrationAction"

export interface SelectedPCIPlusValue {
    id: number
    value: number | null
}

export interface SelectedFastPaymentValue {
    id: number
    value: number | null
    quantity?: number
}
export interface AppCustomerRegistrationInterface {
    invalidFastPayment: boolean
    loadFeeSection: boolean
    updatePrice: boolean
    isProductSelect: boolean
    isTerminalSelect: boolean
    standardFees: CustomerStandardFee[]
    standardFeesLoading: boolean
    formSubmitted: boolean
    selectedPCIPlusValue: SelectedPCIPlusValue
    selectedFastPayment: SelectedFastPaymentValue
    customerRegistrationCompleted: boolean
    showSetupInProgress: boolean
}

const initialState: AppCustomerRegistrationInterface = {
    invalidFastPayment: false,
    loadFeeSection: false,
    updatePrice: false,
    isProductSelect: false,
    isTerminalSelect: false,
    standardFees: [],
    standardFeesLoading: false,
    formSubmitted: false,
    selectedPCIPlusValue: null,
    selectedFastPayment: null,
    customerRegistrationCompleted: false,
    showSetupInProgress: false,
}

export const AppCustomerRegistrationReducer = createReducer(
    { ...initialState },

    on(SetCRUpdatePrice, state => {
        return {
            ...state,
            updatePrice: !state.updatePrice,
        }
    }),

    on(SetCRCardStandardFees, (state, { value }) => {
        return {
            ...state,
            standardFees: value,
        }
    }),

    on(SetCRCardStandardFeesLoading, (state, { status }) => {
        return {
            ...state,
            standardFeesLoading: status,
        }
    }),

    on(SetCRSelectedPCIPlusValue, (state, { value }) => {
        return {
            ...state,
            selectedPCIPlusValue: value,
        }
    }),

    on(SetCRSelectedFasterPaymentValue, (state, { value }) => {
        return {
            ...state,
            selectedFastPayment: value,
        }
    }),

    on(SetCRIsProductSelect, (state, { status }) => {
        return {
            ...state,
            isProductSelect: status,
        }
    }),

    on(SetCRIsTerminalSelect, (state, { status }) => {
        return {
            ...state,
            isTerminalSelect: status,
        }
    }),

    on(SetCRLoadFeeSection, (state, { status }) => {
        return {
            ...state,
            loadFeeSection: status,
        }
    }),

    on(SetCRFormSubmit, (state, { status }) => {
        return {
            ...state,
            formSubmitted: status,
        }
    }),
    on(SetCRInvalidFastPayment, (state, { status }) => {
        return {
            ...state,
            invalidFastPayment: status,
        }
    }),
    on(SetCRResetCustomerRegistration, () => {
        return {
            ...initialState,
        }
    }),
    on(SetCRCustomerRegistrationCompleted, (state, { status }) => {
        return {
            ...state,
            customerRegistrationCompleted: status,
        }
    }),

    on(SetCRCustomerRegistrationSetupInProgress, (state, { status }) => {
        return {
            ...state,
            showSetupInProgress: status,
        }
    }),
)
