/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerNotificationUser, CustomerNotificationUserSerializer } from "./customer-notification-user"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"

export class CustomerCloudMessage {
    id: number = 0
    partnerId: number = 0
    sellerId: number = 0
    createdAt: Date
    updatedAt: Date
    readAt: string
    archivedAt: string
    title: string
    message: string
    category: string
    type: string
    priority: string
    priorityId: string
    status: string
    customerUserId: number = null
    sendPush: number = 0
    files: any
    users: CustomerNotificationUser[]
    isRowCheckboxChecked: boolean = false
}

@Injectable({
    providedIn: "root",
})
export class CustomerCloudMessageSerializer implements ApiResourceSerializer {
    constructor(private customerNotificationUserSerializer: CustomerNotificationUserSerializer) {}

    // TODO Need to check

    fromJson(json: any): CustomerCloudMessage {
        const message = new CustomerCloudMessage()
        message.id = json.id
        message.partnerId = json.partner_id ?? null
        message.sellerId = json.seller_id ?? null
        message.createdAt = new Date(json.created_at)
        message.updatedAt = new Date(json.created_at)
        message.readAt = json.read_at
        message.archivedAt = json.archived_at
        message.title = json.title
        message.message = json.message
        message.category = json.category
        message.type = json.type
        message.priority = json.priority
        message.priorityId = json.priority_id
        message.status = json.status
        message.sendPush = json.send_push
        message.files = json.files
        message.users =
            json.sellers !== undefined && json.sellers !== null
                ? json.sellers.map(item => this.customerNotificationUserSerializer.fromJson(item))
                : []
        return message
    }

    // TODO Need to check

    toJson(notification: CustomerCloudMessage): any {
        return {
            id: notification.id,
            partner_id: notification.partnerId,
            seller_id: notification.sellerId,
            title: notification.title,
            message: notification.message,
            read_at: notification.readAt,
            archived_at: notification.archivedAt,
            category: notification.category,
            type: notification.type,
            priority: notification.priority,
            priority_id: notification.priorityId,
            status: notification.status,
            send_push: notification.sendPush,
            files: notification.files,
            customer_user_id: notification.customerUserId,
        }
    }
}
