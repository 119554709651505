import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-edit-icon-black",
    template: ` <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <defs>
            <style>
                .cls-1 {
                    fill: none;
                    stroke: #000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 0.77px;
                }
            </style>
        </defs>
        <path class="cls-1" d="m.89,28.88l2.96-8.87,6.14,6.14-8.87,2.96c-.14.05-.27-.09-.23-.23Z" />
        <path
            class="cls-1"
            d="m3.85,20.01L22.11,1.75c1.15-1.15,3.02-1.15,4.17,0l1.97,1.97c1.15,1.15,1.15,3.02,0,4.17L9.99,26.15" />
        <line class="cls-1" x1="20.14" y1="3.72" x2="26.28" y2="9.86" />
        <line class="cls-1" x1="21.89" y1="10.41" x2="9.99" y2="22.2" />
        <line class="cls-1" x1="20.1" y1="8.3" x2="8.2" y2="20.1" />
        <line class="cls-1" x1="1.8" y1="26.15" x2="3.85" y2="28.2" />
    </svg>`,
})
export class EditIconBlackComponent {}
