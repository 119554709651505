import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class WidgetTransactionsTotalCountMonthly extends ApiResource {
    id = 0
    year: number
    month: number
    totalCount: number
    forecast?: number
}

@Injectable({
    providedIn: "root",
})
export class WidgetTransactionsTotalCountMonthlySerializer implements ApiResourceSerializer {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): WidgetTransactionsTotalCountMonthly {
        const record = new WidgetTransactionsTotalCountMonthly()
        record.year = json.year
        record.month = json.month
        record.totalCount = json.total_count
        record.forecast = json.forecast
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: WidgetTransactionsTotalCountMonthly): any {
        return {
            year: record.year,
            month: record.month,
            total_count: record.totalCount,
            forecast: record.forecast,
        }
    }
}
