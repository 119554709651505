import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-setting-white",
    template: ` <svg xmlns="http://www.w3.org/2000/svg" width="8.449" height="8.448" viewBox="0 0 8.449 8.448">
        <g id="Group_843" data-name="Group 843" transform="translate(-2710.947 -24.646)">
            <g id="Group_842" data-name="Group 842">
                <g id="Group_841" data-name="Group 841">
                    <path
                        id="Path_571"
                        data-name="Path 571"
                        d="M2719.186,28.084l-.754-.09a3.368,3.368,0,0,0-.335-.812l.469-.6a.244.244,0,0,0-.02-.315l-.776-.777a.245.245,0,0,0-.316-.02l-.6.47a3.319,3.319,0,0,0-.81-.335l-.09-.754a.244.244,0,0,0-.236-.209h-1.1a.244.244,0,0,0-.236.209l-.091.754a3.358,3.358,0,0,0-.81.335l-.6-.469a.245.245,0,0,0-.315.019l-.777.778a.244.244,0,0,0-.019.315l.469.6a3.316,3.316,0,0,0-.335.811l-.754.09a.244.244,0,0,0-.21.236v1.1a.246.246,0,0,0,.21.237l.754.09a3.38,3.38,0,0,0,.335.81l-.468.6a.244.244,0,0,0,.019.315l.776.778a.244.244,0,0,0,.315.019l.6-.47a3.28,3.28,0,0,0,.81.335l.091.754a.244.244,0,0,0,.236.209h1.1a.243.243,0,0,0,.236-.209l.091-.754a3.358,3.358,0,0,0,.81-.335l.6.469a.244.244,0,0,0,.315-.019l.777-.777a.244.244,0,0,0,.019-.316l-.469-.6a3.338,3.338,0,0,0,.335-.811l.754-.09a.244.244,0,0,0,.21-.236v-1.1A.245.245,0,0,0,2719.186,28.084Zm-4.014,2.9a2.112,2.112,0,1,1,2.112-2.112A2.112,2.112,0,0,1,2715.172,30.982Z"
                        fill="#fff" />
                </g>
                <circle
                    id="Ellipse_135"
                    data-name="Ellipse 135"
                    cx="1.074"
                    cy="1.074"
                    r="1.074"
                    transform="translate(2714.097 27.796)"
                    fill="#fff" />
            </g>
        </g>
    </svg>`,
})
export class SettingWhiteComponent {}
