import { Component, Input } from "@angular/core"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"

@Component({
    selector: "app-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
    @Input() title: string
    @Input() message: string
    @Input() btnOkText: string
    @Input() btnCancelText: string

    constructor(private activeModal: NgbActiveModal) {}

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public decline() {
        this.activeModal.close(false)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public accept() {
        this.activeModal.close(true)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public dismiss() {
        this.activeModal.dismiss()
    }
}
