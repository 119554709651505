import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { SetDashboardTotalAmountDailyWidgetAction } from "../../actions/DashboardActions"
import { AlertService } from "@app/_services/alert.service"
import { WidgetTransactionsTotalAmountDailyApiService } from "../../../_services/api/widget-transactions-total-amount-daily-api.service"
import { WidgetTransactionsTotalAmountDaily } from "../../../_models/widget-transactions-total-amount-daily"
import { DatePipe } from "@angular/common"
import { EChartsOption, SeriesOption } from "echarts"
import { DashboardWidgetColor } from "@app/interfaces/dashboard/dashboardWidgetColor"
import { YAXisOption } from "echarts/types/dist/shared"
import HelperVerofy from "@appHelpers/helper-verofy"

export const FetchTotalAmountDailyData = createAction("DashboardTotalAmountDailyData", props<{ partnerId: number }>())

@Injectable()
export class DashboardTotalAmountDailyEffects {
    hasData: boolean = false
    isDefault: boolean = true

    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchTotalAmountDailyData),
                exhaustMap(action =>
                    this.totalAmountDailyApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                {
                                    const totalAmountDaily: WidgetTransactionsTotalAmountDaily[] = payload
                                    const datePipe: DatePipe = new DatePipe("en-US")

                                    let barX = []
                                    const totalAmountValues = []

                                    totalAmountDaily.forEach(record => {
                                        barX.push(datePipe.transform(record.date, "d.MM.YY"))
                                        totalAmountValues.push(record.totalAmount ?? 0)
                                    })

                                    // Display only the first six months of the selected period
                                    if (barX.length > 6) barX = barX.slice(0, 6)

                                    const hasNoData = totalAmountValues.every(
                                        item => item.toString() === "0" || item === null,
                                    )

                                    this.hasData = !hasNoData

                                    let yAxisOption: YAXisOption | YAXisOption[] = {}

                                    if (totalAmountValues.length > 0) {
                                        for (const currentData of totalAmountValues) {
                                            if (parseInt(currentData, 10) > 0) {
                                                this.isDefault = false
                                                break
                                            }
                                        }
                                    }

                                    const currentSeries: SeriesOption[] = [
                                        {
                                            name: "Daily transactions",
                                            type: "line",
                                            data: totalAmountValues,
                                            animationDelay: +(idx => idx * 10),
                                        },
                                    ]

                                    if (this.isDefault) {
                                        yAxisOption = {
                                            type: "category",
                                            boundaryGap: false,
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === "0")
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                            data: [0, 100],
                                        }
                                    } else {
                                        yAxisOption = {
                                            type: "value",
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === 0)
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                        }
                                    }

                                    const eChartOptions: EChartsOption = {
                                        legend: {
                                            data: [],
                                            align: "left",
                                            orient: "horizontal",
                                            top: "10px",
                                            left: "0",
                                        },
                                        title: {
                                            text: this.hasData
                                                ? ""
                                                : "There is currently no transactional data to display.",
                                            subtext: "",
                                            left: "center",
                                            top: "42%",
                                            textStyle: {
                                                fontSize: 12,
                                                opacity: 0.5,
                                                fontWeight: 400,
                                            },
                                        },
                                        color: [DashboardWidgetColor.Real],
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                axis: "auto",
                                                type: "none",
                                            },
                                            textStyle: {
                                                fontSize: 12,
                                            },
                                            formatter: function (params) {
                                                const value = params[0].value
                                                const title = params[0].axisValue
                                                const originalMarker = params[0].marker
                                                const color = params[0].color
                                                const formattedValue = HelperVerofy.formatCurrency(
                                                    Number(value),
                                                    "GBP",
                                                    2,
                                                )

                                                // Create the tooltip content with the title and formatted value
                                                return (
                                                    `<strong> ${title}  </strong>` +
                                                    "<br/>" +
                                                    originalMarker +
                                                    "Volume: " +
                                                    '<span style="color:' +
                                                    color +
                                                    '"><strong>' +
                                                    formattedValue +
                                                    "</strong></span>"
                                                )
                                            },
                                        },
                                        toolbox: {
                                            top: 0,
                                            right: 0,
                                            feature: {
                                                saveAsImage: {
                                                    emphasis: {
                                                        iconStyle: {
                                                            textFill: "#307AFF",
                                                            borderColor: "#307AFF",
                                                        },
                                                    },
                                                    title: "Download report",
                                                    name: "Verofy Daily Transactional Volume",
                                                },
                                            },
                                        },
                                        xAxis: {
                                            data: barX,
                                            silent: false,
                                            splitLine: {
                                                show: true,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                            },
                                            nameTextStyle: {
                                                align: "center",
                                                verticalAlign: "middle",
                                            },
                                        },
                                        yAxis: {
                                            ...yAxisOption,
                                        },
                                        series: currentSeries,
                                        animationEasing: "elasticOut",
                                        animationDelayUpdate: +(idx => idx * 5),
                                        grid: {
                                            containLabel: true,
                                            left: 0,
                                        },
                                    }

                                    return SetDashboardTotalAmountDailyWidgetAction({ data: eChartOptions })
                                }
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private totalAmountDailyApiService: WidgetTransactionsTotalAmountDailyApiService,
    ) {}
}
