/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class BoardingAdditionalQuestion {
    id: number
    code: string
    isMccFlagged: boolean
    question: string
    answerType: string
    mccCodes: number[]
    note: string
    section: string
}

@Injectable({
    providedIn: "root",
})
export class BoardingAdditionalQuestionSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: any): BoardingAdditionalQuestion {
        const record = new BoardingAdditionalQuestion()
        record.id = json.id
        record.code = json.code
        record.isMccFlagged = json.is_mcc_flagged
        record.question = json.question
        record.answerType = json.answer_type
        record.mccCodes = json.mcc_codes
        record.note = json.note
        record.section = json.section
        return record
    }

    toJson(record: BoardingAdditionalQuestion) {
        return {
            id: record.id,
            code: record.code,
            is_mcc_flagged: record.isMccFlagged,
            question: record.question,
            answer_type: record.answerType,
            mcc_codes: record.mccCodes,
            note: record.note,
            section: record.section,
        }
    }
}
