import { ApiResource } from "@app/_models/api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "@app/_core/api-resource.serializer"
import { JsonObject } from "@app/_core/types"

export class SellerPermission implements ApiResource {
    public static ACCESS_CUSTOMERS = 11
    public static ACCESS_USERS = 16
    public static ACCESS_USER_GROUPS = 17
    public static ACCESS_TRANSACTION = 19
    public static ACCESS_COMMISSIONS = 20
    public static ACCESS_LOGIN_HISTORY = 22
    public static ACCESS_ACCOUNTS_AND_COMMERCIALS = 23
    public static ACCESS_ALERTS = 24
    public static ACCESS_SELLER_EARNINGS = 21

    public static WIDGET_TRANSACTIONS_TOTAL_AMOUNT_MONTHLY = 51
    public static WIDGET_TRANSACTIONS_TOTAL_COUNT_MONTHLY = 52
    public static WIDGET_TRANSACTIONS_TOTAL_AMOUNT_DAILY = 53
    public static WIDGET_TRANSACTIONS_TOTAL_COUNT_DAILY = 54
    public static WIDGET_TERMINALS_COUNT_LIVE_MONTHLY = 55
    public static WIDGET_TERMINALS_COUNT_LIVE_DAILY = 56
    public static WIDGET_TERMINALS_COUNT_ORDERED_MONTHLY = 57
    public static WIDGET_TERMINALS_COUNT_ORDERED_DAILY = 58
    public static WIDGET_COMMISSIONS = 59
    public static WIDGET_QUOTES_APPLICATIONS = 61
    public static WIDGET_BUSINESS_OVERVIEW = 62
    public static WIDGET_FINANCE_COMMISSION = 62
    public static WIDGET_RESIDUAL_COMMISSION = 62
    public static CHECK_RESIDUAL_MONTHLY_REPORT = 25
    id = 0
    name: string
}

@Injectable({
    providedIn: "root",
})
export class SellerPermissionSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): SellerPermission {
        const record = new SellerPermission()
        record.id = json.id
        record.name = json.name
        return record
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(record: SellerPermission): any {
        return {
            id: record.id,
            name: record.name,
        }
    }
}
