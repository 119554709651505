import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRRateSets, CRRateSetsSerializer } from "./cr-rate-sets"

export class CRRatesAndFees {
    id: number
    formRateSet: CRRateSets[]
}

@Injectable({
    providedIn: "root",
})
export class CRRatesAndFeesSerializer implements ApiResourceSerializer {
    constructor(private cRRateSetsSerializer: CRRateSetsSerializer) {}

    fromJson(json: JsonObject): CRRatesAndFees {
        const registration = new CRRatesAndFees()
        registration.id = json.id

        registration.formRateSet =
            json.form_rate_set !== undefined && json.form_rate_set !== null
                ? json.form_rate_set.map(item => this.cRRateSetsSerializer.fromJson(item))
                : []

        return registration
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRRatesAndFees): any {
        return {
            id: record.id,
            form_rate_set: record.formRateSet
                ? record.formRateSet.map(item => this.cRRateSetsSerializer.toJson(item))
                : [],
        }
    }
}
