import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class SellerGroup {
    id = 0
    createdAt: Date
    updatedAt: Date
    name: string
    description: string
    dataAccess: number
    permissionsMode: number
    permissions: Array<number>
    partnerId: number
    dashboardId: number
    sellersCount: number
    isMainGroup: boolean
    isSystemGroup: boolean
}

@Injectable({
    providedIn: "root",
})
export class SellerGroupSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): SellerGroup {
        const group = new SellerGroup()
        group.id = json.id
        group.createdAt = new Date(json.created_at)
        group.updatedAt = new Date(json.updated_at)
        group.name = json.name
        group.description = json.description
        group.dataAccess = json.data_access
        group.permissionsMode = json.permissions_mode
        group.permissions = json.permissions
        group.partnerId = json.partner_id
        group.dashboardId = json.dashboard_id
        group.sellersCount = json.sellers_count
        group.isMainGroup = json.is_main_group
        group.isSystemGroup = json.is_system_group

        return group
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(group: SellerGroup): any {
        return {
            name: group.name,
            description: group.description,
            data_access: group.dataAccess,
            permissions_mode: group.permissionsMode,
            permissions: group.permissions,
            partner_id: group.partnerId,
            dashboard_id: group.dashboardId,
            is_main_group: group.isMainGroup,
            is_system_group: group.isSystemGroup,
        }
    }
}
