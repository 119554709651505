import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class Pagination {
    currentPage: number
    from: number
    lastPage: number
    perPage: number
    to: number
    total: number
}

@Injectable({
    providedIn: "root",
})
export class PaginationSerializer {
    fromJson(json: JsonObject): Pagination {
        const pagination = new Pagination()
        pagination.currentPage = json.current_page
        pagination.from = json.from
        pagination.lastPage = json.last_page
        pagination.perPage = json.per_page
        pagination.to = json.to
        pagination.total = json.total

        return pagination
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(pagination: Pagination): any {
        return {}
    }
}
