import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRFormCalculator, CRFormCalculatorSerializer } from "./cr-form-calculator"
import { CRFormRateGroup, CRFormRateGroupSerializer } from "./cr-form-rate-group"

export class CRRateSets {
    id: number = 0
    activeFee: string
    authorisationFeeGroup: number
    authorisationFeeNotAvailable: boolean | null
    transactionFeeNotAvailable: boolean | null
    formCalculator: CRFormCalculator
    formRateGroup: CRFormRateGroup[]
    setId: number
    setName: string
    setUniqueId: string
    productName: string
    isActive: boolean // Local variable
}

@Injectable({
    providedIn: "root",
})
export class CRRateSetsSerializer implements ApiResourceSerializer {
    constructor(
        private cRFormRateGroupSerializer: CRFormRateGroupSerializer,
        private cRFormCalculatorSerializer: CRFormCalculatorSerializer,
    ) {}

    fromJson(json: JsonObject): CRRateSets {
        const record = new CRRateSets()
        record.id = json.id
        record.activeFee = json.active_fee
        record.authorisationFeeGroup = json.authorisation_fee_group
        record.authorisationFeeNotAvailable = json.authorisation_fee_not_available
        record.transactionFeeNotAvailable = json.transaction_fee_not_available
        record.formCalculator =
            json.form_calculator !== undefined && json.form_calculator !== null
                ? this.cRFormCalculatorSerializer.fromJson(json.form_calculator)
                : null
        record.formRateGroup =
            json.form_rate_group !== undefined && json.form_rate_group !== null
                ? json.form_rate_group.map(item => this.cRFormRateGroupSerializer.fromJson(item))
                : []
        record.setId = json.set_id
        record.setName = json.set_name
        record.setUniqueId = json.set_unique_id
        record.isActive = json.is_active
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRRateSets): any {
        return {
            id: record.id,
            active_fee: record.activeFee,
            authorisation_fee_group: record.authorisationFeeGroup,
            authorisation_fee_not_available: record.authorisationFeeNotAvailable,
            transaction_fee_not_available: record.transactionFeeNotAvailable,
            form_calculator:
                record.formCalculator !== undefined && record.formCalculator !== null
                    ? this.cRFormCalculatorSerializer.toJson(record.formCalculator)
                    : null,
            form_rate_group: record.formRateGroup
                ? record.formRateGroup.map(item => this.cRFormRateGroupSerializer.toJson(item))
                : [],
            set_id: record.setId,
            set_name: record.setName,
            set_unique_id: record.setUniqueId,
            is_active: record.isActive,
        }
    }
}
