import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { ProductCodeEnum } from "@app/enums/customerRegistration/ProductCodeEnum"
import { PackageCodeEnum } from "@app/enums/customerRegistration/PackageCodeEnum"

export class CRProductSelection {
    id: number = 0
    moto: number
    gratuity: number
    isFree: number
    pricePerMonth: number
    productDescription: string
    productName: string
    quantity: number
    isProduct: boolean
    isPhysicalHardware: boolean
    productId: number | null
    isProductPack: boolean
    productPackId: number | null
    productsIds: number[]
    code: ProductCodeEnum | PackageCodeEnum
    // inegratedProductsGroup: CRIntegratedProducts[]
}

@Injectable({
    providedIn: "root",
})
export class CRProductSelectionSerializer implements ApiResourceSerializer {
    // private cRIntegratedProductsSerializer: CRIntegratedProductsSerializer

    constructor() {}

    fromJson(json: JsonObject): CRProductSelection {
        const record = new CRProductSelection()
        record.id = json.id
        record.moto = json.moto
        record.gratuity = json.gratuity ?? 0
        record.isFree = json.is_free
        record.pricePerMonth = json.price_per_month
        record.productDescription = json.product_description
        record.productName = json.product_name
        record.quantity = json.quantity
        record.isProduct = json.is_product
        record.isPhysicalHardware = json.is_physical_hardware
        record.productId = json.product_id
        record.isProductPack = json.is_product_pack
        record.productPackId = json.product_pack_id
        record.productsIds = json.products_ids
        record.code = json.code
        /* record.inegratedProductsGroup =
            json.inegrated_products_group !== undefined && json.inegrated_products_group !== null
                ? json.inegrated_products_group.map(item => this.cRIntegratedProductsSerializer.fromJson(item))
                : null */
        return record
    }
    toJson(record: CRProductSelection): JsonObject {
        return {
            id: record.id,
            moto: record.moto,
            gratuity: record.gratuity,
            is_free: record.isFree,
            price_per_month: record.pricePerMonth,
            product_description: record.productDescription,
            product_name: record.productName,
            quantity: record.quantity,
            is_product: record.isProduct,
            is_physical_hardware: record.isPhysicalHardware,
            product_id: record.productId,
            is_product_pack: record.isProductPack,
            product_pack_id: record.productPackId,
            products_ids: record.productsIds,
            code: record.code,
            /* inegrated_products_group: record.inegratedProductsGroup
                ? record.inegratedProductsGroup.map(item => this.cRIntegratedProductsSerializer.toJson(item))
                : [], */
        }
    }
}
