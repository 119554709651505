import { createAction, props } from "@ngrx/store"
import ActionTypes from "../ActionTypes"
import { PageLoadingFrom } from "@app/store/reducers/AppPageReducer"

/**
 * App loading action
 */

export const SetPageInitialize = createAction(ActionTypes.setPageInitialize, props<{ initialize: boolean }>())
export const SetPageAnimation = createAction(ActionTypes.setPageAnimation, props<{ animation: boolean }>())
export const SetPageRestart = createAction(ActionTypes.setPageRestart)
export const SetResetPageLoading = createAction(ActionTypes.setResetPageLoadingFrom)
export const SetPageLoadingFrom = createAction(
    ActionTypes.setPageLoadingFrom,
    props<{ loadingFrom: PageLoadingFrom }>(),
)
