import { Injectable } from "@angular/core"
import { JsonObject } from "../../_core/types"

export class W2Company {
    id: string
    name: string
    vatNumber: string

    address: {
        country: string
        postcode: string
        phoneNumber: string
        city: string
        houseNumber: string
        houseName: string
        province: string
        simpleValue: string
        street: string
        countryId: number
        stateId: number
    }

    companyActivities: {
        activityCode: string
        activityDescription: string
    }[]

    currentDirectors: {
        fullName: string
        dob: string
        address: {
            postcode: string
            simpleValue: string
        }
        position: {
            value: string
            appointmentDate: string
        }[]
    }[]

    shareholdersRequiringKYC: {
        fullName: string
        firstName: string
        lastName: string
        percentageShare: string
        companyName: string
        phoneNumber: string
    }[]

    officers: {
        uniqueId: string
        fullName: string
        firstName: string
        lastName: string
        percentageShare: string
        companyName: string
        phoneNumber: string
        isDirector: string
        isShareholder: string
        isSDIShareholder: string
        dateOfBirth: string
    }[]

    shareCapitalStructure: {
        issuedShareCapital: number
        shareholders: {
            name: string
            percentageShares: number
            formControlName: string
        }[]
    }

    webPages: string[]

    summary: {
        businessName: string
        companyRegistrationNumber: string
        companyStatus: string
        contactAddress: {
            country: string
            postcode: string
            phoneNumber: string
            city: string
            houseNumber: string
            houseName: string
            province: string
            simpleValue: string
            street: string
        }
    }

    contactTelephoneNumber: string

    country: string

    creditRating: {
        commonCreditRatingDescription: string
        creditLimit: number
        providerCreditRating0To100: number
        providerRatingDescription: string
        value: number
    }

    dateOfCompanyRegistration: string
    dateOfStartingOperations: string
    latestShareholderEquityFiqure: string
    legalForm: string
    mainActivity: {
        activityCode: string
        activityDescription: string
    }

    number: string
    registeredCompanyName: string
    vatRegistrationDate: string
    registrationNumber: string
}

@Injectable({
    providedIn: "root",
})
export class W2CompanySerializer {
    fromJson(json: JsonObject): W2Company {
        const company = new W2Company()

        company.id = json.id
        company.name = json.name
        company.vatNumber = json.vat_number

        company.address = {
            country: json.address?.country,
            postcode: json.address?.postcode,
            phoneNumber: json.address?.phone_number,
            city: json.address?.city,
            houseNumber: json.address?.house_number,
            houseName: json.address?.house_name,
            province: json.address?.province,
            simpleValue: json.address?.simple_value,
            street: json.address?.street,
            countryId: json.address?.country_id,
            stateId: json.address?.state_id,
        }
        company.companyActivities = json.company_activities
            ? json.company_activities.map(item => {
                  return {
                      activityCode: item.activity_code,
                      activityDescription: item.activity_description,
                  }
              })
            : null
        company.currentDirectors = json.current_directors
            ? json.current_directors.map(item => {
                  return {
                      fullName: item.full_name,
                      dob: item.dob,
                      address: {
                          postcode: item.address?.postcode,
                          simpleValue: item.address?.simple_value,
                      },
                      position: item.position
                          ? item.position.map(pos => {
                                return {
                                    value: pos.value,
                                    appointmentDate: pos.appointment_date,
                                }
                            })
                          : null,
                  }
              })
            : null
        company.shareholdersRequiringKYC = json.shareholders_requiring_kyc
            ? json.shareholders_requiring_kyc.map(item => {
                  return {
                      fullName: item.full_name,
                      firstName: item.first_name,
                      lastName: item.last_name,
                      percentageShare: item.percentage_share,
                      companyName: item.company_name,
                      phoneNumber: item.phone_number,
                  }
              })
            : null
        company.officers = json.officers
            ? json.officers.map(item => {
                  return {
                      uniqueId: item.unique_id,
                      fullName: item.full_name,
                      firstName: item.first_name,
                      lastName: item.last_name,
                      percentageShare: item.percentage_share,
                      companyName: item.company_name,
                      phoneNumber: item.phone_number,
                      isDirector: item.is_director,
                      isShareholder: item.is_shareholder,
                      isSDIShareholder: item.is_sdi_shareholder,
                      dateOfBirth: item.date_of_birth,
                  }
              })
            : null
        company.shareCapitalStructure = {
            issuedShareCapital: json.share_capital_structure?.issued_share_capital,
            shareholders: json.share_capital_structure?.shareholders
                ? json.share_capital_structure.shareholders.map(item => {
                      return {
                          name: item.name,
                          percentageShares: item.percentage_shares,
                      }
                  })
                : null,
        }
        company.webPages = json.web_pages
        company.summary = {
            businessName: json.summary?.business_name,
            companyRegistrationNumber: json.summary?.company_registration_number,
            companyStatus: json.summary?.company_status,
            contactAddress: {
                country: json.summary?.contact_address?.country,
                postcode: json.summary?.contact_address?.postcode,
                phoneNumber: json.summary?.contact_address?.phone_number,
                city: json.summary?.contact_address?.city,
                houseNumber: json.summary?.contact_address?.house_number,
                houseName: json.summary?.contact_address?.house_name,
                province: json.summary?.contact_address?.province,
                simpleValue: json.summary?.contact_address?.simple_value,
                street: json.summary?.contact_address?.street,
            },
        }
        company.contactTelephoneNumber = json.contact_telephone_number
        company.country = json.country
        company.creditRating = {
            commonCreditRatingDescription: json.credit_rating?.common_credit_rating_description,
            creditLimit: json.credit_rating?.credit_limit,
            providerCreditRating0To100: json.credit_rating?.provider_credit_rating_0_to_100,
            providerRatingDescription: json.credit_rating?.provider_rating_description,
            value: json.credit_rating?.value,
        }
        company.dateOfCompanyRegistration = json.date_of_company_registration
        company.dateOfStartingOperations = json.date_of_starting_operations
        company.latestShareholderEquityFiqure = json.latest_shareholder_equity_fiqure
        company.legalForm = json.legal_form
        company.mainActivity = {
            activityCode: json.main_activity?.activity_code,
            activityDescription: json.main_activity?.activity_description,
        }
        company.number = json.number
        company.registeredCompanyName = json.registered_company_name
        company.vatRegistrationDate = json.vat_registration_date
        company.registrationNumber = json.registration_number
            ? json.registration_number
            : json.summary?.company_registration_number

        return company
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(company: W2Company): any {
        return {
            id: company.id,
            name: company.name,
            vat_number: company.vatNumber,
            address: {
                country: company.address?.country,
                postcode: company.address?.postcode,
                phone_number: company.address?.phoneNumber,
                city: company.address?.city,
                house_number: company.address?.houseNumber,
                house_name: company.address?.houseName,
                province: company.address?.province,
                simple_value: company.address?.simpleValue,
                street: company.address?.street,
                country_id: company.address?.countryId,
                state_id: company.address?.stateId,
            },
            company_activities: company.companyActivities
                ? company.companyActivities.map(item => {
                      return {
                          activity_code: item.activityCode,
                          activity_description: item.activityDescription,
                      }
                  })
                : null,
            current_directors: company.currentDirectors
                ? company.currentDirectors.map(item => {
                      return {
                          full_name: item.fullName,
                          dob: item.dob,
                          address: {
                              postcode: item.address?.postcode,
                              simple_value: item.address?.simpleValue,
                          },
                          position: item.position
                              ? item.position.map(pos => {
                                    return {
                                        value: pos.value,
                                        appointment_date: pos.appointmentDate,
                                    }
                                })
                              : null,
                      }
                  })
                : null,

            shareholders_requiring_kyc: company.shareholdersRequiringKYC
                ? company.shareholdersRequiringKYC.map(item => {
                      return {
                          full_name: item.fullName,
                          first_name: item.firstName,
                          last_name: item.lastName,
                          percentage_share: item.percentageShare,
                          company_name: item.companyName,
                          phone_number: item.phoneNumber,
                      }
                  })
                : null,

            officers: company.officers
                ? company.officers.map(item => {
                      return {
                          unique_id: item.uniqueId,
                          full_name: item.fullName,
                          first_name: item.firstName,
                          last_name: item.lastName,
                          percentage_share: item.percentageShare,
                          company_name: item.companyName,
                          phone_number: item.phoneNumber,
                          is_director: item.isDirector,
                          is_shareholder: item.isShareholder,
                          is_sdi_shareholder: item.isSDIShareholder,
                          date_of_birth: item.dateOfBirth,
                      }
                  })
                : null,

            share_capital_structure: {
                issued_share_capital: company.shareCapitalStructure?.issuedShareCapital,
                share_capital_structure: company.shareCapitalStructure?.shareholders
                    ? company.shareCapitalStructure.shareholders.map(item => {
                          return {
                              name: item.name,
                              percentage_shares: item.percentageShares,
                          }
                      })
                    : null,
            },
            web_pages: company.webPages,

            summary: {
                business_name: company.summary?.businessName,
                company_registration_number: company.summary?.companyRegistrationNumber,
                company_status: company.summary?.companyStatus,
                contact_address: {
                    country: company.summary?.contactAddress?.country,
                    postcode: company.summary?.contactAddress?.postcode,
                    phone_number: company.summary?.contactAddress?.phoneNumber,
                    city: company.summary?.contactAddress?.city,
                    house_number: company.summary?.contactAddress?.houseNumber,
                    house_name: company.summary?.contactAddress?.houseName,
                    province: company.summary?.contactAddress?.province,
                    simple_value: company.summary?.contactAddress?.simpleValue,
                    street: company.summary?.contactAddress?.street,
                },
            },
            contact_telephone_number: company.contactTelephoneNumber,
            country: company.country,
            credit_rating: {
                common_credit_rating_description: company.creditRating?.commonCreditRatingDescription,
                credit_limit: company.creditRating?.creditLimit,
                provider_credit_rating_0_to_100: company.creditRating?.providerCreditRating0To100,
                provider_rating_description: company.creditRating?.providerRatingDescription,
                value: company.creditRating?.value,
            },
            date_of_company_registration: company.dateOfCompanyRegistration,
            date_of_starting_operations: company.dateOfStartingOperations,
            latest_shareholder_equity_fiqure: company.latestShareholderEquityFiqure,
            legal_form: company.legalForm,
            main_activity: {
                activity_code: company.mainActivity?.activityCode,
                activity_description: company.mainActivity?.activityDescription,
            },
            number: company.number,
            registered_company_name: company.registeredCompanyName,
            vat_registration_date: company.vatRegistrationDate,
            registration_number: company.registrationNumber
                ? company.registrationNumber
                : company.summary?.companyRegistrationNumber,
        }
    }
}
