import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-success",
    template: ` <svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        class="svg-icon"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg">
        <path
            [attr.fill]="color"
            d="M681.890909 356.072727c11.636364-13.963636 34.909091-16.290909 48.872727-4.654545 13.963636 11.636364 16.290909 34.909091 4.654546 48.872727l-232.727273 276.945455c-11.636364 13.963636-34.909091 16.290909-48.872727 4.654545L302.545455 556.218182c-13.963636-11.636364-16.290909-34.909091-4.654546-48.872727 11.636364-13.963636 34.909091-16.290909 48.872727-4.654546l125.672728 104.727273 209.454545-251.345455zM512 1024C228.072727 1024 0 795.927273 0 512S228.072727 0 512 0s512 228.072727 512 512-228.072727 512-512 512z m0-46.545455c256 0 465.454545-209.454545 465.454545-465.454545S768 46.545455 512 46.545455 46.545455 256 46.545455 512s209.454545 465.454545 465.454545 465.454545z" />
    </svg>`,
})
export class SuccessIconComponent {
    @Input() width: number = 40
    @Input() height: number = 40
    @Input() color: string = "#fff"
}
