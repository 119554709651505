import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-paypanel",
    template: `<svg
        id="Layer_1"
        [ngStyle]="{ width: size }"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 740 183">
        <defs>
            <style>
                .cls-1 {
                    fill: #97c1ff;
                }

                .cls-2 {
                    fill: #307aff;
                }
            </style>
        </defs>
        <g>
            <path
                class="cls-2"
                d="m154.14,51.98h19.38v8.99h.28c.84-1.22,1.94-2.46,3.3-3.72,1.36-1.26,2.95-2.41,4.78-3.44,1.83-1.03,3.81-1.87,5.97-2.53,2.15-.65,4.45-.98,6.88-.98,5.06,0,9.64.87,13.76,2.6,4.12,1.73,7.65,4.17,10.6,7.3,2.95,3.14,5.22,6.86,6.81,11.16,1.59,4.31,2.39,9.04,2.39,14.18,0,4.77-.73,9.34-2.18,13.69-1.45,4.35-3.51,8.21-6.18,11.58-2.67,3.37-5.92,6.06-9.76,8.08-3.84,2.01-8.19,3.02-13.06,3.02-4.4,0-8.5-.68-12.29-2.04-3.79-1.36-6.9-3.67-9.34-6.95h-.28v18.25c0,11.63-9.43,21.06-21.06,21.06h0V51.98Zm19.38,34.12c0,5.15,1.47,9.32,4.42,12.5,2.95,3.18,7.09,4.77,12.43,4.77s9.48-1.59,12.43-4.77c2.95-3.18,4.42-7.35,4.42-12.5s-1.47-9.31-4.42-12.5c-2.95-3.18-7.09-4.77-12.43-4.77s-9.48,1.59-12.43,4.77c-2.95,3.18-4.42,7.35-4.42,12.5Z" />
            <path
                class="cls-2"
                d="m283.47,111.66h-.28c-2.34,3.65-5.45,6.27-9.34,7.86-3.88,1.59-7.98,2.39-12.29,2.39-3.19,0-6.25-.45-9.2-1.33-2.95-.89-5.55-2.22-7.79-4-2.25-1.78-4.03-3.98-5.34-6.6-1.31-2.62-1.97-5.66-1.97-9.13,0-3.93.72-7.25,2.18-9.97,1.45-2.71,3.42-4.96,5.9-6.74,2.48-1.78,5.31-3.16,8.5-4.14,3.18-.98,6.48-1.71,9.9-2.18,3.42-.47,6.83-.75,10.25-.84,3.42-.09,6.58-.14,9.48-.14,0-3.74-1.33-6.72-4-8.92-2.67-2.2-5.83-3.3-9.48-3.3-3.46,0-6.62.73-9.48,2.18-2.86,1.45-5.41,3.44-7.65,5.97l-11.23-11.52c3.93-3.65,8.52-6.39,13.76-8.21,5.24-1.83,10.67-2.74,16.29-2.74,6.18,0,11.26.77,15.24,2.32,3.98,1.54,7.16,3.82,9.55,6.81,2.39,3,4.05,6.67,4.99,11.02.93,4.35,1.4,9.39,1.4,15.1v34.69h-19.38v-8.57Zm-5.2-21.34c-1.59,0-3.58.07-5.97.21-2.39.14-4.68.54-6.88,1.19-2.2.66-4.07,1.64-5.62,2.95-1.55,1.31-2.32,3.14-2.32,5.48,0,2.53,1.07,4.4,3.23,5.62,2.15,1.22,4.4,1.83,6.74,1.83,2.06,0,4.05-.28,5.97-.84,1.92-.56,3.63-1.36,5.12-2.39,1.5-1.03,2.69-2.34,3.58-3.93.89-1.59,1.33-3.46,1.33-5.62v-4.49h-5.2Z" />
            <path
                class="cls-2"
                d="m355.37,131.32c-1.4,3.65-2.76,6.88-4.07,9.69-1.31,2.81-2.97,5.17-4.99,7.09-2.01,1.92-4.56,3.37-7.65,4.35-3.09.98-7.16,1.47-12.22,1.47-5.43,0-10.67-.84-15.73-2.53l2.81-17.27c3.18,1.4,6.46,2.11,9.83,2.11,2.34,0,4.26-.26,5.76-.77,1.5-.52,2.74-1.26,3.72-2.25s1.8-2.18,2.46-3.58c.65-1.4,1.36-3.04,2.11-4.92l1.55-3.93-29.91-68.81h22.75l17.55,45.08h.28l15.03-45.08h21.63l-30.89,79.34Z" />
            <path
                class="cls-2"
                d="m396.79,54.51h9.27v10.95h.28c1.5-2.25,3.28-4.17,5.34-5.76,2.06-1.59,4.21-2.9,6.46-3.93,2.25-1.03,4.56-1.78,6.95-2.25,2.39-.47,4.66-.7,6.81-.7,5.15,0,9.85.87,14.11,2.6,4.26,1.73,7.93,4.14,11.02,7.23s5.5,6.74,7.23,10.95c1.73,4.21,2.6,8.8,2.6,13.76s-.87,9.55-2.6,13.76c-1.74,4.21-4.14,7.86-7.23,10.95-3.09,3.09-6.76,5.5-11.02,7.23-4.26,1.73-8.96,2.6-14.11,2.6-2.15,0-4.42-.24-6.81-.7-2.39-.47-4.7-1.22-6.95-2.25-2.25-1.03-4.4-2.34-6.46-3.93-2.06-1.59-3.84-3.51-5.34-5.76h-.28v42.13c0,5.12-4.15,9.27-9.27,9.27h0V54.51Zm59.96,32.86c0-3.65-.59-7.07-1.75-10.25-1.17-3.18-2.83-5.94-4.99-8.29-2.16-2.34-4.75-4.19-7.79-5.55-3.04-1.36-6.48-2.04-10.32-2.04s-7.35.68-10.53,2.04c-3.19,1.36-5.95,3.21-8.29,5.55-2.34,2.34-4.17,5.1-5.48,8.29-1.31,3.18-1.97,6.6-1.97,10.25s.65,7.07,1.97,10.25c1.31,3.18,3.13,5.95,5.48,8.29,2.34,2.34,5.1,4.19,8.29,5.55,3.18,1.36,6.69,2.04,10.53,2.04s7.28-.68,10.32-2.04c3.04-1.36,5.64-3.21,7.79-5.55,2.15-2.34,3.81-5.1,4.99-8.29,1.17-3.18,1.75-6.6,1.75-10.25Z" />
            <path
                class="cls-2"
                d="m524.72,79.93v-1.69c0-11.33-5.62-16.99-16.85-16.99-7.68,0-14.37,2.58-20.08,7.72l-5.62-6.6c6.18-6.37,15.45-9.55,27.8-9.55,3.18,0,6.25.47,9.2,1.4,2.95.94,5.5,2.37,7.65,4.28,2.15,1.92,3.88,4.33,5.19,7.23,1.31,2.9,1.97,6.37,1.97,10.39v29.35c0,2.53.12,5.17.35,7.93.23,2.76.49,5.03.77,6.81h-8.99c-.28-1.59-.49-3.32-.63-5.2-.14-1.87-.21-3.7-.21-5.48h-.28c-2.72,4.4-5.92,7.56-9.62,9.48-3.7,1.92-8.21,2.88-13.55,2.88-2.9,0-5.71-.4-8.43-1.19-2.72-.79-5.13-2.01-7.23-3.65-2.11-1.64-3.79-3.65-5.06-6.04-1.26-2.39-1.9-5.17-1.9-8.36,0-5.34,1.38-9.53,4.14-12.57,2.76-3.04,6.23-5.31,10.39-6.81,4.16-1.5,8.61-2.43,13.34-2.81,4.73-.37,9.06-.56,12.99-.56h4.63Zm-4.78,7.58c-2.34,0-5.27.12-8.78.35-3.51.23-6.88.8-10.11,1.68-3.23.89-5.99,2.27-8.29,4.14-2.3,1.87-3.44,4.45-3.44,7.72,0,2.16.44,4,1.33,5.55.89,1.54,2.08,2.81,3.58,3.79,1.5.98,3.16,1.69,4.99,2.11,1.83.42,3.67.63,5.55.63,3.37,0,6.29-.56,8.78-1.69,2.48-1.12,4.56-2.64,6.25-4.56,1.69-1.92,2.93-4.16,3.72-6.74.8-2.57,1.19-5.31,1.19-8.21v-4.77h-4.78Z" />
            <path
                class="cls-2"
                d="m564.32,54.51c.19,1.78.3,3.53.35,5.27.05,1.73.07,3.49.07,5.27h.28c1.03-1.78,2.34-3.42,3.93-4.92,1.59-1.5,3.37-2.78,5.34-3.86,1.97-1.08,4.05-1.92,6.25-2.53,2.2-.61,4.37-.91,6.53-.91,8.43,0,14.65,2.22,18.68,6.67,4.02,4.45,6.04,10.79,6.04,19.03v41.71h-9.27v-36.37c0-7.3-1.26-12.89-3.79-16.78-2.53-3.88-7.21-5.83-14.04-5.83-.47,0-1.78.19-3.93.56-2.16.38-4.42,1.4-6.81,3.09s-4.52,4.21-6.39,7.58c-1.87,3.37-2.81,8.05-2.81,14.04v33.7h-9.27v-51.26c0-1.78-.07-4.02-.21-6.74-.14-2.71-.31-5.29-.49-7.72h9.55Z" />
            <path
                class="cls-2"
                d="m640.01,89.9c.28,3.28,1.07,6.37,2.39,9.27,1.31,2.9,3.04,5.41,5.2,7.51,2.15,2.11,4.63,3.77,7.44,4.99,2.81,1.22,5.85,1.83,9.13,1.83,4.96,0,9.24-1.15,12.85-3.44,3.6-2.29,6.34-4.94,8.21-7.93l7.16,5.9c-3.93,4.96-8.26,8.52-12.99,10.67-4.73,2.15-9.81,3.23-15.23,3.23-4.87,0-9.39-.87-13.55-2.6-4.17-1.73-7.75-4.14-10.74-7.23-3-3.09-5.36-6.74-7.09-10.95-1.73-4.21-2.6-8.8-2.6-13.76s.84-9.55,2.53-13.76c1.68-4.21,4.02-7.86,7.02-10.95,3-3.09,6.51-5.5,10.53-7.23,4.02-1.73,8.38-2.6,13.06-2.6,4.96,0,9.41.89,13.34,2.67,3.93,1.78,7.23,4.14,9.9,7.09,2.67,2.95,4.7,6.41,6.11,10.39,1.4,3.98,2.11,8.21,2.11,12.71v4.21h-54.77Zm44.66-7.58c0-6.27-1.87-11.35-5.62-15.24-3.74-3.88-8.99-5.83-15.73-5.83-3,0-5.9.59-8.71,1.76-2.81,1.17-5.27,2.74-7.37,4.7-2.11,1.97-3.79,4.21-5.06,6.74-1.26,2.53-1.89,5.15-1.89,7.86h44.37Z" />
            <path class="cls-2" d="m723,120.23h-9.27V14.07h9.27v106.16Z" />
        </g>
        <path
            class="cls-1"
            d="m96.54,123.83l-5.76-5.11c9.6-9.6,21.34-21.58,21.14-37.6-.25-20.49-16.71-37.7-37.17-38.82-22.75-1.24-43.96,16.97-41.58,39.32,1.91,17.89,9.59,25.58,17.53,33.13l45.84,44.3c17.29-21.13,0-35.22,0-35.22Z" />
        <path
            class="cls-2"
            d="m72.55,123.42l-5.76-5.11c9.6-9.6,21.34-21.58,21.14-37.6-.25-20.49-16.71-37.7-37.17-38.82-22.75-1.24-43.96,16.97-41.58,39.32,1.91,17.89,9.59,25.58,17.53,33.13l45.84,44.3c17.29-21.13,0-35.22,0-35.22Zm-23.99-57.83c8.63,0,15.62,6.99,15.62,15.62s-6.99,15.62-15.62,15.62-15.62-6.99-15.62-15.62,6.99-15.62,15.62-15.62Z" />
    </svg>`,
})
export class PaypanelComponent {
    @Input() size: string = "120px"
}
