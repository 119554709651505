/* eslint-disable @typescript-eslint/naming-convention */
import { AssociativeArray } from "../_core/types"
import { PhoneNumberUtil } from "google-libphonenumber"
import { UntypedFormGroup } from "@angular/forms"

/**
 * Helper function
 *
 */
export default class HelperVerofy {
    /**
     * Generate unique ID
     */
    static guid(): string {
        const s4 = (): string =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
    }

    /**
     * Format currency value by currency char - GBP
     */
    static formatCurrency(
        value: number,
        currency: string,
        digits: number = 2,
        optional: { [key: string]: number | string | boolean } = { default: "null" },
    ): string {
        let minus = 0
        if (value < 0) {
            value = value * -1
            minus = 1
        }
        value = parseFloat(String(value))
        let valueFormatted: string | number = value
        let currencyWithValue: string = ""

        if (currency === "GBP") {
            currencyWithValue = "£"
        } else if (currency === "EUR") {
            currencyWithValue = "€"
        } else if (currency === "USD") {
            currencyWithValue = "$"
        } else {
            currencyWithValue = ""
        }
        //This check is for formating the currency without effecting the decimals and to show as much decimal points we want
        if (optional.fourDecimal) {
            const formattedValue = value.toFixed(digits)
            const [integerPart, decimalPart] = formattedValue.split(".")

            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            valueFormatted = `${formattedIntegerPart}.${decimalPart}`
            return (minus ? "- " : "") + currencyWithValue + valueFormatted
        }

        valueFormatted = value.toFixed(digits).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

        return (minus ? "- " : "") + currencyWithValue + valueFormatted
    }

    /**
     * Converts associative array to array of objects `{label, value}`
     */
    static convertAssociativeArrayToOptions(items: AssociativeArray): { label: string; value: number }[] {
        const options = []
        for (const [key, value] of Object.entries(items)) {
            options.push({
                label: value,
                value: parseInt(key, 2),
            })
        }
        return options
    }

    /**
     * Generates uniques ID for API communication tracking
     */
    static generateMasterRequestId(): string {
        return "_MP" + (new Date().getUTCMilliseconds().toString() + new Date().getTime().toString()).toString()
    }

    /**
     * Pads a raw number with leading characters (zeros by default)
     *
     * @param rawNumber -
     * @param padLength -
     * @param padChar -
     */
    static padNumberLeft(rawNumber: number, padLength: number, padChar: string = "0"): string {
        const pad = new Array(1 + padLength).join(padChar)
        return (pad + rawNumber).slice(-pad.length)
    }

    /**
     * Returns the current date minus the specific number of months
     *
     * @param subMonths -
     */
    static dateMonthsAgo(subMonths = 0): Date {
        const date = new Date()
        date.setMonth(date.getMonth() - subMonths)
        return date
    }

    /**
     * Returns the current date minus the specific number of days
     *
     * @param subDays -
     */
    static dateDaysAgo(subDays = 0): Date {
        const date = new Date()
        date.setMonth(date.getDay() - subDays)
        return date
    }

    /**
     * Returns the current date minus the specific number of years
     *
     * @param subYears -
     */
    static dateYearsAgo(subYears = 0): Date {
        const date = new Date()
        date.setFullYear(date.getFullYear() - subYears)
        return date
    }

    static getMonthName(month: number): string {
        const months = {
            "01": "January",
            "02": "February",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            "10": "October",
            "11": "November",
            "12": "December",
        }
        return months[month] || ""
    }

    /**
     * Format phone number - get national number
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    static getNationalPhoneNumber(phoneNumber: string): string {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber)
        const formatInOriginalFormat = phoneNumberUtil.formatInOriginalFormat(parse)
        return formatInOriginalFormat
    }

    /**
     * Format phone number - get region code
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    static getRegionCodeOfPhoneNumber(phoneNumber: string): string {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        const countryCode = parse.getCountryCode()
        return phoneNumberUtil.getRegionCodeForCountryCode(countryCode)
    }

    /**
     * Format phone number - get parse boject
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getParseObjectOfPhoneNumber(phoneNumber: string): any {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parseAndKeepRawInput(phoneNumber, "")
        return parse
    }

    /**
     * Format phone number - get formated international phone number
     * - https://www.npmjs.com/package/google-libphonenumber
     *
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getFormatedInternationalPhoneNumber(phoneNumber: string): any {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        return phoneNumberUtil.formatOutOfCountryCallingNumber(parse)
    }

    /**
     * Get object for ngx-intl-tel-input initialization
     *
     * @returns
     */
    static getNgxIntlTelInputObject(phoneNumber: string): {
        number: string
        internationalNumber: string
        nationalNumber: string
        countryCode: string
        dialCode: string
    } {
        const phoneNumberUtil = PhoneNumberUtil.getInstance()
        const parse = phoneNumberUtil.parse(phoneNumber, "")
        const countryCode = parse.getCountryCode()

        return {
            number: phoneNumberUtil.formatInOriginalFormat(parse).toString(),
            internationalNumber: phoneNumberUtil.formatOutOfCountryCallingNumber(parse),
            nationalNumber: phoneNumberUtil.getNationalSignificantNumber(parse), // formatInOriginalFormat
            countryCode: phoneNumberUtil.getRegionCodeForCountryCode(countryCode),
            dialCode: "+" + countryCode,
        }
    }

    static formatNumber(value: number | string, action: string, options?: Intl.NumberFormatOptions): number | string {
        if (action === "numberFormat") {
            const currencyFormatter = Intl.NumberFormat("en-GB", options)
            return currencyFormatter.format(Number(value))
        }

        if (action === "reverseNumberFormat") {
            const thousandSeparator = Intl.NumberFormat("en")
                .format(11111)
                .replace(/\p{Number}/gu, "")
            const decimalSeparator = Intl.NumberFormat("en")
                .format(1.1)
                .replace(/\p{Number}/gu, "")

            return parseFloat(
                value
                    .toString()
                    .replace(new RegExp("\\" + thousandSeparator, "g"), "")
                    .replace(new RegExp("\\" + decimalSeparator), "."),
            )
        }

        if (action === "removeCurrency") {
            return value.toString().replace("£", "")
        }

        return value
    }

    static numberWithCommas(value: number | string): string {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    /**
     * is Mobile Device
     */

    static isMobileDevice(): boolean {
        const userAgent = navigator.userAgent || navigator.vendor
        return /android|iPad|iPhone|iPod/.test(userAgent.toLowerCase())
    }

    // Method to card rate number to Four decimal
    static roundToFourDecimalPlaces(value: number): number {
        return Math.round(value * 10000) / 10000
    }

    // // convert Pence To Number
    static convertPenceToNumber(pence: number): number {
        // Convert pence to a number with up to 4 decimal places
        const numberValue = (pence * 1000) / 10
        // Round the result to 2 decimal places
        return Math.round(numberValue * 100) / 100
    }

    static findInvalidControls(form: UntypedFormGroup) {
        const invalid = []
        const controls = form.controls
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name)
            }
        }
        return invalid
    }

    static customerRegistrationGetContractLengthMonth(value): number {
        switch (value) {
            case 1:
                return 12
            case 2:
                return 24
            case 3:
                return 36
            case 4:
                return 48
        }
    }

    static formatNumbertoDecimals(number: number): number {
        return Number((Math.round(number * 100) / 100).toFixed(2))
    }

    static convertPoundsToPences(number: number): number {
        return this.formatNumbertoDecimals(number * 100)
    }
}
