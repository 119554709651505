import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from "ngx-mask"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { AlertListComponent } from "./alert/alertList.component"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { ValidMaxValueDirective } from "../_validators/directives/check-max-value.directive"
import { ValidMinValueDirective } from "../_validators/directives/check-min-value.directive"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { NgxIntlTelInputModule } from "ngx-intl-tel-input"
import { CodeInputModule } from "angular-code-input"
import { AppSvgModule } from "@app/_components/app-svg/app-svg.module"
import { AlertComponentComponent } from "./alert/alert-component/alert-component.component"
import { AppLoadingComponent } from "@app/_components/app-loading/app-loading.component"
import { NgxCurrencyDirective } from "ngx-currency"
import { OrdinalPipe } from "@app/utils/ordinal.pipe"
import { SentenceCasePipe } from "@app/utils/sentance_case.pipe"
import { CopyClipboardComponent } from "@appComponents/copy-clipboard/copy-clipboard.component"
import { DateRangeSelectorComponent } from "./Components/date-range-selector/date-range-selector.component"
import { ReactiveFormsModule } from "@angular/forms"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatButtonModule } from "@angular/material/button"
import { MatMenuModule } from "@angular/material/menu"
import { AlertDialogComponent } from "./Components/alert-dialog/alert-dialog.component"
import { MatDialogModule } from "@angular/material/dialog"
import { PaginationControlsComponent } from "./Components/pagination-controls/pagination-controls.component"

@NgModule({
    declarations: [
        AlertListComponent,
        ValidMinValueDirective,
        ValidMaxValueDirective,
        AlertComponentComponent,
        AppLoadingComponent,
        OrdinalPipe,
        SentenceCasePipe,
        CopyClipboardComponent,
        DateRangeSelectorComponent,
        AlertDialogComponent,
        PaginationControlsComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbModule,
        BsDropdownModule,
        NgxIntlTelInputModule,
        CodeInputModule,
        NgxCurrencyDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        AppSvgModule,
        MatFormFieldModule,
        MatButtonModule,
        MatMenuModule,
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule,
        MatDialogModule,
    ],
    exports: [
        AlertListComponent,
        FontAwesomeModule,
        NgbModule,
        ValidMinValueDirective,
        ValidMaxValueDirective,
        BsDropdownModule,
        NgxIntlTelInputModule,
        CodeInputModule,
        NgxCurrencyDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        AppLoadingComponent,
        OrdinalPipe,
        SentenceCasePipe,
        AppSvgModule,
        CopyClipboardComponent,
        DateRangeSelectorComponent,
        PaginationControlsComponent,
    ],
    providers: [provideNgxMask()],
})
export class SharedModule {}
