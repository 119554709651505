import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { User, UserSerializer } from "./user"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class CustomerUser {
    id = 0
    createdAt: Date
    updatedAt: Date

    login: string
    name: string
    phone: string
    dob: Date
    email: string
    role: number
    superiorUserId: number
    permissions: Array<number>
    user: User
}

@Injectable({
    providedIn: "root",
})
export class CustomerUserSerializer implements ApiResourceSerializer {
    constructor(private userSerializer: UserSerializer) {}

    fromJson(json: JsonObject): CustomerUser {
        const merUser = new CustomerUser()
        merUser.id = json.id
        merUser.createdAt = new Date(json.created_at)
        merUser.updatedAt = new Date(json.updated_at)
        merUser.name = json.name
        merUser.phone = json.phone
        merUser.dob = new Date(json.dob)
        merUser.email = json.email
        merUser.role = json.role
        merUser.superiorUserId = json.superior_user_id
        merUser.permissions = json.permissions

        merUser.user = json.user !== undefined ? this.userSerializer.fromJson(json.user) : null

        return merUser
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(merUser: CustomerUser): any {
        return {}
    }
}
