import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class WidgetAllBusinessOverview extends ApiResource {
    id = 0
    year: number
    month: number

    transactionsTotalAmount: string
    customerLiveCount: number
    commissionsTotalPaidAmount: string
    totalFinanceAmount: string
    totalResidualAmount: string
}

@Injectable({
    providedIn: "root",
})
export class WidgetAllBusinessOverviewSerializer implements ApiResourceSerializer {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): WidgetAllBusinessOverview {
        const record = new WidgetAllBusinessOverview()
        record.year = json.year
        record.month = json.month

        record.transactionsTotalAmount = json.transactions_total_amount ?? "0"
        record.customerLiveCount = json.customer_live_count ?? 0
        record.commissionsTotalPaidAmount = json.commissions_total_paid_amount ?? "0"
        record.totalFinanceAmount = json.total_finance_amount ?? "0"
        record.totalResidualAmount = json.total_residual_amount ?? "0"

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: WidgetAllBusinessOverview): any {
        return {
            year: record.year,
            month: record.month,

            transactions_total_amount: record.transactionsTotalAmount,
            customer_live_count: record.customerLiveCount,
            commissions_total_paid_amount: record.commissionsTotalPaidAmount,
            total_finance_amount: record.totalFinanceAmount,
            total_residual_amount: record.totalResidualAmount,
        }
    }
}
