import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { JsonObject } from "../_core/types"

export class SwitchablePartner implements ApiResource {
    id: number
    name: string
}

@Injectable({
    providedIn: "root",
})
export class SwitchablePartnerSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): SwitchablePartner {
        const record = new SwitchablePartner()
        record.id = json.id
        record.name = json.name
        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: SwitchablePartner): any {
        return {
            id: record.id,
            name: record.name,
        }
    }
}
