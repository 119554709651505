import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { LookUpAddress } from "./look-up-address"

export class CRAddress {
    id: number
    type: string
    sameAsAddress: string
    houseName: string
    houseNumber: string
    streetName: string
    addressLine1: string
    city: string
    postcode: string
    country: string
    original?: LookUpAddress
}

@Injectable({
    providedIn: "root",
})
export class CRAddressSerializer implements ApiResourceSerializer {
    constructor() {}

    addressFromJson(value: string): string {
        if (value === "address_1") {
            return "address1"
        }
        if (value === "address_2") {
            return "address2"
        }
        return value
    }

    addressToJson(value: string): string {
        if (value === "address1") {
            return "address_1"
        }
        if (value === "address2") {
            return "address_1"
        }
        return value
    }

    fromJson(json: JsonObject): CRAddress {
        const data = new CRAddress()
        data.id = json.id
        data.type = json.type
        data.sameAsAddress = this.addressFromJson(json.same_as_address)

        data.houseName = json.house_name
        data.houseNumber = json.house_number
        data.streetName = json.street_name
        data.addressLine1 = json.address_line_1
        data.city = json.city
        data.postcode = json.postcode
        data.country = json.country

        data.original = json.original
        return data
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(data: CRAddress): any {
        return {
            id: data.id,
            type: data.type,
            same_as_address: this.addressToJson(data.sameAsAddress),

            house_name: data.houseName,
            house_number: data.houseNumber,
            street_name: data.streetName,
            address_line_1: data.addressLine1,
            city: data.city,
            postcode: data.postcode,
            country: data.country,

            original: data.original,
        }
    }
}
