import { Injectable } from "@angular/core"
import { JsonObject } from "../../_core/types"

export class BusinessType {
    id = 0
    name: string
    category: string
}

@Injectable({
    providedIn: "root",
})
export class BusinessTypeSerializer {
    fromJson(json: JsonObject): BusinessType {
        const businessType = new BusinessType()
        businessType.id = json.id
        businessType.name = json.name
        businessType.category = json.category

        return businessType
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(businessType: BusinessType): any {
        return {
            id: businessType.id,
            name: businessType.name,
            category: businessType.category,
        }
    }
}
