import { Injectable } from "@angular/core"
import { Router, ActivatedRouteSnapshot } from "@angular/router"
import { AuthenticationService } from "@app/_services/authentication.service"

@Injectable()
export class CustomerRegistrationAuthGuard {
    constructor(private router: Router, private authService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // state: RouterStateSnapshot
        // TODO
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        const currentUser = this.authService.currentUserValue

        const hash = route.paramMap.get("hash")
        if (hash !== undefined && hash !== "") {
            return true
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(["/login"]) // { queryParams: { returnUrl: state.url } }
        return false
    }
}
