/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssociativeArray } from "../../_core/types"
import { CustomerCloudMessage, CustomerCloudMessageSerializer } from "../../_models/customer-cloud-message"
import { ApiSubResourceService } from "./api-sub-resource.service"
import { PaginationSerializer } from "../../_models/pagination"
import { Injectable, Injector } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { map } from "rxjs/operators"
import { Observable } from "rxjs"
import { Inbox, InboxSerializer } from "@app/_models/inbox"

@Injectable({
    providedIn: "root",
})
export class CustomerCloudMessagesApiService extends ApiSubResourceService<CustomerCloudMessage> {
    constructor(
        httpClient: HttpClient,
        customerNotificationSerializer: CustomerCloudMessageSerializer,
        protected paginationSerializer: PaginationSerializer,
        private injector: Injector,
    ) {
        super(
            httpClient,
            environment.mainApi.url.slice(0, -1),
            environment.mainApi.endpoints.sellers,
            "cloud-messages",
            customerNotificationSerializer,
            paginationSerializer,
        )
    }

    public inbox(
        parentId: number,
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<any> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const serializer = this.injector.get(InboxSerializer)
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/inbox`
        return this.httpClient
            .get<Inbox>(url, { headers, params })
            .pipe(map((data: any) => serializer.fromJson(data.data)))
    }

    public read(parentId: number, id: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}/read`
        return this.httpClient.put(url, customHeaders)
    }

    public multipleRead(parentId: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/read`
        return this.httpClient.put(url, customHeaders)
    }

    public multipleUnread(parentId: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/unread`
        return this.httpClient.put(url, customHeaders)
    }

    public archive(parentId: number, id: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}/archive`
        return this.httpClient.put(url, customHeaders)
    }

    public multipleArchive(parentId: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/archive`
        return this.httpClient.put(url, customHeaders)
    }

    public multipleUnArchive(parentId: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const url = `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/unarchive`
        return this.httpClient.put(url, customHeaders)
    }

    public comment(
        parentId: number,
        notificationId: number,
        customHeaders: AssociativeArray<string> = {},
    ): Observable<CustomerCloudMessage> {
        return this.httpClient
            .post(
                `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/${notificationId}/replies`,
                customHeaders,
            )
            .pipe(map((data: any) => this.serializer.fromJson(data.data) as CustomerCloudMessage))
    }
}
