import { signal } from "@angular/core"
import { DatePickerType, TransactionDatePickerState } from "@app/store/reducers/TransactionReducer"

const TransactionDatePickerSignal = signal<TransactionDatePickerState>({
    toDate: new Date(),
    fromDate: new Date(),
    datepickerType: DatePickerType.exact,
    exactDate: new Date(),
    maxFromDate: new Date(),
    minToDate: new Date(),
})

const ResetTransactionSignalValue = (): void => {
    TransactionDatePickerSignal.update(() => ({
        fromDate: new Date(),
        toDate: new Date(),
        exactDate: new Date(),
        datepickerType: DatePickerType.range,
        maxFromDate: new Date(),
        minToDate: new Date(),
    }))
}

export { TransactionDatePickerSignal, ResetTransactionSignalValue }
