import {
    WidgetQuotesAndApplicationsCountOverall,
    WidgetQuotesAndApplicationsCountOverallSerializer,
} from "./../../_models/widget-quotes-and-applications-count-overall"
import { PaginationSerializer } from "@appModels/pagination"
import { Injectable } from "@angular/core"
import { ApiSubResourceService } from "./api-sub-resource.service"
import { HttpClient } from "@angular/common/http"
import { environment } from "@env/environment"

@Injectable({
    providedIn: "root",
})
export class WidgetQuotesAndApplicationCountOverallApiService extends ApiSubResourceService<WidgetQuotesAndApplicationsCountOverall> {
    constructor(
        httpClient: HttpClient,
        seralizer: WidgetQuotesAndApplicationsCountOverallSerializer,
        protected paginationSerializer: PaginationSerializer,
    ) {
        super(
            httpClient,
            environment.mainApi.url.slice(0, -1),
            environment.mainApi.endpoints.widgets,
            "quotes-and-applications/total-count/overall",
            seralizer,
            paginationSerializer,
        )
    }
}
