import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { JsonObject } from "../_core/types"

export class User implements ApiResource {
    id: number
    accessToken?: string
    refreshToken?: string

    login: string
    name: string
    email: string
    loginAt: Date
    phoneNumber: string
    permissions: Array<number>
    partnersIds: Array<number>

    requires2Fa: boolean
    req2FaMethod: string

    sellerId: number

    newPassword = ""
}

@Injectable({
    providedIn: "root",
})
export class UserSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): User {
        const user = new User()
        user.id = json.id
        user.accessToken = json.access_token
        user.refreshToken = json.refresh_token
        user.login = json.login
        user.name = json.name
        user.email = json.email
        user.loginAt = json.login_at
        user.phoneNumber = json.phone_number
        user.permissions = json.permissions
        user.partnersIds = json.partners_ids

        user.requires2Fa = json.requires_2fa
        user.req2FaMethod = json.req_2fa_method

        user.sellerId = json.seller_id

        return user
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(user: User): any {
        return {
            id: user.id,
            name: user.name,
            login: user.login,
            email: user.email,
            new_password: user.newPassword,
        }
    }
}
