import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class SystemFile {
    id = 0
    createdAt: Date
    updatedAt: Date
    fullUrl: string
    path: string
    fileSize: number
    mimeType: string
    fileName: string
}

@Injectable({
    providedIn: "root",
})
export class SystemFileSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): SystemFile {
        const record = new SystemFile()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.fullUrl = json.full_url
        record.path = json.path
        record.fileSize = json.file_size
        record.mimeType = json.mime_type
        record.fileName = json.file_name
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: SystemFile): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            full_url: record.fullUrl,
            path: record.path,
            file_size: record.fileSize,
            mime_type: record.mimeType,
            file_name: record.fileName,
        }
    }
}
