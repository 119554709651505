import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-vsupport-logo",
    template: `<svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150.97 35">
        <defs>
            <style>
                .cls-1 {
                    fill: #2f7aff;
                    stroke-width: 0;
                }
            </style>
        </defs>
        <g>
            <polygon class="cls-1" points="18.9 4.6 15.53 11.08 12.09 4.6 9.88 4.6 15.58 15.2 21.18 4.6 18.9 4.6" />
            <path
                class="cls-1"
                d="m44.26,4.6c-2.94,0-5.33,2.39-5.33,5.33s2.39,5.33,5.33,5.33,5.33-2.39,5.33-5.33-2.39-5.33-5.33-5.33Zm0,8.49c-1.74,0-3.16-1.42-3.16-3.16s1.42-3.16,3.16-3.16,3.16,1.42,3.16,3.16-1.42,3.16-3.16,3.16Z" />
            <path
                class="cls-1"
                d="m25.78,4.55c-2.94,0-5.33,2.39-5.33,5.33s2.39,5.33,5.33,5.33c2.29,0,4.31-1.44,5.04-3.6h-2.41c-.59.89-1.57,1.42-2.64,1.42-.69,0-1.34-.22-1.89-.63-.53-.4-.93-.97-1.12-1.6l-.02-.08h8.31c.04-.28.07-.57.07-.84,0-2.94-2.39-5.33-5.33-5.33Zm-2.88,4.05l.04-.09c.53-1.1,1.62-1.79,2.84-1.79s2.31.68,2.84,1.79l.04.09h-5.76Z" />
            <path
                class="cls-1"
                d="m32.89,7.57c-.3.58-.48,1.24-.55,2.01-.12,1.43.03,5.12.06,5.63h2.23c-.02-.53-.13-4.28,0-5.64.1-1.07.82-1.86,2.08-2.3.8-.28,1.59-.32,1.81-.33v-2.08c-.29-.03-1.74-.15-3.53.71-.95.46-1.66,1.13-2.11,1.99Z" />
            <path
                class="cls-1"
                d="m57.03.05c-1.96-.29-3.78.86-3.79.88-1.02.72-1.72,1.68-2.08,2.86-.3.98-.3,1.91-.3,2.46v8.98h2.34l-.05-8.43h3.89v-1.94h-3.68v-.07c.3-1.49,1.3-2.13,2.09-2.4.72-.25,1.38-.25,1.59-.24V.05Z" />
            <path
                class="cls-1"
                d="m66.79,4.84l-.05,5.04c-.04.87-.29,1.65-.73,2.2-.55.7-1.35,1.04-2.38,1.01-2.11-.05-3.08-1.17-3.26-3.73h0v-4.52h-2.18c-.01.44-.07,3.22-.02,4.51.04.96.15,2.21.72,3.34.72,1.43,2,2.28,3.8,2.53.57.08,1.3.06,1.97-.06.59-.1,1.4-.32,1.98-.81l.1-.08v.13s.09.74-.27,1.51c-.81,1.75-3.3,1.87-6.01.83v2.25c3.08,1.2,5.23.55,6.62-.39,1.75-1.19,2-3.01,2-4.52.01-2.18,0-8.54,0-9.23h-2.29Z" />
        </g>
        <path
            class="cls-1"
            d="m119.43,4.84c.71,0,1.38.13,2,.4.63.27,1.17.64,1.64,1.11s.84,1.02,1.11,1.64c.27.63.4,1.29.4,2s-.13,1.38-.4,2c-.27.63-.64,1.17-1.11,1.64-.47.47-1.02.84-1.64,1.11-.63.27-1.29.4-2,.4s-1.38-.13-2-.4c-.63-.27-1.17-.64-1.64-1.11s-.84-1.02-1.11-1.64c-.27-.63-.4-1.29-.4-2s.13-1.38.4-2c.27-.63.64-1.17,1.11-1.64.47-.47,1.02-.84,1.64-1.11.63-.27,1.29-.4,2-.4Zm3.92,5.16c0-.54-.1-1.04-.31-1.52s-.49-.89-.84-1.25-.77-.63-1.25-.84-.98-.31-1.52-.31-1.06.1-1.53.31-.89.49-1.24.84-.63.77-.84,1.25c-.2.48-.3.98-.3,1.52s.1,1.06.3,1.53c.2.48.48.89.84,1.24s.77.63,1.24.84c.48.2.99.3,1.53.3s1.04-.1,1.52-.3c.48-.2.89-.48,1.25-.84s.63-.77.84-1.24.31-.99.31-1.53Z" />
        <path
            class="cls-1"
            d="m133.72,0v4.62h4.12v1.31h-4.13v4.13c0,.53.1,1.03.3,1.49.2.46.49.86.86,1.2.37.34.8.61,1.31.81.51.2,1.06.29,1.66.29v1.31c-.84,0-1.6-.15-2.27-.44-.67-.29-1.24-.68-1.72-1.15-.47-.48-.83-1.02-1.08-1.63-.25-.61-.38-1.23-.38-1.87V0h1.32Z" />
        <g>
            <path
                class="cls-1"
                d="m140.71,2.61c-.25-.25-.57-.38-.92-.38s-.68.14-.92.38c-.25.25-.38.57-.38.92s.14.68.38.92c.25.25.57.38.92.38s.68-.14.92-.38c.25-.25.38-.57.38-.92s-.14-.68-.38-.92Zm-.92,2.01c-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09,1.09.49,1.09,1.09-.49,1.09-1.09,1.09Z" />
            <path
                class="cls-1"
                d="m140.08,3.57c.17-.07.25-.19.25-.36,0-.13-.05-.22-.16-.3-.11-.07-.25-.11-.44-.11-.12,0-.26.01-.42.04v1.42h.2v-.63h.26c.21,0,.34.21.4.63h.2c-.03-.22-.07-.38-.12-.47-.04-.1-.11-.17-.19-.22Zm-.31-.09h-.25v-.5c.06,0,.13,0,.21,0,.27,0,.4.08.4.25s-.12.25-.37.25Z" />
        </g>
        <path
            class="cls-1"
            d="m127.13,6.57c.18-.2.37-.4.57-.56.21-.16.42-.31.64-.43.22-.12.44-.23.67-.31.22-.08.44-.15.65-.21.49-.12.98-.2,1.48-.22v1.31c-.49,0-.93.06-1.32.18s-.72.28-1.02.47c-.29.2-.55.41-.76.66s-.38.49-.51.73c-.13.25-.23.48-.29.7-.06.22-.09.4-.09.55v5.75h-1.26v-5.69s0-1.56,1.25-2.94Z" />
        <path
            class="cls-1"
            d="m96.17,4.84c-2.82,0-5.11,2.29-5.11,5.11,0,.09,0,10.05,0,10.05h1.25v-6.69c.94,1.07,2.31,1.75,3.85,1.75,2.82,0,5.11-2.29,5.11-5.11s-2.29-5.11-5.11-5.11Zm0,8.99c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88,3.88,1.74,3.88,3.88-1.74,3.88-3.88,3.88Z" />
        <path
            class="cls-1"
            d="m107.84,4.84c-2.82,0-5.11,2.29-5.11,5.11,0,.09,0,10.05,0,10.05h1.25v-6.69c.94,1.07,2.31,1.75,3.85,1.75,2.82,0,5.11-2.29,5.11-5.11s-2.29-5.11-5.11-5.11Zm0,8.99c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88,3.88,1.74,3.88,3.88-1.74,3.88-3.88,3.88Z" />
        <path
            class="cls-1"
            d="m88.52,4.84v5.18c0,.6-.1,1.14-.32,1.62-.21.49-.49.9-.85,1.24s-.77.61-1.23.8c-.44.18-.91.27-1.39.28-.48,0-.95-.1-1.39-.28-.47-.19-.88-.46-1.23-.8s-.64-.76-.85-1.24c-.21-.49-.32-1.03-.32-1.62v-5.18h-1.29v5.18c0,.64.11,1.26.34,1.88.23.62.57,1.17,1.01,1.66.44.49.99.88,1.63,1.19.6.28,1.28.43,2.04.45h0s.1,0,.11,0h0c.76-.02,1.44-.17,2.04-.45.64-.3,1.19-.7,1.63-1.19.44-.49.78-1.04,1.01-1.66.23-.62.34-1.24.34-1.88v-5.18h-1.29Z" />
        <path
            class="cls-1"
            d="m74.33,15.22c-1.55,0-2.48-.54-2.99-.99-.62-.56-1.01-1.34-1.04-2.15l1.37-.07c.04.85.77,1.84,2.67,1.84,0,0,.02,0,.03,0,1.24,0,1.89-.41,2.22-.75.28-.3.43-.67.42-1.02-.02-.34-.05-1.14-2.9-1.44-1.54-.16-3.6-.6-3.66-2.51-.02-.55.21-1.44.88-2.14.71-.74,1.73-1.11,3.04-1.12.01,0,.03,0,.04,0,1.99,0,2.99.84,3.49,1.55.54.78.59,1.55.59,1.64l-1.37.06h0c-.01-.19-.19-1.88-2.72-1.88-.01,0-.02,0-.04,0-.93,0-1.62.24-2.07.7-.43.44-.51.98-.5,1.15,0,.26.03.94,2.43,1.19,1.1.12,4.02.43,4.13,2.74.03.72-.26,1.46-.79,2.02-.51.53-1.47,1.17-3.2,1.18-.01,0-.02,0-.04,0Z" />
    </svg>`,
})
export class VSupportLogoComponent {
    @Input() width: number = 140
    @Input() height: number = 40
}
