import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiBaseService } from "./api-base.service"
import { AssociativeArray } from "../../_core/types"
import { ElectricityEnums, ElectricityEnumsSerializer } from "../../_models/electricity-enums"
import { ElectricityQuote, ElectricityQuoteSerializer } from "../../_models/electricity-quote"
import { CustomerUtilityQuote, CustomerUtilityQuoteSerializer } from "../../_models/customer-utility-quote"
import { GasEnums, GasEnumsSerializer } from "../../_models/gas-enums"
import { GasQuote, GasQuoteSerializer } from "../../_models/gas-quote"

@Injectable({
    providedIn: "root",
})
export class UtilitiesApiService extends ApiBaseService {
    protected url

    constructor(
        protected httpClient: HttpClient,
        private electricityEnumsSerializer: ElectricityEnumsSerializer,
        private gasEnumsSerializer: GasEnumsSerializer,
        private electricityQuoteSerializer: ElectricityQuoteSerializer,
        private gasQuoteSerializer: GasQuoteSerializer,
        private customerUtilityQuoteSerializer: CustomerUtilityQuoteSerializer,
    ) {
        super()
        this.url = environment.utilitiesApi.url.slice(0, -1)
    }

    getElectricityEnums(queryOptions: AssociativeArray<string> = {}): Observable<ElectricityEnums> {
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/options-electricity`, { params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => this.electricityEnumsSerializer.fromJson(response.data)))
        )
    }

    getGasEnums(queryOptions: AssociativeArray<string> = {}): Observable<GasEnums> {
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/options-gas`, { params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => this.gasEnumsSerializer.fromJson(response.data)))
        )
    }

    getElectricityQuotes(queryOptions: AssociativeArray<string> = {}): Observable<ElectricityQuote[]> {
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/request-data-electricity`, { params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => response.data.map(item => this.electricityQuoteSerializer.fromJson(item))))
        )
    }

    getGasQuotes(queryOptions: AssociativeArray<string> = {}): Observable<GasQuote[]> {
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/request-data-gas`, { params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => response.data.map(item => this.gasQuoteSerializer.fromJson(item))))
        )
    }

    getCustomerQuoteByHash(hash: string): Observable<CustomerUtilityQuote> {
        const url = this.url + "/customer-quotes/valid-hash/" + hash
        return (
            this.httpClient
                .get<CustomerUtilityQuote>(url)
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => this.customerUtilityQuoteSerializer.fromJson(response.data)))
        )
    }

    getCustomerElectricityQuote(quoteId: number): Observable<CustomerUtilityQuote> {
        const url = this.url + "/customer-electricity-quotes/" + quoteId
        return (
            this.httpClient
                .get<CustomerUtilityQuote>(url)
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => this.customerUtilityQuoteSerializer.fromJson(response.data)))
        )
    }

    getCustomerGasQuote(quoteId: number): Observable<CustomerUtilityQuote> {
        const url = this.url + "/customer-gas-quotes/" + quoteId
        return (
            this.httpClient
                .get<CustomerUtilityQuote>(url)
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((response: any) => this.customerUtilityQuoteSerializer.fromJson(response.data)))
        )
    }

    public updateCustomerElectricityQuote(
        quote: CustomerUtilityQuote,
        data: AssociativeArray,
    ): Observable<CustomerUtilityQuote> {
        const url = this.url + "/customer-electricity-quotes/" + quote.id
        return this.httpClient.put<CustomerUtilityQuote>(url, data).pipe(
            map(
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (response: any) => this.customerUtilityQuoteSerializer.fromJson(response.data) as CustomerUtilityQuote,
            ),
        )
    }

    public updateCustomerGasQuote(
        quote: CustomerUtilityQuote,
        data: AssociativeArray,
    ): Observable<CustomerUtilityQuote> {
        const url = this.url + "/customer-gas-quotes/" + quote.id
        return this.httpClient.put<CustomerUtilityQuote>(url, data).pipe(
            map(
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (response: any) => this.customerUtilityQuoteSerializer.fromJson(response.data) as CustomerUtilityQuote,
            ),
        )
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public confirmCustomerElectricityQuote(quote: CustomerUtilityQuote): Observable<any> {
        const url = this.url + "/customer-electricity-quotes/" + quote.id + "/confirmation"
        return this.httpClient.put<CustomerUtilityQuote>(url, null)
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public confirmCustomerGasQuote(quote: CustomerUtilityQuote): Observable<any> {
        const url = this.url + "/customer-gas-quotes/" + quote.id + "/confirmation"
        return this.httpClient.put<CustomerUtilityQuote>(url, null)
    }
}
