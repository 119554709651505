import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CRContactAddress {
    id: number = 0
    addressLine1: string
    city: string
    country: number
    postcode: string
    houseName: string
    streetName: string
    houseNumber: string
    livedAtForLessThan6Months: boolean
}

@Injectable({
    providedIn: "root",
})
export class CRContactAddressSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): CRContactAddress {
        const record = new CRContactAddress()
        record.id = json.id ?? 0
        record.addressLine1 = json.address_line_1
        record.city = json.city
        record.country = json.country
        record.postcode = json.postcode
        record.houseName = json.house_name
        record.streetName = json.street_name
        record.houseNumber = json.house_number
        record.livedAtForLessThan6Months = json.lived_at_for_less_than_6_months
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRContactAddress): any {
        return {
            id: record.id ?? 0,
            address_line_1: record.addressLine1,
            city: record.city,
            country: record.country,
            postcode: record.postcode,
            house_name: record.houseName,
            street_name: record.streetName,
            house_number: record.houseNumber,
            lived_at_for_less_than_6_months: record.livedAtForLessThan6Months,
        }
    }
}
