import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CRFormRateCard {
    id: number = 0
    cardId: number
    cardName: string
    secureRatePerTransaction: number
    transactionFee: number
}

@Injectable({
    providedIn: "root",
})
export class CRFormRateCardSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): CRFormRateCard {
        const record = new CRFormRateCard()
        record.id = json.id
        record.cardId = json.card_id
        record.cardName = json.card_name
        record.secureRatePerTransaction = json.secure_rate_per_transaction
        record.transactionFee = json.transaction_fee
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRFormRateCard): any {
        return {
            id: record.id,
            card_id: record.cardId,
            card_name: record.cardName,
            secure_rate_per_transaction: record.secureRatePerTransaction,
            transaction_fee: record.transactionFee,
        }
    }
}
