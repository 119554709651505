import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-arrow-down",
    template: `<svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 12 8"
        style="enable-background:new 0 0 12 8;"
        xml:space="preserve">
        <style type="text/css">
            .st0 {
                fill: #575756;
            }
        </style>
        <g id="Layer_2"></g>
        <g id="Layer_1">
            <path
                class="st0"
                d="M10.59,0.53c-0.29,0-0.59,0.11-0.82,0.33l-3.9,3.78L2.25,0.89C1.8,0.42,1.05,0.41,0.59,0.86
                C0.12,1.31,0.11,2.05,0.56,2.52l4.45,4.59c0.22,0.22,0.51,0.35,0.83,0.36c0.31,0.01,0.61-0.11,0.84-0.33l4.74-4.59
                c0.47-0.45,0.48-1.19,0.03-1.66C11.21,0.65,10.9,0.53,10.59,0.53z" />
        </g>
    </svg>`,
})
export class ArrowDownComponent {}
