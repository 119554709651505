import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRProductsServices } from "./cr-products-services"
import { SystemFile, SystemFileSerializer } from "./file"

export class CRProductCategory {
    id: number = 0
    name: string
    subcategories: string
    parentCategoryId: string
    image: SystemFile

    // Category products
    products?: CRProductsServices[]
    accessories?: CRProductsServices[]

    // For customer registration logic
    isActive: boolean = false
    hasProductWithMotoAvailable = false
}

@Injectable({
    providedIn: "root",
})
export class CRProductCategorySerializer implements ApiResourceSerializer {
    constructor(private systemFileSerializer: SystemFileSerializer) {}

    fromJson(json: JsonObject, mode: string = "full"): CRProductCategory {
        const record = new CRProductCategory()
        if (mode === "full") {
            record.id = json.id
            record.name = json.name
            record.subcategories = json.subcategories
            record.parentCategoryId = json.parent_category_id
            record.image =
                json.image !== undefined && json.image !== null && json.image !== ""
                    ? this.systemFileSerializer.fromJson(json.image)
                    : null
        } else {
            record.id = json.id
            record.name = json.name
            record.subcategories = json.subcategories
            record.parentCategoryId = json.parent_category_id
            record.image =
                json.image !== undefined && json.image !== null && json.image !== ""
                    ? this.systemFileSerializer.fromJson(json.image)
                    : null
            record.products = []
            record.accessories = []
        }
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRProductCategory, mode: string = "full"): any {
        if (mode === "full") {
            return {
                id: record.id,
                name: record.name,
                subcategories: record.subcategories,
                parent_category_id: record.parentCategoryId,
                image:
                    record.image !== undefined && record.image !== null
                        ? this.systemFileSerializer.toJson(record.image)
                        : null,
            }
        } else {
            return {
                id: record.id,
                name: record.name,
                subcategories: record.subcategories,
                parent_category_id: record.parentCategoryId,
                image:
                    record.image !== undefined && record.image !== null
                        ? this.systemFileSerializer.toJson(record.image)
                        : null,
                products: [],
                accessories: [],
            }
        }
    }
}
