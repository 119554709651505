import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CustomerDeliveryPackage implements ApiResource {
    id: number = 0
    orderedAt: string
    estArrival: string
    dispatchedAt: string
    deliveredAt: string
    status: number
    statusOptions: {
        id: number
        name: string
    }[]
    deliveryType: number
    deliveryTypeLabel: string
    trackingNumber: string
    deliveryDays: number
    deliveryService: string

    getStatus(): string {
        if (this.statusOptions) {
            const status = this.statusOptions.find(item => item.id === this.status)
            if (status) return status.name
        }
        return "N/A"
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerDeliveryPackageSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): CustomerDeliveryPackage {
        const record = new CustomerDeliveryPackage()
        record.id = json.id
        record.orderedAt = json.ordered_at
        record.estArrival = json.est_arrival
        record.dispatchedAt = json.dispatched_at
        record.deliveredAt = json.delivered_at
        record.status = json.status
        record.statusOptions = json.status_options
        record.deliveryType = json.delivery_type
        record.deliveryTypeLabel = json.delivery_type_label
        record.trackingNumber = json.tracking_number
        record.deliveryDays = json.delivery_days
        record.deliveryService = json.delivery_service
        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(deliveryPackage: CustomerDeliveryPackage): any {
        return {
            id: deliveryPackage.id,
            ordered_at: deliveryPackage.orderedAt,
            est_arrival: deliveryPackage.estArrival,
            dispatched_at: deliveryPackage.dispatchedAt,
            delivered_at: deliveryPackage.deliveredAt,
            status: deliveryPackage.status,
            status_options: deliveryPackage.statusOptions,
            delivery_type: deliveryPackage.deliveryType,
            delivery_type_label: deliveryPackage.deliveryTypeLabel,
            tracking_number: deliveryPackage.trackingNumber,
            delivery_days: deliveryPackage.deliveryDays,
            delivery_service: deliveryPackage.deliveryService,
        }
    }
}
