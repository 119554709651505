import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-admin-icon",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10.769 8.709">
        <g id="Group_411" data-name="Group 411" transform="translate(-3993.843 -207.384)">
            <g id="Group_410" data-name="Group 410">
                <g id="Group_407" data-name="Group 407">
                    <path
                        id="Path_388"
                        data-name="Path 388"
                        d="M4000.481,213.1a5.68,5.68,0,0,1-1.51-.658.972.972,0,0,1-.3-.509,1.871,1.871,0,0,0,.388-.848c.087-.007.2-.129.323-.561.168-.594-.011-.681-.162-.667a1.459,1.459,0,0,0,.066-.244,3.7,3.7,0,0,0,.052-.518,1.414,1.414,0,0,0-.433-1.187,1.784,1.784,0,0,0-1.417-.513v0c-.031,0-.061,0-.093.005a1.409,1.409,0,0,0-.372.071,1.628,1.628,0,0,0-.409.209,2.122,2.122,0,0,0-.422.349,1.7,1.7,0,0,0-.456.8,1.752,1.752,0,0,0,0,.782,1.335,1.335,0,0,0,.066.244c-.151-.014-.33.073-.162.667.121.434.234.554.321.561a1.89,1.89,0,0,0,.371.836,1.125,1.125,0,0,1-.493.646,5.545,5.545,0,0,1-1.3.533c-.686.174-.72.966-.691,1.359,0,0,.037.532.076.766a7.8,7.8,0,0,0,7.166,0c.041-.234.08-.766.08-.766C4001.2,214.064,4001.167,213.272,4000.481,213.1Z" />
                </g>
                <g id="Group_409" data-name="Group 409">
                    <g id="Group_408" data-name="Group 408">
                        <path
                            id="Path_389"
                            data-name="Path 389"
                            d="M4004.5,210.475l-.407-.049a1.832,1.832,0,0,0-.181-.438l.253-.322a.132.132,0,0,0-.011-.17l-.419-.42a.134.134,0,0,0-.171-.011l-.322.254a1.79,1.79,0,0,0-.437-.181l-.049-.407a.132.132,0,0,0-.127-.113h-.594a.131.131,0,0,0-.127.113l-.049.407a1.854,1.854,0,0,0-.438.181l-.322-.253a.132.132,0,0,0-.17.01l-.42.42a.132.132,0,0,0-.009.17l.252.322a1.816,1.816,0,0,0-.18.438l-.407.049a.133.133,0,0,0-.114.128v.593a.131.131,0,0,0,.114.127l.407.049a1.781,1.781,0,0,0,.181.438l-.253.322a.132.132,0,0,0,.01.17l.419.42a.132.132,0,0,0,.17.01l.322-.253a1.854,1.854,0,0,0,.438.181l.049.406a.132.132,0,0,0,.127.114h.594a.131.131,0,0,0,.127-.114l.049-.406a1.854,1.854,0,0,0,.438-.181l.322.253a.133.133,0,0,0,.17-.01l.42-.42a.133.133,0,0,0,.01-.171l-.253-.322a1.826,1.826,0,0,0,.181-.437l.406-.049a.131.131,0,0,0,.114-.127V210.6A.131.131,0,0,0,4004.5,210.475Zm-2.168,1.564a1.141,1.141,0,1,1,1.141-1.14A1.14,1.14,0,0,1,4002.331,212.039Z" />
                    </g>
                    <circle
                        id="Ellipse_46"
                        data-name="Ellipse 46"
                        cx="0.58"
                        cy="0.58"
                        r="0.58"
                        transform="translate(4001.751 210.319)" />
                </g>
            </g>
        </g>
    </svg>`,
})
export class AdminIconComponent {}
