import { ProductQuantityType } from "./enums/product-quantity-type"
import { CRProductsServices, CRProductsServicesSerializer } from "./cr-products-services"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export interface ProductSetup {
    id: number
    quantity: number
}

export class CRProductPack {
    id = 0
    name: string
    description: string
    code: string
    productsIds: number[]
    products: CRProductsServices[]
    pricing: {
        priceSell: number
        priceFloor: number
        priceMax: number
    }
    categoryId: number
    quantityType: string = ProductQuantityType.QUANTITY_TYPE_NUMBER

    calculatePackPrices(): void {
        let priceSellTotal = 0
        let priceMinValue = 0
        let priceMaxValue = 0

        this.products.forEach(product => {
            if (product.pricing) {
                if (product.pricing[0] !== undefined && product.pricing[0] !== null) {
                    priceSellTotal += product.pricing[0].priceSell
                    priceMinValue += product.pricing[0].priceFloor
                    priceMaxValue += product.pricing[0].priceMax
                }
            }
        })

        this.pricing.priceSell = priceSellTotal
        this.pricing.priceFloor = priceMinValue
        this.pricing.priceMax = priceMaxValue
    }
}

@Injectable({
    providedIn: "root",
})
export class CRProductPackSerializer implements ApiResourceSerializer {
    constructor(private cRProductsServicesSerializer: CRProductsServicesSerializer) {}

    fromJson(json: JsonObject): CRProductPack {
        const record = new CRProductPack()
        record.id = json.id
        record.name = json.name
        record.description = json.description
        record.code = json.code === undefined || json.code === null || json.code === "" ? "VT-PBL-PACK" : json.code
        record.productsIds = json.products_ids
        record.pricing = {
            priceSell: json.pricing !== undefined ? json.pricing.price_sell : 0,
            priceFloor: json.pricing !== undefined ? json.pricing.price_floor : 0,
            priceMax: json.pricing !== undefined ? json.pricing.price_max : 0,
        }
        record.categoryId = json.category_id
        record.products =
            json.products !== undefined && json.products !== null
                ? json.products.map(item => this.cRProductsServicesSerializer.fromJson(item, "withoutNesting"))
                : []
        record.quantityType = json.quantity_type
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRProductPack): any {
        return {
            id: record.id,
            name: record.name,
            description: record.description,
            code: record.code,
            products_ids: record.productsIds,
            pricing: {
                price_sell: record.pricing.priceSell,
                price_floor: record.pricing.priceFloor,
                price_max: record.pricing.priceMax,
            },
            category_id: record.categoryId,
            products:
                record.products !== undefined && record.products !== null
                    ? record.products.map(item => this.cRProductsServicesSerializer.toJson(item, "withoutNesting"))
                    : [],
            quantity_type: record.quantityType,
        }
    }
}
