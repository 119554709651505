import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRFormRateCard, CRFormRateCardSerializer } from "./cr-form-rate-card"

export class CRFormRateGroup {
    id: number = 0
    formRateCard: CRFormRateCard[]
    groupId: number
    groupName: string
    secureRatePerTransaction: number
    transactionFee: number
    isActive: boolean // Local variable
    transactionFeeNotApplicable: boolean
}

@Injectable({
    providedIn: "root",
})
export class CRFormRateGroupSerializer implements ApiResourceSerializer {
    constructor(private cRFormRateCardSerializer: CRFormRateCardSerializer) {}

    fromJson(json: JsonObject): CRFormRateGroup {
        const record = new CRFormRateGroup()
        record.id = json.id
        record.formRateCard =
            json.form_rate_card !== undefined && json.form_rate_card !== null
                ? json.form_rate_card.map(item => this.cRFormRateCardSerializer.fromJson(item))
                : null
        record.groupId = json.group_id
        record.groupName = json.group_name
        record.secureRatePerTransaction = json.secure_rate_per_transaction
        record.transactionFee = json.transaction_fee
        record.transactionFeeNotApplicable = json.transaction_fee_not_available

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRFormRateGroup): any {
        return {
            id: record.id,
            form_rate_card: record.formRateCard
                ? record.formRateCard.map(item => this.cRFormRateCardSerializer.toJson(item))
                : [],
            group_id: record.groupId,
            group_name: record.groupName,
            secure_rate_per_transaction: record.secureRatePerTransaction,
            transaction_fee: record.transactionFee,
            transaction_fee_not_available: record.transactionFeeNotApplicable,
        }
    }
}
