import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-select-all-white",
    template: ` <svg xmlns="http://www.w3.org/2000/svg" width="17.036" height="15.69" viewBox="0 0 17.036 15.69">
        <g id="Group_73" data-name="Group 73" transform="translate(-305.092 -523.857)">
            <path
                id="Path_51"
                data-name="Path 51"
                d="M305.092,529.992a1.59,1.59,0,0,1,1.646-.928c2.882.026,5.764.01,8.646.011,1.087,0,1.544.463,1.544,1.561q0,3.705,0,7.411a1.3,1.3,0,0,1-1.482,1.487c-2.91,0-5.82-.017-8.73.012a1.526,1.526,0,0,1-1.624-.951Zm8.609,1.983-3.3,3.3c-.42.42-.524.425-.93.01-.383-.391-.754-.793-1.061-1.117l-.949.883c.368.368.748.748,1.129,1.127a1.664,1.664,0,0,0,2.665.015q1.521-1.518,3.039-3.04c.1-.1.184-.2.285-.311Z"
                fill="#ffffff" />
            <path
                id="Path_52"
                data-name="Path 52"
                d="M322.108,534.979h-1.243v-.522q0-4.236,0-8.471c0-.7-.169-.87-.882-.87h-9.821v-1.258h9.887a1.9,1.9,0,0,1,2.078,2.09q0,4.3,0,8.6C322.127,534.684,322.115,534.822,322.108,534.979Z"
                fill="#ffffff" />
            <path
                id="Path_53"
                data-name="Path 53"
                d="M307.66,527.613v-1.237c.161-.007.3-.019.438-.019q4.683,0,9.365,0a1.937,1.937,0,0,1,2.169,2.185q0,4.234,0,8.47v.454h-1.257v-.484q0-4.215,0-8.428c0-.767-.176-.94-.958-.94H307.66Z"
                fill="#ffffff" />
        </g>
    </svg>`,
})
export class SelectAllWhiteComponent {}
