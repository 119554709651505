import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-transact",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="91.225" height="46.505" viewBox="0 0 91.225 46.505">
        <g id="Group_449" data-name="Group 449" transform="translate(-5616.36 -18.767)">
            <g id="Group_445" data-name="Group 445">
                <path
                    id="Path_252"
                    data-name="Path 252"
                    d="M5624.751,64.474a5.453,5.453,0,0,1-1.214.359,7.688,7.688,0,0,1-1.363.12,4.024,4.024,0,0,1-2.89-.935,3.68,3.68,0,0,1-.975-2.782V55.285h-1.949l.922-2.577h1.027V50.017l3.478-1v3.694h3.647l-.922,2.577h-2.725V61a1.61,1.61,0,0,0,.285,1.06,1.1,1.1,0,0,0,.878.33,2.184,2.184,0,0,0,.581-.074,2.838,2.838,0,0,0,.525-.2Z"
                    fill="#307aff" />
                <path
                    id="Path_253"
                    data-name="Path 253"
                    d="M5626.6,52.548h2.668V54.11a3.336,3.336,0,0,1,1.152-1.4,2.779,2.779,0,0,1,1.573-.479,2.923,2.923,0,0,1,1.266.319l-.969,2.68a2.138,2.138,0,0,0-.9-.274,1.424,1.424,0,0,0-1.214.889,8.131,8.131,0,0,0-.5,3.489l.011.6v4.095l-3.09.922Z"
                    fill="#307aff" />
                <path
                    id="Path_254"
                    data-name="Path 254"
                    d="M5642.98,53.47l3.112-.922V64.031l-3.112.922V63.642a6.125,6.125,0,0,1-1.828,1.248,5.1,5.1,0,0,1-1.987.382,5.523,5.523,0,0,1-4.157-1.864,6.5,6.5,0,0,1-1.753-4.635,6.657,6.657,0,0,1,1.7-4.708,5.4,5.4,0,0,1,4.122-1.836,5.242,5.242,0,0,1,2.095.422,5.731,5.731,0,0,1,1.811,1.265Zm-3.272,1.632a3.144,3.144,0,0,0-2.4,1.021,4.075,4.075,0,0,0,.017,5.261,3.148,3.148,0,0,0,2.392,1.038,3.206,3.206,0,0,0,2.432-1.021,3.728,3.728,0,0,0,.966-2.673,3.623,3.623,0,0,0-.966-2.623A3.249,3.249,0,0,0,5639.708,55.1Z"
                    fill="#307aff" />
                <path
                    id="Path_255"
                    data-name="Path 255"
                    d="M5648.047,53.47l3.112-.922V53.82a7.453,7.453,0,0,1,1.918-1.242,4.63,4.63,0,0,1,1.759-.349,4.261,4.261,0,0,1,3.131,1.288,4.424,4.424,0,0,1,1.082,3.238v7.276l-3.079.922V59.52a13.731,13.731,0,0,0-.2-2.949,2.017,2.017,0,0,0-.694-1.11,1.945,1.945,0,0,0-1.222-.382,2.293,2.293,0,0,0-1.621.632,3.377,3.377,0,0,0-.938,1.749,14.1,14.1,0,0,0-.137,2.516v4.055l-3.112.922Z"
                    fill="#307aff" />
                <path
                    id="Path_256"
                    data-name="Path 256"
                    d="M5669.155,54.281l-1.927,1.927a3.26,3.26,0,0,0-2.132-1.163,1.338,1.338,0,0,0-.821.223.666.666,0,0,0-.3.552.675.675,0,0,0,.188.462,3.484,3.484,0,0,0,.929.576l1.14.57a6.709,6.709,0,0,1,2.474,1.813,3.776,3.776,0,0,1-.541,4.925,4.651,4.651,0,0,1-3.255,1.106,5.174,5.174,0,0,1-4.333-2.121l1.916-2.086a4.4,4.4,0,0,0,1.282,1.032,2.841,2.841,0,0,0,1.306.393,1.562,1.562,0,0,0,.992-.3.862.862,0,0,0,.376-.684q0-.719-1.357-1.4l-1.049-.524q-3.009-1.517-3.01-3.8a3.294,3.294,0,0,1,1.135-2.514,4.121,4.121,0,0,1,2.9-1.043,5.032,5.032,0,0,1,4.082,2.052Z"
                    fill="#307aff" />
                <path
                    id="Path_257"
                    data-name="Path 257"
                    d="M5680.3,53.47l3.112-.922V64.031l-3.112.922V63.642a6.129,6.129,0,0,1-1.828,1.248,5.107,5.107,0,0,1-1.987.382,5.521,5.521,0,0,1-4.157-1.864,6.5,6.5,0,0,1-1.753-4.635,6.659,6.659,0,0,1,1.7-4.708,5.4,5.4,0,0,1,4.122-1.836,5.244,5.244,0,0,1,2.1.422,5.724,5.724,0,0,1,1.811,1.265ZM5677.03,55.1a3.145,3.145,0,0,0-2.4,1.021,4.077,4.077,0,0,0,.017,5.261,3.148,3.148,0,0,0,2.392,1.038,3.206,3.206,0,0,0,2.432-1.021,3.727,3.727,0,0,0,.966-2.673,3.623,3.623,0,0,0-.966-2.623A3.25,3.25,0,0,0,5677.03,55.1Z"
                    fill="#307aff" />
                <path
                    id="Path_258"
                    data-name="Path 258"
                    d="M5697.519,55.057l-2.591,1.425a3.841,3.841,0,0,0-3.116-1.357,3.894,3.894,0,0,0-2.825,1.042,3.549,3.549,0,0,0-1.079,2.669,3.429,3.429,0,0,0,1.039,2.584,3.764,3.764,0,0,0,2.728,1A3.967,3.967,0,0,0,5694.928,61l2.454,1.68a7.561,7.561,0,0,1-10.757.657,6.445,6.445,0,0,1,1.562-10.238,7.5,7.5,0,0,1,3.609-.867,7.31,7.31,0,0,1,3.318.736A6.287,6.287,0,0,1,5697.519,55.057Z"
                    fill="#307aff" />
                <path
                    id="Path_259"
                    data-name="Path 259"
                    d="M5706.9,64.474a5.437,5.437,0,0,1-1.214.359,7.68,7.68,0,0,1-1.362.12,4.027,4.027,0,0,1-2.891-.935,3.682,3.682,0,0,1-.974-2.782V55.285h-1.95l.922-2.577h1.028V50.017l3.477-1v3.694h3.647l-.922,2.577h-2.725V61a1.61,1.61,0,0,0,.285,1.06,1.1,1.1,0,0,0,.878.33,2.187,2.187,0,0,0,.581-.074,2.834,2.834,0,0,0,.525-.2Z"
                    fill="#307aff" />
            </g>
            <g id="Group_448" data-name="Group 448">
                <g id="Group_446" data-name="Group 446">
                    <path
                        id="Path_260"
                        data-name="Path 260"
                        d="M5649.821,35.979a4.344,4.344,0,0,1,4.344-4.344h17.768l-4.313,4.344Z"
                        fill="#fff" />
                    <path id="Path_261" data-name="Path 261" d="M5674.054,35.979h-.028l.019-.018Z" fill="#fff" />
                    <path
                        id="Path_262"
                        data-name="Path 262"
                        d="M5668.791,41.049a4.955,4.955,0,0,1-6.526.323h0l5.355-5.393h6.406Z"
                        fill="#fff" />
                    <path
                        id="Path_263"
                        data-name="Path 263"
                        d="M5671.934,31.635l.078-.079,2.112,4.329-.078.076-2.071-4.326Z"
                        fill="#fff" />
                    <path
                        id="Path_264"
                        data-name="Path 264"
                        d="M5671.974,31.635l2.071,4.326-.019.018h-6.407l4.314-4.345Z"
                        fill="#fff" />
                </g>
                <g id="Group_447" data-name="Group 447">
                    <path
                        id="Path_265"
                        data-name="Path 265"
                        d="M5649.821,25.233a4.344,4.344,0,0,0,4.344,4.344h17.768l-4.313-4.344Z"
                        fill="#fff" />
                    <path id="Path_266" data-name="Path 266" d="M5674.054,25.233h-.028l.019.018Z" fill="#fff" />
                    <path
                        id="Path_267"
                        data-name="Path 267"
                        d="M5668.791,20.163a4.955,4.955,0,0,0-6.526-.323h0l5.355,5.393h6.406Z"
                        fill="#fff" />
                    <path
                        id="Path_268"
                        data-name="Path 268"
                        d="M5671.934,29.577l.078.079,2.112-4.329-.078-.076-2.071,4.326Z"
                        fill="#fff" />
                    <path
                        id="Path_269"
                        data-name="Path 269"
                        d="M5671.974,29.577l2.071-4.326-.019-.018h-6.407l4.314,4.345Z"
                        fill="#fff" />
                </g>
            </g>
        </g>
    </svg>`,
})
export class TransactComponent {}
