import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-copy-icon",
    template: `<div>
        <svg
            id="Layer_1"
            [ngStyle]="{
                'width': width + 'px',
                'height': height + 'px',
                'cursor': cursor,
                'stroke': isClicked ? 'blue' : 'black'
            }"
            class="cls-12"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30">
            <defs>
                <style>
                    .cls-12 {
                        fill: none;
                    }
                </style>
            </defs>
            <rect x="4.32" y="6.16" width="16.21" height="22.84" rx="3.56" ry="3.56" />
            <path
                d="m9.47,6.16v-2.53c0-1.45,1.17-2.62,2.62-2.62h10.96c1.45,0,2.62,1.17,2.62,2.62v17.6c0,1.45-1.17,2.62-2.62,2.62h-2.53" />
        </svg>
    </div>`,
})
export class CopyIconComponent {
    @Input() width: number = 13
    @Input() height: number = 13
    @Input() cursor: string = "pointer"
    @Input() isClicked: boolean = false
}
