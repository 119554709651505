import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"

export class CustomerNotificationCaseComment {
    id: number = 0
    content: string
    assignedUserId: string
    createdAt: Date
    modifiedAt: Date
    relatedTo: string
    creator: string
    parentComments: string
    source: string
    customer: string
    userId: string
    reasontoedIt: string
    isPrivate: string
    filename: string
    relatedEmailId: string
}

@Injectable({
    providedIn: "root",
})
export class CustomerNotificationCaseCommentSerializer implements ApiResourceSerializer {
    constructor() {}

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fromJson(json: any): CustomerNotificationCaseComment {
        const item = new CustomerNotificationCaseComment()
        item.id = json.id
        item.content = json.content
        item.assignedUserId = json.assigned_user_id
        item.createdAt = json.created_at
        item.modifiedAt = json.modified_at
        item.relatedTo = json.related_to
        item.creator = json.creator
        item.parentComments = json.parent_comments
        item.source = json.source
        item.customer = json.customer
        item.userId = json.userid
        item.reasontoedIt = json.reasontoedit
        item.isPrivate = json.is_private
        item.filename = json.filename
        item.relatedEmailId = json.related_email_id
        return item
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(item: CustomerNotificationCaseComment): any {
        return {
            id: item.id,
        }
    }
}
