import { AppLoadingInterface, AppLoadingReducer } from "./reducers/AppLoadingReducer"
import { ActionReducerMap } from "@ngrx/store"
import { TransactionReducer, TransactionState } from "@app/store/reducers/TransactionReducer"
import { DashboardReducer, DashboardState } from "./reducers/DashboardReducer"
import { AppHeaderInterface, AppHeaderReducer } from "./reducers/AppHeaderReducer"
import { AppPageInterface, AppPageReducer } from "@app/store/reducers/AppPageReducer"
import { AppHttpInterface, AppHttpReducer } from "@app/store/reducers/AppHttpReducer"
import {
    AppCustomerRegistrationInterface,
    AppCustomerRegistrationReducer,
} from "@appStore/reducers/AppCustomerRegistrationReducer"

export interface AppState {
    http: AppHttpInterface
    header: AppHeaderInterface
    page: AppPageInterface
    loading: AppLoadingInterface
    transaction: TransactionState
    dashboard: DashboardState
    customerRegistration: AppCustomerRegistrationInterface
}

export const appReducer: ActionReducerMap<AppState> = {
    http: AppHttpReducer,
    header: AppHeaderReducer,
    page: AppPageReducer,
    loading: AppLoadingReducer,
    transaction: TransactionReducer,
    dashboard: DashboardReducer,
    customerRegistration: AppCustomerRegistrationReducer,
}
