import { ChangeDetectorRef, Component, ElementRef, ApplicationRef, ViewChild, OnInit, OnDestroy } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { AppState } from "@app/store"
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap"
import { Store } from "@ngrx/store"
import { Observable, Subscription, interval } from "rxjs"
import { AppHttpExceptionType } from "@app/store/reducers/AppHttpReducer"
import { BodyScrollService } from "./_services/body-scroll.service"
import { SwUpdate } from "@angular/service-worker"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styles: [
        `
            body {
            }
            .scrollable {
                margin-right: 17px;
            }
        `,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild("routerOutlet") routerOutlet: ElementRef

    title = "Verofy Portal"
    routerOutletMt: string = "160px"
    currentRoute: string = ""
    activeRoute: string = ""
    initializeHeader: boolean = false
    isPageAnimated: Observable<boolean> = this.store.select(state => state.page.isPageAnimation)
    isPageInitialize: Observable<boolean> = this.store.select(state => state.page.initializePage)
    refreshingToken: boolean

    isHeaderActive: boolean = true
    isFooterActive: boolean = true
    isLogin: boolean = true

    isScrollable: boolean
    private scrollableSubscription: Subscription
    private routerSubscription: Subscription

    withoutFooterAndHeader: string[] = [
        "/login/android",
        "/pin-reset",
        "/login",
        "/login/ios",
        "/android/public/after-cloud-customer-registraion",
        "/ios/public/after-cloud-customer-registraion",
        "/users/account-activation",
    ]

    constructor(
        modalConfig: NgbModalConfig,
        private store: Store<AppState>,
        private ch: ChangeDetectorRef,
        private router: Router,
        private bodyScrollService: BodyScrollService,
        private appRef: ApplicationRef,
        // private versionCheckService: VersionCheckService,
        private softwareUpdate: SwUpdate,
    ) {
        // Modal windows global configuration.
        modalConfig.backdrop = "static"
        modalConfig.keyboard = false

        // Update after route
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const url = val.url.split("?")[0]
                this.currentRoute = val.url
                this.setRouterMargin(this.currentRoute)
                if (url === "/login") this.isLogin = true

                if (this.withoutFooterAndHeader.indexOf(url) > -1) {
                    this.isHeaderActive = false
                    this.isFooterActive = false
                } else {
                    const firstRouteCurrentRoute = this.router.url.split("/")

                    if (firstRouteCurrentRoute[1] === "login") this.isHeaderActive = this.isFooterActive = false
                    else this.isHeaderActive = this.isFooterActive = true
                }
            }
        })

        // this.updateNewVersion()
        // this.checkForNewVersion()
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe()
        }

        if (this.scrollableSubscription) {
            this.scrollableSubscription.unsubscribe()
        }
    }

    private updateScrollableState(): void {
        if (this.scrollableSubscription) {
            this.scrollableSubscription.unsubscribe()
        }

        this.scrollableSubscription = this.bodyScrollService.isBodyScrollable().subscribe(scrollable => {
            this.isScrollable = scrollable
            // document.body.style.overflow = scrollable ? "scroll" : "hidden"
            // console.log("> APP is scrollable: ", this.isScrollable)
        })
    }

    checkForNewVersion(): void {
        this.appRef.isStable.subscribe(isStable => {
            if (isStable) {
                const timeInterval = interval(30 * 1000)

                timeInterval.subscribe(() => {
                    this.softwareUpdate.checkForUpdate().then(() => console.log("Software update: Checked"))
                    console.log("Software update: Update checked")
                })
            }
        })
    }

    setRouterMargin(routeUrl: string): void {
        const firstRoute = routeUrl.split("/")
        this.activeRoute = firstRoute[1]

        const firstRouteCurrentRoute = this.router.url.split("/")

        if (firstRoute[1] === "transactions") this.routerOutletMt = "180px"
        else if (firstRoute[1] === "settings") this.routerOutletMt = "210px"
        else if (firstRoute[1] === "customer") this.routerOutletMt = "190px"
        else if (firstRoute[1] === "pay") this.routerOutletMt = "220px"
        else if (firstRoute[1] === "accounts" || firstRoute[1] === "commercials") this.routerOutletMt = "240px"
        else if (firstRoute[1] === "login" || firstRoute[1] === "pin-reset") this.routerOutletMt = "0"
        else if (firstRoute[1] === "users" && firstRoute[2] === "account-activation") this.routerOutletMt = "0"
        else {
            if (firstRouteCurrentRoute[1] !== "login") this.routerOutletMt = "160px"
            else this.routerOutletMt = "0"
        }
    }

    /* updateNewVersion(): void {
        if (!this.softwareUpdate.isEnabled) {
            console.log("Software update: Not Enabled")
            return
        }

        this.softwareUpdate.versionUpdates.subscribe(async evt => {
            console.log("Software update: : versionUpdates", evt)
            switch (evt.type) {
                case "VERSION_DETECTED":
                    console.log(`=> Downloading new app version: ${evt.version.hash}`)
                    break
                case "VERSION_READY":
                    console.log(`=> Current app version: ${evt.currentVersion.hash}`)
                    console.log(`=> New app version ready for use: ${evt.latestVersion.hash}`)
                    await this.softwareUpdate.activateUpdate()
                    location.reload()
                    break
                case "VERSION_INSTALLATION_FAILED":
                    console.log(`=> Failed to install app version '${evt.version.hash}': ${evt.error}`)
                    break
            }
        })
    } */

    ngOnInit(): void {
        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateScrollableState()
            }
        })

        // If change happen this need to update
        this.store
            .select(state => state.header.initializeHeader)
            .subscribe(init => {
                this.initializeHeader = init
                this.ch.detectChanges()
            })

        this.store
            .select(state => state.page.initializePage)
            .subscribe(() => {
                this.ch.detectChanges()
            })

        // This will trigger when token is refreshing
        this.store
            .select(state => state.http.refreshTokenStatus)
            .subscribe(tokenRefresh => {
                switch (tokenRefresh) {
                    case AppHttpExceptionType.HttpRefreshing:
                        this.refreshingToken = true
                        break
                    case AppHttpExceptionType.HttpRefreshed:
                        this.refreshingToken = false
                }
                this.ch.detectChanges()
            })
    }
}
