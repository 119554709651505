import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, Router } from "@angular/router"
import { AuthUserStorage } from "@app/_models/auth-user-storage"
import { AlertService } from "@app/_services/alert.service"
import { AuthenticationService } from "@app/_services/authentication.service"
import { SellerPermission } from "@app/_models/seller-permission"

@Injectable()
export class PermissionGuard {
    currentUser: AuthUserStorage
    firstPath: string = ""
    secondPath: string = ""
    thirdPath: string = ""
    fourthPath: string = ""

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private alertService: AlertService,
    ) {
        this.authService.currentUser.subscribe(user => {
            this.currentUser = user
        })
    }

    getRoutePaths(route: ActivatedRouteSnapshot): void {
        this.firstPath = this.secondPath = ""
        if (route.url.length > 0) {
            this.firstPath = route.url[0].path

            if (route.url[1] !== undefined && route.url[1] !== null) {
                this.secondPath = route.url[1].path
            }

            if (route.url[2] !== undefined && route.url[2] !== null) {
                this.thirdPath = route.url[2].path
            }

            if (route.url[3] !== undefined && route.url[3] !== null) {
                this.fourthPath = route.url[3].path
            }
        }
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const _currentUser = this.authService.currentUserValue
        this.getRoutePaths(route)

        if (_currentUser) {
            if (
                this.firstPath === "settings" &&
                this.secondPath === "user-groups" &&
                !this.checkPermission(SellerPermission.ACCESS_USER_GROUPS)
            ) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            if (
                this.firstPath === "settings" &&
                this.secondPath === "login-history" &&
                !this.checkPermission(SellerPermission.ACCESS_LOGIN_HISTORY)
            ) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            if (this.firstPath === "transactions" && !this.checkPermission(SellerPermission.ACCESS_TRANSACTION)) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            if (this.firstPath === "commissions" && !this.checkPermission(SellerPermission.ACCESS_COMMISSIONS)) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            if (this.firstPath === "customers" && !this.checkPermission(SellerPermission.ACCESS_CUSTOMERS)) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            if (
                this.firstPath === "accounts" &&
                !this.checkPermission(SellerPermission.ACCESS_ACCOUNTS_AND_COMMERCIALS)
            ) {
                this.alertService.error("Access denied, you do not have permission to access this section", true, true)
                this.router.navigate([""])
                return false
            }

            return true
        }

        this.router.navigate(["/login"]) // { queryParams: { returnUrl: state.url } }
        return false
    }

    checkPermission(permissionCode: number): boolean {
        return this.currentUser.permissions.indexOf(permissionCode) > -1 ? true : false
    }
}
