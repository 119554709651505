import { Injectable } from "@angular/core"
import { Router, ActivatedRouteSnapshot } from "@angular/router"
import { AuthenticationService } from "../_services/authentication.service"
import { AlertService } from "../_services/alert.service"
import { SellerPermission } from "../_models/seller-permission"
import { LocalStorageService } from "@app/_services/local-storage.service"

@Injectable()
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private alertService: AlertService,
        private localStorageService: LocalStorageService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // state: RouterStateSnapshot
        const currentUser = this.authService.currentUserValue

        if (!this.localStorageService.isStorageValid()) {
            this.authService.setCurrentUser(null)
            this.localStorageService.removeAllDataWithException(["deviceUniqueId"])
            this.router.navigate(["/login"])
            return false
        }

        if (currentUser) {
            // Check expired token.
            if (this.authService.isAuthenticated() === false) {
                this.authService.checkValidToken()
            }

            // -----------------------------------------------------------------
            // check permissions
            // -----------------------------------------------------------------
            // console.log(currentUser.permissions);
            let firstPath = ""
            if (route.url.length > 0) {
                firstPath = route.url[0].path
            }

            switch (firstPath) {
                case "customers":
                    if (currentUser.hasPermission(SellerPermission.ACCESS_CUSTOMERS)) {
                        return true
                    }
                    this.permissionDeniedAlert()
                    break
                case "users":
                    if (currentUser.hasPermission(SellerPermission.ACCESS_USERS)) {
                        return true
                    }
                    this.permissionDeniedAlert()
                    break
                case "user-groups":
                    if (currentUser.hasPermission(SellerPermission.ACCESS_USER_GROUPS)) {
                        return true
                    }
                    this.permissionDeniedAlert()
                    break
                case "transactions":
                    if (currentUser.hasPermission(SellerPermission.ACCESS_TRANSACTION)) {
                        return true
                    }
                    this.permissionDeniedAlert()
                    break
                default:
                    return true
            }
            // -----------------------------------------------------------------
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(["/login"]) // { queryParams: { returnUrl: state.url } }
        return false
    }

    permissionDeniedAlert(): void {
        this.alertService.error("Sorry, permission denied")
    }
}
