import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-notification-white",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="6.41" height="7.697" viewBox="0 0 6.41 7.697">
        <g id="Group_859" data-name="Group 859" transform="translate(-2685.94 -25.009)">
            <path
                id="Path_582"
                data-name="Path 582"
                d="M2689.217,25.009a.363.363,0,0,1,.253.409c0,.082-.027.186.013.241s.144.044.221.064a2.235,2.235,0,0,1,1.671,1.922c.022.19.013.385.017.577.006.278-.007.558.021.833a2.034,2.034,0,0,0,.684,1.323.663.663,0,0,1,.246.383.557.557,0,0,1-.5.655.909.909,0,0,1-.1,0h-5.191a.571.571,0,0,1-.568-.351.551.551,0,0,1,.158-.64,2.211,2.211,0,0,0,.59-.8,2.093,2.093,0,0,0,.17-.8c0-.3,0-.6,0-.894a2.24,2.24,0,0,1,1.892-2.257.163.163,0,0,0,.032-.011c0-.085,0-.175,0-.263a.352.352,0,0,1,.256-.4Z"
                fill="#fff" />
            <path
                id="Path_583"
                data-name="Path 583"
                d="M2687.973,31.743h2.338c.019.406-.514.889-1.05.955A1.208,1.208,0,0,1,2687.973,31.743Z"
                fill="#fff" />
        </g>
    </svg>`,
})
export class NotificationWhiteComponent {}
