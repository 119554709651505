import { createReducer, on } from "@ngrx/store"
import {
    SetHttpLogout,
    SetHttpRequestClearTimer,
    SetHttpRequestInitialized,
    SetHttpRequestInProgress,
    SetHttpRequestObserve,
    SetRefreshTokenException,
} from "@app/store/actions/AppHttpActions"

export enum AppHttpExceptionType {
    HttpInitialize = "INITIALIZE",
    HttpRefreshing = "REFRESHING",
    HttpRefreshed = "REFRESHED",
}

export enum AppHttpRequestProgressType {
    HttpRequestInProgress = "REQUEST_IN_PROGRESS",
    HttpRequestInitialize = "REQUEST_INITIALIZE",
}

export interface AppHttpInterface {
    refreshTokenStatus: AppHttpExceptionType
    logOut: boolean
    httpRequestStatus: AppHttpRequestProgressType
    httpRequestObserve: boolean
    clearHttpRequestTimer: boolean
}

const initialState: AppHttpInterface = {
    refreshTokenStatus: AppHttpExceptionType.HttpInitialize,
    logOut: false,
    httpRequestStatus: AppHttpRequestProgressType.HttpRequestInitialize,
    httpRequestObserve: false,
    clearHttpRequestTimer: false,
}

export const AppHttpReducer = createReducer(
    initialState,

    on(SetRefreshTokenException, (state, { httpType }) => {
        return {
            ...state,
            refreshTokenStatus: httpType,
        }
    }),

    on(SetHttpLogout, (state, { isLogout }) => {
        return {
            ...state,
            logOut: isLogout,
        }
    }),

    on(SetHttpRequestInProgress, state => {
        return {
            ...state,
            httpRequestStatus: AppHttpRequestProgressType.HttpRequestInProgress,
        }
    }),

    on(SetHttpRequestInitialized, state => {
        return {
            ...state,
            httpRequestStatus: AppHttpRequestProgressType.HttpRequestInitialize,
        }
    }),

    on(SetHttpRequestObserve, (state, { isObserve }) => {
        return {
            ...state,
            httpRequestObserve: isObserve,
        }
    }),

    on(SetHttpRequestClearTimer, state => {
        return {
            ...state,
            clearHttpRequestTimer: !state.clearHttpRequestTimer,
        }
    }),
)
