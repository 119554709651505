import { createAction, props } from "@ngrx/store"
import ActionTypes from "../ActionTypes"
import { AppHttpExceptionType } from "@app/store/reducers/AppHttpReducer"

/**
 * App loading action
 */

export const SetRefreshTokenException = createAction(
    ActionTypes.setRefreshTokenInterceptor,
    props<{ httpType: AppHttpExceptionType }>(),
)
export const SetHttpLogout = createAction(ActionTypes.setHttpLogout, props<{ isLogout: boolean }>())

export const SetHttpRequestObserve = createAction(ActionTypes.setHttpRequestObserve, props<{ isObserve: boolean }>())
export const SetHttpRequestInProgress = createAction(ActionTypes.setHttpRequestInProgress)
export const SetHttpRequestInitialized = createAction(ActionTypes.setHttpRequestInitialize)
export const SetHttpRequestClearTimer = createAction(ActionTypes.setHttpRequestClearTimer)
