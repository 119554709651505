import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { StandardFeeEnum } from "@app/enums/customerRegistration/StandardFeeEnum"

export class CRStandardFees {
    id: number
    feeChargedPerLabel: string
    feeDescription: string
    feeId: number
    feeName: string
    feeQuantity: number
    feeValue: number
    isFree: boolean
    chargedPer?: number
    code: StandardFeeEnum = StandardFeeEnum.UNKNOWN
}

@Injectable({
    providedIn: "root",
})
export class CRStandardFeesSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): CRStandardFees {
        const record = new CRStandardFees()
        record.id = json.id
        record.feeChargedPerLabel = json.fee_charged_per_label
        record.feeDescription = json.fee_description
        record.feeId = json.fee_id
        record.feeName = json.fee_name
        record.feeQuantity = json.fee_quantity
        record.feeValue = json.fee_value
        record.isFree = json.is_free
        record.code = json.code ? this.getCode(json.code) : StandardFeeEnum.UNKNOWN
        return record
    }

    toJson(record: CRStandardFees): JsonObject {
        return {
            id: record.id,
            fee_charged_per_label: record.feeChargedPerLabel,
            fee_description: record.feeDescription,
            fee_id: record.feeId,
            fee_name: record.feeName,
            fee_quantity: record.feeQuantity,
            fee_value: record.feeValue,
            is_free: record.isFree,
            code: record.code,
        }
    }

    private getCode(code: string): StandardFeeEnum {
        switch (code) {
            case StandardFeeEnum.PCI_PLUS_VT_PB:
                return StandardFeeEnum.PCI_PLUS_VT_PB
            case StandardFeeEnum.FASTER_PAYMENT:
                return StandardFeeEnum.FASTER_PAYMENT
            case StandardFeeEnum.PCI_PLUS:
                return StandardFeeEnum.PCI_PLUS
            default:
                return StandardFeeEnum.UNKNOWN
        }
    }
}
