import { PaginationSerializer } from "../../_models/pagination"
import { Injectable } from "@angular/core"
import { ApiSubResourceService } from "./api-sub-resource.service"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import {
    WidgetAllBusinessOverview,
    WidgetAllBusinessOverviewSerializer,
} from "@app/_models/widget-all-business-overview"

@Injectable({
    providedIn: "root",
})
export class WidgetAllBusinessOverviewApiService extends ApiSubResourceService<WidgetAllBusinessOverview> {
    constructor(
        httpClient: HttpClient,
        serializer: WidgetAllBusinessOverviewSerializer,
        protected paginationSerializer: PaginationSerializer,
    ) {
        super(
            httpClient,
            environment.mainApi.url.slice(0, -1),
            environment.mainApi.endpoints.widgets,
            "transactions/all-total-amount/monthly",
            serializer,
            paginationSerializer,
        )
    }
}
