import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRPartnerRecord, CRPartnerRecordSerializer } from "./cr-partner-record"
import { CRContactAddress, CRContactAddressSerializer } from "./cr-contact-address"

export class CRPartnership {
    id: number = 0
    title: string | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    email: string | null
    share: number | null
    secondPartner: CRPartnerRecord
    partners: CRPartnerRecord[]
    address: CRContactAddress
    previousAddress?: CRContactAddress
}

@Injectable({
    providedIn: "root",
})
export class CRPartnershipSerializer implements ApiResourceSerializer {
    constructor(
        private cRPartnerShipPartnerSerializer: CRPartnerRecordSerializer,
        private cRContactAddressSerializer: CRContactAddressSerializer,
    ) {}

    fromJson(json: JsonObject): CRPartnership {
        const record = new CRPartnership()
        record.id = json.id
        record.title = json.title
        record.firstName = json.first_name
        record.lastName = json.last_name
        record.phoneNumber = json.phone_number
        record.email = json.email
        record.share = json.share
        record.secondPartner =
            json.second_partner !== undefined && json.second_partner !== null
                ? this.cRPartnerShipPartnerSerializer.fromJson(json.second_partner)
                : null
        record.partners =
            json.partners !== undefined && json.partners !== null
                ? json.partners.map(item => this.cRPartnerShipPartnerSerializer.fromJson(item))
                : undefined
        record.address = json.address ? this.cRContactAddressSerializer.fromJson(json.address) : null
        record.previousAddress = json.previous_address
            ? this.cRContactAddressSerializer.fromJson(json.previous_address)
            : null
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRPartnership): any {
        return {
            id: record.id,
            title: record.title,
            first_name: record.firstName,
            last_name: record.lastName,
            phone_number: record.phoneNumber,
            email: record.email,
            share: record.share,
            second_partner:
                record.secondPartner !== undefined && record.secondPartner != null
                    ? this.cRPartnerShipPartnerSerializer.toJson(record.secondPartner)
                    : null,
            partners: record.partners
                ? record.partners.map(item => this.cRPartnerShipPartnerSerializer.toJson(item))
                : [],
            address: record.address ? this.cRContactAddressSerializer.toJson(record.address) : null,
            previous_address: record.previousAddress
                ? this.cRContactAddressSerializer.toJson(record.previousAddress)
                : null,
        }
    }
}
