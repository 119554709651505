import { Injectable, EventEmitter } from "@angular/core"

@Injectable({
    providedIn: "root",
})
export class HeaderService {
    timerResetEmitter = new EventEmitter()

    resetCoundDownEmitter(): void {
        this.timerResetEmitter.emit("Reset count down")
    }
}
