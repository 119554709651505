import { Injectable } from "@angular/core"
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http"

import { EMPTY, Observable } from "rxjs"
import { AlertService } from "@appServices/alert.service"

/** Pass untouched request through to the next request handler. */
@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService) {}
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Check if the browser is online
        if (!navigator.onLine) {
            this.alertService.error("Please check your internet connection", false, true)
            return EMPTY
        }

        // Continue with the request if online
        return next.handle(request)
    }
}
