/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomerMid, CustomerMidSerializer } from "./customer-mid"
import { Injectable } from "@angular/core"
import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { State, StateSerializer } from "./state"
import { Country, CountrySerializer } from "./country"
import { JsonObject } from "../_core/types"

export class CustomerLocation extends ApiResource {
    id: number = 0
    createdAt: Date
    updatedAt: Date

    name: string
    description: string
    isEnabled: boolean

    // Legacy variables
    address1: string
    address2: string
    address3: string
    houseNameNumber: string

    // Newly added variables
    addressLine1: string
    addressLine2: string
    addressLine3: string
    houseName: string
    houseNumber: string
    streetName: string

    // Variables
    city: string
    postcode: string
    stateId: number
    state: State
    countryId: number
    country: Country
    phone: string

    // Other
    mids: CustomerMid[]
    products: any

    constructor() {
        super()
        this.id = 0
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerLocationSerializer implements ApiResourceSerializer {
    constructor(
        private midSerializer: CustomerMidSerializer,
        private countrySerializer: CountrySerializer,
        private stateSerializer: StateSerializer,
    ) {}

    fromJson(json: JsonObject): CustomerLocation {
        const location = new CustomerLocation()
        location.id = json.id
        location.createdAt = new Date(json.created_at)
        location.updatedAt = new Date(json.updated_at)

        location.name = json.name
        location.description = json.description
        location.isEnabled = json.is_enabled

        // Legacy variables
        location.address1 = json.address_1
        location.address2 = json.address_2
        location.address3 = json.address_3
        location.houseNameNumber = json.house_name_number

        // Newly added variables
        location.addressLine1 = json.address_line_1
        location.addressLine2 = json.address_line_2
        location.addressLine3 = json.address_line_3
        location.houseName = json.house_name
        location.houseNumber = json.house_number
        location.streetName = json.street_name

        // Variables
        location.city = json.city
        location.postcode = json.postcode
        location.stateId = json.state !== undefined && json.state !== null ? json.state.id : null
        location.state =
            json.state !== undefined && json.state !== null ? this.stateSerializer.fromJson(json.state) : null
        location.countryId = json.country !== undefined && json.country !== null ? json.country.id : null
        location.country =
            json.country !== undefined && json.country !== null ? this.countrySerializer.fromJson(json.country) : null
        location.phone = json.phone

        location.mids = json.mids ? json.mids.map(item => this.midSerializer.fromJson(item)) : []
        location.products = json.products

        return location
    }

    // TODO

    toJson(location: CustomerLocation): any {
        return {
            id: location.id,

            name: location.name,
            description: location.description,
            is_enabled: location.isEnabled,

            // Legacy variables
            address_1: location.address1,
            address_2: location.address2,
            address_3: location.address3,
            house_name_number: location.houseNameNumber,

            // Newly added variables
            address_line_1: location.addressLine1,
            address_line_2: location.addressLine2,
            address_line_3: location.addressLine3,
            house_name: location.houseName,
            house_number: location.houseNumber,
            street_name: location.streetName,

            // Variables
            city: location.city,
            postcode: location.postcode,
            state_id: location.stateId,
            country_id: location.countryId,
            phone: location.phone,

            mids: location.mids ? location.mids.map(item => this.midSerializer.toJson(item)) : [],
            products: location.products,
        }
    }
}
