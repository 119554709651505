<section [style]="componentStyle"
         [ngClass]="[appLoadingClass, 'appLoader ', float ? 'loaderFlow' : '', noMargin ? 'noMargin': '', bgTransparent ? 'bgTransparent' : '']">
  <article class="appLoaderContent">
      <header class="la-ball-pulse" [style]="{color: componentColor}">
        <div></div>
        <div></div>
        <div></div>
      </header>

      <footer class="appLoadingFooter" *ngIf="renderText()">
        {{ text }}
      </footer>
  </article>
</section>
