import { createReducer, on } from "@ngrx/store"
import {
    IsTransactionDatePickerMaxFromDate,
    IsTransactionDatePickerMinToDate,
    SetChargebackDatePickerExactDate,
    SetChargebackDatePickerFromDate,
    SetChargebackDatePickerState,
    SetChargebackDatePickerToDate,
    SetChargebackDatePickerType,
    SetSettlementDatePickerExactDate,
    SetSettlementDatePickerFromDate,
    SetSettlementDatePickerState,
    SetSettlementDatePickerType,
    SetSettlementsDatePickerToDate,
    SetTransactionDatePickerExactDate,
    SetTransactionDatePickerFromDate,
    SetTransactionDatePickerState,
    SetTransactionDatePickerToDate,
    SetTransactionDatePickerType,
} from "@app/store/actions/TransactionActions"

export enum DatePickerType {
    exact = "exact",
    range = "range",
}

export interface TransactionDatePickerState {
    fromDate?: Date
    toDate?: Date
    datepickerType?: DatePickerType | null

    exactDate?: Date
    maxFromDate?: Date
    minToDate?: Date
}

export interface SettlementDatePickerState {
    fromDate?: Date
    toDate?: Date
    datepickerType?: DatePickerType | null

    exactDate?: Date
    maxFromDate?: Date
    minToDate?: Date
}

export interface TransactionState {
    optionsForTransactionDatePicker: {
        datepickerType: DatePickerType | null
        fromDate: string
        toDate: string
        exactDate: string
        maxFromDate: string
        minToDate: string
    }

    optionsForSettlementDatePicker: {
        datepickerType: DatePickerType | null
        fromDate: string
        toDate: string
        exactDate: string
    }

    optionsForChargebackDatePicker: {
        datepickerType: DatePickerType | null
        fromDate: string
        toDate: string
        exactDate: string
    }
}

const initialState: TransactionState = {
    optionsForTransactionDatePicker: {
        datepickerType: DatePickerType.range,
        fromDate: "",
        toDate: "",
        exactDate: "",
        maxFromDate: "",
        minToDate: "",
    },

    optionsForSettlementDatePicker: {
        datepickerType: DatePickerType.range,
        fromDate: "",
        toDate: "",
        exactDate: "",
    },

    optionsForChargebackDatePicker: {
        datepickerType: DatePickerType.range,
        fromDate: "",
        toDate: "",
        exactDate: "",
    },
}

export const TransactionReducer = createReducer(
    initialState,
    /**
     * Transaction date picker actions
     */
    on(SetTransactionDatePickerState, (state, { transactionData }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: transactionData,
        }
    }),

    on(SetTransactionDatePickerType, (state, { pickerType }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                datepickerType: pickerType,
            },
        }
    }),

    on(SetTransactionDatePickerExactDate, (state, { date }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                exactDate: date,
            },
        }
    }),

    on(SetTransactionDatePickerFromDate, (state, { date }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                fromDate: date,
            },
        }
    }),

    on(SetTransactionDatePickerToDate, (state, { date }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                toDate: date,
            },
        }
    }),

    on(IsTransactionDatePickerMaxFromDate, (state, { date }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                maxFromDate: date,
            },
        }
    }),

    on(IsTransactionDatePickerMinToDate, (state, { date }) => {
        return {
            ...state,
            optionsForTransactionDatePicker: {
                ...state.optionsForTransactionDatePicker,
                minToDate: date,
            },
        }
    }),

    /**
     * Settlements date picker actions
     */
    on(SetSettlementDatePickerState, (state, { settlementData }) => {
        return {
            ...state,
            optionsForSettlementDatePicker: settlementData,
        }
    }),

    on(SetSettlementDatePickerType, (state, { pickerType }) => {
        return {
            ...state,
            optionsForSettlementDatePicker: {
                ...state.optionsForSettlementDatePicker,
                datepickerType: pickerType,
            },
        }
    }),

    on(SetSettlementDatePickerExactDate, (state, { date }) => {
        return {
            ...state,
            optionsForSettlementDatePicker: {
                ...state.optionsForSettlementDatePicker,
                exactDate: date,
            },
        }
    }),

    on(SetSettlementDatePickerFromDate, (state, { date }) => {
        return {
            ...state,
            optionsForSettlementDatePicker: {
                ...state.optionsForSettlementDatePicker,
                fromDate: date,
            },
        }
    }),

    on(SetSettlementsDatePickerToDate, (state, { date }) => {
        return {
            ...state,
            optionsForSettlementDatePicker: {
                ...state.optionsForSettlementDatePicker,
                toDate: date,
            },
        }
    }),

    /**
     * Chargeback date picker actions
     */
    on(SetChargebackDatePickerState, (state, { chargebackData }) => {
        return {
            ...state,
            optionsForChargebackDatePicker: chargebackData,
        }
    }),

    on(SetChargebackDatePickerType, (state, { pickerType }) => {
        return {
            ...state,
            optionsForChargebackDatePicker: {
                ...state.optionsForChargebackDatePicker,
                datepickerType: pickerType,
            },
        }
    }),

    on(SetChargebackDatePickerExactDate, (state, { date }) => {
        return {
            ...state,
            optionsForChargebackDatePicker: {
                ...state.optionsForChargebackDatePicker,
                exactDate: date,
            },
        }
    }),

    on(SetChargebackDatePickerFromDate, (state, { date }) => {
        return {
            ...state,
            optionsForChargebackDatePicker: {
                ...state.optionsForChargebackDatePicker,
                fromDate: date,
            },
        }
    }),

    on(SetChargebackDatePickerToDate, (state, { date }) => {
        return {
            ...state,
            optionsForChargebackDatePicker: {
                ...state.optionsForChargebackDatePicker,
                toDate: date,
            },
        }
    }),
)
