import { Injectable } from "@angular/core"

const getLocalStorage = (): Storage => {
    return localStorage
}

const getSessionStorage = (): Storage => {
    return sessionStorage
}

@Injectable({ providedIn: "root" })
export class LocalStorageRefService {
    get localStorage(): Storage {
        return getLocalStorage()
    }
    get sessionStorage(): Storage {
        return getSessionStorage()
    }
}
