import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-info",
    template: `<svg
        viewBox="0 0 24 24"
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                [attr.fill]="color"
                d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                [attr.stroke]="iconColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
    </svg>`,
})
export class InfoIconComponent {
    @Input() width: number = 40
    @Input() height: number = 40
    @Input() color: string = "#fff"
    @Input() iconColor: string = "#0f66d0"
}
