import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-remove-icon",
    template: `<svg
        id="Layer_1"
        [ngStyle]="{
            'width': width + 'px',
            'height': height + 'px',
            'cursor': cursor,
            'stroke': color
        }"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 158 158">
        <defs>
            <style>
                .cls-1 {
                    fill: none;
                    stroke-miterlimit: 10;
                    stroke-width: 7px;
                }

                .cls-2 {
                    fill: #3a3a3a;
                    stroke-width: 0;
                }
            </style>
        </defs>
        <circle class="cls-1" cx="79" cy="79" r="72.64" />
        <rect class="cls-2" x="47.67" y="70.47" width="62.66" height="17.05" />
    </svg>`,
})
export class RemoveIconComponent {
    @Input() width: number = 13
    @Input() height: number = 13
    @Input() cursor: string = "pointer"
    @Input() color: string = "#3a3a3a"
}
