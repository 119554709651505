import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class WidgetCommissions extends ApiResource {
    totalSettled: string
    availableResidualCommission: number
    chargebacks: number
    availableFinanceCommission: number
}

@Injectable({
    providedIn: "root",
})
export class WidgetCommissionsSerializer implements ApiResourceSerializer {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): WidgetCommissions {
        const record = new WidgetCommissions()
        record.totalSettled = json.total_settled
        record.availableResidualCommission = json.available_residual_commission
        record.chargebacks = json.chargebacks
        record.availableFinanceCommission = json.available_finance_commission
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: WidgetCommissions): any {
        return {}
    }
}
