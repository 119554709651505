import { Injectable } from "@angular/core"
import { SelectOption, SelectOptionSerializer } from "./select-option"
import { JsonObject } from "../_core/types"

export class GasEnums {
    termOptions: SelectOption[]
    typeOptions: SelectOption[]
    consumptionUnitOptions: SelectOption[]

    public getOptionName(property: string, id: number): string {
        if (this[property] === undefined) {
            return ""
        }
        const foundItem = this[property].find(item => item.id === id)
        return foundItem ? foundItem.name : "N/A"
    }
}

@Injectable({
    providedIn: "root",
})
export class GasEnumsSerializer {
    constructor(private selectOptionSerializer: SelectOptionSerializer) {}

    fromJson(json: JsonObject): GasEnums {
        const enums = new GasEnums()
        enums.termOptions =
            json.term !== undefined ? json.term.map(item => this.selectOptionSerializer.fromJson(item)) : []
        enums.typeOptions =
            json.type !== undefined ? json.type.map(item => this.selectOptionSerializer.fromJson(item)) : []
        enums.consumptionUnitOptions =
            json.consumption_unit !== undefined
                ? json.consumption_unit.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        return enums
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(enums: GasEnums): any {
        return {}
    }
}
