import { AlertComponentInstance } from "./alert-component-instance.enum"

export class Alert {
    title: string = ""
    message: string
    type: AlertType
    autoClose = true
    keepAfterRouteChange: boolean
    instance = AlertComponentInstance.all
    closeTime: number

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init)
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning,
}
