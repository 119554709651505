import { HttpHandler, HttpEvent, HttpInterceptor, HttpRequest, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
//import { environment } from "../../environments/environment"
import { Injectable } from "@angular/core"

@Injectable()
export class XdebugInterceptor implements HttpInterceptor {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Keeps the original request params. as a new HttpParams
        const newParams = new HttpParams({ fromString: request.params.toString() })

        // Add any params (can also chain .append() but I was conditionally adding params)
        // if (environment.allowXdebugHttpSession === true) {
        //     newParams = newParams.append("XDEBUG_SESSION_START", "PHPSTORM")
        // }

        // Clone the request with params instead of setParams
        const requestClone = request.clone({
            params: newParams,
        })

        return next.handle(requestClone)
    }
}
