import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-message-inbox-open",
    template: `<svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px', 'stroke': color }"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200">
        <defs>
            <style>
                .cls-1 {
                    stroke-width: 12.38px;
                }

                .cls-1,
                .cls-2 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                }

                .cls-2 {
                    stroke-width: 13.12px;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="m181.79,86.5v77.38c0,6.69-6.1,12.12-13.62,12.12H31.38c-7.52,0-13.62-5.42-13.62-12.12v-77.38" />
        <path class="cls-2" d="m181.5,86.5l-81,43L17.5,86.5,90.88,25.96c3.87-3.19,9.43-3.25,13.37-.16l77.25,60.7Z" />
        <line class="cls-2" x1="74.26" y1="121.54" x2="25.5" y2="171.5" />
        <line class="cls-2" x1="119.5" y1="119.5" x2="178.5" y2="171.5" />
    </svg>`,
})
export class MessageInboxOpenComponent {
    @Input() width: number = 15
    @Input() height: number = 15
    @Input() color: string = "#575756"
}
