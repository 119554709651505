import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "@appCore/types"

export class WidgetQuotesAndApplicationsCountOverall extends ApiResource {
    id = 0
    quotesCount: number
    applicationsCount: number
}

@Injectable({
    providedIn: "root",
})
export class WidgetQuotesAndApplicationsCountOverallSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): WidgetQuotesAndApplicationsCountOverall {
        const record = new WidgetQuotesAndApplicationsCountOverall()
        record.id = json.id
        record.quotesCount = json.quotes_count
        record.applicationsCount = json.applications_count
        return record
    }

    toJson(record: WidgetQuotesAndApplicationsCountOverall): JsonObject {
        return {
            quotes_count: record.quotesCount,
            applications_count: record.applicationsCount,
        }
    }
}
