/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ProductPrice, ProductPriceSerializer } from "./product-price"
import { SystemFile, SystemFileSerializer } from "./file"
import { JsonObject } from "../_core/types"
import { PriceRegularity } from "./price-regularity"

export class Product {
    id = 0
    createdAt: Date
    updatedAt: Date

    name: string
    code: string
    description: string
    aditionalInfo: string
    type: number
    categoryId: number
    isCardPresent: boolean
    isIntegrated: boolean
    requiresMid: boolean
    canBeFinancedExternally: boolean
    isForFree: boolean
    maxQty: number
    productImage: SystemFile
    productIcon: SystemFile
    pricing: ProductPrice[]
    pricingFrom: {
        monthly: number
        oneOff: number
        service: number
    }
    integratedProducts: Product[]
    parentProduct: Product

    qty = 0
    contractLength = 1
    isSelected: boolean
    totalPrice = 0

    allowedContractLengths
    allowedPriceRegularity

    commissions?: {
        display: boolean
        rental: number
        pci: number
        1: number
        2: number
        3: number
        4: number
    }[]

    isToggle: boolean = false // Local app variable

    calculatePrices(): void {
        const self = this
        let totalPrice = 0
        this.pricing.forEach(productPrice => {
            if (productPrice.contractLength === self.contractLength) {
                totalPrice += productPrice.priceBuy
            }
        })
        this.totalPrice = this.qty * totalPrice

        this.integratedProducts.forEach(intProduct => {
            intProduct.calculatePrices()
            this.totalPrice += intProduct.totalPrice
        })
    }

    isAllowedPriceRegularity(regularity: number): boolean {
        return this.allowedPriceRegularity?.find(item => item.price_regularity === regularity) !== undefined
    }
}

@Injectable({
    providedIn: "root",
})
export class ProductSerializer implements ApiResourceSerializer {
    constructor(
        private systemFileSerializer: SystemFileSerializer,
        private productPriceSerializer: ProductPriceSerializer,
    ) {}

    fromJson(json: JsonObject): Product {
        const record = new Product()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.name = json.name
        record.code = json.code
        record.description = json.description
        record.aditionalInfo = json.aditional_info
        record.type = json.type
        record.categoryId = json.category_id
        record.isCardPresent = json.is_card_present
        record.isIntegrated = json.is_integrated
        record.requiresMid = json.requires_mid
        record.canBeFinancedExternally = json.can_be_financed_externally
        record.isForFree = json.is_for_free
        record.maxQty = json.max_qty
        record.productImage =
            json.product_image !== undefined && json.product_image !== null && json.product_image !== ""
                ? this.systemFileSerializer.fromJson(json.product_image)
                : null
        record.productIcon =
            json.product_icon !== undefined && json.product_icon !== null && json.product_icon !== ""
                ? this.systemFileSerializer.fromJson(json.product_icon)
                : null
        record.pricing =
            json.pricing !== undefined && json.pricing !== null
                ? json.pricing.map(item => this.productPriceSerializer.fromJson(item))
                : []
        record.pricingFrom =
            json.pricing_from !== null
                ? {
                      monthly: json.pricing_from.monthly,
                      oneOff: json.pricing_from.one_off,
                      service: json.pricing_from.service,
                  }
                : null

        record.integratedProducts =
            json.integrated_products !== undefined && json.integrated_products !== null
                ? json.integrated_products.map(item => {
                      const intProduct = this.fromJson(item)
                      intProduct.parentProduct = record

                      return intProduct
                  })
                : []

        record.allowedPriceRegularity = json.allowed_price_regularity
        const monthlyPriceRegularity = json.allowed_price_regularity?.find(
            item => item.price_regularity === PriceRegularity.MONTHLY,
        )
        record.allowedContractLengths = monthlyPriceRegularity ? monthlyPriceRegularity.contract_lengths : []

        record.commissions =
            json.commissions !== undefined && json.commissions !== null
                ? json.commissions.map(item => {
                      return {
                          display: true,
                          rental: item.rental,
                          pci: item.pci,
                          1: item["1yr"],
                          2: item["2yr"],
                          3: item["3yr"],
                          4: item["4yr"],
                      }
                  })
                : null

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: Product): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            name: record.name,
            code: record.code,
            description: record.description,
            aditional_info: record.aditionalInfo,
            type: record.type,
            category_id: record.categoryId,
            is_card_present: record.isCardPresent,
            is_integrated: record.isIntegrated,
            requires_mid: record.requiresMid,
            can_be_financed_externally: record.canBeFinancedExternally,
            is_for_free: record.isForFree,
            max_qty: record.maxQty,

            product_image:
                record.productImage !== undefined && record.productImage !== null
                    ? this.systemFileSerializer.fromJson(record.productImage)
                    : null,

            product_icon:
                record.productIcon !== undefined && record.productIcon !== null
                    ? this.systemFileSerializer.toJson(record.productIcon)
                    : null,

            pricing:
                record.pricing !== undefined && record.pricing !== null
                    ? record.pricing.map(item => this.productPriceSerializer.toJson(item))
                    : [],

            pricing_from:
                record.pricingFrom !== null
                    ? {
                          monthly: record.pricingFrom.monthly,
                          one_off: record.pricingFrom.oneOff,
                          service: record.pricingFrom.service,
                      }
                    : null,

            integrated_products:
                record.integratedProducts !== undefined && record.integratedProducts !== null
                    ? record.integratedProducts.map(item => {
                          const intProduct = this.toJson(item)
                          intProduct.parentProduct = record
                          return intProduct
                      })
                    : [],

            allowed_price_regularity: record.allowedPriceRegularity,
            commissions:
                record.commissions !== undefined && record.commissions !== null
                    ? record.commissions.map(item => {
                          return {
                              display: true,
                              rental: item.rental,
                              pci: item.pci,
                              1: item["1yr"],
                              2: item["2yr"],
                              3: item["3yr"],
                              4: item["4yr"],
                          }
                      })
                    : null,
        }
    }
}
