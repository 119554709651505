/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { BoardingAdditionalQuestionSerializer } from "./boarding-additional-question"

export class BoardingAddition {
    id: number
    code: string
    isMccFlagged: boolean
    question: string
    answerType: string
    additionalQuestions: {
        id: number
        code: string
        isMccFlagged: boolean
        question: string
        answerType: string
        mccCodes: number[]
        note: string
        section: string
    }[]
    mccCodes: number[]
    note: string
    section: string

    // Application variable
    isActive: boolean = false
}

@Injectable({
    providedIn: "root",
})
export class BoardingAdditionSerializer implements ApiResourceSerializer {
    constructor(private boardingAdditionalQuestionSerializer: BoardingAdditionalQuestionSerializer) {}

    fromJson(json: any): BoardingAddition {
        const record = new BoardingAddition()
        record.id = json.id
        record.code = json.code
        record.isMccFlagged = json.is_mcc_flagged
        record.question = json.question
        record.answerType = json.answer_type
        record.additionalQuestions = json.additional_questions
            ? json.additional_questions.map(item => this.boardingAdditionalQuestionSerializer.fromJson(item))
            : []
        record.mccCodes = json.mcc_codes
        record.note = json.note
        record.section = json.section
        return record
    }

    toJson(record: BoardingAddition) {
        return {
            id: record.id,
            code: record.code,
            is_mcc_flagged: record.isMccFlagged,
            question: record.question,
            answer_type: record.answerType,
            additional_questions: record.additionalQuestions
                ? record.additionalQuestions.map(item => this.boardingAdditionalQuestionSerializer.toJson(item))
                : [],
            mcc_codes: record.mccCodes,
            note: record.note,
            section: record.section,
        }
    }
}
