import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { Country, CountrySerializer } from "./country"
import { JsonObject } from "../_core/types"

export class CustomerAddress {
    id = 0
    createdAt: Date
    updatedAt: Date

    // Legacy variables
    address1: string
    address2: string
    address3: string
    houseNameNumber: string

    // Newly added variables
    addressLine1: string
    addressLine2: string
    addressLine3: string
    houseName: string
    houseNumber: string
    streetName: string

    // Variables
    city: string
    postcode: string
    countryId: number = Country.DEFAULT_COUNTRY_ID
    country: Country

    types: string[]
    type: number[]
}

@Injectable({
    providedIn: "root",
})
export class CustomerAddressSerializer implements ApiResourceSerializer {
    constructor(private countrySerializer: CountrySerializer) {}

    fromJson(json: JsonObject): CustomerAddress {
        const record = new CustomerAddress()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)

        // Legacy variables
        record.address1 = json.address_1
        record.address2 = json.address_2
        record.address3 = json.address_3
        record.houseNameNumber = json.house_name_number

        // Newly added variables
        record.addressLine1 = json.address_line_1
        record.addressLine2 = json.address_line_2
        record.addressLine3 = json.address_line_3
        record.houseName = json.house_name
        record.houseNumber = json.house_number
        record.streetName = json.street_name

        // Variables
        record.city = json.city
        record.postcode = json.postcode
        record.countryId = json.country_id
        record.country = json.country ? this.countrySerializer.fromJson(json.country) : null

        record.types = json.types
        record.type = json.type

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CustomerAddress): any {
        return {
            id: record.id,

            // Legacy variables
            address_1: record.address1,
            address_2: record.address2,
            address_3: record.address3,
            house_name_number: record.houseNameNumber,

            // Newly added variables
            address_line_1: record.addressLine1,
            address_line_2: record.addressLine2,
            address_line_3: record.addressLine3,
            house_name: record.houseName,
            house_number: record.houseNumber,
            street_name: record.streetName,

            // Variables
            city: record.city,
            postcode: record.postcode,
            country_id: record.countryId,
            country: record.country ? this.countrySerializer.toJson(record.country) : null,

            types: record.types,
            type: record.type,
        }
    }
}
