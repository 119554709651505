import {
    WidgetTransactionsTotalCountDaily,
    WidgetTransactionsTotalCountDailySerializer,
} from "../../_models/widget-transactions-total-count-daily"
import { PaginationSerializer } from "../../_models/pagination"
import { Injectable } from "@angular/core"
import { ApiSubResourceService } from "./api-sub-resource.service"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

@Injectable({
    providedIn: "root",
})
export class WidgetTransactionsTotalCountDailyApiService extends ApiSubResourceService<WidgetTransactionsTotalCountDaily> {
    constructor(
        httpClient: HttpClient,
        serializer: WidgetTransactionsTotalCountDailySerializer,
        protected paginationSerializer: PaginationSerializer,
    ) {
        super(
            httpClient,
            environment.mainApi.url.slice(0, -1),
            environment.mainApi.endpoints.widgets,
            "transactions/total-count/daily",
            serializer,
            paginationSerializer,
        )
    }
}
