import { DashboardTotalAmountDailyEffects } from "./dashboard/TotalAmountDailyEffect"
import { DashboardTotalAmountMonthlyEffects } from "./dashboard/TotalAmountMonthlyEffect"
import { DashboardTerminalCountDailyEffects } from "./dashboard/TerminalCountDailyEffect"
import { DashboardTotalCountMonthlyEffects } from "./dashboard/TotalCountMonthlyEffect"
import { CommissionsEffects } from "./dashboard/CommissionsEffect"
import { QuoteAndApplicationsCountOverallEffect } from "./dashboard/QuoteAndApplicationsCountOverallEffect"
import { AllBusinessOverviewEffects } from "./dashboard/AllBusinessOverviewEffect"
import { CommissionFinanaceEffect } from "./dashboard/CommissionFinanaceEffect"
import { CommissionResidualEffect } from "./dashboard/CommissionResidualEffect"

const AllEffect = [
    DashboardTotalAmountDailyEffects, // +
    DashboardTotalAmountMonthlyEffects, // +
    DashboardTerminalCountDailyEffects, // +
    DashboardTotalCountMonthlyEffects, // +
    CommissionsEffects, // +
    QuoteAndApplicationsCountOverallEffect, // +
    AllBusinessOverviewEffects, // +
    CommissionFinanaceEffect, // +
    CommissionResidualEffect, // +
]
export default AllEffect
