import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { CustomerNotificationCase, CustomerNotificationCaseSerializer } from "./customer-notification-case"

export class CustomerNotificationUser {
    id: number = 0
    name: string
    readAt: Date
    archivedAt: Date
    pushSentAt: Date
    reply: string
    case: CustomerNotificationCase
}

@Injectable({
    providedIn: "root",
})
export class CustomerNotificationUserSerializer implements ApiResourceSerializer {
    constructor(private customerNotificationCaseSerializer: CustomerNotificationCaseSerializer) {}

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fromJson(json: any): CustomerNotificationUser {
        const notificationUser = new CustomerNotificationUser()
        notificationUser.id = json.id
        notificationUser.name = json.name
        notificationUser.readAt = json.read_at
        notificationUser.archivedAt = json.archived_at
        notificationUser.pushSentAt = json.push_sent_at
        notificationUser.case =
            json.case !== undefined && json.case !== null
                ? this.customerNotificationCaseSerializer.fromJson(json.case)
                : null
        return notificationUser
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(customerNotificationUser: CustomerNotificationUser): any {
        return {
            id: customerNotificationUser.id,
        }
    }
}
