import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Terminal, TerminalSerializer } from "./terminal"

export class CustomerMid extends ApiResource {
    id: number
    createdAt: Date
    updatedAt: Date
    mid: string
    midType: number
    status: number
    customerLocationId: number
    terminals: Terminal[]

    constructor() {
        super()
        this.id = 0
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerMidSerializer implements ApiResourceSerializer {
    constructor(private terminalSerializer: TerminalSerializer) {}

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): CustomerMid {
        const mid = new CustomerMid()
        mid.id = json.id
        mid.createdAt = new Date(json.created_at)
        mid.updatedAt = new Date(json.updated_at)
        mid.mid = json.mid
        mid.midType = json.mid_type
        mid.status = json.status
        mid.customerLocationId = json.customer_location_id
        mid.terminals =
            json.terminals !== undefined ? json.terminals.map(item => this.terminalSerializer.fromJson(item)) : []
        return mid
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(mid: CustomerMid): any {
        return {
            id: mid.id,
            mid: mid.mid,
            mid_type: mid.midType,
            status: mid.status,
            customer_location_id: mid.customerLocationId,
        }
    }
}
