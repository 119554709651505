import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class OpeningHours {
    id: number
    day: number
    is_open: boolean
    open_from: number
    open_to: number
}

@Injectable({
    providedIn: "root",
})
export class OpeninghoursSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): OpeningHours {
        const hours = new OpeningHours()
        hours.id = 1
        hours.day = json.day
        hours.is_open = json.is_open
        hours.open_from = json.open_from
        hours.open_to = json.open_to

        return hours
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(hours: OpeningHours): any {
        return {
            day: hours.day,
            is_open: hours.is_open,
            open_from: hours.open_from,
            open_to: hours.open_to,
        }
    }
}
