import { Injectable } from "@angular/core"
import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CustomerFinanceAgreement extends ApiResource {
    id: number
    createdAt: Date
    updatedAt: Date

    status: number
    info: string

    constructor() {
        super()
        this.id = 0
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerFinanceAgreementSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): CustomerFinanceAgreement {
        const agreement = new CustomerFinanceAgreement()
        agreement.id = json.id
        agreement.createdAt = new Date(json.created_at)
        agreement.updatedAt = new Date(json.updated_at)

        agreement.status = json.status
        agreement.info = json.info

        return agreement
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(agreement: CustomerFinanceAgreement): any {
        return {
            id: agreement.id,
        }
    }
}
