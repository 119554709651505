/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy, NgZone } from "@angular/core"
import { NavigationStart, Router, NavigationEnd } from "@angular/router"
import { EmptyError, Observable, Subject, Subscription } from "rxjs"
import { Alert, AlertType } from "../shared/alert/alert"
import { AlertComponentInstance } from "../shared/alert/alert-component-instance.enum"
import { filter } from "rxjs/operators"
import { AppError } from "../_core/app-error"
import { AppState } from "@app/store"
import { Store } from "@ngrx/store"
@Injectable({
    providedIn: "root",
})
export class AlertService implements OnDestroy {
    navigationEndUrl: string
    isLoginPage: boolean = false
    islogOut: boolean = false

    unauthorizedPages: string[] = ["/login", "/pin-reset", "/users/account-activation", "/login/android", "/login/ios"]

    private routerEventsSubscription: Subscription
    private subject = new Subject<Alert>()
    private keepAfterRouteChange = false

    constructor(private router: Router, private store: Store<AppState>, private zone: NgZone) {
        this.navigationEndUrl = this.router.url

        // Clear alert message on route change
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false
                } else {
                    this.clear()
                }
            }
        })

        this.routerEventsSubscription = this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                const url = val.url.split("?")[0]
                this.zone.run(() => {
                    this.navigationEndUrl = url
                    this.isLoginPage = this.unauthorizedPages.indexOf(url) > -1
                })
            }
        })
    }

    ngOnDestroy(): void {
        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe()
        }
    }

    success(
        message: string,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
    ): void {
        this.alert(new Alert({ message, type: AlertType.Success, keepAfterRouteChange, autoClose, instance }))
    }

    error(
        message: string,
        keepAfterRouteChange = false,
        autoClose = true,
        instance = AlertComponentInstance.all,
    ): void {
        this.alert(new Alert({ message, type: AlertType.Error, keepAfterRouteChange, autoClose, instance }))
    }

    warning(
        message: string,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
    ): void {
        this.alert(new Alert({ message, type: AlertType.Warning, keepAfterRouteChange, autoClose, instance }))
    }

    info(
        message: string,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
    ): void {
        this.alert(new Alert({ message, type: AlertType.Info, keepAfterRouteChange, autoClose, instance }))
    }

    onAlert(instance?: AlertComponentInstance): Observable<Alert> {
        return this.subject
            .asObservable()
            .pipe(filter(x => x && (x.instance === AlertComponentInstance.all || x.instance === instance)))
    }

    // Main alert method
    alert(alert: Alert): void {
        this.keepAfterRouteChange = alert.keepAfterRouteChange
        this.subject.next(alert)
    }

    // Clear alerts
    clear(instance?: AlertComponentInstance): void {
        this.subject.next(new Alert({ instance }))
    }

    apiRawError(
        error: any,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
    ): void {
        if (error.error.message) {
            this.apiError(error.error)
        } else {
            this.error(error.message, keepAfterRouteChange, autoClose, instance)
        }
    }

    /**
     * Alerts error based on API JSON response
     */
    apiError(
        errorResponse: any,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
    ): void {
        let message = ""

        if (errorResponse.message !== "") {
            message += errorResponse.message
        }

        if (errorResponse.errors !== undefined && errorResponse.errors.length !== 0) {
            const errors = errorResponse.errors
            if (Array.isArray(errors) === true) {
                for (const error of errors) {
                    if (Array.isArray(error) === true) {
                        for (const msg of error) {
                            message += "<br /> - " + msg
                        }
                    } else {
                        message += "<br /> - " + error
                    }
                }
            } else {
                for (const key of Object.keys(errors)) {
                    message += "<br /> - " + errors[key]
                }
            }
        }

        this.error(message, keepAfterRouteChange, autoClose, instance)
    }

    userError(
        appError: AppError,
        defaultMessage?: string | null,
        keepAfterRouteChange = false,
        autoClose = true,
        instance = AlertComponentInstance.all,
    ): void {
        const error = appError.error?.error
        let message = ""

        if (error?.user_errors?.general && error.user_errors.general.length !== 0) {
            const errors = error.user_errors.general
            if (Array.isArray(errors) === true) {
                for (const err of errors) {
                    if (Array.isArray(err) === true) {
                        for (const msg of err) {
                            message += " - " + msg + "<br />"
                        }
                    } else {
                        message += err + "<br />"
                    }
                }
            } else {
                for (const key of Object.keys(errors)) {
                    message += "<br /> - " + errors[key]
                }
            }
        }

        if (error?.user_errors?.fields && error.user_errors.fields.length !== 0) {
            const fieldErrors = error.user_errors.fields as { [f: string]: string[] }
            Object.entries(fieldErrors).forEach(value => {
                if (Array.isArray(value) === true) {
                    for (const fieldErr of value) {
                        if (Array.isArray(fieldErr) === true) {
                            for (const msg of fieldErr) {
                                message += " - " + msg + "<br />"
                            }
                        } else {
                            message += fieldErr + "<br />"
                        }
                    }
                }
            })
        }

        message = message === "" ? defaultMessage : message

        this.store
            .select(state => state.http.logOut)
            .subscribe(islogOut => {
                this.islogOut = islogOut
            })

        if (
            (!this.islogOut && !this.isLoginPage && error?.status !== 401) ||
            error?.status === "Forbidden" ||
            error?.status === "Not Found"
        ) {
            this.error(message, keepAfterRouteChange, autoClose, instance)
        }
    }

    /**
     * Alerts error based on API JSON response
     */
    applicationError(
        appError: AppError,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
        showUserErrors: boolean = false,
    ): void {
        const error = appError.error.error
        const message: string = this.getMessage(error, showUserErrors)
        const defaultMessage =
            "We're sorry but the application having some technical difficulties. Please try to reload the page!"

        this.store
            .select(state => state.http.logOut)
            .subscribe(islogOut => {
                this.islogOut = islogOut
            })

        if (!this.islogOut && !this.isLoginPage && error?.status !== 401) {
            this.error(message ?? defaultMessage, keepAfterRouteChange, autoClose, instance)
        }
    }

    unauthorizedError(
        appError: AppError | EmptyError,
        defaultMessage?: string | null,
        keepAfterRouteChange = false,
        autoClose = false,
        instance = AlertComponentInstance.all,
        showUserErrors: boolean = false,
    ): void {
        if (appError instanceof EmptyError) {
            return
        }

        let message: string = this.getMessage(appError?.error?.error, showUserErrors)
        message = message === "" ? defaultMessage : message
        this.error(message, keepAfterRouteChange, autoClose, instance)
    }

    private getMessage(error, showUserErrors): string {
        let message = ""

        if (showUserErrors === true) {
            if (error?.user_errors?.general && error.user_errors.general.length !== 0) {
                const errors = error.user_errors.general
                if (Array.isArray(errors) === true) {
                    for (const err of errors) {
                        if (Array.isArray(err) === true) {
                            for (const msg of err) {
                                message += " - " + msg + "<br />"
                            }
                        } else {
                            message += err + "<br />"
                        }
                    }
                } else {
                    for (const key of Object.keys(errors)) {
                        message += "<br /> - " + errors[key]
                    }
                }
            }
        } else {
            if (error?.message !== "") {
                message += error.message
            }

            if (error?.errors !== undefined && error.errors !== null && error.errors.length !== 0) {
                const errors = error.errors
                if (Array.isArray(errors) === true) {
                    for (const err of errors) {
                        if (Array.isArray(err) === true) {
                            for (const msg of err) {
                                message += "<br /> - " + msg
                            }
                        } else {
                            message += "<br /> - " + err
                        }
                    }
                } else {
                    for (const key of Object.keys(errors)) {
                        message += "<br /> - " + errors[key]
                    }
                }
            }
        }

        return message
    }
}
