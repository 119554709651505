import { Component, EventEmitter, Inject, Output } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

interface AlertDialogData {
    title: string
    message: string
}

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
})
export class AlertDialogComponent {
    @Output() closed = new EventEmitter<void>()
    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    ) {}

    onClose(): void {
        this.closed.emit()
        this.dialogRef.close()
    }
}
