import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { State, StateSerializer } from "./state"
import { Country, CountrySerializer } from "./country"
import { EnumsService } from "../_services/enums.service"
import { JsonObject } from "../_core/types"

export class CustomerApplicant {
    id = 0
    createdAt: Date
    updatedAt: Date

    title: string
    firstName: string
    middleName: string
    lastName: string
    dob: Date
    gender: string
    email: string
    phone: string
    ownership: string

    isContactPerson = false
    isResponsibleParty = false
    isAuthorisedSigner = false
    isBeneficialOwner = false
    isDirector = false

    isPep = false
    isRca = false
    nationalityId: number
    nationality: Country
    personIdType: number
    personId: string

    streetNo: string
    streetName: string
    address1: string
    address2: string
    address3: string
    city: string
    postcode: string
    state: State
    stateId: number
    country: Country
    countryId: number
    lengthOfResidenceYears: number
    lengthOfResidenceMonths: number

    paStreetNo: string
    paStreetName: string
    paAddress1: string
    paAddress2: string
    paAddress3: string
    paCity: string
    paPostcode: string
    paState: State
    paStateId: number
    paCountry: Country
    paCountryId: number
    paLengthOfResidenceYears: number
    paLengthOfResidenceMonths: number
    w2DirectorName: string

    constructor(private enumsService?: EnumsService) {}

    get fullName(): string {
        return this.enumsService.getOptionName("titleOptions", this.title) + " " + this.firstName + " " + this.lastName
    }

    get totalFullName(): string {
        return (
            this.enumsService.getOptionName("titleOptions", this.title) +
            " " +
            this.firstName +
            " " +
            this.middleName +
            " " +
            this.lastName
        )
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerApplicantSerializer implements ApiResourceSerializer {
    constructor(
        private countrySerializer: CountrySerializer,
        private stateSerializer: StateSerializer,
        private enumsService: EnumsService,
    ) {}

    fromJson(json: JsonObject): CustomerApplicant {
        const applicant = new CustomerApplicant(this.enumsService)
        applicant.id = json.id
        applicant.createdAt = new Date(json.created_at)
        applicant.updatedAt = new Date(json.updated_at)

        applicant.title = json.title
        applicant.firstName = json.first_name
        applicant.middleName = json.middle_name
        applicant.lastName = json.last_name
        applicant.dob = json.dob !== undefined && json.dob !== null ? new Date(json.dob) : null
        applicant.gender = json.gender
        applicant.email = json.email
        applicant.phone = json.phone
        applicant.ownership = json.ownership

        applicant.isContactPerson = json.is_contact_person
        applicant.isResponsibleParty = json.is_responsible_party
        applicant.isAuthorisedSigner = json.is_authorised_signer
        applicant.isBeneficialOwner = json.is_beneficial_owner
        applicant.isDirector = json.is_director

        applicant.isPep = json.is_pep
        applicant.isRca = json.is_rca
        applicant.nationalityId =
            json.nationality !== undefined && json.nationality !== null ? json.nationality.id : null
        applicant.nationality =
            json.nationality !== undefined && json.nationality !== null
                ? this.countrySerializer.fromJson(json.nationality)
                : null
        applicant.personIdType = json.person_id_type
        applicant.personId = json.person_id

        applicant.streetNo = json.street_no
        applicant.streetName = json.street_name
        applicant.address1 = json.address_1
        applicant.address2 = json.address_2
        applicant.address3 = json.address_3
        applicant.city = json.city
        applicant.postcode = json.postcode
        applicant.stateId = json.state !== undefined && json.state !== null ? json.state.id : null
        applicant.state =
            json.state !== undefined && json.state !== null ? this.stateSerializer.fromJson(json.state) : null
        applicant.countryId = json.country !== undefined && json.country != null ? json.country.id : null
        applicant.country =
            json.country !== undefined && json.country !== null ? this.countrySerializer.fromJson(json.country) : null
        applicant.lengthOfResidenceYears = json.length_of_residence_years
        applicant.lengthOfResidenceMonths = json.length_of_residence_months

        applicant.paStreetNo = json.pa_street_no
        applicant.paStreetName = json.pa_street_name
        applicant.paAddress1 = json.pa_address_1
        applicant.paAddress2 = json.pa_address_2
        applicant.paAddress3 = json.pa_address_3
        applicant.paCity = json.pa_city
        applicant.paPostcode = json.pa_postcode
        applicant.paStateId = json.pa_state !== undefined && json.pa_state !== null ? json.pa_state.id : null
        applicant.paState =
            json.pa_state !== undefined && json.pa_state !== null ? this.stateSerializer.fromJson(json.pa_state) : null
        applicant.paCountryId = json.pa_country !== undefined && json.pa_country !== null ? json.pa_country.id : null
        applicant.paCountry =
            json.pa_country !== undefined && json.pa_country !== null
                ? this.countrySerializer.fromJson(json.pa_country)
                : null
        applicant.paLengthOfResidenceYears = json.pa_length_of_residence_years
        applicant.paLengthOfResidenceMonths = json.pa_length_of_residence_months

        applicant.w2DirectorName = json.w2_director_name

        return applicant
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(applicant: CustomerApplicant): any {
        return {
            id: applicant.id,

            title: applicant.title,
            first_name: applicant.firstName,
            middle_name: applicant.middleName,
            last_name: applicant.lastName,
            dob: applicant.dob,
            gender: applicant.gender,
            email: applicant.email,
            phone: applicant.phone,
            ownership: applicant.ownership,

            is_contact_person: applicant.isContactPerson === true,
            is_responsible_party: applicant.isResponsibleParty === true,
            is_authorised_signer: applicant.isAuthorisedSigner === true,
            is_beneficial_owner: applicant.isBeneficialOwner === true,
            is_director: applicant.isDirector === true,

            is_pep: applicant.isPep === true,
            is_rca: applicant.isRca === true,
            nationality_id: applicant.nationalityId,
            person_id_type: applicant.personIdType,
            person_id: applicant.personId,

            street_no: applicant.streetNo,
            street_name: applicant.streetName,
            address_1: applicant.address1,
            address_2: applicant.address2,
            address_3: applicant.address3,
            city: applicant.city,
            postcode: applicant.postcode,
            state_id: applicant.stateId,
            country_id: applicant.countryId,
            length_of_residence_years: applicant.lengthOfResidenceYears,
            length_of_residence_months: applicant.lengthOfResidenceMonths,

            pa_street_no: applicant.paStreetNo,
            pa_street_name: applicant.paStreetName,
            pa_address_1: applicant.paAddress1,
            pa_address_2: applicant.paAddress2,
            pa_address_3: applicant.paAddress3,
            pa_city: applicant.paCity,
            pa_postcode: applicant.paPostcode,
            pa_state_id: applicant.paStateId,
            pa_country_id: applicant.paCountryId,
            pa_length_of_residence_years: applicant.paLengthOfResidenceYears,
            pa_length_of_residence_months: applicant.paLengthOfResidenceMonths,

            w2_director_name: applicant.w2DirectorName,
        }
    }
}
