import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-search-icon",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="23.37" height="23.37" viewBox="0 0 23.37 23.37">
        <g id="Group_54" data-name="Group 54" transform="translate(-657.557 -343.34)">
            <g id="Group_53" data-name="Group 53">
                <g id="Group_52" data-name="Group 52">
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M659.922,345.7a8.072,8.072,0,1,0,11.416,0A8.071,8.071,0,0,0,659.922,345.7Zm9.619,9.62a5.532,5.532,0,1,1,0-7.824A5.538,5.538,0,0,1,669.541,355.324Z"
                        fill="#bbb7b7" />
                </g>
            </g>
            <rect
                id="Rectangle_16"
                data-name="Rectangle 16"
                width="2.846"
                height="2.846"
                transform="translate(669.209 357.004) rotate(-45)"
                fill="#bbb7b7" />
            <path
                id="Path_30"
                data-name="Path 30"
                d="M680.4,365.738l-.445.444a1.8,1.8,0,0,1-2.549,0l-6.561-6.561a.161.161,0,0,1,0-.228l2.765-2.765a.161.161,0,0,1,.228,0l6.562,6.561A1.8,1.8,0,0,1,680.4,365.738Z"
                fill="#bbb7b7" />
        </g>
    </svg>`,
})
export class SearchIconComponent {}
