import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-message-inbox",
    template: `<svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px', 'stroke': color }"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200">
        <defs>
            <style>
                .cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 13.12px;
                }
            </style>
        </defs>
        <rect class="cls-1" x="17.76" y="39.74" width="164.02" height="121.38" rx="13.62" ry="13.62" />
        <polyline class="cls-1" points="17.76 51.08 100.26 115.54 181.79 51.08" />
        <line class="cls-1" x1="74.26" y1="95.54" x2="21.26" y2="156.54" />
        <line class="cls-1" x1="125.26" y1="98.54" x2="178.26" y2="156.54" />
    </svg>`,
})
export class MessageInboxComponent {
    @Input() width: number = 15
    @Input() height: number = 15
    @Input() color: string = "#575756"
}
