import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-vpayment-icon",
    template: `<svg
        [ngStyle]="{ width: size }"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1069 432">
        <defs>
            <style>
                .cls-1 {
                    fill: #3179ff;
                }

                .cls-2 {
                    fill: #fff;
                    font-family: Avenir-Medium, Avenir;
                    font-size: 173.88px;
                    font-weight: 500;
                    letter-spacing: -0.04em;
                }
            </style>
        </defs>
        <rect class="cls-1" x="43.06" y="70.35" width="975.57" height="287.65" rx="107.57" ry="107.57" />
        <text class="cls-2" transform="translate(133.88 266.21)"><tspan x="0" y="0">vPayments</tspan></text>
    </svg>`,
})
export class VPaymentIconComponent {
    @Input() size: string = "120px"
}
