export enum DashboardWidgetColor {
    Real = "#307aff",
    Refund = "#89a9f8",
    Predicted = "#BDBCBC",
    MasterCard = "#f79e1b",
    Visa = "#1434cb",
    VisaCredit = "#1f2f3e",
    Maestro = "#eb001b",
    Amex = "#016fd0",
}
