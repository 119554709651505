<section class="container {{animationClass}}">
  <figure class="alertContent {{ getCssClass() }}Alert appPa-15">
    <section class="iconPlaceholder">
            <span aria-hidden="true">
              <svg-warning *ngIf="alert.type === 3"></svg-warning>
              <svg-error *ngIf="alert.type === 1"></svg-error>
              <svg-info *ngIf="alert.type === 2"></svg-info>
              <svg-success *ngIf="alert.type === 0" [width]="30" [height]="30"></svg-success>
            </span>
    </section>

    <section class="alertBody">
      <header>
        <h2>{{getAlertTitle()}}</h2>
      </header>
      <p class="text" [innerHtml]="alert.message"></p>
    </section>

    <section class="alertClose">
      <button (click)="removeAlert()">
        <svg-close></svg-close>
      </button>
    </section>

  </figure>
</section>
