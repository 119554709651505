import { Injectable } from "@angular/core"
import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { CustomerFinanceAgreement, CustomerFinanceAgreementSerializer } from "./customer-finance-agreement"
import { JsonObject } from "../_core/types"

export class CustomerContract extends ApiResource {
    id: number
    createdAt: Date
    updatedAt: Date

    signedAt: Date | null
    expireAt: Date | null
    status: number
    priceRegularity: number
    contractLength: number
    info: string
    completedInfo: string

    financeAgreements: CustomerFinanceAgreement[]

    constructor() {
        super()
        this.id = 0
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerContractSerializer implements ApiResourceSerializer {
    constructor(private customerFinanceAgreementSerializer: CustomerFinanceAgreementSerializer) {}

    fromJson(json: JsonObject): CustomerContract {
        const contract = new CustomerContract()
        contract.id = json.id
        contract.createdAt = new Date(json.created_at)
        contract.updatedAt = new Date(json.updated_at)

        contract.signedAt = json.signed_at !== null ? new Date(json.signed_at) : null
        contract.expireAt = json.expire_at !== null ? new Date(json.expire_at) : null

        contract.status = json.status
        contract.priceRegularity = json.price_regularity
        contract.contractLength = json.contract_length
        contract.info = json.info
        contract.completedInfo = json.completed_info

        contract.financeAgreements =
            json.finance_agreements !== undefined && json.finance_agreements !== null
                ? json.finance_agreements.map(item => this.customerFinanceAgreementSerializer.fromJson(item))
                : null

        return contract
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(contract: CustomerContract): any {
        return {
            id: contract.id,
        }
    }
}
