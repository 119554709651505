import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"

export class WidgetTransactionsTotalAmountDaily extends ApiResource {
    id = 0
    date: number
    totalAmount: string
}

@Injectable({
    providedIn: "root",
})
export class WidgetTransactionsTotalAmountDailySerializer implements ApiResourceSerializer {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJson(json: any): WidgetTransactionsTotalAmountDaily {
        const record = new WidgetTransactionsTotalAmountDaily()
        record.date = json.date
        record.totalAmount = json.total_amount
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: WidgetTransactionsTotalAmountDaily): any {
        return {
            date: record.date,
            total_amount: record.totalAmount,
        }
    }
}
