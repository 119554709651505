<footer class="poweredByPayPanel" [ngClass]="[padding ? '' : 'p-0', size]">
    <p>Powered by</p>

    <section *ngIf="poweredBy === 'payPanel'">
      <svg-verofy-footer-logo />
    </section>

    <picture *ngIf="poweredBy === 'vPayment'">
        <img class="pImg" [ngClass]="[imgMargin ? 'appMl-10' : '']" src="./assets/img/vpayment.png" alt="My pay links"/>
    </picture>

    <picture *ngIf="poweredBy === 'myPayLink'">
        <img class="pImg" [ngClass]="[imgMargin ? 'appMl-10' : '']" src="./assets/img/pay_by_link.png" alt="My pay links"/>
    </picture>
</footer>
