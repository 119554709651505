import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-delete-icon-black",
    template: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <defs>
            <style>
                .cls-1 {
                    fill: none;
                    stroke: #000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 0.68px;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="m25.55,5.28l-1.92,23.06c-.05.66-.61,1.17-1.27,1.17H7.64c-.66,0-1.21-.51-1.27-1.17l-1.92-23.06" />
        <rect class="cls-1" x="3.06" y="2.55" width="23.88" height="2.73" rx=".58" ry=".58" />
        <path
            class="cls-1"
            d="m11.99,2.36c-.04-.06-.06-.13-.06-.21V.9c0-.22.18-.4.4-.4h5.35c.22,0,.4.18.4.4v1.25c0,.11-.04.21-.12.28" />
        <line class="cls-1" x1="15.12" y1="9.7" x2="15.12" y2="25.72" />
        <line class="cls-1" x1="9.32" y1="9.7" x2="10.69" y2="25.72" />
        <line class="cls-1" x1="20.92" y1="9.7" x2="19.56" y2="25.72" />
    </svg>`,
})
export class DeleteIconBlackComponent {}
