import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiResource } from "../../_models/api-resource"
import { ApiResourceSerializer } from "../../_core/api-resource.serializer"
import { AssociativeArray } from "../../_core/types"
import { ApiBaseService } from "./api-base.service"
import { PaginationSerializer } from "../../_models/pagination"

export class ApiResourceService<T extends ApiResource> extends ApiBaseService {
    constructor(
        protected httpClient: HttpClient,
        protected url: string,
        protected endpoint: string,
        protected serializer: ApiResourceSerializer,
        protected paginationSerializer: PaginationSerializer,
    ) {
        super()
    }

    public create(item: T, customHeaders: AssociativeArray<string> = {}): Observable<T> {
        const headers = this.prepareHttpHeaders(customHeaders)
        return (
            this.httpClient
                .post<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item), { headers })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public update(item: T, customHeaders: AssociativeArray<string> = {}): Observable<T> {
        const headers = this.prepareHttpHeaders(customHeaders)
        return (
            this.httpClient
                .put<T>(`${this.url}/${this.endpoint}/${item.id}`, this.serializer.toJson(item), { headers })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public get(
        id?: number,
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<T> {
        const headers = this.prepareHttpHeaders(customHeaders)
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/${this.endpoint}` + (id !== undefined ? "/" + id : ""), { headers, params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public list(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<T[]> {
        const headers = this.prepareHttpHeaders(customHeaders)
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(`${this.url}/${this.endpoint}`, { headers, params })
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.convertData(data.data)))
        )
    }

    public paginate(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
        // TODO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<{ items: T[]; pagination: any }> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        return this.httpClient.get(`${this.url}/${this.endpoint}`, { headers, params }).pipe(
            // TODO
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((data: any) => ({
                items: this.convertData(data.data.items),
                pagination: this.paginationSerializer.fromJson(data.data.pagination),
            })),
        )
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete(id: number, customHeaders: AssociativeArray<string> = {}): Observable<any> {
        const headers = this.prepareHttpHeaders(customHeaders)
        return this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`, { headers })
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected convertData(data: any): T[] {
        return data.map(item => this.serializer.fromJson(item))
    }
}
