import { Injectable } from "@angular/core"
import { Inbox } from "@app/_models/inbox"
import { BehaviorSubject, Observable } from "rxjs"
import { Title } from "@angular/platform-browser"

export class Breadcrumbs {
    label: string
    url: string

    constructor(label: string, url: string = "") {
        this.label = label
        this.url = url
    }

    public static create(label: string, url: string = ""): Breadcrumbs {
        return new Breadcrumbs(label, url)
    }
}

@Injectable({
    providedIn: "root",
})
export class AppService {
    private titleSubject = new BehaviorSubject<string>("Partner portal")
    private title = this.titleSubject.asObservable()

    private breadcrumbsSubject = new BehaviorSubject<Breadcrumbs[]>(null)
    private breadcrumbs = this.breadcrumbsSubject.asObservable()

    private pageInfoSubject = new BehaviorSubject<string>(null)
    private pageInfo = this.pageInfoSubject.asObservable()

    private eventFiredSubject = new BehaviorSubject<string>(null)
    private eventFired = this.eventFiredSubject.asObservable()

    private inboxSubject = new BehaviorSubject<Inbox>(null)
    private inbox = this.inboxSubject.asObservable()

    constructor(private metaTitle: Title) {
        this.metaTitle.setTitle("Verofy Partner Cloud")
    }

    setTitle(title: string): void {
        this.titleSubject.next(title)
    }

    setMetaTitle(title: string): void {
        this.metaTitle.setTitle(`Verofy Partner Cloud - ${title}`)
    }

    getTitle(): Observable<string> {
        return this.title
    }

    // Inbox
    setInbox(inbox: Inbox): void {
        this.inboxSubject.next(inbox)
    }

    getInbox(): Observable<Inbox> {
        return this.inbox
    }

    setBreadcrumbs(items: Breadcrumbs[], showDashboard = true): void {
        if (showDashboard === true) {
            const dashboardBreadcrumbs = {
                label: "Dashboard",
                url: items.length === 0 ? "" : "/",
            }
            items.unshift(dashboardBreadcrumbs)
        }
        this.breadcrumbsSubject.next(items)
    }

    getBreadcrumbs(): Observable<Breadcrumbs[]> {
        return this.breadcrumbs
    }

    setPageInfo(pageInfo: string): void {
        this.pageInfoSubject.next(pageInfo)
    }

    getPageInfo(): Observable<string> {
        return this.pageInfo
    }

    setEventFired(event: string): void {
        this.eventFiredSubject.next(event)
    }

    getEventFired(): Observable<string> {
        return this.eventFired
    }
}
