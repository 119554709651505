import { Injectable } from "@angular/core"
import { HttpErrorResponse } from "@angular/common/http"

@Injectable({
    providedIn: "root",
})
export class ErrorService {
    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            return "No Internet Connection"
        }
        return error.message ? error.message : error.toString()
    }

    getClientStack(error: Error): string {
        return error.stack
    }

    getServerMessage(error: HttpErrorResponse): string {
        return error.message
        // return this.getApiError(error.error);
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    getServerStack(error: HttpErrorResponse): string {
        // handle stack trace
        return "stack"
    }

    /**
     * Alerts error based on API JSON response
     */
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getApiError(errorResponse: any): string {
        let message = ""

        if (errorResponse.message !== "") {
            message += errorResponse.message
        }

        if (errorResponse.errors !== undefined && errorResponse.errors.length !== 0) {
            const errors = errorResponse.errors
            if (Array.isArray(errors) === true) {
                for (const error of errors) {
                    if (Array.isArray(error) === true) {
                        for (const msg of error) {
                            message += "<br /> - " + msg
                        }
                    } else {
                        message += "<br /> - " + error
                    }
                }
            } else {
                for (const key of Object.keys(errors)) {
                    message += "<br /> - " + errors[key]
                }
            }
        }

        return message
    }
}
