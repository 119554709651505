import { Injectable } from "@angular/core"
import { LocalStorageRefService } from "./local-storage-ref.service"
import * as CryptoJS from "crypto-js"
import { environment } from "../../environments/environment"

@Injectable({ providedIn: "root" })
export class LocalStorageService {
    private key: string = environment.encryptKey
    private iv: string = environment.encryptIV
    private encryptionisActive: boolean = environment.encryptionisActive

    constructor(private localStorageRefService: LocalStorageRefService) {}

    protected destinations: { [key: string]: string } = {
        "deviceUniqueId": "L",
        "enums": "S",
        "authUser": "S",
        "customerUtilityQuoteHash": "S",
        "customerRegistrationHash": "S",
        "customerQuoteHash": "S",
        "customerRegistration": "S",
        "currentPartner": "S",
        "currentPartnerId": "S",
        "currentSwitchablePartners": "S",
        "crProductsAndServices": "S",
        "partnerPortalDatase": "S",
        "storageVersion": "S",
    }

    saveData(key: string, data: string): void {
        if (this.encryptionisActive) {
            try {
                const jsonData = this.encrypt(data)
                if (this.destinations[key] && this.destinations[key] === "L") {
                    this.localStorageRefService.localStorage.setItem(key, jsonData)
                } else {
                    this.localStorageRefService.sessionStorage.setItem(key, jsonData)
                }
            } catch (error) {
                console.log(error.message)
                this.removeAllData()
                window.location.reload()
                return null
            }
        } else {
            if (this.destinations[key] && this.destinations[key] === "L") {
                this.localStorageRefService.localStorage.setItem(key, data)
            } else {
                this.localStorageRefService.sessionStorage.setItem(key, data)
            }
        }
    }

    getData(key: string): string {
        if (this.encryptionisActive) {
            try {
                let value = ""
                if (this.destinations[key] && this.destinations[key] === "L") {
                    value = this.localStorageRefService.localStorage.getItem(key)
                } else {
                    value = this.localStorageRefService.sessionStorage.getItem(key)
                }
                if (value !== "" && value !== null && value !== undefined) {
                    return this.decrypt(value !== null && value !== undefined ? value : "")
                } else {
                    return value
                }
            } catch (error) {
                console.log(error.message)
                this.removeAllData()
                window.location.reload()
                return null
            }
        } else {
            if (this.destinations[key] && this.destinations[key] === "L") {
                return this.localStorageRefService.localStorage.getItem(key)
            } else {
                return this.localStorageRefService.sessionStorage.getItem(key)
            }
        }
    }

    removeData(key: string): void {
        if (this.destinations[key] && this.destinations[key] === "L") {
            this.localStorageRefService.localStorage.removeItem(key)
        } else {
            this.localStorageRefService.sessionStorage.removeItem(key)
        }
    }

    removeSpecificData(keys: string[]): void {
        keys.forEach(key => {
            if (this.destinations[key] && this.destinations[key] === "L") {
                this.localStorageRefService.localStorage.removeItem(key)
            } else {
                this.localStorageRefService.sessionStorage.removeItem(key)
            }
        })
    }

    removeAllDataWithException(excludedKeys: string[]): void {
        const keys = Object.keys(this.localStorageRefService.localStorage)
        for (const key of keys) {
            if (excludedKeys.indexOf(key) === -1) {
                this.localStorageRefService.localStorage.removeItem(key)
            }
        }

        const keysInSessionSotrage = Object.keys(this.localStorageRefService.sessionStorage)
        for (const key of keysInSessionSotrage) {
            if (excludedKeys.indexOf(key) === -1) {
                this.localStorageRefService.sessionStorage.removeItem(key)
            }
        }
    }

    removeAllData(): void {
        this.localStorageRefService.localStorage.clear()
        this.localStorageRefService.sessionStorage.clear()
    }

    isStorageValid(): boolean {
        const appVersion = this.getData("storageVersion")
        if (!appVersion || appVersion !== environment.storageVersion.toString()) {
            console.log("Storage version is out of date!")
            return false
        }
        return true
    }

    private encrypt(txt: string): string {
        const encrypted = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Base64.parse(this.key), {
            iv: CryptoJS.enc.Base64.parse(this.iv),
        })
        return encrypted.toString()
    }

    private decrypt(txtToDecrypt: string): string {
        const decrypted2 = CryptoJS.AES.decrypt(txtToDecrypt, CryptoJS.enc.Base64.parse(this.key), {
            iv: CryptoJS.enc.Base64.parse(this.iv),
        })
        return decrypted2.toString(CryptoJS.enc.Utf8)
    }
}
