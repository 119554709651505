import { Injectable, Injector } from "@angular/core"
import { ApiResourceService } from "./api-resource.service"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"

import { Partner, PartnerSerializer } from "../../_models"
import { PaginationSerializer } from "../../_models/pagination"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { UserPhoneChangeRequest, UserPhoneChangeRequestSerializer } from "@app/_models/user-phone-change-request"
import {
    UserPhoneChangeVerification,
    UserPhoneChangeVerificationSerializer,
} from "@app/_models/user-phone-change-verification"
import { SwitchablePartner, SwitchablePartnerSerializer } from "@app/_models/switchable-partner"
import { AssociativeArray } from "@app/_core/types"
import { PartnerStorage, PartnerStorageSerializer } from "@app/_models/partner-storage"

@Injectable({
    providedIn: "root",
})
export class PartnersApiService extends ApiResourceService<Partner> {
    constructor(
        httpClient: HttpClient,
        partnerSerializer: PartnerSerializer,
        paginationSerializer: PaginationSerializer,
        private injector: Injector,
    ) {
        super(
            httpClient,
            environment.mainApiUrl.slice(0, -1),
            environment.mainApi.endpoints.partners,
            partnerSerializer,
            paginationSerializer,
        )
    }

    /**
     * Updates specific partner's details
     */
    public updateProfileDetails(partner: Partner): Observable<Partner> {
        // Prepare the data
        const partnerData = this.serializer.toJson(partner)
        const inData = {
            name: partnerData.name,
            language_id: partnerData.language_id,
            currency_id: partnerData.currency_id,
            webhook_url: partnerData.webhook_url,

            company_name: partnerData.company_name,
            company_type: partnerData.company_type,
            registration_number: partnerData.registration_number,
            vat_number: partnerData.vat_number,

            contact_title: partnerData.contact_title,
            contact_first_name: partnerData.contact_first_name,
            contact_last_name: partnerData.contact_last_name,
            contact_dob: partnerData.contact_dob,
            contact_gender: partnerData.contact_gender,
            contact_email: partnerData.contact_email,
            contact_phone: partnerData.contact_phone,
            contact_website: partnerData.contact_website,

            account_number: partnerData.account_number,
            iban: partnerData.iban,
            account_name: partnerData.account_name,
            sort_code: partnerData.sort_code,
            bank_name: partnerData.bank_name,

            sign_up_email: partnerData.sign_up_email,
            reset_password_email: partnerData.reset_password_email,
        }
        return (
            this.httpClient
                .put<Partner>(`${this.url}/${this.endpoint}/${partner.id}/profile-details`, inData)
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as Partner))
        )
    }

    public getSwitchablePartners(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<SwitchablePartner[]> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const url = environment.mainApi.url + "storage/partners"
        const serializer = this.injector.get(SwitchablePartnerSerializer)
        return (
            this.httpClient
                // fetch-sellers route HAS permission rescrition
                .get<SwitchablePartner[]>(url, {
                    headers,
                    params,
                })
                // TODO Need to check
                /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                .pipe(map((data: any) => data.data.map(item => serializer.fromJson(item))))
        )
    }

    public getStoragePartner(
        partnerId,
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<PartnerStorage> {
        queryOptions["resource"] = "storage"
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const url = environment.mainApi.url + "storage/partners/" + partnerId
        const serializer = this.injector.get(PartnerStorageSerializer)
        return (
            this.httpClient
                // fetch-sellers route HAS permission rescrition
                .get<PartnerStorage>(url, {
                    headers,
                    params,
                })
                // TODO Need to check
                /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                .pipe(map((data: any) => serializer.fromJson(data.data) as PartnerStorage))
        )
    }

    /**
     * Sending phone number chnage request
     *
     * @param customerId - number
     * @param phoneNumber - string
     * @returns - any
     */
    public requestPhoneNumberChange(phoneNumber: string): Observable<UserPhoneChangeRequest> {
        // Prepare the data
        const inData = {
            "phone_number": phoneNumber,
        }
        console.log(environment.mainApi.endpoints.customers)
        const serializer = this.injector.get(UserPhoneChangeRequestSerializer)
        return (
            this.httpClient
                .put(`${this.url}/sellers/profile/phone-number`, inData)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => serializer.fromJson(data) as UserPhoneChangeRequest))
        )
    }

    /**
     *  After sending phone number change requst, the request is verofied with the code
     *
     * @param requestId - number
     * @param customerId - number
     * @param verificationCode - string
     * @returns - any
     */
    public verofyPhoneNumberRequest(
        requestId: number,
        verificationCode: string,
    ): Observable<UserPhoneChangeVerification> {
        // Prepare the data
        const inData = {
            "verification_code": verificationCode,
        }
        const serializer = this.injector.get(UserPhoneChangeVerificationSerializer)
        return (
            this.httpClient
                .put(`${this.url}/sellers/requests/${requestId}/verification`, inData)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => serializer.fromJson(data) as UserPhoneChangeVerification))
        )
    }

    /**
     * Updates default partner for currently logged seller
     *
     * @param partnerId -
     */
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setDefaultPartner(partnerId: number): Observable<any> {
        // Prepare the data
        const inData = {
            partner_id: partnerId,
        }
        return this.httpClient.put<Partner>(`${this.url}/${this.endpoint}/default`, inData)
    }
}
