import { PartnersApiService } from "./api/partners.api.service"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, take } from "rxjs"
import { LocalStorageService } from "./local-storage.service"
import { PartnerStorage, PartnerStorageSerializer } from "@app/_models/partner-storage"
import { AppError } from "@app/_core/app-error"
import { AlertService } from "./alert.service"

@Injectable({
    providedIn: "root",
})
export class CurrentPartnerService {
    public currentPartner: Observable<PartnerStorage>
    private currentPartnerSubject: BehaviorSubject<PartnerStorage>

    constructor(
        private partnersApiService: PartnersApiService,
        private partnerSerializer: PartnerStorageSerializer,
        private localStorageService: LocalStorageService,
        private alertService: AlertService,
    ) {
        this.currentPartnerSubject = new BehaviorSubject<PartnerStorage>(this.getCurrentPartner())
        this.currentPartner = this.currentPartnerSubject.asObservable()
    }

    getCurrentPartner(): PartnerStorage {
        const currentPartner = this.localStorageService.getData("currentPartner")
        if (currentPartner !== null) {
            return this.partnerSerializer.fromJson(JSON.parse(currentPartner))
        }
        return null
    }

    public get currentPartnerValue(): PartnerStorage {
        return this.currentPartnerSubject.value
    }

    public setPartner(partner: PartnerStorage): void {
        this.currentPartnerSubject.next(partner)
    }

    preparePartner(partnerId: number): Observable<PartnerStorage> {
        return this.partnersApiService.getStoragePartner(partnerId, {
            "include": "mids",
            "partner_location_id": partnerId.toString(),
        })
    }

    public loadPartner(partnerId: number): void {
        this.partnersApiService
            .getStoragePartner(partnerId, {
                "include": "mids",
                "partner_location_id": partnerId.toString(),
            })
            .pipe(take(1))
            .subscribe({
                next: (partner: PartnerStorage) => {
                    console.log("Current partner: ", partner)
                    const partnerJson = this.partnerSerializer.toJson(partner)
                    this.localStorageService.saveData("currentPartner", JSON.stringify(partnerJson))
                    this.currentPartnerSubject.next(partner)
                    return partner
                },
                error: (error: AppError) => {
                    this.alertService.userError(
                        error,
                        "We're sorry but the application having some technical difficulties. Please try to reload the page.",
                        false,
                        true,
                    )
                    throw error
                },
            })
    }
}
