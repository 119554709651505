import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-logout-black",
    template: `<svg
        style="cursor: pointer;"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 8.982 7.4">
        <g id="Group_1006" data-name="Group 1006" transform="translate(-1654.817 -128.497)">
            <g id="Group_1005" data-name="Group 1005">
                <g id="Group_1004" data-name="Group 1004">
                    <g id="Group_1003" data-name="Group 1003">
                        <path
                            id="Path_640"
                            data-name="Path 640"
                            d="M1661.183,132.952h-4.417a.42.42,0,0,1-.42-.42v-.913a.42.42,0,0,1,.42-.419h4.417Z"
                            fill="#575756" />
                        <path
                            id="Path_641"
                            data-name="Path 641"
                            d="M1660.6,132.076v-2.648l1.6,1.324,1.6,1.324-1.6,1.324-1.6,1.324Z"
                            fill="#575756" />
                    </g>
                    <path
                        id="Path_642"
                        data-name="Path 642"
                        d="M1659.187,135.9h-4.37v-7.4h4.37Zm-4.024-.346h3.678v-6.708h-3.678Z"
                        fill="#575756" />
                </g>
            </g>
        </g>
    </svg>`,
})
export class LogoutBlackComponent {}
