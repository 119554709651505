import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { JsonObject } from "../_core/types"

export class AuthUserVerification implements ApiResource {
    id: number = 0
    data: {
        verification: {
            status: string
            twoFactorAuthRequestId: number
        } | null
    }
    message: string
    status: string
    success: boolean
}

@Injectable({
    providedIn: "root",
})
export class AuthUserVerificationSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): AuthUserVerification {
        const record = new AuthUserVerification()
        record.id = json.id ?? 0
        record.data = {
            verification: json.data.verification
                ? {
                      status: json.data.verification.status,
                      twoFactorAuthRequestId: json.data.verification.two_factor_auth_request_id,
                  }
                : null,
        }
        record.message = json.message
        record.status = json.status
        record.success = json.success
        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: AuthUserVerification): any {
        return {
            id: record.id,
            data: {
                verification: {
                    status: record.data.verification.status,
                    two_factor_auth_request_id: record.data.verification.twoFactorAuthRequestId,
                },
            },
        }
    }
}
