import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { Store } from "@ngrx/store"
import { AppState } from "@app/store"
import { AppHttpRequestProgressType } from "@app/store/reducers/AppHttpReducer"
import { SetHideAppLoadingGlobal } from "@app/store/actions/AppLoadingActions"

const httpRequestInProgress = 5 // 15 mins
@Component({
    selector: "app-http-request-observe",
    templateUrl: "./http-request-observe.component.html",
})
export class HttpRequestObserveComponent implements OnInit {
    @Input({ required: true }) routerOutputRef: HTMLDivElement
    @ViewChild("requestProgressElement") requestProgressElement
    showRequestInProgress: boolean = false
    showElement: boolean = false
    hideElement: boolean = false
    isCurrentRouteRequestObserve: boolean = false
    httpRequestInProgress: boolean = false
    httpRequestTimeout: number
    timer: ReturnType<typeof setInterval>

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.httpRequestTimeout = httpRequestInProgress
        this.store
            .select(state => state.http.clearHttpRequestTimer)
            .subscribe(() => {
                this.clearAllTimer()
            })

        this.store
            .select(state => state.http.httpRequestObserve)
            .subscribe(isHttpObserve => {
                if (isHttpObserve) {
                    this.isCurrentRouteRequestObserve = true
                    this.countDown()
                } else {
                    this.isCurrentRouteRequestObserve = false
                    if (this.timer) {
                        this.clearAllTimer()
                    }
                }
            })

        this.store
            .select(state => state.http.httpRequestStatus)
            .subscribe(requestStatus => {
                if (requestStatus === AppHttpRequestProgressType.HttpRequestInProgress) {
                    this.httpRequestInProgress = true
                    this.countDown()
                }

                if (requestStatus === AppHttpRequestProgressType.HttpRequestInitialize) {
                    clearInterval(this.timer)
                    if (this.requestProgressElement) {
                        this.showElement = false
                        this.hideElement = true
                        this.httpRequestInProgress = false
                        setTimeout(() => {
                            this.showRequestInProgress = false
                            this.requestProgressElement.nativeElement.style.height = `0px`
                        }, 1500)
                    }
                }
            })
    }

    countDown(): void {
        this.clearAllTimer()
        if (!this.isCurrentRouteRequestObserve || !this.httpRequestInProgress) {
            return
        }

        let currentTime: number = 0
        this.timer = setInterval(() => {
            currentTime++
            if (this.httpRequestTimeout <= currentTime) {
                this.showRequestInProgress = true
                this.hideElement = false
                this.store.dispatch(SetHideAppLoadingGlobal())
                setTimeout(() => {
                    if (this.requestProgressElement) {
                        this.requestProgressElement.nativeElement.style.height = `${this.routerOutputRef.offsetHeight}px`
                        this.showElement = true
                    }
                }, 10)
                this.clearAllTimer()
            }
        }, 1000)
    }

    clearAllTimer(): void {
        for (let i = 1; i <= this.timer; i++) {
            clearInterval(i)
        }
    }
}
