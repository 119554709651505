import { Injectable } from "@angular/core"
import { SelectOption, SelectOptionSerializer } from "./select-option"
import { JsonObject } from "../_core/types"

export class ElectricityEnums {
    public static METER_TYPE_SINGLE_RATE = 1
    public static METER_TYPE_DAY_NIGHT = 2
    public static METER_TYPE_WEEKEND = 3
    public static METER_TYPE_OFF_PEAK = 4

    termOptions: SelectOption[]
    profileOptions: SelectOption[]
    meterTypeOptions: SelectOption[]
    consumptionUnitOptions: SelectOption[]

    public getOptionName(property: string, id: number): string {
        if (this[property] === undefined) {
            return ""
        }
        const foundItem = this[property].find(item => item.id === id)
        return foundItem ? foundItem.name : "N/A"
    }
}

@Injectable({
    providedIn: "root",
})
export class ElectricityEnumsSerializer {
    constructor(private selectOptionSerializer: SelectOptionSerializer) {}

    fromJson(json: JsonObject): ElectricityEnums {
        const enums = new ElectricityEnums()
        enums.termOptions =
            json.term !== undefined ? json.term.map(item => this.selectOptionSerializer.fromJson(item)) : []
        enums.profileOptions =
            json.profile !== undefined ? json.profile.map(item => this.selectOptionSerializer.fromJson(item)) : []
        enums.meterTypeOptions =
            json.meter_type !== undefined ? json.meter_type.map(item => this.selectOptionSerializer.fromJson(item)) : []
        enums.consumptionUnitOptions =
            json.consumption_unit !== undefined
                ? json.consumption_unit.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        return enums
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(enums: ElectricityEnums): any {
        return {}
    }
}
