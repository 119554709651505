import { Injectable } from "@angular/core"
import { EnumsApiService } from "./api/enums.api.service"
import { BehaviorSubject, Observable } from "rxjs"
import { Enums, EnumsSerializer } from "../_models/enums"
import { LocalStorageService } from "./local-storage.service"

@Injectable({
    providedIn: "root",
})
export class EnumsService {
    public enums: Observable<Enums>
    private enumsSubject: BehaviorSubject<Enums>
    constructor(
        private enumsApiService: EnumsApiService,
        private enumsSerializer: EnumsSerializer,
        private localStorageService: LocalStorageService,
    ) {
        this.enumsSubject = new BehaviorSubject<Enums>(
            this.enumsSerializer.fromJson(JSON.parse(this.localStorageService.getData("enums"))),
        )
        this.enums = this.enumsSubject.asObservable()
    }

    public get enumsValue(): Enums {
        return this.enumsSubject.value
    }

    public loadEnums(partnerId: number): void {
        this.enumsApiService.list({ "partner_id": partnerId.toString() }).subscribe((enums: Enums) => {
            const enumsJson = this.enumsSerializer.toJson(enums)
            this.localStorageService.saveData("enums", JSON.stringify(enumsJson))
            this.enumsSubject.next(enums)
            return enums
        })
    }

    public getOptionName(property: string, id: number | string): string {
        if (this.enumsValue[property] === undefined) {
            return ""
        }
        const foundItem = this.enumsValue[property].find(item => item.id === id)
        return foundItem ? foundItem.name : "N/A"
    }
}
