import { createAction, props } from "@ngrx/store"
import ActionTypes from "../ActionTypes"
import { DatePickerType, TransactionState } from "@app/store/reducers/TransactionReducer"

/**
 * Transaction date picker action
 */
export const SetTransactionDatePickerState = createAction(
    ActionTypes.setTransactionDatePicker,
    props<{ transactionData: TransactionState["optionsForTransactionDatePicker"] }>(),
)

export const SetTransactionDatePickerType = createAction(
    ActionTypes.setTransactionDatePickerType,
    props<{ pickerType: DatePickerType }>(),
)

export const SetTransactionDatePickerExactDate = createAction(
    ActionTypes.setTransactionDatePickerExactDate,
    props<{ date: string }>(),
)

export const SetTransactionDatePickerFromDate = createAction(
    ActionTypes.setTransactionDatePickerFromDate,
    props<{ date: string }>(),
)

export const SetTransactionDatePickerToDate = createAction(
    ActionTypes.setTransactionDatePickerToDate,
    props<{ date: string }>(),
)

export const IsTransactionDatePickerMaxFromDate = createAction(
    ActionTypes.setTransactionDatePickerMaxFromDate,
    props<{ date: string }>(),
)

export const IsTransactionDatePickerMinToDate = createAction(
    ActionTypes.setTransactionDatePickerMinToDate,
    props<{ date: string }>(),
)

/**
 * Settlement date picker action
 */
export const SetSettlementDatePickerState = createAction(
    ActionTypes.setSettlementsDatePicker,
    props<{ settlementData: TransactionState["optionsForSettlementDatePicker"] }>(),
)

export const SetSettlementDatePickerType = createAction(
    ActionTypes.setSettlementsDatePickerType,
    props<{ pickerType: DatePickerType }>(),
)

export const SetSettlementDatePickerExactDate = createAction(
    ActionTypes.setSettlementsDatePickerExactDate,
    props<{ date: string }>(),
)

export const SetSettlementDatePickerFromDate = createAction(
    ActionTypes.setSettlementsDatePickerFromDate,
    props<{ date: string }>(),
)

export const SetSettlementsDatePickerToDate = createAction(
    ActionTypes.setSettlementsDatePickerToDate,
    props<{ date: string }>(),
)

/**
 * Chargeback date picker action
 */
export const SetChargebackDatePickerState = createAction(
    ActionTypes.setChargebackDatePicker,
    props<{ chargebackData: TransactionState["optionsForChargebackDatePicker"] }>(),
)

export const SetChargebackDatePickerType = createAction(
    ActionTypes.setChargebackDatePickerType,
    props<{ pickerType: DatePickerType }>(),
)

export const SetChargebackDatePickerExactDate = createAction(
    ActionTypes.setChargebackDatePickerExactDate,
    props<{ date: string }>(),
)

export const SetChargebackDatePickerFromDate = createAction(
    ActionTypes.setChargebackDatePickerFromDate,
    props<{ date: string }>(),
)

export const SetChargebackDatePickerToDate = createAction(
    ActionTypes.setChargebackDatePickerToDate,
    props<{ date: string }>(),
)
