import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, Subject } from "rxjs"
import { AlertService } from "./alert.service"
import { Router } from "@angular/router"
import { AppError } from "../_core/app-error"
import { CustomerUtilityQuote } from "../_models/customer-utility-quote"
import { UtilitiesApiService } from "./api/utilities.api.service"
import { LocalStorageService } from "./local-storage.service"

@Injectable({
    providedIn: "root",
})
export class CustomerUtilitiesService {
    public static readonly AUTH_HEADER_NAME = "Customer-Utility-Quote-Hash"

    private quoteValidated = new Subject<CustomerUtilityQuote>()
    private quoteHashSubject: BehaviorSubject<string>

    constructor(
        private utilitiesApiService: UtilitiesApiService,
        private alertService: AlertService,
        private router: Router,
        private localStorageService: LocalStorageService,
    ) {
        this.quoteHashSubject = new BehaviorSubject<string>(
            this.localStorageService.getData("customerUtilityQuoteHash"),
        )
    }

    getQuoteValidated(): Observable<CustomerUtilityQuote> {
        return this.quoteValidated.asObservable()
    }

    setQuoteValidated(quote: CustomerUtilityQuote): void {
        this.quoteValidated.next(quote)
    }

    /**
     * Returns local storage auth access token
     */
    public getQuoteHash(): string {
        const hash = this.quoteHashSubject.value
        return hash ? hash : null
    }

    setQuoteHash(registrationHash: string): void {
        this.localStorageService.saveData("customerUtilityQuoteHash", registrationHash)
        this.quoteHashSubject.next(registrationHash)
    }

    validateQuote(hash: string): void {
        this.setQuoteHash(hash)

        this.utilitiesApiService.getCustomerQuoteByHash(hash).subscribe({
            next: (quote: CustomerUtilityQuote) => {
                this.setQuoteValidated(quote)
            },
            error: (error: AppError) => {
                this.clearQuoteHash()
                this.alertService.userError(error, "Invalid customer utility quote hash.")
                this.router.navigate(["/"])
                throw error
            },
        })
    }

    clearQuoteHash(): void {
        this.localStorageService.removeData("customerUtilityQuoteHash")
        this.quoteHashSubject.next(null)
    }

    prepareElectricityNavigationLink(url: string): string {
        return "utility-electricity/" + this.getQuoteHash() + "/" + url
    }

    prepareGasNavigationLink(url: string): string {
        return "utility-gas/" + this.getQuoteHash() + "/" + url
    }
}
