import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class CRSelfEmployedContact {
    id: number = 0
    title: string | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    email: string | null
    share: number | null
}

@Injectable({
    providedIn: "root",
})
export class CRSelfEmployedContactSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): CRSelfEmployedContact {
        const record = new CRSelfEmployedContact()
        record.id = json.id
        record.title = json.title
        record.firstName = json.first_name
        record.lastName = json.last_name
        record.phoneNumber = json.phone_number
        record.email = json.email
        record.share = json.share
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRSelfEmployedContact): any {
        return {
            id: record.id,
            title: record.title,
            first_name: record.firstName,
            last_name: record.lastName,
            phone_number: record.phoneNumber,
            email: record.email,
            share: record.share,
        }
    }
}
