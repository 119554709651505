import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { AlertService } from "@app/_services/alert.service"
import { SetDashboardTotalAmountMonthlyWidgetAction } from "../../actions/DashboardActions"
import { WidgetTransactionsTotalAmountMonthlyApiService } from "app/_services/api/widget-transactions-total-amount-monthly-api.service"
import { WidgetTransactionsTotalAmountMonthly } from "app/_models/widget-transactions-total-amount-monthly"
import { EChartsOption } from "echarts"
import { DashboardWidgetColor } from "@app/interfaces/dashboard/dashboardWidgetColor"
// import { getMonthFromNumber, getShortenYear } from "@appUtils/helpers"
import { YAXisOption } from "echarts/types/dist/shared"
import HelperVerofy from "@appHelpers/helper-verofy"
import { DatePipe } from "@angular/common"

export const FetchTotalAmountMonthly = createAction("DashboardTotalAmountMonthlyAction", props<{ partnerId: number }>())

@Injectable()
export class DashboardTotalAmountMonthlyEffects {
    hasData: boolean = false

    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchTotalAmountMonthly),
                exhaustMap(action =>
                    this.widgetTransactionsTotalAmountMonthlyApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                {
                                    const totalAmountMonthly: WidgetTransactionsTotalAmountMonthly[] = payload.slice(
                                        6,
                                        12,
                                    )
                                    const datePipe: DatePipe = new DatePipe("en-US")
                                    const bar = []
                                    const data = []
                                    const dataSecondary = []

                                    totalAmountMonthly.forEach(record => {
                                        const monthLabel = datePipe.transform(
                                            record.year + "-" + record.month + "-1",
                                            "M.YY",
                                        )

                                        bar.push(monthLabel)
                                        // bar.push(getMonthFromNumber(record.month) + " " + getShortenYear(record.year))
                                        data.push(record.totalAmount)
                                        dataSecondary.push(record.forecast ? record.forecast : 0)
                                    })

                                    const hasNoData = data.every(item => item === 0 || item == "0.00" || item == null)
                                    const hasNoSecondaryData = dataSecondary.every(item => item === 0)

                                    if (hasNoData && hasNoSecondaryData) this.hasData = false
                                    else this.hasData = true

                                    let yAxisOption: YAXisOption | YAXisOption[] = {}
                                    let isDefault = true
                                    if (data.length > 0) {
                                        for (const currentData of data) {
                                            if (parseInt(currentData, 10) > 0) {
                                                isDefault = false
                                                break
                                            }
                                        }
                                    }

                                    if (isDefault) {
                                        yAxisOption = {
                                            type: "category",
                                            boundaryGap: false,
                                            splitLine: {
                                                show: false,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === "0")
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                            data: [0, 100],
                                        }
                                    } else {
                                        yAxisOption = {
                                            type: "value",
                                            splitLine: {
                                                show: false,
                                            },
                                            axisLabel: {
                                                fontSize: 11,
                                                formatter(value) {
                                                    if (value === 0)
                                                        return HelperVerofy.formatCurrency(Number(value), "GBP", 2)
                                                    else return HelperVerofy.formatCurrency(Number(value), "GBP", 0)
                                                },
                                            },
                                        }
                                    }

                                    const eChartOptions: EChartsOption = {
                                        legend: {
                                            selectorPosition: "start",
                                            data: ["Refund"],
                                            align: "left",
                                            orient: "horizontal",
                                            top: "10px",
                                            left: "0",
                                        },
                                        title: {
                                            text: this.hasData
                                                ? ""
                                                : "There is currently no transactional data to display.",
                                            subtext: "",
                                            left: "center",
                                            top: "42%",
                                            textStyle: {
                                                fontSize: 12,
                                                opacity: 0.5,
                                                fontWeight: 400,
                                            },
                                        },
                                        color: [DashboardWidgetColor.Real, DashboardWidgetColor.Refund],
                                        tooltip: {
                                            trigger: "axis",
                                            axisPointer: {
                                                axis: "auto",
                                                type: "none",
                                            },
                                            textStyle: {
                                                fontSize: 12,
                                            },
                                            formatter: function (params) {
                                                const value = params[0].value
                                                const title = params[0].axisValue
                                                const originalMarker = params[0].marker
                                                const color = params[0].color

                                                const formattedValue =
                                                    value === undefined
                                                        ? HelperVerofy.formatCurrency(0, "GBP", 2)
                                                        : HelperVerofy.formatCurrency(Number(value), "GBP", 2)

                                                // Create the tooltip content with the title and formatted value
                                                return (
                                                    `<strong> ${title}  </strong>` +
                                                    "<br/>" +
                                                    originalMarker +
                                                    "Volume: " +
                                                    '<span style="color:' +
                                                    color +
                                                    '"><strong>' +
                                                    formattedValue +
                                                    "</strong></span>"
                                                )
                                            },
                                        },
                                        toolbox: {
                                            top: 0,
                                            right: 0,
                                            feature: {
                                                saveAsImage: {
                                                    emphasis: {
                                                        iconStyle: {
                                                            textFill: "#307AFF",
                                                            borderColor: "#307AFF",
                                                        },
                                                    },
                                                    title: "Download report",
                                                    name: "Verofy Monthly Transactional Volume",
                                                },
                                            },
                                        },
                                        grid: {
                                            containLabel: true,
                                            left: 0,
                                        },
                                        xAxis: {
                                            data: bar,
                                            silent: false,
                                            splitLine: {
                                                show: true,
                                            },
                                            axisLabel: {
                                                align: "right",
                                                fontSize: 11,
                                            },
                                            nameTextStyle: {
                                                align: "right",
                                                verticalAlign: "middle",
                                            },
                                            alignTicks: true,
                                        },
                                        yAxis: {
                                            ...yAxisOption,
                                        },
                                        series: [
                                            {
                                                name: "£",
                                                type: "bar",
                                                data,
                                                animationDelay: +(idx => idx * 10),
                                            },
                                        ],
                                        animationEasing: "elasticOut",
                                        animationDelayUpdate: +(idx => idx * 5),
                                    }

                                    return SetDashboardTotalAmountMonthlyWidgetAction({
                                        data: eChartOptions,
                                    })
                                }
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private widgetTransactionsTotalAmountMonthlyApiService: WidgetTransactionsTotalAmountMonthlyApiService,
    ) {}
}
