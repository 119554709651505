import { PaginationSerializer } from "./../../_models/pagination"
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiResource } from "../../_models/api-resource"
import { ApiResourceSerializer } from "../../_core/api-resource.serializer"
import { AssociativeArray, JsonObject } from "../../_core/types"
import { ApiBaseService } from "./api-base.service"

export class ApiSubResourceService<T extends ApiResource> extends ApiBaseService {
    constructor(
        protected httpClient: HttpClient,
        protected url: string,
        protected parentEndpoint: string,
        protected endpoint: string,
        protected serializer: ApiResourceSerializer,
        protected paginationSerializer?: PaginationSerializer,
    ) {
        super()
    }

    public create(parentId: number, item: T): Observable<T> {
        return (
            this.httpClient
                .post<T>(
                    `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}`,
                    this.serializer.toJson(item),
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public update(parentId: number, item: T): Observable<T> {
        return (
            this.httpClient
                .put<T>(
                    this.url +
                        "/" +
                        this.parentEndpoint +
                        "/" +
                        parentId +
                        "/" +
                        this.endpoint +
                        (item.id !== null ? "/" + item.id : ""),
                    this.serializer.toJson(item),
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public get(
        id: number,
        secondId: number,
        queryOptions: AssociativeArray<string> | null = {},
        customHeaders: AssociativeArray<string> | null = {},
    ): Observable<T> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const url =
            this.url +
            "/" +
            this.parentEndpoint +
            (id !== null ? "/" + id : "") +
            (this.endpoint !== null && this.endpoint !== "" ? "/" + this.endpoint : "") +
            (secondId !== null ? "/" + secondId : "")
        return this.httpClient
            .get(url, { headers, params })
            .pipe(map((data: JsonObject) => this.serializer.fromJson(data.data) as T))
    }

    public list(
        parentId: number,
        queryOptions: AssociativeArray<string> | null = null,
        customHeaders: AssociativeArray<string> | null = {},
    ): Observable<T[]> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        return (
            this.httpClient
                .get(
                    this.url +
                        "/" +
                        this.parentEndpoint +
                        (parentId !== null ? "/" + parentId : "") +
                        (this.endpoint !== null && this.endpoint !== "" ? "/" + this.endpoint : ""),
                    {
                        headers,
                        params,
                    },
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.convertData(data.data)))
        )
    }

    public paginate(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
        parentId: number,
        id: number,
        // TODO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<{ items: T[]; pagination: any }> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)

        return this.httpClient
            .get(
                this.url +
                    "/" +
                    this.parentEndpoint +
                    (parentId !== null ? "/" + parentId : "") +
                    "/" +
                    this.endpoint +
                    (id !== null ? "/" + id : ""),
                { headers, params },
            )
            .pipe(
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((data: any) => ({
                    items: this.convertData(data.data.items),
                    pagination: this.paginationSerializer.fromJson(data.data.pagination),
                })),
            )
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public delete(parentId: number, id: number): Observable<any> {
        return this.httpClient.delete(`${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}`)
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected convertData(data: any): T[] {
        return data.map(item => this.serializer.fromJson(item))
    }
    public List(parentId: number, id: number, queryOptions: AssociativeArray<string> | null = {}): Observable<T> {
        const params = this.prepareHttpParams(queryOptions)
        return (
            this.httpClient
                .get(
                    this.url +
                        "/" +
                        this.parentEndpoint +
                        (parentId !== null ? "/" + parentId : "") +
                        "/" +
                        this.endpoint +
                        (id !== null ? "/" + id : ""),
                    { params },
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public getForPartnerTransaction(
        parentId: number,
        id: number,
        queryOptions: AssociativeArray<string> | null = {},
        customHeaders: AssociativeArray<string> | null = {},
    ): Observable<T> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        return (
            this.httpClient
                .get(
                    this.url +
                        "/" +
                        "partners" +
                        (parentId !== null ? "/" + parentId : "") +
                        "/" +
                        this.endpoint +
                        (id !== null ? "/" + id : ""),
                    { headers, params },
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.serializer.fromJson(data.data) as T))
        )
    }

    public Dashboardlist(parentId: number): Observable<T[]> {
        return (
            this.httpClient
                .get(
                    this.url +
                        "/" +
                        this.parentEndpoint +
                        (this.endpoint !== null && this.endpoint !== "" ? "/" + this.endpoint : "") +
                        "?partner_id=" +
                        (parentId !== null ? parentId : ""),
                )
                // TODO
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => this.convertData(data.data)))
        )
    }
}
