import { createAction, props } from "@ngrx/store"
import ActionTypes from "../ActionTypes"
import { DashboardData } from "app/interfaces/dashboard/dashboardInterface"
import { Customer } from "app/_models"
import { EChartsOption } from "echarts"
import { AuthUserStorage } from "@app/_models/auth-user-storage"
import { Partner } from "@app/_models/partner"

export const SetDashboardDataAction = createAction(
    ActionTypes.setDashboardData,
    props<{ dashboard: DashboardData | null }>(),
)

export const SetDashboardCustomerId = createAction(ActionTypes.setDashboardCustomerId, props<{ id: number }>())

export const SetDashboardPartnerId = createAction(ActionTypes.setDashboardPartnerId, props<{ id: number }>())

export const SetDashboardEditingMode = createAction(ActionTypes.setDashboardEditingMode, props<{ mode: boolean }>())

export const SetDashboardWidgetUpdateStatus = createAction(
    ActionTypes.setDashboardWidgetUpdateStatus,
    props<{ widgetType: number; status: boolean }>(),
)

export const SetDashboardCurrentCustomer = createAction(
    ActionTypes.setDashboardCurrentCustomer,
    props<{ currentCustomer: Customer }>(),
)

export const SetDashboardCurrentUser = createAction(
    ActionTypes.setDashboardCurrentUser,
    props<{ currentUser: AuthUserStorage }>(),
)

export const SetDashboardCurrentPartner = createAction(
    ActionTypes.setDashboardCurrentCustomer,
    props<{ currentPartner: Partner }>(),
)

export const SetDashboardRestartAction = createAction(ActionTypes.setRestartDashboard)

export const SetDashboardCardCountMonthlyWidgetAction = createAction(
    ActionTypes.setDashboardCardCountMonthlyWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardCardCountDailyWidgetAction = createAction(
    ActionTypes.setDashboardCardCountDailyWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardTotalAmountDailyWidgetAction = createAction(
    ActionTypes.setDashboardTotalAmountDailyWidget,
    props<{ data: EChartsOption }>(),
)
export const SetDashboardTotalAmountMonthlyWidgetAction = createAction(
    ActionTypes.setDashboardTotalAmountMonthlyWidget,
    props<{ data: EChartsOption }>(),
)
export const SetDashboardTotalAtvDailyWidgetAction = createAction(
    ActionTypes.setDashboardTotalAtvDailyWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardTotalAtvMonthlyWidgetAction = createAction(
    ActionTypes.setDashboardTotalAtvMonthlyWidget,
    props<{ data: EChartsOption }>(),
)
export const SetDashboardTerminalCountDailyWidgetAction = createAction(
    ActionTypes.setDashboardTerminalCountDailyWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardTotalCountMonthlyWidgetAction = createAction(
    ActionTypes.setDashboardTotalCountMonthlyWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardCardTypeWidgetAction = createAction(
    ActionTypes.setDashboardCardTypeWidget,
    props<{ data: EChartsOption }>(),
)

export const SetDashboardBusinessOverviewWidgetAction = createAction(
    ActionTypes.setBusinessOverviewDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetTerminalsCountLiveMonthlyAction = createAction(
    ActionTypes.setTerminalsCountLiveMonthlyDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetTerminalsCountLiveDailyAction = createAction(
    ActionTypes.setTerminalsCountLiveDailyDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetTerminalsCountOrderedMonthlyAction = createAction(
    ActionTypes.setTerminalsCountOrderedMonthlyDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetTerminalsCountOrderedDailyAction = createAction(
    ActionTypes.setTerminalsCountOrderedDailyDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetDashboardCommissionsWidgetAction = createAction(
    ActionTypes.setCommissionsDashboard,
    props<{ data: EChartsOption }>(),
)

export const SetQuoteAndApplicationWidgetAction = createAction(
    ActionTypes.setQuoteAndApplicationDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetAllBusinessOverviewWidgetAction = createAction(
    ActionTypes.setAllBusinessOverviewDashboard,
    props<{ data: EChartsOption }>(),
)
export const SetFinanceCommissionWidgetAction = createAction(
    ActionTypes.setFinanceCommissionDashboard,
    props<{ data: EChartsOption }>(),
)

export const SetResidualCommissionWidgetAction = createAction(
    ActionTypes.setResidualCommissionDashboard,
    props<{ data: EChartsOption }>(),
)
