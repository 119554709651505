export interface DashboardDraggable {
    enabled?: boolean
}

export interface DashboardResizable {
    enabled?: boolean
}

export interface DashboardPushDirections {
    east?: boolean
    west?: boolean
    north?: boolean
    south?: boolean
}

export interface DashboardOptions {
    api?: [] | null | object
    swap?: boolean
    margin?: number | null
    maxCols?: number | null
    maxRows?: number | null
    minCols?: number | null
    minRows?: number | null
    gridType?: string | null
    draggable?: DashboardDraggable
    pushItems?: boolean
    resizable?: DashboardResizable
    compactType?: string
    displayGrid?: string
    maxItemArea?: number | null
    maxItemCols?: number | null
    maxItemRows?: number | null
    minItemArea?: number | null
    minItemCols?: number | null
    minItemRows?: number | null
    outerMargin?: boolean
    scrollSpeed?: number | null
    setGridSize?: boolean
    fixedColWidth?: number | null
    fixedRowHeight?: number | null
    outerMarginTop?: number | null
    pushDirections?: DashboardPushDirections
    defaultItemCols?: number | null
    defaultItemRows?: number | null
    disableWarnings?: boolean
    outerMarginLeft?: number | null
    pushResizeItems?: boolean
    mobileBreakpoint?: number | null
    outerMarginRight?: number | null
    scrollToNewItems?: boolean
    disablePushOnDrag?: boolean
    ignoreMarginInRow?: boolean
    outerMarginBottom?: number | null
    scrollSensitivity?: number | null
    disablePushOnResize?: boolean
    disableWindowResize?: boolean
    enableEmptyCellDrag?: boolean
    enableEmptyCellDrop?: boolean
    emptyCellDragMaxCols?: number | null
    emptyCellDragMaxRows?: number | null
    enableEmptyCellClick?: boolean
    enableOccupiedCellDrop?: boolean
    keepFixedWidthInMobile?: boolean
    keepFixedHeightInMobile?: boolean
    useTransformPositioning?: boolean
    enableEmptyCellContextMenu?: boolean
}

export interface DashboardSetting {
    x: number | null
    y: number | null
    id?: number | null
    cols: number | null
    rows: number | null
    title?: string
}

export interface DashboardData {
    id: number | null
    name: string
    description: string
    dashboardOptions: DashboardOptions
    dashboardSettings: DashboardSetting[]
}

export interface DashboardRootObject {
    success: boolean
    status: string
    message: string
    data: DashboardData
}

export enum DashboardWidgetName {
    totalCountDaily = "TOTAL_COUNT",
    totalCountMonthly = "TOTAL_COUNT_MONTHLY",
    totalAmountDaily = "TOTAL_AMOUNT_DAILY",
    totalAmountMonthly = "TOTAL_AMOUNT_MONTHLY",
    commissions = "COMMISSIONS",
    quoteAndApplications = "QUOTES_AND_APPLICATION_WIDGET",
    allBusinessOverview = "ALL_BUSINESS_OVERVIEW",
}
