import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "@appCore/types"

export class MccCode extends ApiResource {
    id = 0
    description: string
    code: string
    isProhibited: boolean
    eposnowCode: boolean
    riskLevelId: string
    sector: string
    additionalInfo: string
}

@Injectable({
    providedIn: "root",
})
export class MccCodeSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): MccCode {
        const code = new MccCode()
        code.id = json.id
        code.description = json.description
        code.code = json.code
        code.isProhibited = json.is_prohibited
        code.eposnowCode = json.eposnow_code
        code.riskLevelId = json.risk_level_id
        code.sector = json.sector
        code.additionalInfo = json.additional_info
        return code
    }

    toJson(code: MccCode): JsonObject {
        return {
            id: code.id,
            description: code.description,
            code: code.code,
            is_prohibited: code.isProhibited,
            eposnow_code: code.eposnowCode,
            risk_level_id: code.riskLevelId,
            sector: code.sector,
            additional_info: code.additionalInfo,
        }
    }
}
