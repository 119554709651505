import { ErrorHandler, Injectable, Injector } from "@angular/core"
import { LoggingService } from "../_services/logging.service"
import { ErrorService } from "../_services/error.service"
import { AppError } from "./app-error"
import { EmptyError } from "rxjs"

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector) {}

    handleError(error: Error): void {
        // use the Injector instance to directly to inject the dependency and not depend on the Dependency injection framework
        const errorService = this.injector.get(ErrorService)
        const logger = this.injector.get(LoggingService)

        let message
        let stackTrace

        if (error instanceof AppError) {
            // Server Error
            message = errorService.getServerMessage(error.error)
            stackTrace = errorService.getServerStack(error.error)
        } else {
            // Client Error
            message = errorService.getClientMessage(error)
            stackTrace = errorService.getClientStack(error)
        }

        // Always log errors
        logger.logError(message, stackTrace)

        if (error instanceof EmptyError) {
            return
        }

        console.error(error)
    }
}
