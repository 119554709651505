import { createAction, props } from "@ngrx/store"
import ActionTypes from "../ActionTypes"
import { CustomerStandardFee } from "@appModels/customer-standard-fee"
import { SelectedFastPaymentValue, SelectedPCIPlusValue } from "@appStore/reducers/AppCustomerRegistrationReducer"

/**
 * App loading action
 */

export const SetCRUpdatePrice = createAction(ActionTypes.setCustomerRegistrationUpdatePrice, props)

export const SetCRCardStandardFees = createAction(
    ActionTypes.setCustomerRegistrationStandardFees,
    props<{ value: CustomerStandardFee[] }>(),
)

export const SetCRCardStandardFeesLoading = createAction(
    ActionTypes.setCustomerRegistrationStandardFeesLoading,
    props<{ status: boolean }>(),
)

export const SetCRSelectedPCIPlusValue = createAction(
    ActionTypes.setCustomerRegistrationPCIPlusValue,
    props<{ value: SelectedPCIPlusValue }>(),
)

export const SetCRSelectedFasterPaymentValue = createAction(
    ActionTypes.setCustomerRegistrationFasterPaymentValue,
    props<{ value: SelectedFastPaymentValue }>(),
)

export const SetCRIsProductSelect = createAction(
    ActionTypes.setCustomerRegistrationProductSelect,
    props<{ status: boolean }>(),
)

export const SetCRIsTerminalSelect = createAction(
    ActionTypes.setCustomerRegistrationTerminalSelect,
    props<{ status: boolean }>(),
)

export const SetCRLoadFeeSection = createAction(
    ActionTypes.setCustomerRegistrationLoadFeeSection,
    props<{ status: boolean }>(),
)

export const SetCRFormSubmit = createAction(ActionTypes.setCustomerRegistrationFormSubmit, props<{ status: boolean }>())

export const SetCRInvalidFastPayment = createAction(
    ActionTypes.setCustomerRegistrationInvalidFastPayment,
    props<{ status: boolean }>(),
)
export const SetCRResetCustomerRegistration = createAction(ActionTypes.setCustomerRegistrationReset, props)

export const SetCRCustomerRegistrationCompleted = createAction(
    ActionTypes.setCustomerRegistrationCompleted,
    props<{ status: boolean }>(),
)

export const SetCRCustomerRegistrationSetupInProgress = createAction(
    ActionTypes.setCustomerRegistrationFormSetupInProgress,
    props<{ status: boolean }>(),
)
