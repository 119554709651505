import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-pound-symbol",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="13.363" height="13.363" viewBox="0 0 13.363 13.363">
        <g id="Group_844" data-name="Group 844" transform="translate(-4346.863 -3399.851)">
            <circle
                id="Ellipse_73"
                data-name="Ellipse 73"
                cx="6.681"
                cy="6.681"
                r="6.681"
                transform="translate(4346.863 3399.851)"
                fill="#d8d8d8" />
            <g id="Group_843" data-name="Group 843">
                <path
                    id="Path_505"
                    data-name="Path 505"
                    d="M4352.9,3406.818l.03.756a1.534,1.534,0,0,1-.274.944h2.987l-.005.867h-4.5v-.867h.352a.4.4,0,0,0,.26-.277,1.785,1.785,0,0,0,.092-.629l-.03-.794h-.691v-.854h.665l-.034-1.038a1.785,1.785,0,0,1,.536-1.369,1.978,1.978,0,0,1,1.408-.5,1.919,1.919,0,0,1,1.369.468,1.643,1.643,0,0,1,.5,1.258H4354.5a.785.785,0,0,0-.855-.859.726.726,0,0,0-.6.279,1.131,1.131,0,0,0-.222.726l.039,1.038h1.335v.854Z"
                    fill="#fff" />
            </g>
        </g>
    </svg>`,
})
export class PoundSymbolComponent {}
