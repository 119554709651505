import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "ordinal",
})
export class OrdinalPipe implements PipeTransform {
    transform(value: string): string {
        const splitedDate = value.split(" ")
        const date = +splitedDate[0]
        let restOfTheDate = ""

        for (let d = 1; d < splitedDate.length; d++) restOfTheDate += splitedDate[d] + " "

        if (date % 100 >= 11 && date % 100 <= 13) return " " + date + "th" + " " + restOfTheDate

        switch (date % 10) {
            case 1:
                return date + "st" + " " + restOfTheDate
            case 2:
                return date + "nd" + " " + restOfTheDate
            case 3:
                return date + "rd" + " " + restOfTheDate
            default:
                return date + "th" + " " + restOfTheDate
        }
    }
}
