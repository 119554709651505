import { createReducer, on } from "@ngrx/store"
import {
    SetDashboardCurrentCustomer,
    SetDashboardCurrentUser,
    SetDashboardCurrentPartner,
    SetDashboardCustomerId,
    SetDashboardDataAction,
    SetDashboardEditingMode,
    SetDashboardRestartAction,
    SetDashboardTotalAmountDailyWidgetAction,
    SetDashboardTotalAmountMonthlyWidgetAction,
    SetDashboardTerminalCountDailyWidgetAction,
    SetDashboardTotalCountMonthlyWidgetAction,
    SetDashboardWidgetUpdateStatus,
    SetDashboardCommissionsWidgetAction,
    SetQuoteAndApplicationWidgetAction,
    SetAllBusinessOverviewWidgetAction,
    SetDashboardPartnerId,
    SetFinanceCommissionWidgetAction,
    SetResidualCommissionWidgetAction,
} from "app/store/actions/DashboardActions"
import { DashboardOptions, DashboardSetting, DashboardWidgetName } from "app/interfaces/dashboard/dashboardInterface"
import { Customer } from "app/_models"
import { EChartsOption } from "echarts"
import { AuthUserStorage } from "@app/_models/auth-user-storage"
import { Partner } from "../../_models/partner"

export interface DashboardState {
    dashboardOptions: DashboardOptions | null
    dashboardSettings: DashboardSetting[] | null
    id: number | null
    name: string | null
    description: string | null
    partnerId: number | null
    currentCustomer: Customer | null
    currentUser: AuthUserStorage | null
    editingMode: boolean
    currentPartner: Partner | null

    // Total ATV monthly
    widgets: {
        totalAmountDaily: EChartsOption | null
        totalAmountDailyPrefetch: boolean
        totalAmountMonthly: EChartsOption | null
        totalAmountMonthlyPrefetch: boolean
        terminalCountDaily: EChartsOption | null
        terminalCountDailyPrefetch: boolean
        totalCountMonthly: EChartsOption | null
        totalCountMonthlyPrefetch: boolean
        commissions: EChartsOption | null
        commissionsPrefetch: boolean
        quoteAndApplication: EChartsOption | null
        quoteAndApplicationPrefetch: boolean
        allBusinessOverview: EChartsOption | null
        allBusinessOverviewPrefetch: boolean
        financeCommission: EChartsOption | null
        financeCommissionPrefetch: boolean
        residualCommission: EChartsOption | null
        residualCommissionPrefetch: boolean
    } | null

    updateWidgetStatus: {
        type: DashboardWidgetName | null
        status: boolean
    } | null
}

const initialState: DashboardState = {
    editingMode: false,
    dashboardOptions: null,
    dashboardSettings: null,
    id: null,
    name: null,
    description: null,
    partnerId: null,
    currentCustomer: null,
    currentUser: null,
    currentPartner: null,
    widgets: {
        totalAmountDaily: null,
        totalAmountDailyPrefetch: false,
        terminalCountDaily: null,
        terminalCountDailyPrefetch: false,
        totalAmountMonthly: null,
        totalAmountMonthlyPrefetch: false,
        totalCountMonthly: null,
        totalCountMonthlyPrefetch: false,
        commissions: null,
        commissionsPrefetch: false,
        quoteAndApplication: null,
        quoteAndApplicationPrefetch: false,
        allBusinessOverview: null,
        allBusinessOverviewPrefetch: false,
        financeCommission: null,
        financeCommissionPrefetch: false,
        residualCommission: null,
        residualCommissionPrefetch: false,
    },
    updateWidgetStatus: null,
}

export const DashboardReducer = createReducer(
    initialState,
    on(SetDashboardEditingMode, (state, { mode }) => {
        return {
            ...state,
            editingMode: mode,
        }
    }),
    on(SetDashboardDataAction, (state, { dashboard }) => {
        if (typeof dashboard === "object") {
            return {
                ...state,
                dashboardOptions: dashboard.dashboardOptions,
                dashboardSettings: dashboard.dashboardSettings,
                id: dashboard.id,
                name: dashboard.name,
                description: dashboard.description,
            }
        }

        return {
            ...state,
        }
    }),

    on(SetDashboardCurrentCustomer, (state, { currentCustomer }) => {
        return {
            ...state,
            currentCustomer,
        }
    }),

    on(SetDashboardCurrentUser, (state, { currentUser }) => {
        return {
            ...state,
            currentUser,
        }
    }),

    on(SetDashboardCurrentPartner, (state, { currentPartner }) => {
        return {
            ...state,
            currentPartner,
        }
    }),

    on(SetDashboardCustomerId, (state, { id }) => {
        return {
            ...state,
            customerId: id,
        }
    }),

    on(SetDashboardPartnerId, (state, { id }) => {
        return {
            ...state,
            partnerId: id,
        }
    }),

    // Total amount monthly
    on(SetDashboardTotalAmountMonthlyWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                totalAmountMonthlyPrefetch: true,
                totalAmountMonthly: data,
            },
        }
    }),

    // Commissions
    on(SetDashboardCommissionsWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                commissionsPrefetch: true,
                commissions: data,
            },
        }
    }),

    // Widget - Total daily
    on(SetDashboardTotalAmountDailyWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                totalAmountDailyPrefetch: true,
                totalAmountDaily: data,
            },
        }
    }),

    // Widget - Total count daily
    on(SetDashboardTerminalCountDailyWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                terminalCountDailyPrefetch: true,
                terminalCountDaily: data,
            },
        }
    }),

    // Widget - Total count monthly
    on(SetDashboardTotalCountMonthlyWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                totalCountMonthlyPrefetch: true,
                totalCountMonthly: data,
            },
        }
    }),

    // Widget - Quote and applications
    on(SetQuoteAndApplicationWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                quoteAndApplicationPrefetch: true,
                quoteAndApplication: data,
            },
        }
    }),

    // Widget - All business overview
    on(SetAllBusinessOverviewWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                allBusinessOverviewPrefetch: true,
                allBusinessOverview: data,
            },
        }
    }),

    // Widget - Finance income
    on(SetFinanceCommissionWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                financeCommissionPrefetch: true,
                financeCommission: data,
            },
        }
    }),

    // Widget - Residual income
    on(SetResidualCommissionWidgetAction, (state, { data }) => {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                residualCommissionPrefetch: true,
                residualCommission: data,
            },
        }
    }),

    // Widget - Card type
    on(SetDashboardWidgetUpdateStatus, (state, { widgetType, status }) => {
        let currentWidgetType = null
        switch (widgetType) {
            case 3:
                currentWidgetType = DashboardWidgetName.totalAmountMonthly
                break
            case 4:
                currentWidgetType = DashboardWidgetName.totalCountMonthly
                break
            case 6:
                currentWidgetType = DashboardWidgetName.totalCountDaily
                break
            case 7:
                currentWidgetType = DashboardWidgetName.totalAmountDaily
                break
            case 12:
                currentWidgetType = DashboardWidgetName.quoteAndApplications
                break
            case 13:
                currentWidgetType = DashboardWidgetName.allBusinessOverview
                break
            case 23:
                currentWidgetType = DashboardWidgetName.commissions
        }

        return {
            ...state,
            updateWidgetStatus: {
                type: currentWidgetType,
                status,
            },
        }
    }),

    // Restart dashboard
    on(SetDashboardRestartAction, () => {
        return {
            ...initialState,
        }
    }),
)
