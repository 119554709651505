import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { AlertService } from "@app/_services/alert.service"
import { SetQuoteAndApplicationWidgetAction } from "../../actions/DashboardActions"
import { EChartsOption } from "echarts"
import { WidgetQuotesAndApplicationsCountOverall } from "@app/_models/widget-quotes-and-applications-count-overall"
import { WidgetQuotesAndApplicationCountOverallApiService } from "@app/_services/api/widget-quotes-and-applications-count-overall-api.service"

export const FetchQuoteAndApplicationCountOverall = createAction(
    "QuoteAndApplicationsCountOverallAction",
    props<{ partnerId: number }>(),
)

@Injectable()
export class QuoteAndApplicationsCountOverallEffect {
    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchQuoteAndApplicationCountOverall),
                exhaustMap(action =>
                    this.widgetQuotesAndApplicationsApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                {
                                    const overall: WidgetQuotesAndApplicationsCountOverall[] = payload

                                    const bar = []
                                    const data = []

                                    if (overall[0]) {
                                        const fetchedData = overall[0]

                                        bar.push("Applications")
                                        data.push({
                                            "name": "Applications",
                                            "value": fetchedData["applicationsCount"],
                                        })

                                        bar.push("Quotes")
                                        data.push({
                                            "name": "Quotes",
                                            "value": fetchedData["quotesCount"],
                                        })
                                    }

                                    const eChartOption: EChartsOption = {
                                        tooltip: {
                                            trigger: "item",
                                            formatter: a => {
                                                const originalMarker = a.marker
                                                const color = a.color
                                                const customContent = `${a.data.name}: <span style="color:${color}"><strong>${a.data.value}</strong></span>`
                                                return `${originalMarker}${customContent}`
                                            },
                                            textStyle: {
                                                fontSize: 12,
                                            },
                                        },
                                        color: ["#0078fa", "#97c1fe"],
                                        legend: {
                                            selectorPosition: "start",
                                            align: "left",
                                            orient: "horizontal",
                                            top: "2px",
                                            data: bar,
                                        },
                                        toolbox: {
                                            top: 0,
                                            right: 0,
                                            feature: {
                                                saveAsImage: {
                                                    emphasis: {
                                                        iconStyle: {
                                                            textFill: "#307AFF",
                                                            borderColor: "#307AFF",
                                                        },
                                                    },
                                                    title: "Download report",
                                                    name: "Verofy Applications Quotes",
                                                },
                                            },
                                        },
                                        calculable: true,
                                        series: [
                                            {
                                                name: "Value",
                                                type: "pie",
                                                radius: ["40%", "60%"],
                                                avoidLabelOverlap: false,
                                                itemStyle: {
                                                    borderRadius: 5,
                                                    borderColor: "#fff",
                                                    borderWidth: 1,
                                                },
                                                label: {
                                                    show: false,
                                                    position: "center",
                                                },
                                                emphasis: {
                                                    label: {
                                                        show: false,
                                                    },
                                                },
                                                labelLine: {
                                                    show: false,
                                                },
                                                animationDelay: 0,
                                                data,
                                            },
                                        ],
                                    }

                                    return SetQuoteAndApplicationWidgetAction({
                                        data: eChartOption,
                                    })
                                }
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private widgetQuotesAndApplicationsApiService: WidgetQuotesAndApplicationCountOverallApiService,
    ) {}
}
