import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class UserAuthenticationLog implements ApiResource {
    id: number
    createdAt: Date
    updatedAt: Date
    loginAt: Date
    ipAddress: string
    userAgent: string
    osType: string
    osFamily: string
    osName: string
    osVersion: string
    osTitle: string
    deviceType: string
    browserName: string
    browserVersion: string
    browserTitle: string
    geoLocation: {
        id: number
        createdAt: Date
        updatedAt: Date
        ip: string
        version: string
        city: string
        region: string
        regionCode: string
        country: string
        countryName: string
        countryCode: string
        countryCodeIso3: string
        countryCapital: string
        countryTld: string
        continentCode: string
        inEU: string
        postal: string
        latitude: string
        longitude: string
        timezone: string
        utcOffset: string
        countryCallingCode: string
        currency: string
        currencyName: string
        languages: string
        countryArea: string
        countryPopulation: string
        asn: string
        org: string
    } | null
}

@Injectable({
    providedIn: "root",
})
export class UserAuthenticationLogSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): UserAuthenticationLog {
        const record = new UserAuthenticationLog()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.loginAt = new Date(json.login_at)
        record.ipAddress = json.ip_address
        record.userAgent = json.user_agent
        record.osType = json.os_type
        record.osFamily = json.os_family
        record.osName = json.os_name
        record.osVersion = json.os_version
        record.osTitle = json.os_title
        record.deviceType = json.device_type
        record.browserName = json.browser_name
        record.browserVersion = json.browser_version
        record.browserTitle = json.browser_title
        record.geoLocation = json.geo_location
            ? {
                  id: json.geo_location.id,
                  createdAt: new Date(json.geo_location.created_at),
                  updatedAt: new Date(json.geo_location.updated_at),
                  ip: json.geo_location.ip,
                  version: json.geo_location.version,
                  city: json.geo_location.city,
                  region: json.geo_location.region,
                  regionCode: json.geo_location.region_code,
                  country: json.geo_location.country,
                  countryName: json.geo_location.country_name,
                  countryCode: json.geo_location.country_code,
                  countryCodeIso3: json.geo_location.country_code_iso3,
                  countryCapital: json.geo_location.country_capital,
                  countryTld: json.geo_location.country_tld,
                  continentCode: json.geo_location.continent_code,
                  inEU: json.geo_location.in_eu,
                  postal: json.geo_location.postal,
                  latitude: json.geo_location.latitude,
                  longitude: json.geo_location.longitude,
                  timezone: json.geo_location.timezone,
                  utcOffset: json.geo_location.utc_offset,
                  countryCallingCode: json.geo_location.country_calling_code,
                  currency: json.geo_location.currency,
                  currencyName: json.geo_location.currency_name,
                  languages: json.geo_location.languages,
                  countryArea: json.geo_location.country_area,
                  countryPopulation: json.geo_location.country_population,
                  asn: json.geo_location.asn,
                  org: json.geo_location.org,
              }
            : null
        return record
    }

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(item: UserAuthenticationLog): any {
        return {
            id: item.id,
            created_at: item.createdAt,
            updated_at: item.updatedAt,
            login_at: item.loginAt,
            ip_address: item.ipAddress,
            user_agent: item.userAgent,
            os_type: item.osType,
            os_family: item.osFamily,
            os_name: item.osName,
            os_version: item.osVersion,
            os_title: item.osTitle,
            device_type: item.deviceType,
            browser_name: item.browserName,
            browser_version: item.browserVersion,
            browser_title: item.browserTitle,
            geo_location:
                item.geoLocation !== null
                    ? {
                          id: item.geoLocation.id,
                          created_at: item.geoLocation.createdAt,
                          updated_at: item.geoLocation.updatedAt,
                          ip: item.geoLocation.ip,
                          version: item.geoLocation.version,
                          city: item.geoLocation.city,
                          region: item.geoLocation.region,
                          region_code: item.geoLocation.regionCode,
                          country: item.geoLocation.country,
                          country_name: item.geoLocation.countryName,
                          country_code: item.geoLocation.countryCode,
                          country_code_iso3: item.geoLocation.countryCodeIso3,
                          country_capital: item.geoLocation.countryCapital,
                          country_tld: item.geoLocation.countryTld,
                          continent_code: item.geoLocation.continentCode,
                          in_eu: item.geoLocation.inEU,
                          postal: item.geoLocation.postal,
                          latitude: item.geoLocation.latitude,
                          longitude: item.geoLocation.longitude,
                          timezone: item.geoLocation.timezone,
                          utc_offset: item.geoLocation.utcOffset,
                          country_calling_code: item.geoLocation.countryCallingCode,
                          currency: item.geoLocation.currency,
                          currency_name: item.geoLocation.currencyName,
                          languages: item.geoLocation.languages,
                          country_area: item.geoLocation.countryArea,
                          country_population: item.geoLocation.countryPopulation,
                          asn: item.geoLocation.asn,
                          org: item.geoLocation.org,
                      }
                    : {},
        }
    }
}
