import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-star-icon",
    template: `<svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 249.41 249.41">
        <polygon
            [attr.fill]="colorBlue"
            points="124.71 0 153.04 18.97 187.06 16.71 202.11 47.3 232.7 62.35 230.44 96.37 249.41 124.71 230.44 153.04 232.7 187.06 202.11 202.11 187.06 232.7 153.04 230.44 124.71 249.41 96.37 230.44 62.35 232.7 47.3 202.11 16.71 187.06 18.97 153.04 0 124.71 18.97 96.37 16.71 62.35 47.3 47.3 62.35 16.71 96.37 18.97 124.71 0" />
        <path
            [attr.fill]="colorWhite"
            d="M124.71,197.6c-40.2,0-72.9-32.7-72.9-72.9S84.51,51.81,124.71,51.81s72.9,32.7,72.9,72.9-32.7,72.9-72.9,72.9ZM124.71,61.53c-34.83,0-63.17,28.34-63.17,63.18s28.34,63.17,63.17,63.17,63.17-28.34,63.17-63.17-28.34-63.18-63.17-63.18Z" />
        <path
            [attr.fill]="colorWhite"
            d="M124.71,159.09c-2.68,0-4.86-2.18-4.86-4.86v-59.04c0-2.68,2.18-4.86,4.86-4.86s4.86,2.18,4.86,4.86v59.04c0,2.68-2.18,4.86-4.86,4.86Z" />
        <path
            [attr.fill]="colorWhite"
            d="M150.26,144.33c-.82,0-1.66-.21-2.43-.65l-51.13-29.52c-2.33-1.34-3.12-4.32-1.78-6.64,1.34-2.33,4.31-3.12,6.64-1.78l51.13,29.52c2.33,1.34,3.12,4.32,1.78,6.64-.9,1.56-2.53,2.43-4.21,2.43Z" />
        <path
            [attr.fill]="colorWhite"
            d="M99.15,144.33c-1.68,0-3.31-.87-4.21-2.43-1.34-2.33-.55-5.3,1.78-6.64l51.13-29.52c2.33-1.34,5.3-.55,6.64,1.78,1.34,2.33.55,5.3-1.78,6.64l-51.13,29.52c-.77.44-1.6.65-2.43.65Z" />
    </svg>`,
})
export class StarIconComponent {
    @Input() width: number = 22
    @Input() height: number = 22
    @Input() colorBlue: string = "#2f7aff"
    @Input() colorWhite: string = "#fff"
}
