import { createAction } from "@ngrx/store"
import ActionTypes from "../ActionTypes"

/**
 * App loading action
 */

export const SetShowAppLoadingGlobal = createAction(ActionTypes.setShowAppLoadingGlobal, (prop: string = null) => ({
    prop,
}))

export const SetHideAppLoadingGlobal = createAction(ActionTypes.setHideAppLoadingGlobal)

export const SetShowAppLoadingHeader = createAction(ActionTypes.setShowAppLoadingHeader)

export const SetHideAppLoadingHeader = createAction(ActionTypes.setHideAppLoadingHeader)

export const SetShowAppLoadingComponent = createAction(ActionTypes.setShowAppLoadingComponent)
export const SetHideAppLoadingComponent = createAction(ActionTypes.setHideAppLoadingComponent)
