import { Observable } from "rxjs"
import { AssociativeArray } from "./../../_core/types"
import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { ApiSubResourceService } from "./api-sub-resource.service"
import { Notification, NotificationSerializer } from "../../_models/notification"
import { map } from "rxjs/operators"

@Injectable({
    providedIn: "root",
})
export class NotificationApiService extends ApiSubResourceService<Notification> {
    constructor(httpClient: HttpClient, serializer: NotificationSerializer) {
        super(
            httpClient,
            environment.mainApiUrl.slice(0, -1),
            environment.mainApi.endpoints.sellers,
            "push-notifications",
            serializer,
        )
    }

    public setClosed(
        parentId: number,
        customHeaders: AssociativeArray<string> = {},
        partner_id: number,
    ): Observable<Notification[]> {
        return (
            this.httpClient
                .post(
                    `${this.url}/${this.parentEndpoint}/${parentId}/${this.endpoint}/set-closed?partner_id=${partner_id}`,
                    customHeaders,
                )
                // TODO Need to check
                /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                .pipe(map((data: any) => this.convertData(data.data)))
        )
    }
}
