import { Component, Input, OnInit } from "@angular/core"
import { Store } from "@ngrx/store"
import { AppState } from "@app/store"
@Component({
    selector: "app-loading",
    templateUrl: "./app-loading.component.html",
})
export class AppLoadingComponent implements OnInit {
    @Input() float: boolean = false
    @Input() noMargin: boolean = false
    @Input() propsColor: string = ""
    @Input() appLoadingClass: string = ""
    @Input() text: string = null
    @Input() bgTransparent: boolean = false
    componentColor: string = ""

    @Input() componentStyle: object = {}

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        if (this.propsColor !== "") {
            this.componentColor = this.propsColor
        }

        this.store
            .select(state => state.loading.color)
            .subscribe(color => {
                if (this.propsColor === "") {
                    this.componentColor = color
                }
            })

        this.store
            .select(state => state.loading.loadingText)
            .subscribe(loadingText => {
                if (!this.text) {
                    this.text = loadingText
                }
            })
    }

    renderText(): boolean {
        return this.text && this.text.length > 0
    }
}
