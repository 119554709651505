import { environment } from "@env/environment"
import { ErrorHandler, NgModule } from "@angular/core"
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AppComponent } from "./app.component"
import { AuthGuard } from "./_guards/auth.guard"
import { AuthenticationService } from "./_services/authentication.service"
import { AlertService } from "./_services/alert.service"
import { HeaderComponent } from "./_components/layout/header/header.component"
import { CurrencyPipe, DatePipe } from "@angular/common"
import { NgbDateAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap"
import { AuthHttpInterceptor } from "./_interceptors/auth-http.interceptor"
import { XdebugInterceptor } from "./_interceptors/xdebug.interceptor"
import { ServerErrorInterceptor } from "./_interceptors/server-error.interceptor"
import { GlobalErrorHandler } from "./_core/global-error-handler"
import { AppRoutingModule } from "./app-routing.module"
import { CoreModule } from "./core/core.module"
import { JwtModule } from "@auth0/angular-jwt"
import { RefreshTokenInterceptor } from "./_interceptors/refresh-token.interceptor"
import { CustomerRegistrationAuthGuard } from "./_guards/customer-registration-auth.guard"
import { FooterComponent } from "./_components/layout/footer/footer.component"
import { PartnerRegistrationAuthGuard } from "./_guards/partner-registration-auth.guard"
import { NgbStringAdapter } from "./_adapters/ngb-string.adapter"
import { NgbDateCustomParserFormatter } from "./_formatters/ngb-date-custom-parser.formatter"
import { ConfirmationDialogComponent } from "./_components/layout/confirmation-dialog/confirmation-dialog.component"
import { getSaver, SAVER } from "./_providers/saver.provider"
import { NotificationDetailComponent } from "./_components/notifications/notification-detail/notification-detail.component"
import { StoreModule } from "@ngrx/store"
import { StoreDevtoolsModule } from "@ngrx/store-devtools"
import { appReducer } from "app/store"
import { EffectsModule } from "@ngrx/effects"
import AllEffect from "./store/effects/Index"
import { HttpRequestObserveComponent } from "./_components/http-request-progress/http-request-observe.component"
import { HeaderTimerComponent } from "./_components/layout/header-timer/header-timer.component"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { PermissionGuard } from "./_guards/permission.guard"
import { AppSvgModule } from "@app/_components/app-svg/app-svg.module"
import { PoweredByComponentComponent } from "./_components/powered-by-component/powered-by-component.component"
import { DetectPhoneGuard } from "./_guards/detectPhone.guard"
import { HeaderService } from "./_services/header.service"
import { SharedModule } from "./shared/shared.module"
import { ServiceWorkerModule } from "@angular/service-worker"
import { AnimateGuard } from "@app/_guards/animate.guard"
import { OfflineInterceptor } from "@appInterceptors/offline.interceptor"
import { NgSelectModule } from "@ng-select/ng-select"
import { ReactiveFormsModule } from "@angular/forms"

// import * as Sentry from "@sentry/angular-ivy"

const jwtTokenGetter = (): string => localStorage.getItem("authToken")

@NgModule({
    imports: [
        AppRoutingModule,
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                // whitelistedDomains: ['example.com'],
                // blacklistedRoutes: ['example.com/examplebadroute/']
            },
        }),
        StoreModule.forRoot(appReducer),
        EffectsModule.forRoot(AllEffect),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        TooltipModule.forRoot(),
        AppSvgModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.isSoftwareUpdateEnabled,
            // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000", // registerImmediately || registerWhenStable:30000
        }),
        NgSelectModule,
        ReactiveFormsModule,
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        ConfirmationDialogComponent,
        NotificationDetailComponent,
        HttpRequestObserveComponent,
        HeaderTimerComponent,
        PoweredByComponentComponent,
    ],
    providers: [
        AuthGuard,
        PermissionGuard,
        CustomerRegistrationAuthGuard,
        PartnerRegistrationAuthGuard,
        AnimateGuard,
        AuthenticationService,
        AlertService,
        HeaderService,
        DetectPhoneGuard,
        DatePipe,
        CurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: XdebugInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: NgbDateAdapter,
            useClass: NgbStringAdapter,
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        {
            provide: SAVER,
            useFactory: getSaver,
        },
        // {
        //   provide: ErrorHandler,
        //   useValue: Sentry.createErrorHandler({
        //     showDialog: false,
        //   }),
        // },
        // {
        //   provide: Sentry.TraceService,
        //   deps: [Router],
        // },
        // {
        //   provide: APP_INITIALIZER,
        //   // eslint-disable-next-line @typescript-eslint/no-empty-function
        //   useFactory: () => () => {},
        //   deps: [Sentry.TraceService],
        //   multi: true,
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// enableProdMode();
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(success => console.log(`Tracy: Bootstrap success`))
//   .catch(err => console.error(err));
