import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-delete-blue",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="18.33" height="21.815" viewBox="0 0 18.33 21.815">
        <g id="Group_72" data-name="Group 72" transform="translate(-1597.05 -413.287)">
            <path
                id="Path_47"
                data-name="Path 47"
                d="M1599.279,419.641c-.22,0-.417.012-.613,0a1.73,1.73,0,0,1-1.615-1.714c0-.277,0-.554,0-.83a1.73,1.73,0,0,1,1.742-1.742c1.15-.006,2.3,0,3.452,0h.887v-.163c0-.36,0-.721,0-1.081a.785.785,0,0,1,.823-.819q2.251,0,4.5,0a.782.782,0,0,1,.818.808c.006.361,0,.722,0,1.082v.165c.071,0,.133.008.195.008,1.378,0,2.756,0,4.133,0a1.725,1.725,0,0,1,1.651,1.151,1,1,0,0,1,.052.185,4.656,4.656,0,0,1,0,1.606,1.709,1.709,0,0,1-1.731,1.355c-.142,0-.284,0-.446,0v11.479a3.95,3.95,0,0,1-3.973,3.972h-5.912a3.945,3.945,0,0,1-3.966-3.963q-.006-5.631,0-11.261Zm1.581.017V430.9c0,.109,0,.217.005.326a2.362,2.362,0,0,0,2.353,2.29c1.99.01,3.981,0,5.971,0a2.243,2.243,0,0,0,1.211-.343,2.39,2.39,0,0,0,1.153-2.167q-.008-5.593,0-11.188c0-.053-.005-.105-.007-.165Zm5.346-1.594h7.319c.213,0,.253-.039.253-.247s0-.395,0-.593c0-.269-.024-.293-.3-.293h-14.623c-.174,0-.222.048-.224.219,0,.222,0,.444,0,.666s.041.247.254.247Zm1.481-3.179h-2.957v.455h2.957Z"
                fill="#575756" />
            <path
                id="Path_48"
                data-name="Path 48"
                d="M1605.416,426.569q0-2.311,0-4.623a.789.789,0,1,1,1.578-.023c.005.8,0,1.6,0,2.4q0,3.438,0,6.876a.791.791,0,1,1-1.58.008q0-2.067,0-4.134v-.5Z"
                fill="#575756" />
            <path
                id="Path_49"
                data-name="Path 49"
                d="M1610.624,422.088c-.08.853-.168,1.792-.256,2.731q-.183,1.961-.365,3.922c-.08.861-.158,1.721-.241,2.581a.782.782,0,0,1-.854.706.769.769,0,0,1-.717-.826c.054-.713.126-1.426.193-2.139q.169-1.821.341-3.643c.111-1.189.218-2.379.333-3.568a.781.781,0,0,1,.876-.711A.813.813,0,0,1,1610.624,422.088Z"
                fill="#575756" />
            <path
                id="Path_50"
                data-name="Path 50"
                d="M1601.791,422.14c-.041-.586.268-.968.695-1a.787.787,0,0,1,.871.736q.375,4.026.749,8.052c.04.428.088.855.116,1.284a.786.786,0,0,1-1.508.343,1.614,1.614,0,0,1-.083-.433q-.281-2.994-.556-5.989C1601.978,424.091,1601.877,423.05,1601.791,422.14Z"
                fill="#575756" />
        </g>
    </svg>`,
})
export class DeleteBlueComponent {}
