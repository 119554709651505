import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-question-mark",
    template: ` <svg
        [ngStyle]="{ width: width, height: height, 'cursor': cursor, 'fill': color }"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <defs>
            <style>
                .cls-1,
                .cls-2 {
                    isolation: isolate;
                }
            </style>
        </defs>
        <path
            class="cls-3"
            d="M10,1.05c4.93,0,8.95,4.01,8.95,8.95s-4.01,8.95-8.95,8.95S1.05,14.93,1.05,10,5.07,1.05,10,1.05M10,0C4.48,0,0,4.48,0,10s4.48,10,10,10,10-4.48,10-10S15.52,0,10,0h0Z" />
        <g class="cls-1">
            <text class="cls-2" transform="translate(7.57 13.79)">
                <tspan x="0" y="0">?</tspan>
            </text>
            <text class="cls-2" transform="translate(7.57 13.79)">
                <tspan x="0" y="0">?</tspan>
            </text>
        </g>
    </svg>`,
})
export class QuestionMarkComponent {
    @Input() width: string = "14px"
    @Input() height: string = "14px"
    @Input() color: string = "#2f7aff"
    @Input() cursor: string = "pointer"
}
