import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class Terminal extends ApiResource {
    id: number
    createdAt: Date
    updatedAt: Date
    midId: number
    apiTerminalId: string
    serialNumber: number
    courierReference: number
    dispatchedAt: Date

    constructor() {
        super()
        this.id = 0
    }
}

@Injectable({
    providedIn: "root",
})
export class TerminalSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Terminal {
        const terminal = new Terminal()
        terminal.id = json.id
        terminal.createdAt = new Date(json.created_at)
        terminal.updatedAt = new Date(json.updated_at)
        terminal.midId = json.mid_id
        terminal.apiTerminalId = json.api_terminal_id
        terminal.serialNumber = json.status
        terminal.courierReference = json.courier_reference
        terminal.dispatchedAt = new Date(json.dispatched_at)
        return terminal
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(terminal: Terminal): any {
        return {
            id: terminal.id,
            api_terminal_id: terminal.apiTerminalId,
            serial_number: terminal.serialNumber,
            courier_reference: terminal.courierReference,
            dispatched_at: terminal.dispatchedAt,
        }
    }
}
