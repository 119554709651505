import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-three-dots-icon",
    template: `
        <svg
            [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#307AFF"
            class="bi bi-three-dots"
            viewBox="0 0 16 16">
            <path
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
        </svg>
    `,
})
export class ThreeDotsIconComponent {
    @Input() width: number = 40
    @Input() height: number = 40
}
