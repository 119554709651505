import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-message-archive",
    template: `<svg
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px', 'stroke': color }"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200">
        <defs>
            <style>
                .cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 13.12px;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="m113.33,53.46v-15.87c0-3.92-3.18-7.09-7.09-7.09H28.57c-3.92,0-7.09,3.18-7.09,7.09v120.31c0,3.92,3.18,7.09,7.09,7.09h146.56c3.92,0,7.09-3.18,7.09-7.09V60.56c0-3.92-3.18-7.09-7.09-7.09h-61.8Z" />
        <line class="cls-1" x1="101.84" y1="131.04" x2="101.84" y2="82.44" />
        <polyline class="cls-1" points="80.52 109.23 101.84 132.66 122.62 109.23" />
    </svg>`,
})
export class MessageArchiveComponent {
    @Input() width: number = 15
    @Input() height: number = 15
    @Input() color: string = "#575756"
}
