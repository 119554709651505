<ng-container *ngIf="!isLoginPage">
  <!-- TOP main menu -->
  <nav *ngIf="currentUser" class="navbar navbar-expand-sm navbar-light"
       [ngStyle]="{'background': currentUser.branding.bgColor, 'color': currentUser.branding.fontColor}">

    <div class="container">
      <ng-container *ngIf="currentPartner">
        <div class="top-header-drop">
          <div class="demo-drop-main">
            <div class="demo-btn">
              <button (click)="toggle($event, 'partnerMain')">{{ currentPartner.name }}</button>
            </div>

            <div class="drop-icon" (click)="toggle($event, 'partnerMain')">
              <svg-arrow-down></svg-arrow-down>
            </div>
          </div>

          <div *ngIf="isPartnerMainCollapsed" class="drop-down-header customerDropdownContainer" #dropdownPartnerMain>
            <!-- Current customer -->
            <div class="top-header-section noblackclr remove-border-btm">
              <div class="top-inner-section">
                <section class="js-icon">
                  <span>{{ getFirstLetter(currentPartner.name) }}</span>
                </section>
                <section>
                  <span>{{ currentPartner.name }}</span>
                </section>
              </div>
            </div>

            <!-- Customers selection -->
            <ul class="top-section customerSelection" *ngIf="switchablePartners">
              <ng-container *ngFor="let partner of switchablePartners">
                <li class="main-header test" (click)="switchPartner(partner.id); $event.stopPropagation()"
                    *ngIf="partner.id !== currentPartnerId">
                  <span>
                    <span class="js-icon">{{ getFirstLetter(partner.name) }}</span>
                    <!-- <img src="/assets/img/v92.png" alt="exro-icon"> -->
                  </span>
                  {{ partner.name }}
                </li>
              </ng-container>
              <!-- <li><a href="javascript: void(0);" class="blue-clr"><span class="add-secting">+</span> Add a new
                  organisation</a></li> -->
            </ul>

            <!-- Other -->
            <ul class="middle-section" *ngIf="checkPermission(SellerPermission.ACCESS_ACCOUNTS_AND_COMMERCIALS)" (click)="toggle($event, 'partnerMain')">
              <li (click)="router.navigate(['/', 'messages']);">
                Messages
              </li>

              <li (click)="router.navigate(['/', 'accounts']);">
                Account details
              </li>

              <li (click)="onRouterClick($event, 'settings');">
                Settings
              </li>

              <li (click)="onLogout()">
                Logout
              </li>
            </ul>
            <app-powered-by-component [poweredBy]="'payPanel'" [svgSize]="'80px'" [padding]="true">
            </app-powered-by-component>
          </div>
        </div>

        <a class="navbar-brand" href="javascript: void(0);" [routerLinkActive]="['active']"
           [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']">Dashboard</a>

        <div class="navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <ng-container *ngIf="checkPermission(SellerPermission.ACCESS_CUSTOMERS)">
              <li class="nav-item dropdown general cross-arrow-icon">
                <a i18n class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/customers']">Customers</a>
              </li>
            </ng-container>


            <ng-container *ngIf="checkPermission(SellerPermission.ACCESS_TRANSACTION)">
              <li class="nav-item dropdown general user-icon">
                <a i18n class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/transactions']">Transactions</a>
              </li>
            </ng-container>

            <ng-container *ngIf="checkPermission(SellerPermission.ACCESS_COMMISSIONS)">
              <li class="nav-item dropdown general user-icon">
                <a i18n class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/commissions']">Commissions</a>
              </li>
            </ng-container>

            <ng-container *ngIf="isCurrentUserAdmin()">
              <li class="nav-item dropdown general cross-arrow-icon">
                <a i18n class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                   [routerLink]="['/alerts']">Verofy® Alerts</a>
              </li>
            </ng-container>

          </ul>

          <div class="right-logo">
            <ul>
              <li class="hover-change">
                <a href="javascript: void(0);" class="msg-icon" [routerLink]="['/messages']">
                  <span *ngIf="inbox && inbox.totalUnreadMessages > 0" class="notification-msg">
                    !</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9.065" height="6.232" viewBox="0 0 9.065 6.232">
                    <g fill="currentColor" transform="translate(-2660.336 -26.231)">
                      <g id="Group_852" data-name="Group 852" fill="currentColor">
                        <g>
                          <path
                            d="M2668.834,26.231H2660.9a.567.567,0,0,0-.567.567v.322l4.532,1.919,4.533-1.92V26.8A.567.567,0,0,0,2668.834,26.231Z"
                            fill="currentColor"/>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M2664.979,29.608a.288.288,0,0,1-.221,0l-4.422-1.873V31.9a.567.567,0,0,0,.567.567h7.931a.567.567,0,0,0,.567-.567V27.735Z"
                            fill="currentColor"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="drop-down-main hover-change" #dropdownNotification>
                <span class="noti-icon" style="cursor: pointer" (click)="toggle($event, 'notificationArea')">
                  <span *ngIf="notClosedNotifications > 0" class="notification-msg">
                    !
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6.41" height="7.697" viewBox="0 0 6.41 7.697">
                    <g id="Group_859" data-name="Group 859" transform="translate(-2685.94 -25.009)">
                      <path id="Path_582" data-name="Path 582"
                            d="M2689.217,25.009a.363.363,0,0,1,.253.409c0,.082-.027.186.013.241s.144.044.221.064a2.235,2.235,0,0,1,1.671,1.922c.022.19.013.385.017.577.006.278-.007.558.021.833a2.034,2.034,0,0,0,.684,1.323.663.663,0,0,1,.246.383.557.557,0,0,1-.5.655.909.909,0,0,1-.1,0h-5.191a.571.571,0,0,1-.568-.351.551.551,0,0,1,.158-.64,2.211,2.211,0,0,0,.59-.8,2.093,2.093,0,0,0,.17-.8c0-.3,0-.6,0-.894a2.24,2.24,0,0,1,1.892-2.257.163.163,0,0,0,.032-.011c0-.085,0-.175,0-.263a.352.352,0,0,1,.256-.4Z"
                            fill="currentColor"/>
                      <path id="Path_583" data-name="Path 583"
                            d="M2687.973,31.743h2.338c.019.406-.514.889-1.05.955A1.208,1.208,0,0,1,2687.973,31.743Z"
                            fill="currentColor"/>
                    </g>
                  </svg>
                </span>

                <section class="first-drop-down-menu notificationContainer" *ngIf="isNotificationAreaCollapsed">
                  <section class="notificationContent">

                    <section class="notificationHeader">
                      <h2>Notifications</h2>

                      <div class="close-btn appMr-10" (click)="toggle($event, 'notificationArea')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 6.305 6.303">
                          <g id="Group_1044" data-name="Group 1044" transform="translate(-1519.395 -64.949)">
                            <path id="Path_680" data-name="Path 680"
                                  d="M1520.284,71.252h-.013a.521.521,0,0,0-.043-.05q-.39-.393-.782-.784c-.016-.015-.03-.031-.051-.053l2.274-2.273-2.259-2.259.884-.884,2.269,2.269,2.262-2.262.824.825c.016.015.034.028.051.043v.012a.213.213,0,0,0-.023.019l-2.211,2.211c-.011.011-.02.024-.032.036l.037.039,2.2,2.2c.009.009.019.016.028.024v.012c-.011.009-.023.016-.033.026l-.816.815a.411.411,0,0,0-.025.033h-.012c-.007-.008-.013-.016-.02-.023q-1.1-1.105-2.21-2.21a.379.379,0,0,0-.037-.032c-.014.014-.027.024-.038.036l-2.2,2.2C1520.3,71.233,1520.292,71.243,1520.284,71.252Z"
                                  fill="currentColor"/>
                          </g>
                        </svg>
                      </div>
                    </section>

                    <article class="notificationBody" [ngClass]="{noScroll: notifications.length <= 0 }">
                      <ul>
                        <li style="width: 100%" *ngIf="notifications.length === 0">
                          There are currently no notifications.
                        </li>

                        <li *ngFor="let notification of notifications">
                          <div class="notify-msg">
                            <span>{{ notification.message }}</span>
                            <a (click)="setNotificationClosed(notification.id)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                              </svg>
                            </a>
                          </div>
                          <br/><span class="notify-date">{{ notification.createdAt | date:'d MMM yyyy HH:mm' }}</span>
                        </li>
                      </ul>
                    </article>
                  </section>

                  <footer class="notificationFooter">
                    <ul class="footer">
                      <li>
                        <a class="navLink" [routerLink]="['/settings/notifications']">Settings</a>
                      </li>

                      <li>
                        <a class="navLink" href="javascript: void(0);" (click)="setAllNotificationsClosed()">Delete all</a>
                      </li>
                    </ul>
                  </footer>
                </section>
              </li>

              <li class="hover-change">
                <a href="#" (click)="onRouterClick($event, 'settings')" class="search-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8.449" height="8.448" viewBox="0 0 8.449 8.448">
                    <g id="Group_843" data-name="Group 843" transform="translate(-2710.947 -24.646)">
                      <g id="Group_842" data-name="Group 842">
                        <g id="Group_841" data-name="Group 841">
                          <path id="Path_571" data-name="Path 571"
                                d="M2719.186,28.084l-.754-.09a3.368,3.368,0,0,0-.335-.812l.469-.6a.244.244,0,0,0-.02-.315l-.776-.777a.245.245,0,0,0-.316-.02l-.6.47a3.319,3.319,0,0,0-.81-.335l-.09-.754a.244.244,0,0,0-.236-.209h-1.1a.244.244,0,0,0-.236.209l-.091.754a3.358,3.358,0,0,0-.81.335l-.6-.469a.245.245,0,0,0-.315.019l-.777.778a.244.244,0,0,0-.019.315l.469.6a3.316,3.316,0,0,0-.335.811l-.754.09a.244.244,0,0,0-.21.236v1.1a.246.246,0,0,0,.21.237l.754.09a3.38,3.38,0,0,0,.335.81l-.468.6a.244.244,0,0,0,.019.315l.776.778a.244.244,0,0,0,.315.019l.6-.47a3.28,3.28,0,0,0,.81.335l.091.754a.244.244,0,0,0,.236.209h1.1a.243.243,0,0,0,.236-.209l.091-.754a3.358,3.358,0,0,0,.81-.335l.6.469a.244.244,0,0,0,.315-.019l.777-.777a.244.244,0,0,0,.019-.316l-.469-.6a3.338,3.338,0,0,0,.335-.811l.754-.09a.244.244,0,0,0,.21-.236v-1.1A.245.245,0,0,0,2719.186,28.084Zm-4.014,2.9a2.112,2.112,0,1,1,2.112-2.112A2.112,2.112,0,0,1,2715.172,30.982Z"
                                fill="currentColor"/>
                        </g>
                        <circle id="Ellipse_135" data-name="Ellipse 135" cx="1.074" cy="1.074" r="1.074"
                                transform="translate(2714.097 27.796)" fill="currentColor"/>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="hover-change">
                <a href="https://verofy.support/" target="_blank" class="faq-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="7.601" height="7.601" viewBox="0 0 7.601 7.601">
                    <g id="Group_864" data-name="Group 864" transform="translate(-2759.702 -25.239)">
                      <circle id="Ellipse_139" data-name="Ellipse 139" cx="3.8" cy="3.8" r="3.8"
                              transform="translate(2759.702 25.239)" fill="currentColor"/>
                      <g id="Group_863" data-name="Group 863">
                        <path id="Path_585" data-name="Path 585"
                              d="M2763.041,30.045a1.866,1.866,0,0,1,.108-.7,1.843,1.843,0,0,1,.431-.572,3.311,3.311,0,0,0,.407-.444.778.778,0,0,0,.132-.435.668.668,0,0,0-.153-.473.594.594,0,0,0-.451-.162.646.646,0,0,0-.456.159.556.556,0,0,0-.173.432h-.8a1.18,1.18,0,0,1,.4-.924,1.515,1.515,0,0,1,1.033-.34,1.48,1.48,0,0,1,1.034.337,1.2,1.2,0,0,1,.371.94,1.542,1.542,0,0,1-.5,1.06l-.406.4a1.085,1.085,0,0,0-.224.723Zm-.057,1.024a.422.422,0,0,1,.122-.314.453.453,0,0,1,.33-.12.448.448,0,0,1,.333.124.45.45,0,0,1,0,.612.452.452,0,0,1-.337.122.442.442,0,0,1-.334-.122A.415.415,0,0,1,2762.984,31.069Z"
                              fill="#121925"/>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="drop-down-main dropdown">
                <span class="js-icon" data-bs-toggle="dropdown" aria-expanded="false"
                      (click)="toggle($event, 'currentUser')">{{ getInitials() }}
                </span>

                <div class="drop-down-menu dropdown-menu">
                  <ul>
                    <li>
                      <h2 *ngIf="currentPartner">{{ currentPartner.name }}</h2>
                      <span>
                        <a [routerLink]="['/settings/users', currentUser.sellerId, 'detail']">Edit
                          profile</a></span>
                    </li>
                    <li *ngIf="checkPermission(SellerPermission.ACCESS_ACCOUNTS_AND_COMMERCIALS)"
                        class="hover"
                        (click)="router.navigate(['/accounts'])">
                      <a [routerLink]="['/accounts']">Accounts</a>
                    </li>
                    <li class="nav-item logout hover" (click)="onLogout()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 8.982 7.4">
                        <g id="Group_1006" data-name="Group 1006" transform="translate(-1654.817 -128.497)">
                          <g id="Group_1005" data-name="Group 1005">
                            <g id="Group_1004" data-name="Group 1004">
                              <g id="Group_1003" data-name="Group 1003">
                                <path id="Path_640" data-name="Path 640"
                                      d="M1661.183,132.952h-4.417a.42.42,0,0,1-.42-.42v-.913a.42.42,0,0,1,.42-.419h4.417Z"
                                      fill="#575756"/>
                                <path id="Path_641" data-name="Path 641"
                                      d="M1660.6,132.076v-2.648l1.6,1.324,1.6,1.324-1.6,1.324-1.6,1.324Z"
                                      fill="#575756"/>
                              </g>
                              <path id="Path_642" data-name="Path 642"
                                    d="M1659.187,135.9h-4.37v-7.4h4.37Zm-4.024-.346h3.678v-6.708h-3.678Z"
                                    fill="#575756"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <a i18n="Main menu - Logout|Main menu - Logout@@menuSubItemLogout" class="nav-link"
                         style="cursor: pointer" (click)="onLogout()">Logout</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a href="javascript: void(0);" class="right-logo-img">
                  <ng-container *ngIf="currentUser.branding.logoUrl">
                    <img src="{{ currentUser.branding.logoUrl }}">
                  </ng-container>

                  <ng-container *ngIf="!currentUser.branding.logoUrl">
                    <svg _ngcontent-qji-c167="" xmlns="http://www.w3.org/2000/svg" width="91.225" height="46.505"
                         viewBox="0 0 91.225 46.505">
                      <g _ngcontent-qji-c167="" id="Group_449" data-name="Group 449"
                         transform="translate(-5616.36 -18.767)">
                        <g _ngcontent-qji-c167="" id="Group_445" data-name="Group 445">
                          <path _ngcontent-qji-c167="" id="Path_252" data-name="Path 252"
                                d="M5624.751,64.474a5.453,5.453,0,0,1-1.214.359,7.688,7.688,0,0,1-1.363.12,4.024,4.024,0,0,1-2.89-.935,3.68,3.68,0,0,1-.975-2.782V55.285h-1.949l.922-2.577h1.027V50.017l3.478-1v3.694h3.647l-.922,2.577h-2.725V61a1.61,1.61,0,0,0,.285,1.06,1.1,1.1,0,0,0,.878.33,2.184,2.184,0,0,0,.581-.074,2.838,2.838,0,0,0,.525-.2Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_253" data-name="Path 253"
                                d="M5626.6,52.548h2.668V54.11a3.336,3.336,0,0,1,1.152-1.4,2.779,2.779,0,0,1,1.573-.479,2.923,2.923,0,0,1,1.266.319l-.969,2.68a2.138,2.138,0,0,0-.9-.274,1.424,1.424,0,0,0-1.214.889,8.131,8.131,0,0,0-.5,3.489l.011.6v4.095l-3.09.922Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_254" data-name="Path 254"
                                d="M5642.98,53.47l3.112-.922V64.031l-3.112.922V63.642a6.125,6.125,0,0,1-1.828,1.248,5.1,5.1,0,0,1-1.987.382,5.523,5.523,0,0,1-4.157-1.864,6.5,6.5,0,0,1-1.753-4.635,6.657,6.657,0,0,1,1.7-4.708,5.4,5.4,0,0,1,4.122-1.836,5.242,5.242,0,0,1,2.095.422,5.731,5.731,0,0,1,1.811,1.265Zm-3.272,1.632a3.144,3.144,0,0,0-2.4,1.021,4.075,4.075,0,0,0,.017,5.261,3.148,3.148,0,0,0,2.392,1.038,3.206,3.206,0,0,0,2.432-1.021,3.728,3.728,0,0,0,.966-2.673,3.623,3.623,0,0,0-.966-2.623A3.249,3.249,0,0,0,5639.708,55.1Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_255" data-name="Path 255"
                                d="M5648.047,53.47l3.112-.922V53.82a7.453,7.453,0,0,1,1.918-1.242,4.63,4.63,0,0,1,1.759-.349,4.261,4.261,0,0,1,3.131,1.288,4.424,4.424,0,0,1,1.082,3.238v7.276l-3.079.922V59.52a13.731,13.731,0,0,0-.2-2.949,2.017,2.017,0,0,0-.694-1.11,1.945,1.945,0,0,0-1.222-.382,2.293,2.293,0,0,0-1.621.632,3.377,3.377,0,0,0-.938,1.749,14.1,14.1,0,0,0-.137,2.516v4.055l-3.112.922Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_256" data-name="Path 256"
                                d="M5669.155,54.281l-1.927,1.927a3.26,3.26,0,0,0-2.132-1.163,1.338,1.338,0,0,0-.821.223.666.666,0,0,0-.3.552.675.675,0,0,0,.188.462,3.484,3.484,0,0,0,.929.576l1.14.57a6.709,6.709,0,0,1,2.474,1.813,3.776,3.776,0,0,1-.541,4.925,4.651,4.651,0,0,1-3.255,1.106,5.174,5.174,0,0,1-4.333-2.121l1.916-2.086a4.4,4.4,0,0,0,1.282,1.032,2.841,2.841,0,0,0,1.306.393,1.562,1.562,0,0,0,.992-.3.862.862,0,0,0,.376-.684q0-.719-1.357-1.4l-1.049-.524q-3.009-1.517-3.01-3.8a3.294,3.294,0,0,1,1.135-2.514,4.121,4.121,0,0,1,2.9-1.043,5.032,5.032,0,0,1,4.082,2.052Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_257" data-name="Path 257"
                                d="M5680.3,53.47l3.112-.922V64.031l-3.112.922V63.642a6.129,6.129,0,0,1-1.828,1.248,5.107,5.107,0,0,1-1.987.382,5.521,5.521,0,0,1-4.157-1.864,6.5,6.5,0,0,1-1.753-4.635,6.659,6.659,0,0,1,1.7-4.708,5.4,5.4,0,0,1,4.122-1.836,5.244,5.244,0,0,1,2.1.422,5.724,5.724,0,0,1,1.811,1.265ZM5677.03,55.1a3.145,3.145,0,0,0-2.4,1.021,4.077,4.077,0,0,0,.017,5.261,3.148,3.148,0,0,0,2.392,1.038,3.206,3.206,0,0,0,2.432-1.021,3.727,3.727,0,0,0,.966-2.673,3.623,3.623,0,0,0-.966-2.623A3.25,3.25,0,0,0,5677.03,55.1Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_258" data-name="Path 258"
                                d="M5697.519,55.057l-2.591,1.425a3.841,3.841,0,0,0-3.116-1.357,3.894,3.894,0,0,0-2.825,1.042,3.549,3.549,0,0,0-1.079,2.669,3.429,3.429,0,0,0,1.039,2.584,3.764,3.764,0,0,0,2.728,1A3.967,3.967,0,0,0,5694.928,61l2.454,1.68a7.561,7.561,0,0,1-10.757.657,6.445,6.445,0,0,1,1.562-10.238,7.5,7.5,0,0,1,3.609-.867,7.31,7.31,0,0,1,3.318.736A6.287,6.287,0,0,1,5697.519,55.057Z"
                                fill="#307aff"></path>
                          <path _ngcontent-qji-c167="" id="Path_259" data-name="Path 259"
                                d="M5706.9,64.474a5.437,5.437,0,0,1-1.214.359,7.68,7.68,0,0,1-1.362.12,4.027,4.027,0,0,1-2.891-.935,3.682,3.682,0,0,1-.974-2.782V55.285h-1.95l.922-2.577h1.028V50.017l3.477-1v3.694h3.647l-.922,2.577h-2.725V61a1.61,1.61,0,0,0,.285,1.06,1.1,1.1,0,0,0,.878.33,2.187,2.187,0,0,0,.581-.074,2.834,2.834,0,0,0,.525-.2Z"
                                fill="#307aff"></path>
                        </g>
                        <g _ngcontent-qji-c167="" id="Group_448" data-name="Group 448">
                          <g _ngcontent-qji-c167="" id="Group_446" data-name="Group 446">
                            <path _ngcontent-qji-c167="" id="Path_260" data-name="Path 260"
                                  d="M5649.821,35.979a4.344,4.344,0,0,1,4.344-4.344h17.768l-4.313,4.344Z" fill="#fff">
                            </path>
                            <path _ngcontent-qji-c167="" id="Path_261" data-name="Path 261"
                                  d="M5674.054,35.979h-.028l.019-.018Z" fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_262" data-name="Path 262"
                                  d="M5668.791,41.049a4.955,4.955,0,0,1-6.526.323h0l5.355-5.393h6.406Z"
                                  fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_263" data-name="Path 263"
                                  d="M5671.934,31.635l.078-.079,2.112,4.329-.078.076-2.071-4.326Z" fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_264" data-name="Path 264"
                                  d="M5671.974,31.635l2.071,4.326-.019.018h-6.407l4.314-4.345Z" fill="#fff"></path>
                          </g>
                          <g _ngcontent-qji-c167="" id="Group_447" data-name="Group 447">
                            <path _ngcontent-qji-c167="" id="Path_265" data-name="Path 265"
                                  d="M5649.821,25.233a4.344,4.344,0,0,0,4.344,4.344h17.768l-4.313-4.344Z" fill="#fff">
                            </path>
                            <path _ngcontent-qji-c167="" id="Path_266" data-name="Path 266"
                                  d="M5674.054,25.233h-.028l.019.018Z" fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_267" data-name="Path 267"
                                  d="M5668.791,20.163a4.955,4.955,0,0,0-6.526-.323h0l5.355,5.393h6.406Z"
                                  fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_268" data-name="Path 268"
                                  d="M5671.934,29.577l.078.079,2.112-4.329-.078-.076-2.071,4.326Z" fill="#fff"></path>
                            <path _ngcontent-qji-c167="" id="Path_269" data-name="Path 269"
                                  d="M5671.974,29.577l2.071-4.326-.019-.018h-6.407l4.314,4.345Z" fill="#fff"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </ng-container>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </nav>

  <!-- Breadcrumb - OTHER PAGES -->
  <nav *ngIf="!isHomepage && (initializeHeader | async)" class="breadcrumb-section" aria-label="breadcrumb" [ngClass]="{
            'bg-clr-div':
                navigationEndUrl === '/users' ||
                navigationEndUrl === '/user-groups' ||
                navigationEndUrl === '/group-dashboards',
            'group-user-div': navigationEndUrl === '/user-groups'
        }">
    <div class="container">
      <div class="left-section">
        <ol class="breadcrumb">
          <li *ngFor="let item of breadcrumbs; first as isFirst; last as isLast" class="breadcrumb-item"
              [ngClass]="{ 'active': isLast }" [attr.aria-current]="isLast ? 'page' : ''">
            <ng-container *ngIf="item.url === ''">{{ item.label }}</ng-container>
            <ng-container *ngIf="item.url !== ''">
              <a [routerLink]="[item.url]">{{ item.label }}</a>
            </ng-container>
          </li>
        </ol>
        <!-- <h2>{{ title }}</h2> -->
      </div>

      <app-loading *ngIf="appLoadingHeader | async"></app-loading>

      <div class="rightSection" *ngIf="currentPartner">
        <div class="version-dash">
          <div class="top-right-head">
            <app-header-timer></app-header-timer>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- Customers - HOMEPAGE -->
  <nav class="breadcrumb-section home-page" aria-label="breadcrumb" *ngIf="isHomepage && (initializeHeader | async)">
    <div class="container">
      <div class="left-section">
        <h2 *ngIf="currentPartner">{{ currentPartner.name }}</h2>
        <!-- <p>Welcome to PayPanel</p> -->
      </div>

      <div class="rightSection">
        <div class="version-dash">
          <div class="top-right-head">
            <app-header-timer></app-header-timer>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <ng-container *ngIf="(initializeHeader | async) && appLoadingGlobal === false">
    <div class="top-nav-section-div" *ngIf="navigationEndUrl === '/accounts' || navigationEndUrl === '/commercials'">
      <div class="container">
        <div class="user-main-div">
          <div class="user-container">
            <ul>
              <li [routerLinkActive]="['active']">
                <a i18n [routerLink]="['/accounts']" [state]="{ data: { loader: 'header' } }">Account</a>
              </li>

              <li [routerLinkActive]="['active']">
                <a i18n [routerLink]="['/commercials']" [state]="{ data: { loader: 'header' } }">Commercials</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="top-nav-section-div" *ngIf="navigationEndUrl === '/addresses'">
      <div class="container">
        <div class="user-main-div">
          <p>General</p>
          <div class="user-container">
            <ul>
              <li [routerLinkActive]="['active']">
                <a [routerLink]="['/addresses']">Addresses</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="top-nav-section-div" *ngIf="
                navigationEndUrl === '/settings' ||
                navigationEndUrl === '/settings/login-history' ||
                navigationEndUrl === '/settings/notifications' ||
                navigationEndUrl === '/settings/alerts' ||
                urlContains('/settings/user-groups') ||
                urlContains('/settings/users')" >
      <div class="container">
        <div class="user-main-div">
          <div class="user-container">
            <ul>
              <li *ngIf="checkPermission(SellerPermission.ACCESS_USERS)" [ngClass]="{active: isUrlActive('users')}">
                <a i18n [routerLink]="['/settings']" (click)="setLoadingFromHeader()">Users</a>
              </li>
              <li *ngIf="checkPermission(SellerPermission.ACCESS_USER_GROUPS)"
                  [ngClass]="{active: isUrlActive('user-groups')}">
                <a i18n [routerLink]="['/settings/user-groups']" (click)="setLoadingFromHeader()">User
                  groups</a>
              </li>

              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                <a i18n [routerLink]="['/settings/notifications']">Notifications</a>
              </li>

              <!-- *ngIf="checkPermission(SellerPermission.ACCESS_ALERTS)" -->
              <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
                <a i18n [routerLink]="['/settings/alerts']">Alerts</a>
              </li>

              <li *ngIf="checkPermission(SellerPermission.ACCESS_LOGIN_HISTORY)" [routerLinkActive]="['active']"
                  [routerLinkActiveOptions]="{ exact: true }">
                <a i18n [routerLink]="['/settings/login-history']">Login history</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<section *ngIf="isGlobalLoaderOn" [ngClass]="['globalLoader', appLoadingGlobalAnimation, logoutLoading]">
  <app-loading [appLoadingClass]="'appLoadingGlobal'"></app-loading>
</section>
