import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
    effect,
    WritableSignal,
    Renderer2,
    OnDestroy,
    AfterViewInit,
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { Router } from "@angular/router"

import { DateRangeService } from "@app/shared/services/DateRangeService"
import { TransactionDatePickerSignal } from "@app/signals/transactions/TransactionDatePickerSignal"
import {
    ISAdvancedSearchSignal,
    ISSetRadioSignal,
    MenuSignal,
    SelectedOptionChange,
} from "@app/signals/transactions/transactionOpenMenu"
import { DatePickerType } from "@app/store/reducers/TransactionReducer"
import { BsDaterangepickerDirective } from "ngx-bootstrap/datepicker"

@Component({
    selector: "app-date-range-selector",
    templateUrl: "./date-range-selector.component.html",
})
export class DateRangeSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() rangeSelected = new EventEmitter<{ fromDate: Date; toDate: Date }>()
    selectedRange: WritableSignal<string>
    @ViewChild("dp") dp: BsDaterangepickerDirective
    @ViewChild("popupContainer") popupContainer: ElementRef
    maxDateRangeFilterDate: Date = new Date()
    options = [
        { value: "today", label: "Today" },
        { value: "yesterday", label: "Yesterday" },
        { value: "this_week", label: "This week" },
        { value: "last_week", label: "Last week" },
        { value: "this_month", label: "This month" },
        { value: "previous_month", label: "Previous month" },
        { value: "custom", label: "Custom" },
    ]
    openMenu: boolean = MenuSignal()
    filterAdjustPeriod: FormControl = new FormControl(null, {})

    initializeCustomOptionChange: boolean = false
    selectedOption: string = ""
    isVTerminalRoute: boolean = false

    private listenerFn: () => void
    private isFirstClick = true

    constructor(private dateRangeService: DateRangeService, private route: Router, private renderer: Renderer2) {
        // Use an effect to reactively update openMenu when the signal changes
        effect(() => {
            this.openMenu = MenuSignal()
        })

        effect(() => {
            SelectedOptionChange()
            this.selectedRange = SelectedOptionChange
            console.log("now selectedRange", this.selectedRange())
        })
    }

    checkRoute(): void {
        const currentRoute = this.route.url
        this.isVTerminalRoute =
            currentRoute.includes("pay/verofy-vterminal") || currentRoute.includes("pay/verofy-paylinks")
    }

    ngOnInit(): void {
        this.checkRoute()

        this.filterAdjustPeriod.valueChanges.subscribe(value => {
            if (Array.isArray(value) && value.length === 2 && value.every(val => val instanceof Date)) {
                const fromDate = value[0]
                const toDate = value[1]
                TransactionDatePickerSignal.update(() => ({
                    fromDate: fromDate,
                    toDate: toDate,
                    datepickerType: DatePickerType.range,
                    exactDate: null,
                    maxFromDate: null,
                    minToDate: null,
                    selectedRange: this.selectedRange,
                }))
                ISAdvancedSearchSignal.set(true)
                this.closeMenu()
            }
        })

        this.onMenuSelection(this.selectedRange().toString())

        if (this.isFirstClick) {
            this.listenerFn = this.renderer.listen("document", "click", (event: MouseEvent) => {
                this.onDocumentClick(event)
            })
            this.isFirstClick = false
        }
    }

    ngAfterViewInit(): void {
        // Add event listener for date selection after the view has been initialized
        if (this.dp) {
            this.dp.bsValueChange.subscribe((value: Date[]) => {
                if (Array.isArray(value) && value.length === 2) {
                    this.closeMenu()
                }
            })
        }
    }

    private onDocumentClick(event: MouseEvent): void {
        // Check if the MenuSignal is true (popup is open)
        if (MenuSignal()) {
            // Check if the click is outside the popup
            const clickedInsidePopup = (event.target as HTMLElement).closest(
                ".popupContainer, .popupContainer-vterminal, .toggle-button",
            )
            if (!clickedInsidePopup) {
                // If clicked outside the popup, close it.
                MenuSignal.set(false)
            }
        }
    }

    ngOnDestroy(): void {
        // Clean up the listener
        if (this.listenerFn) {
            this.listenerFn()
        }
    }

    onMenuSelection(value: string): void {
        SelectedOptionChange.update(() => value)
    }

    // Method to open the menu
    openTheMenu() {
        MenuSignal.set(true)
    }

    // Method to close the menu
    closeMenu(): void {
        MenuSignal.set(false)
    }

    // Method to open the date range picker
    openDatePicker(): void {
        if (this.dp) {
            this.dp.show()
        }
    }

    confirmSelection(): void {
        let selectedDateRange
        let datepickerType: DatePickerType
        const selectedRangeValue = this.selectedRange().toString()
        switch (selectedRangeValue) {
            case "today":
                selectedDateRange = this.dateRangeService.todayDay()
                datepickerType = DatePickerType.exact
                break
            case "yesterday":
                selectedDateRange = this.dateRangeService.yesterday()
                datepickerType = DatePickerType.exact
                break
            case "last_week":
                selectedDateRange = this.dateRangeService.lastWeek()
                datepickerType = DatePickerType.range
                break
            case "this_week":
                selectedDateRange = this.dateRangeService.thisWeek()
                datepickerType = DatePickerType.range
                break
            case "previous_month":
                selectedDateRange = this.dateRangeService.previousMonth()
                datepickerType = DatePickerType.range
                break
            case "this_month":
                selectedDateRange = this.dateRangeService.thisMonth()
                datepickerType = DatePickerType.range
                break
            case "custom":
                this.openDatePicker()
                datepickerType = DatePickerType.range
                return

            default:
                datepickerType = DatePickerType.range
                break
        }
        this.closeMenu()

        TransactionDatePickerSignal.update(() => ({
            fromDate: selectedDateRange.fromDate,
            toDate: selectedDateRange.toDate,
            datepickerType: datepickerType,
            exactDate: selectedDateRange.exactDate,
            selectedRange: selectedRangeValue,
        }))
        ISAdvancedSearchSignal.set(true)
        ISSetRadioSignal.set(false)
    }

    isSelected(value: string): boolean {
        if (this.selectedRange() === value) {
            return true
        }
        return false
    }
}
