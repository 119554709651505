import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class CustomerExternalData {
    id = 0
    createdAt: Date
    updatedAt: Date

    registrationHash: string

    utilMpan: string
    utilMprn: string
    utilAvailableCapacity: number
    utilCapacityCharge: number

    w2KybData: string
    isW2KybConfirmed: boolean
}

@Injectable({
    providedIn: "root",
})
export class CustomerExternalDataSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): CustomerExternalData {
        const extData = new CustomerExternalData()
        extData.id = json.id
        extData.createdAt = new Date(json.created_at)
        extData.updatedAt = new Date(json.updated_at)
        extData.registrationHash = json.registration_hash

        extData.utilMpan = json.util_mpan
        extData.utilMprn = json.util_mprn
        extData.utilAvailableCapacity = json.util_available_capacity
        extData.utilCapacityCharge = json.util_capacity_charge

        extData.w2KybData = json.w2_kyb_data
        extData.isW2KybConfirmed = json.is_w2_kyb_confirmed

        return extData
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(extData: CustomerExternalData): any {
        return {
            util_mpan: extData.utilMpan,
            util_mprn: extData.utilMprn,
            util_available_capacity: extData.utilAvailableCapacity,
            util_capacity_charge: extData.utilCapacityCharge,
        }
    }
}
