import { SetHeaderInitialize, SetHeaderLogout } from "./../actions/AppHeaderActions"
import { createReducer, on } from "@ngrx/store"

export interface AppHeaderInterface {
    initializeHeader: boolean
    logout: boolean
}

const initialState: AppHeaderInterface = {
    initializeHeader: false,
    logout: false,
}

export const AppHeaderReducer = createReducer(
    initialState,

    on(SetHeaderInitialize, state => {
        return {
            ...state,
            initializeHeader: true,
        }
    }),
    on(SetHeaderLogout, (state, { status }) => {
        return {
            ...state,
            logout: status,
        }
    }),
)
