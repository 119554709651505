<section class="bottom-table-foot borderBottom" *ngIf="pagination">
  <ul>
    <li>
      <div class="pagination">
        <div class="page">
          <span>Page </span>
          <select (change)="onPageChange($event.target.value)">
            <option value="{{ page }}" *ngFor="let page of createPageRange(pagination.lastPage)"
                    [selected]="pagination.currentPage === page">{{ page }}</option>
          </select>
          <span> of {{ pagination.lastPage }} ({{ pagination.total }} total items)</span>
        </div>
      </div>
    </li>
    <li>
      <div class="showing">
        <div class="show">
          <span>Showing </span>
          <select (change)="onPerPageChange($event.target.value)">
            <option value="{{ perPage }}" *ngFor="let perPage of createPerPageRange()"
                    [selected]="pagination.perPage === perPage"> {{ perPage }}
            </option>
          </select>
          <span> items per page</span>
        </div>
      </div>
    </li>
    <li class="next-prev-btn">
      <ng-container *ngIf="pagination.currentPage > 1">
        <div class="next-btn start" (click)="onPaginationNextPreviousClick('start')">
          <span>&lt;&lt; Start</span>
        </div>
        <div class="next-btn" (click)="onPaginationNextPreviousClick('previous')">
          <span>&lt; Previous</span>
        </div>
      </ng-container>

      <div class="number">
        <span>{{ pagination.currentPage }}</span>
      </div>

      <ng-container *ngIf="pagination.lastPage > pagination.currentPage">
        <div class="next-btn" (click)="onPaginationNextPreviousClick('next')">
          <span>Next &gt;</span>
        </div>
        <div class="end-btn" (click)="onPaginationNextPreviousClick('end')">
          <span>End &gt;&gt;</span>
        </div>
      </ng-container>
    </li>
  </ul>
</section>
