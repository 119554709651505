import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { PartnerStorage, PartnerStorageSerializer } from "./partner-storage"
import { AuthUserStorage, AuthUserStorageSerializer } from "./auth-user-storage"
import { SwitchablePartner, SwitchablePartnerSerializer } from "./switchable-partner"

export class PartnerPortalDataset {
    id = 0
    partnerStorage: PartnerStorage
    partnerId: number
    authUser: AuthUserStorage
    switchablePartners: SwitchablePartner[]
}

@Injectable({
    providedIn: "root",
})
export class PartnerPortalDatasetSerializer implements ApiResourceSerializer {
    constructor(
        private partnerStorageSerializer: PartnerStorageSerializer,
        private authUserStorageSerializer: AuthUserStorageSerializer,
        private switchablePartnerSerializer: SwitchablePartnerSerializer,
    ) {}

    fromJson(json: JsonObject): PartnerPortalDataset {
        if (!json) return null
        const record = new PartnerPortalDataset()
        record.id = json.id
        record.partnerStorage = json.partner_storage
            ? this.partnerStorageSerializer.fromJson(json.partner_storage)
            : null
        record.partnerId = json.partner_id
        record.authUser = json.auth_user ? this.authUserStorageSerializer.fromJson(json.auth_user) : null
        record.switchablePartners = json.switchable_partners
            ? json.switchable_partners.map(item => this.switchablePartnerSerializer.fromJson(item))
            : null
        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: PartnerPortalDataset): any {
        return {
            id: record.id,
            partner_id: record.partnerId,
            partner_storage: record.partnerStorage ? this.partnerStorageSerializer.toJson(record.partnerStorage) : null,
            auth_user: record.authUser ? this.authUserStorageSerializer.toJson(record.authUser) : null,
            switchable_partners: record.switchablePartners
                ? record.switchablePartners.map(item => this.switchablePartnerSerializer.toJson(item))
                : null,
        }
    }
}
