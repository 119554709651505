import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core"
import { AuthUserStorage } from "@app/_models/auth-user-storage"
import { AuthenticationService } from "@app/_services/authentication.service"
import { combineLatest, Subject, takeUntil } from "rxjs"
import { addLeadingZeros } from "@app/_utils/util"
import { AlertService } from "@app/_services/alert.service"
import { LoggingService } from "@app/_services/logging.service"
import { HeaderService } from "@app/_services/header.service"

const autoLogoutConstant = 30 * 60 // 30 mins

@Component({
    selector: "app-header-timer",
    templateUrl: "./header-timer.component.html",
    styleUrls: ["./header-timer.component.scss"],
})
export class HeaderTimerComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Math: any
    currentPartnerId: number
    currentUser: AuthUserStorage
    autoLogout = 100
    private unsubscribe$ = new Subject()

    constructor(
        private authService: AuthenticationService,
        private _ngZone: NgZone,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
        private loggingService: LoggingService,
        private headerService: HeaderService,
    ) {
        this.autoLogout = autoLogoutConstant
        this.Math = Math

        combineLatest([this.authService.currentPartnerId, this.authService.currentUser])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([currentPartnerId, currentUser]) => {
                if (this.authService.isAuthenticated()) {
                    this.currentUser = currentUser
                    this.currentPartnerId = currentPartnerId
                }
            })

        this.headerService.timerResetEmitter.subscribe(res => {
            console.log("Reseting Timer", res)
            this.resetCountDown()
        })
    }

    ngOnInit(): void {
        this.countDown()
    }

    countDown(): void {
        this._ngZone.runOutsideAngular(() => {
            this._increaseProgress(() => {
                this._ngZone.run(() => {
                    console.log("Outside Done!")
                })
            })
        })
    }

    _increaseProgress(doneCallback: () => void): void {
        this.autoLogout--
        // console.log(this.autoLogout)
        this.cdr.detectChanges()

        if (this.authService.isAuthenticated() && this.autoLogout <= 0) {
            this.autoLogout = autoLogoutConstant
            this.alertService.info("You have been automatically logged out of Verofy", true, true)
            this.loggingService.logDebugInfo("AUTO LOGOUT due to inactivity")
            this.authService.logout({
                returnUrl: document.location.pathname,
                preserveDeviceUniqueId: true,
                timerLogout: true,
            })
            doneCallback()
        } else setTimeout(() => this._increaseProgress(doneCallback), 1000)
    }

    resetCountDown(): void {
        this.autoLogout = autoLogoutConstant
    }

    addLeadingZeros(num: number, length: number): string {
        return addLeadingZeros(num, length)
    }
}
