import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class Language extends ApiResource {
    id = 0
    name: string
    code: string
    isEnabled: boolean
    isDefault: boolean
}

@Injectable({
    providedIn: "root",
})
export class LanguageSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Language {
        const language = new Language()
        language.id = json.id
        language.name = json.name
        language.code = json.code
        language.isEnabled = json.is_enabled
        language.isDefault = json.is_default
        return language
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(language: Language): any {
        return {
            id: language.id,
            name: language.name,
            code: language.code,
            is_enabled: language.isEnabled,
            is_default: language.isDefault,
        }
    }
}
