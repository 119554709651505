import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-edit-icon",
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="21.784" height="21.796" viewBox="0 0 21.784 21.796">
        <g id="Group_73" data-name="Group 73" transform="translate(-1501.615 -413.314)">
            <path
                id="Path_51"
                data-name="Path 51"
                d="M1523.4,418.608a.761.761,0,0,1-.252.394c-.675.689-1.347,1.38-2.028,2.062q-4.917,4.92-9.839,9.836c-.477.477-.964.945-1.417,1.444a1.9,1.9,0,0,1-.82.522c-1.041.363-2.08.73-3.125,1.081-1.1.372-2.215.727-3.323,1.088a.84.84,0,0,1-.553.044.667.667,0,0,1-.4-.768q.762-2.337,1.529-4.674c.216-.661.446-1.318.641-1.985a1.416,1.416,0,0,1,.383-.629q3.19-3.2,6.375-6.4,3.51-3.512,7.032-7.013c.108-.108.233-.2.351-.3h.311a1.148,1.148,0,0,1,.274.15q2.176,2.144,4.34,4.3a6.987,6.987,0,0,1,.525.667Zm-17.861,8.979,3.526,3.527,10.336-10.335-3.445-3.5C1515.673,417.429,1506.121,426.894,1505.538,427.587Zm11.364-11.339,3.54,3.541,1.256-1.265s0-.006-.01-.013q-1.7-1.693-3.4-3.389a.13.13,0,0,0-.214,0Zm-13.514,16.993.037.03,4.357-1.417c-.961-1.012-1.939-1.952-2.952-2.909Z"
                fill="#575756" />
        </g>
    </svg>`,
})
export class EditIconComponent {}
