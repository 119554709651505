import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-head-set-icon",
    template: `<div>
        <svg
            version="1.1"
            id="Layer_1"
            [ngStyle]="{
                'width': width + 'px',
                'height': height + 'px',
            }"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 75 75"
            style="enable-background:new 0 0 75 75;"
            xml:space="preserve">
            <style type="text/css">
                .st0 {
                    fill: #fff;
                }
            </style>
            <path
                class="st0"
                d="M70.8,38C70.8,17,55.9,0,37.5,0C15.5,0,5.1,20.8,4.3,40.9c-0.1,0.5-0.2,1-0.2,1.5v3.5c0,6.6,5.4,12,12,12
	c3.7,0,6.8-3,6.8-6.8v-14c0-3.7-3.1-6.8-6.8-6.8c-2,0-3.9,0.5-5.5,1.4c3-14.2,11.6-26.6,26.9-26.6c14.3,0,26.1,12.5,27.8,28.6
	c-2.2-2.1-5.1-3.4-8.4-3.4c-3.7,0-6.8,3-6.8,6.8v14c0,3.7,3.1,6.8,6.8,6.8c1,0,2-0.2,2.9-0.4c-2.7,4.1-6.2,7.4-10.1,9.3
	c-0.6-2.2-2.5-3.9-5-3.9h-6.2c-2.9,0-5.2,2.4-5.2,5.2v1.6c0,2.9,2.3,5.2,5.2,5.2h6.2c1.7,0,3.2-0.9,4.1-2.2
	C61.4,68.7,70.8,54.5,70.8,38z" />
        </svg>
    </div>`,
})
export class HeadSetComponent {
    @Input() width: number = 17
    @Input() height: number = 17
}
