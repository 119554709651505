import { ApiResource } from "@app/_models/api-resource"
import { Injectable, Injector } from "@angular/core"
import { ApiResourceSerializer } from "@app/_core/api-resource.serializer"
import { JsonObject } from "@app/_core/types"
import { SellerPermission, SellerPermissionSerializer } from "@app/_models/seller-permission"

export class SellerPermissionCategory extends ApiResource {
    id: number = 0
    name: string
    permissions: SellerPermission[]
}

@Injectable({
    providedIn: "root",
})
export class SellerPermissionCategorySerializer implements ApiResourceSerializer {
    constructor(private injector: Injector) {}
    fromJson(json: JsonObject): SellerPermissionCategory {
        const sellerPermissionSerializer = this.injector.get(SellerPermissionSerializer)
        const record = new SellerPermissionCategory()
        record.id = json.id
        record.name = json.name
        record.permissions =
            json.permissions !== undefined
                ? json.permissions.map(item => sellerPermissionSerializer.fromJson(item))
                : []
        return record
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(record: SellerPermissionCategory): any {
        const sellerPermissionSerializer = this.injector.get(SellerPermissionSerializer)
        return {
            id: record.id,
            name: record.name,
            permissions: record.permissions
                ? record.permissions.map(item => sellerPermissionSerializer.toJson(item))
                : null,
        }
    }
}
