<!-- <div [class.scrollable]="isScrollable">
</div> -->

<!-- Header -->
<header class="headerFixed">
  <app-header></app-header>
</header>
<!-- Header -->

<!-- Body -->
<app-alert instance="main"></app-alert>

<app-http-request-observe *ngIf="isHeaderActive" [routerOutputRef]="routerOutlet"
  class="httpRequestObserveContainer"></app-http-request-observe>

<div #routerOutlet [ngStyle]="{ 'margin-top': routerOutletMt }"
     [ngClass]="[(isHeaderActive ? '': 'routerBackground'),  'routerOutlet', (isPageAnimated | async) === true ? 'animatePage' : '', (isPageInitialize | async) === true ? 'initialize' : '']">
<router-outlet *ngIf="(initializeHeader && !refreshingToken) || isLogin"></router-outlet>

<!-- Body -->

<!-- Footer -->
<footer class="footer mt-auto py-3" [ngClass]="{ 'appFooter': activeRoute === 'pin-reset' }" *ngIf="isFooterActive">
  <app-footer></app-footer>
</footer>
</div>
<!-- Footer -->
