import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"
import { AuthenticationService } from "@app/_services/authentication.service"
import { AppError } from "../_core/app-error"
import { NotFoundError } from "../_core/not-found-error"
import { BadRequestError } from "../_core/bad-request-error"
import { ValidationError } from "../_core/validation-error"
import { ForbiddenError } from "../_core/forbidden-error"

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) {}

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // retry(1),
            catchError((err: HttpErrorResponse) => {
                // console.log("Intercepter", err)
                if (err.status === 400) {
                    return throwError(() => new BadRequestError(err))
                } else if (err.status === 401) {
                    // Auto logout if 401 response returned from api
                    this.authService.logout({
                        returnUrl: null,
                        preserveDeviceUniqueId: true,
                        timerLogout: false,
                    })
                    location.reload()
                    return
                } else if (err.status === 404) {
                    return throwError(() => new NotFoundError(err))
                } else if (err.status === 403) {
                    return throwError(() => new ForbiddenError(err))
                } else if (err.status === 422) {
                    return throwError(() => new ValidationError(err))
                }
                return throwError(() => new AppError(err))
            }),
        )
    }
}
