import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class UserPhoneChangeVerification {
    id: number = 0
    message: string
    status: string
    success: boolean
    data: {
        requestId: number
        createdAt: Date
        updatedAt: Date
        data: {
            phoneNumber: string
        }
        status: number
        statusLabel: string
        type: string
        typeLabel: string
    }
}

@Injectable({
    providedIn: "root",
})
export class UserPhoneChangeVerificationSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): UserPhoneChangeVerification {
        const record = new UserPhoneChangeVerification()
        record.message = json.message
        record.status = json.status
        record.success = json.success
        record.data = {
            requestId: json.data.id,
            createdAt: new Date(json.data.created_at),
            updatedAt: new Date(json.data.updated_at),
            data: {
                phoneNumber: json.data.data.phone_number,
            },
            status: json.data.status,
            statusLabel: json.data.status_label,
            type: json.data.type,
            typeLabel: json.data.type_label,
        }
        return record
    }

    // TODO Need to check
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    toJson(record: UserPhoneChangeVerification): any {
        return {
            message: record.message,
            status: record.status,
            success: record.success,
            data: {
                id: record.data.requestId,
                created_at: record.data.createdAt,
                updated_at: record.data.updatedAt,
                data: {
                    phone_number: record.data.data.phoneNumber,
                },
                status: record.data.status,
                status_label: record.data.statusLabel,
                type: record.data.type,
                type_label: record.data.typeLabel,
            },
        }
    }
}
