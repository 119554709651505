import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { catchError, exhaustMap, map, take } from "rxjs/operators"
import { createAction, props } from "@ngrx/store"
import { AlertService } from "@app/_services/alert.service"
import { SetDashboardTerminalCountDailyWidgetAction } from "app/store/actions/DashboardActions"
import { WidgetTransactionsTotalCountDaily } from "app/_models/widget-transactions-total-count-daily"
import { DatePipe } from "@angular/common"
import { WidgetTransactionsTotalCountDailyApiService } from "app/_services/api/widget-transactions-total-count-daily-api.service"
import { EChartsOption, SeriesOption } from "echarts"
import { DashboardWidgetColor } from "@app/interfaces/dashboard/dashboardWidgetColor"
import { YAXisOption } from "echarts/types/dist/shared"
import HelperVerofy from "@appHelpers/helper-verofy"

export const FetchTerminalCountDailyData = createAction(
    "DashboardTerminalCountDailyDaily",
    props<{ partnerId: number }>(),
)

@Injectable()
export class DashboardTerminalCountDailyEffects {
    hasData: boolean = false

    loadData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FetchTerminalCountDailyData),
                exhaustMap(action =>
                    this.totalCountDailyApiService
                        .Dashboardlist(action.partnerId)
                        .pipe(take(1))
                        .pipe(
                            map(payload => {
                                const datePipe: DatePipe = new DatePipe("en-US")
                                const totalCountDaily: WidgetTransactionsTotalCountDaily[] = payload

                                let bar = []
                                const data = []

                                totalCountDaily.forEach(record => {
                                    bar.push(datePipe.transform(record.date, "d.MM.YY"))
                                    data.push(record.totalCount ? record.totalCount : 0)
                                })

                                // Display only the first six months of the selected period
                                if (bar.length > 6) bar = bar.slice(0, 6)

                                const hasNoData = data.every(item => item === 0)

                                if (hasNoData) this.hasData = false
                                else this.hasData = true

                                let yAxisOption: YAXisOption | YAXisOption[] = {}
                                let isDefault = true
                                if (data.length > 0) {
                                    for (const currentData of data) {
                                        if (parseInt(currentData, 10) > 0) {
                                            isDefault = false
                                            break
                                        }
                                    }
                                }

                                const currentSeries: SeriesOption[] = [
                                    {
                                        name: "Transactions:",
                                        type: "bar",
                                        data,
                                        animationDelay: +(idx => idx * 10),
                                    },
                                ]

                                if (isDefault) {
                                    yAxisOption = {
                                        type: "category",
                                        boundaryGap: false,
                                        axisLabel: {
                                            fontSize: 11,
                                            formatter(value) {
                                                return HelperVerofy.numberWithCommas(value)
                                            },
                                        },
                                        data: [0, 100],
                                    }
                                } else {
                                    yAxisOption = {
                                        type: "value",
                                        axisLabel: {
                                            fontSize: 11,
                                            formatter(value) {
                                                if (value === 0) return "0"
                                                else return HelperVerofy.numberWithCommas(value)
                                            },
                                        },
                                    }
                                }

                                const totalCountDailyOption: EChartsOption = {
                                    legend: {
                                        data: [],
                                        align: "left",
                                        orient: "horizontal",
                                        top: "10px",
                                        left: "0",
                                    },
                                    title: {
                                        text: this.hasData
                                            ? ""
                                            : "There is currently no transactional data to display.",
                                        subtext: "",
                                        left: "center",
                                        top: "42%",
                                        textStyle: {
                                            fontSize: 12,
                                            opacity: 0.5,
                                            fontWeight: 400,
                                        },
                                    },
                                    color: [DashboardWidgetColor.Real],
                                    tooltip: {
                                        trigger: "axis",
                                        axisPointer: {
                                            axis: "auto",
                                            type: "none",
                                        },
                                        textStyle: {
                                            fontSize: 12,
                                        },
                                        formatter: function (params) {
                                            const value = params[0].value
                                            const title = params[0].axisValue
                                            const originalMarker = params[0].marker
                                            const color = params[0].color
                                            const formattedValue = value === undefined ? 0 : value

                                            // Combine the original marker and custom content
                                            return (
                                                `<strong> ${title} </strong>` +
                                                "<br/>" +
                                                originalMarker +
                                                "Transactions: " +
                                                '<span style="color:' +
                                                color +
                                                '"><strong>' +
                                                formattedValue +
                                                "</strong></span>"
                                            )
                                        },
                                    },
                                    xAxis: {
                                        data: bar,
                                        silent: false,
                                        splitLine: {
                                            show: true,
                                        },
                                        axisLabel: {
                                            fontSize: 11,
                                        },
                                    },
                                    toolbox: {
                                        top: 0,
                                        right: 0,
                                        feature: {
                                            saveAsImage: {
                                                emphasis: {
                                                    iconStyle: {
                                                        textFill: "#307AFF",
                                                        borderColor: "#307AFF",
                                                    },
                                                },
                                                title: "Download report",
                                                name: "Verofy Transactions Total Count Daily",
                                            },
                                        },
                                    },
                                    yAxis: {
                                        ...yAxisOption,
                                    },
                                    series: currentSeries,
                                    animationEasing: "elasticOut",
                                    animationDelayUpdate: +(idx => idx * 5),
                                    grid: {
                                        containLabel: true,
                                        left: 0,
                                    },
                                }
                                return SetDashboardTerminalCountDailyWidgetAction({
                                    data: totalCountDailyOption,
                                })
                            }),
                            catchError(error => {
                                this.alertService.error("Unable to load data, please refresh the page", false, true)
                                throw error
                            }),
                        ),
                ),
            )
        },
        { dispatch: true },
    )

    constructor(
        private actions$: Actions,
        private alertService: AlertService,
        private totalCountDailyApiService: WidgetTransactionsTotalCountDailyApiService,
    ) {}
}
