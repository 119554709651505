import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Tax, TaxSerializer } from "./tax"
import { JsonObject } from "../_core/types"

export class ProductPrice {
    id = 0
    createdAt: Date
    updatedAt: Date
    name: string
    type: number
    regularity: number
    contractLength: number
    priceBuy: number
    priceSell: number
    priceFloor: number
    priceMax: number
    priceBuyTax: Tax
    priceSellTax: Tax
    priceValue: number
}

@Injectable({
    providedIn: "root",
})
export class ProductPriceSerializer implements ApiResourceSerializer {
    constructor(private taxSerializer: TaxSerializer) {}

    fromJson(json: JsonObject): ProductPrice {
        const record = new ProductPrice()
        record.id = json.id
        record.createdAt = new Date(json.created_at)
        record.updatedAt = new Date(json.updated_at)
        record.name = json.name
        record.type = json.type
        record.regularity = json.regularity
        record.contractLength = json.contract_length
        record.priceBuy = json.price_buy
        record.priceSell = json.price_sell
        record.priceFloor = json.price_floor
        record.priceMax = json.price_max
        record.priceBuyTax =
            json.price_buy_tax !== undefined && json.price_buy_tax !== null
                ? this.taxSerializer.fromJson(json.price_buy_tax)
                : null
        record.priceSellTax =
            json.price_sell_tax !== undefined && json.price_sell_tax !== null
                ? this.taxSerializer.fromJson(json.price_sell_tax)
                : null
        record.priceValue = json.price_buy

        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: ProductPrice): any {
        return {
            id: record.id,
            created_at: record.createdAt,
            updated_at: record.updatedAt,
            name: record.name,
            type: record.type,
            regularity: record.regularity,
            contract_length: record.contractLength,
            price_sell: record.priceSell,
            price_floor: record.priceFloor,
            price_max: record.priceMax,
            price_buy_tax:
                record.priceBuyTax !== undefined && record.priceBuyTax !== null
                    ? this.taxSerializer.toJson(record.priceBuyTax)
                    : null,
            price_sell_tax:
                record.priceSellTax !== undefined && record.priceSellTax !== null
                    ? this.taxSerializer.toJson(record.priceSellTax)
                    : null,
            price_buy: record.priceValue,
        }
    }
}
