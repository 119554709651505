import { Injectable } from "@angular/core"

@Injectable({
    providedIn: "root",
})
export class DateRangeService {
    constructor() {}

    getMonday(date: Date) {
        const day = date.getDay()
        const diff = date.getDate() - day + (day === 0 ? -6 : 1)
        const monday = new Date(date.setDate(diff))
        return monday
    }

    yesterday() {
        const yesterdayDate = new Date()
        yesterdayDate.setDate(yesterdayDate.getDate() - 1)
        return { fromDate: yesterdayDate, toDate: yesterdayDate, exactDate: yesterdayDate }
    }

    todayDay() {
        const todayDate = new Date()
        return { fromDate: todayDate, toDate: todayDate, exactDate: todayDate }
    }

    thisWeek() {
        const todayDate = new Date()
        const monday = this.getMonday(todayDate)
        return { fromDate: monday, toDate: new Date() }
    }

    lastWeek() {
        const todayDate = new Date()
        const monday = this.getMonday(todayDate)
        monday.setDate(monday.getDate() - 7)
        const sunday = new Date(monday)
        sunday.setDate(monday.getDate() + 6)
        return { fromDate: monday, toDate: sunday }
    }

    thisMonth() {
        const todayDate = new Date()
        const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
        const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0)
        return { fromDate: firstDay, toDate: lastDay }
    }

    previousMonth() {
        const todayDate = new Date()
        const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1)
        const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)
        return { fromDate: firstDay, toDate: lastDay }
    }
}
