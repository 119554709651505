import { CRRegistration } from "./../_models/cr-registration"
import { SetDashboardCustomerId } from "./../store/actions/DashboardActions"
import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { environment } from "../../environments/environment"
import { BehaviorSubject, Observable, Subject, Subscriber, Subscription, forkJoin, throwError, timer } from "rxjs"
import { flatMap, map } from "rxjs/operators"
import { Router } from "@angular/router"
import { JwtHelperService } from "@auth0/angular-jwt"
import { AlertService } from "./alert.service"
import { AppError } from "../_core/app-error"
import { AuthUserStorage, AuthUserStorageSerializer } from "../_models/auth-user-storage"
import VerofyHelper from "../_helpers/verofy.helper"
import { CurrentPartnerService } from "./current-partner.service"
import { LocalStorageService } from "./local-storage.service"
import { CurrentSwitchablePartnersService } from "./current-switchable-partners.service"
import { AppState } from "@app/store"
import { Store } from "@ngrx/store"
import { CRProductsAndServicesService } from "./cr-product-and-services.service"
import { AuthUserCheck, AuthUserCheckSerializer } from "@app/_models/auth-user-check"
import { AuthUserVerification, AuthUserVerificationSerializer } from "@app/_models/auth-user-verification"
import { SetHideAppLoadingGlobal } from "@appStore/actions/AppLoadingActions"
import { JsonObject } from "@appCore/types"
import { SetHttpLogout } from "@app/store/actions/AppHttpActions"
import { CRProductsAndPacks, CRProductsAndPacksSerializer } from "@app/_models/cr-products-and-packs"
import { PartnerStorage, PartnerStorageSerializer } from "@app/_models/partner-storage"
import { SwitchablePartner, SwitchablePartnerSerializer } from "@app/_models/switchable-partner"
import { PartnerPortalDataset, PartnerPortalDatasetSerializer } from "@app/_models/partner-portal-dataset"
import { EnumsService } from "@appServices/enums.service"
import { AuthUserSignal } from "@app/signals/customerOnboarding/AuthUserSignal"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let $: any

@Injectable()
export class AuthenticationService {
    switchedPartnerSubject = new Subject<boolean>()

    private currentUserSubject: BehaviorSubject<AuthUserStorage>
    public currentUser: Observable<AuthUserStorage>

    private currentPartnerIdSubject: BehaviorSubject<number>
    public currentPartnerId: Observable<number>

    private partnerPortalDatasetSubject: BehaviorSubject<PartnerPortalDataset>
    public partnerPortalDataset: Observable<PartnerPortalDataset>

    private sms2FaToken: string

    navigationEndUrl: string

    constructor(
        private http: HttpClient,
        private authUserSerializer: AuthUserStorageSerializer,
        private authUserCheckSerializer: AuthUserCheckSerializer,
        private currentSwitchablePartnersService: CurrentSwitchablePartnersService,
        private authUserVerificationSerializer: AuthUserVerificationSerializer,
        private router: Router,
        private jwtHelper: JwtHelperService,
        private currentPartnerService: CurrentPartnerService,
        private cRProductsAndServicesService: CRProductsAndServicesService,
        private cRProductsAndPacksSerializer: CRProductsAndPacksSerializer,
        private partnerSerializer: PartnerStorageSerializer,
        private localStorageService: LocalStorageService,
        private alertService: AlertService,
        private store: Store<AppState>,
        private switchablePartnerSerializer: SwitchablePartnerSerializer,
        private partnerPortalDatasetSerializer: PartnerPortalDatasetSerializer,
        private enumsService: EnumsService,
    ) {
        this.navigationEndUrl = this.router.url

        this.currentUserSubject = new BehaviorSubject<AuthUserStorage>(
            this.authUserSerializer.fromJson(JSON.parse(this.localStorageService.getData("authUser"))),
        )

        this.currentUser = this.currentUserSubject.asObservable()

        this.partnerPortalDatasetSubject = new BehaviorSubject<PartnerPortalDataset>(
            this.partnerPortalDatasetSerializer.fromJson(
                JSON.parse(this.localStorageService.getData("partnerPortalDatase")),
            ),
        )

        this.partnerPortalDataset = this.partnerPortalDatasetSubject.asObservable()

        this.currentPartnerIdSubject = new BehaviorSubject<number>(
            JSON.parse(this.localStorageService.getData("currentPartnerId")),
        )

        this.currentPartnerId = this.currentPartnerIdSubject.asObservable()
    }

    public get currentUserValue(): AuthUserStorage {
        return this.currentUserSubject.value
    }

    public get currentPartnerIdValue(): number {
        return this.currentPartnerIdSubject.value
    }

    public get customerPortalDatasetValue(): PartnerPortalDataset {
        return this.partnerPortalDatasetSubject.value
    }

    private setPartnerPortalDatasetValue(value: PartnerPortalDataset): void {
        this.partnerPortalDatasetSubject.next(value)
        AuthUserSignal.update(() => value)
    }

    public setSms2FaToken(token: string): void {
        this.sms2FaToken = token
    }

    public getAccessToken(): string {
        const user = this.currentUserValue
        return user ? user.accessToken : null
    }

    public getRefreshToken(): string {
        const user = this.currentUserValue
        return user ? user.refreshToken : null
    }

    /**
     * Stores device unique ID into the local storage.
     */
    public setDeviceUniqueId(deviceId: string): void {
        this.localStorageService.saveData("deviceUniqueId", JSON.stringify(deviceId))
    }

    /**
     * Returns device unique ID from the local storage.
     */
    public getDeviceUniqueId(): string {
        let deviceUniqueId = this.localStorageService.getData("deviceUniqueId")
        if (deviceUniqueId === undefined || deviceUniqueId === null) {
            deviceUniqueId = VerofyHelper.guid()
            this.setDeviceUniqueId(deviceUniqueId)
        } else {
            deviceUniqueId = JSON.parse(deviceUniqueId)
        }
        return deviceUniqueId
    }

    /**
     * Returns true if there is a valid token in the local storage
     */
    public isAuthenticated(): boolean {
        const accessToken = this.getAccessToken()
        // Return a boolean reflecting whether or not the token is expired.
        return !this.jwtHelper.isTokenExpired(accessToken)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refreshAuthToken(): Observable<any> {
        const url = environment.mainApiUrl + "refresh-token"
        return this.http.put(url, { "refresh_token": this.currentUserValue?.refreshToken ?? "" }).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((response: any) => {
                const data = this.authUserSerializer.fromJson(response.data)
                if (!data.accessToken || !data.refreshToken) {
                    throwError(() => new Error("No auth token."))
                }

                const user = this.currentUserValue
                user.accessToken = data.accessToken
                user.refreshToken = data.refreshToken
                // this.setCurrentUser(user)
                return user
            }),
        )
    }

    checkValidToken(): Subscription {
        const url = environment.mainApiUrl + "account-security/valid-token"
        return this.http.get(url).subscribe()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAuthUser(partnerId: number): Observable<any> {
        let params = new HttpParams()
        params = params.append("partner_id", String(partnerId))
        return this.http.get(environment.mainApiUrl + "auth/user", { params })
    }

    userCheck(data: { [param: string]: string | string[] }): Observable<AuthUserCheck> {
        const url = environment.mainApi.url + "auth/user-check"
        return this.http
            .post(url, data)
            .pipe(map((response: AuthUserCheck) => this.authUserCheckSerializer.fromJson(response)))
    }

    twoFactorVerification(data: { [param: string]: string | string[] }): Observable<AuthUserVerification> {
        const url = environment.mainApi.url + "auth/2fa-verification"
        return this.http
            .post(url, data)
            .pipe(map((response: AuthUserVerification) => this.authUserVerificationSerializer.fromJson(response)))
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pinVerification(data: { [param: string]: string | string[] }): Observable<any> {
        const url = environment.mainApi.url + "auth/pin-verification"
        return this.http.post(url, data)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    login(data: { [param: string]: string | string[] }): Observable<any> {
        const url = environment.mainApi.url + "auth/login"
        return this.http.post(url, data).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            flatMap((response: any) => {
                console.log(response)
                const user = this.authUserSerializer.fromJson(response.data)
                if (!user.accessToken || !user?.refreshToken) {
                    return throwError("No auth token.")
                }
                this.initAuthUser(user)
                return new Observable<boolean>(observer => {
                    observer.next(true)
                })
            }),
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    secondFactor(user: AuthUserStorage): Observable<any> {
        if (user.requires2Fa === false) {
            this.initAuthUser(user)
            return new Observable<boolean>(observer => {
                observer.next(true)
            })
        } else {
            if (user.req2FaMethod === "one_touch") {
                return this.oneTouch2FALogin(user)
            } else {
                return this.sms2FALogin(user)
            }
        }
    }

    switchPartner(partnerId: number, redirectAfter: string[] = null): void {
        this.fetchPartnerData(partnerId, redirectAfter)
    }

    fetchPartnerData(partnerId: number, redirectAfter: string[] = null): void {
        forkJoin([
            this.currentPartnerService.preparePartner(partnerId),
            this.currentSwitchablePartnersService.prepareSwitchablePartners(),
            this.getAuthUser(partnerId),
        ]).subscribe({
            next: ([partner, switchablePartners, authUser]) => {
                console.log("Currently fetched partner ID: ", partnerId)
                // Set up the application IDs
                this.setCurrentPartnerId(partnerId)

                this.setPartner(partner)

                this.setSwitchablePartners(switchablePartners)

                const user = this.authUserSerializer.fromJson(authUser.data)
                this.reloadAuthUserPermissions(user)

                this.saveCurrentDataset(partner, switchablePartners, partnerId, user)
                this.enumsService.loadEnums(partnerId)

                if (redirectAfter) this.router.navigate(redirectAfter)
                this.store.dispatch(SetHideAppLoadingGlobal())
                console.log("Partner portal data-set fetched")
            },
            error: error => {
                this.alertService.userError(error, "We're sorry but an unexpected error occurred", false, true)
                this.store.dispatch(SetHideAppLoadingGlobal())
                throw error
            },
        })

        this.cRProductsAndServicesService.loadProductsAndServices(CRRegistration.DEFAULT_TERM_LENGTH, partnerId)
    }

    saveCurrentDataset(
        partnerStorage: PartnerStorage,
        switchablePartners: SwitchablePartner[],
        partnerId: number,
        authUser: AuthUserStorage,
    ): void {
        const customerPortalDataset: PartnerPortalDataset = new PartnerPortalDataset()
        customerPortalDataset.partnerStorage = partnerStorage
        customerPortalDataset.partnerId = partnerId
        customerPortalDataset.authUser = authUser
        customerPortalDataset.switchablePartners = switchablePartners
        this.localStorageService.saveData(
            "partnerPortalDatase",
            JSON.stringify(this.partnerPortalDatasetSerializer.toJson(customerPortalDataset)),
        )
        this.setPartnerPortalDatasetValue(customerPortalDataset)
        console.log(customerPortalDataset)
    }

    setSwitchablePartners(switchablePartners: SwitchablePartner[]): void {
        const json = switchablePartners.map(item => this.switchablePartnerSerializer.toJson(item))
        this.localStorageService.saveData("currentSwitchablePartners", JSON.stringify(json))
        this.currentSwitchablePartnersService.setSwitchablePartners(switchablePartners)
    }

    setProductsAndServices(productsAndServices: CRProductsAndPacks): void {
        const json = this.cRProductsAndPacksSerializer.toJson(productsAndServices)
        this.localStorageService.saveData("crProductsAndServices", JSON.stringify(json))
        this.cRProductsAndServicesService.setCurrentProductsAndServices(productsAndServices)
    }

    setPartner(partner: PartnerStorage): void {
        const partnerJson = this.partnerSerializer.toJson(partner)
        this.localStorageService.saveData("currentPartner", JSON.stringify(partnerJson))
        this.currentPartnerService.setPartner(partner)
    }

    setCurrentPartnerId(partnerId: number): void {
        this.localStorageService.saveData("currentPartnerId", String(partnerId))
        this.store.dispatch(SetDashboardCustomerId({ id: partnerId }))
        this.currentPartnerIdSubject.next(partnerId)
        this.switchedPartnerSubject.next(true)
    }

    setCurrentUser(user: AuthUserStorage): void {
        const userJson = this.authUserSerializer.toJson(user)
        this.localStorageService.saveData("authUser", JSON.stringify(userJson))
        this.currentUserSubject.next(user)
    }

    /**
     * Reloads authenticated user permissions.
     */
    reloadAuthUserPermissions(authUser: AuthUserStorage): void {
        // Re-load current user permissions.
        console.log("New user: ", authUser)
        console.log("Currently logged: ", this.currentUserValue)
        const user = { ...this.currentUserValue }
        user.permissions = authUser.permissions
        user.branding = authUser.branding
        const newUser = new AuthUserStorage()
        Object.assign(newUser, user)
        console.log("Final merge: ", newUser)
        this.setCurrentUser(newUser)
    }

    initAuthUser(user: AuthUserStorage): void {
        // Store user details and basic auth credentials in local storage to keep user logged in between page refreshes.
        console.log("Currently initialized user:", user)
        this.setCurrentUser(user)

        const partnerId = this.getPartnerIdFromUser(user)
        this.fetchPartnerData(partnerId)
    }

    getPartnerIdFromUser(user: AuthUserStorage): number {
        let partnerId: number | null = null
        if (user.defaultPartnerId !== undefined) {
            partnerId = user.defaultPartnerId
        } else {
            if (user.partnersIds !== undefined) {
                partnerId = user.partnersIds[0]
            }
        }
        return partnerId
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oneTouch2FALogin(user: AuthUserStorage): Observable<any> {
        // Show modal window.
        $("#2faLoginModal").modal({
            backdrop: "static",
            keyboard: false,
        })

        const twoFactorAuthRequestId = user.twoFactorAuthRequestId

        const tick: Observable<number> = timer(1000, 1000)
        return new Observable(subject => {
            let tock = 0
            const timerSubscription = tick.subscribe(() => {
                tock++

                if (tock % 2 === 0) {
                    this.oneTouchStatus(twoFactorAuthRequestId).subscribe({
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        next: (response: any) => {
                            if (response.data.verification.status === "approved") {
                                this.initAuthUser(user)
                                this.closeSecondFactorObservables(subject, true, timerSubscription)
                            } else if (response.data.verification.status === "denied") {
                                this.closeSecondFactorObservables(subject, false, timerSubscription)
                                this.alertService.error("Login denied, please try again")
                            }
                        },
                        error: (error: AppError) => {
                            this.closeSecondFactorObservables(subject, false, timerSubscription)
                            this.alertService.userError(error, "We're sorry but we're unable to login (2FA failed).")
                            throw error
                        },
                    })
                }

                if (tock === 60) {
                    this.closeSecondFactorObservables(subject, false, timerSubscription)
                    this.alertService.error("Time out, please try again")
                }
            })
        })
    }

    /**
     * Keeps trying to verify Authy 2FA SMS token for 120 seconds.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sms2FALogin(user: AuthUserStorage): Observable<any> {
        // show modal window
        $("#2faSmsLoginModal").modal({
            backdrop: "static",
            keyboard: false,
        })

        const tick: Observable<number> = timer(1000, 1000)
        return new Observable(subject => {
            let tock = 0
            const timerSubscription = tick.subscribe(() => {
                tock++

                if (this.sms2FaToken !== undefined && this.sms2FaToken !== "") {
                    // Stop timer - double call prevention.
                    timerSubscription.unsubscribe()

                    this.verifySmsToken(user.twoFactorAuthRequestId, this.sms2FaToken).subscribe({
                        next: () => {
                            this.initAuthUser(user)
                            this.closeSecondFactorObservables(subject, true, timerSubscription)
                        },

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore

                        error: (error: AppError) => {
                            this.closeSecondFactorObservables(subject, false, timerSubscription)
                            this.alertService.userError(error, "We're sorry but we're unable to login (2FA failed).")
                        },
                    })
                }

                if (tock === 120) {
                    this.closeSecondFactorObservables(subject, false, timerSubscription)
                    this.alertService.error("Time out, please try again")
                }
            })
        })
    }

    /**
     * Logs out current user from the system
     */
    logout(attributes: JsonObject = {}): Subscription {
        const params = {
            ...{
                returnUrl: null,
                preserveDeviceUniqueId: false,
                timerLogout: false,
            },
            ...attributes,
        }

        this.store.dispatch(SetHttpLogout({ isLogout: true }))
        this.alertService.clear()

        const url = environment.mainApiUrl + environment.mainApiLogout
        return this.http.put(url, { "timer_logout": params.timerLogout }).subscribe({
            next: (response: JsonObject) => {
                if (response && response.status === "OK") {
                    this.alertService.success(
                        response.message ? response.message : "You have been successfully logged out",
                        true,
                        true,
                    )
                }
                this.store.dispatch(SetHideAppLoadingGlobal())
                this.finishLogout(params.returnUrl, params.preserveDeviceUniqueId)
            },
            error: () => {
                this.store.dispatch(SetHideAppLoadingGlobal())
                this.finishLogout(params.returnUrl, params.preserveDeviceUniqueId)
            },
        })
    }

    /**
     * Clears local storage and redirects back to the login page
     */
    finishLogout(returnUrl: string = null, preserveDeviceUniqueId = false): void {
        const preserveKeys = ["storageVersion"]
        if (preserveDeviceUniqueId === true) preserveKeys.push("deviceUniqueId")

        this.localStorageService.removeAllDataWithException(preserveKeys)

        this.currentUserSubject.next(null)
        this.currentPartnerIdSubject.next(null)
        // this.store.dispatch(SetHttpLogout({ isLogout: false }))

        if (returnUrl) {
            // this.router.navigate(["/login"], { queryParams: { returnUrl } })
            this.router.navigate(["/login"])
        } else {
            this.router.navigate(["/login"])
        }
    }

    /**
     * Verifies Authy 2FA UUID received by push notification.
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oneTouchStatus(twoFactorAuthRequestId: number): Observable<any> {
        const data = {
            two_factor_auth_request_id: twoFactorAuthRequestId,
        }
        const url = environment.mainApi.url + "account-security/2fa-verification"
        return this.http.post(url, data)
    }
    resendVerificationCode(data: { [param: string]: string | string[] }): Observable<JsonObject> {
        const url = environment.mainApi.url + "auth/2fa"
        return this.http.post(url, data)
    }

    /**
     * Verifies Authy 2FA token received by SMS.
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    verifySmsToken(twoFactorAuthRequestId: number, smsToken: string): Observable<any> {
        const data = {
            two_factor_auth_request_id: twoFactorAuthRequestId,
            verification_code: smsToken,
        }
        const url = environment.mainApi.url + "account-security/2fa-verification"
        return this.http.post(url, data)
    }

    private closeSecondFactorObservables(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        subject: Subscriber<any>,
        result: boolean,
        timerSubscription: Subscription,
    ): void {
        // Close 2FA modal.
        $("#2faLoginModal").modal("hide")
        $("#2faSmsLoginModal").modal("hide")

        subject.next(result)
        subject.complete()
        timerSubscription.unsubscribe()
    }
}
