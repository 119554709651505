import { DashboardGridConfig } from "../_components/layout/home/home.component"
import { DisplayGrid, GridsterItem, GridType } from "angular-gridster2"
// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toInteger = (value: any): number => parseInt(`${value}`, 10)

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toString = (value: any): string => (value !== undefined && value !== null ? `${value}` : "")

export const getValueInRange = (value: number, max: number, min = 0): number => Math.max(Math.min(value, max), min)

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (value: any): value is string => typeof value === "string"

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any): value is number => !isNaN(toInteger(value))

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInteger = (value: any): value is number =>
    typeof value === "number" && isFinite(value) && Math.floor(value) === value

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDefined = (value: any): boolean => value !== undefined && value !== null

export const padNumber = (value: number): string => {
    if (isNumber(value)) {
        return `0${value}`.slice(-2)
    } else {
        return ""
    }
}

export const addLeadingZeros = (num: number, length: number): string => {
    if (num < 0) {
        const withoutMinus = String(num).slice(1)
        return "-" + withoutMinus.padStart(length, "0")
    }

    return String(num).padStart(length, "0")
}

export const regExpEscape = (text: string): string => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasClassName = (element: any, className: string): boolean =>
    element && element.className && element.className.split && element.className.split(/\s+/).indexOf(className) >= 0

if (typeof Element !== "undefined" && !Element.prototype.closest) {
    // Polyfill for ie10+

    if (!Element.prototype.matches) {
        // IE uses the non-standard name: msMatchesSelector
        Element.prototype.matches =
            // TODO
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector
    }

    Element.prototype.closest = function (s: string) {
        let self = this
        if (!document.documentElement.contains(self)) {
            return null
        }
        do {
            if (self.matches(s)) {
                return self
            }
            self = self.parentElement || self.parentNode
        } while (self !== null && self.nodeType === 1)
        return null
    }
}

export const closest = (element: HTMLElement, selector: string): HTMLElement | null => {
    if (!selector) {
        return null
    }

    return element.closest(selector)
}

export const snakeCaseToCamelCase = (input: string): string =>
    input
        .split("_")
        .reduce(
            (res, word, i) =>
                i === 0 ? word.toLowerCase() : `${res}${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`,
            "",
        )

export const camelCaseToSnakeCase = (input: string): string =>
    input.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

/**
 * Assigns all properties existing in obj1 from obj2.
 */
// TODO
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ObjectAssignExistingProps = (obj1: any, obj2: any): any => {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(obj2).forEach((key: any): any => {
        if (key in obj1) {
            // or Object.prototype.hasOwnProperty.call(obj1, key)
            obj1[key] = obj2[key]
        }
    })

    return obj1
}

export const getDefaultDashboardSetting = (): Array<GridsterItem> => {
    return [
        { cols: 2, rows: 1, y: 0, x: 0, id: 13, isHidden: false },
        { cols: 1, rows: 1, y: 1, x: 0, id: 23, isHidden: false },
        { cols: 1, rows: 1, y: 1, x: 1, id: 12, isHidden: false },
        { cols: 1, rows: 1, y: 2, x: 0, id: 3, isHidden: false },
        { cols: 1, rows: 1, y: 2, x: 1, id: 7, isHidden: false },
        { cols: 1, rows: 1, y: 3, x: 0, id: 4, isHidden: false },
        { cols: 1, rows: 1, y: 3, x: 1, id: 6, isHidden: false },
        { cols: 1, rows: 1, y: 0, x: 0, id: 24, isHidden: false },
        { cols: 1, rows: 1, y: 0, x: 0, id: 25, isHidden: false },
    ]
}
export const getNewDashboard = (): Array<GridsterItem> => {
    return [{ cols: 1, rows: 1, y: 5, x: 0, id: 12, isHidden: false }]
}

export const getDisableDashboard = (): Array<GridsterItem> => {
    return [
        { cols: 1, rows: 1, y: 5, x: 0, id: 5, isHidden: false },
        { cols: 1, rows: 1, y: 5, x: 0, id: 8, isHidden: false },
        { cols: 1, rows: 1, y: 5, x: 0, id: 9, isHidden: false },
        { cols: 1, rows: 1, y: 5, x: 0, id: 10, isHidden: false },
    ]
}
export const getActiveDashboardItems = (availableWidgets: Array<GridsterItem>): Array<GridsterItem> => {
    return availableWidgets.filter(currentWidget => !currentWidget.isHidden)
}

export const getGridSterDefaultOptions = (currentOption, rowHeight): DashboardGridConfig => {
    return {
        ...currentOption,
        gridType: GridType.VerticalFixed,
        displayGrid: DisplayGrid.None,
        pushItems: true,
        draggable: {
            enabled: false,
        },
        resizable: {
            enabled: false,
            handles: {
                s: false,
                e: true,
                n: false,
                w: true,
                se: false,
                ne: false,
                sw: false,
                nw: false,
            },
        },
        minCols: 2,
        maxCols: 2,
        minRows: 1,
        maxRows: 9,
        maxItemCols: 2,
        minItemCols: 1,
        maxItemRows: 3,
        minItemRows: 1,
        maxItemArea: 8,
        minItemArea: 1,
        defaultItemCols: 1,
        defaultItemRows: 1,
        addEmptyRowsCount: 0,
        compactType: "compactUp",
        compactEnabled: true,
        fixedRowHeight: rowHeight,
        margin: 20,
        disableScrollHorizontal: true,
        disableScrollVertical: true,
    }
}
