import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-verofy-logo-main",
    template: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
        <defs>
            <style>
                .cls-1 {
                    fill: #fff;
                }

                .cls-1,
                .cls-2 {
                    stroke-width: 0px;
                }

                .cls-2 {
                    fill: #2f7aff;
                }
            </style>
        </defs>
        <rect class="cls-2" x="0" y="0" width="80" height="80" rx="40" ry="40" />
        <g>
            <path
                class="cls-1"
                d="M67.31,32.01c0-1.21.81-2.05,2.02-2.05s2.02.84,2.02,2.05-.81,2.05-2.02,2.05-2.02-.84-2.02-2.05ZM69.33,33.68c.97,0,1.59-.68,1.59-1.67s-.62-1.67-1.59-1.67-1.59.68-1.59,1.67.62,1.67,1.59,1.67ZM68.57,30.91h.83c.5,0,.81.24.81.71,0,.32-.2.59-.51.7l.37.44c.12.15.28.31.36.38h-.5s-.12-.12-.21-.24l-.43-.52h-.28v.76h-.43v-2.23ZM69.37,32.02c.26,0,.4-.19.4-.39,0-.25-.12-.39-.38-.39h-.4v.78h.38Z" />
            <g>
                <path
                    class="cls-1"
                    d="M42.08,35.29c-2.95,0-5.33,2.39-5.33,5.33s2.39,5.33,5.33,5.33,5.33-2.39,5.33-5.33-2.39-5.33-5.33-5.33ZM42.08,43.93c-1.82,0-3.3-1.48-3.3-3.3s1.48-3.3,3.3-3.3,3.3,1.48,3.3,3.3-1.48,3.3-3.3,3.3Z" />
                <path
                    class="cls-1"
                    d="M54.03,32v-2.04c-2.95,0-5.33,2.39-5.33,5.33h0v10.67h2.04v-8.63h3.3v-2.04h-3.3c0-1.82,1.48-3.3,3.3-3.3Z" />
                <path
                    class="cls-1"
                    d="M30.88,40.63h0v5.33h2.04v-5.33c0-1.82,1.48-3.3,3.3-3.3v-2.04c-2.95,0-5.33,2.39-5.33,5.33Z" />
                <path
                    class="cls-1"
                    d="M66.01,40.63h0v-5.34h-2.04v5.34c0,1.82-1.48,3.3-3.3,3.3s-3.3-1.48-3.3-3.3h0v-5.33h-2.04v5.33h0c0,2.95,2.39,5.33,5.33,5.33,1.24,0,2.39-.43,3.29-1.14-.06,1.76-1.51,3.18-3.29,3.18-1.2,0-2.33-.32-3.3-.88v2.27c1.02.42,2.13.65,3.3.65,2.95,0,5.33-2.39,5.33-5.33h0v-4.08Z" />
                <path
                    class="cls-1"
                    d="M29.5,41.65c.06-.33.1-.67.1-1.02s-.03-.69-.1-1.02c-.48-2.46-2.64-4.32-5.24-4.32s-4.76,1.86-5.24,4.32c-.06.33-.1.67-.1,1.02s.03.69.1,1.02c.48,2.46,2.64,4.32,5.24,4.32.06,0,.11,0,.17,0,1.51-.03,2.92-.44,4.15-1.15l-.56-1.12-.35-.7c-.98.59-2.12.93-3.34.94h-.01s-.03,0-.05,0h0c-1.47,0-2.71-.96-3.14-2.28h8.37ZM21.13,39.61c.43-1.32,1.67-2.28,3.14-2.28s2.71.96,3.14,2.28h-6.27Z" />
                <polygon
                    class="cls-1"
                    points="19.33 35.29 17.05 35.29 13.99 41.41 10.94 35.29 8.66 35.29 13.99 45.96 13.99 45.96 13.99 45.96 19.33 35.29" />
            </g>
        </g>
    </svg>`,
})
export class VerofyLogoMainComponent {}
