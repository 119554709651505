import { ApiResource } from "./api-resource"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class Currency extends ApiResource {
    id = 0
    name: string
    code: string
    symbol: string
    format: string
    exchangeRate: string
    isEnabled: boolean
}

@Injectable({
    providedIn: "root",
})
export class CurrencySerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): Currency {
        const currency = new Currency()
        currency.id = json.id
        currency.name = json.name
        currency.code = json.code
        currency.symbol = json.symbol
        currency.format = json.format
        currency.exchangeRate = json.exchange_rate
        currency.isEnabled = json.is_enabled
        return currency
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(currency: Currency): any {
        return {
            id: currency.id,
            name: currency.name,
            code: currency.code,
            symbol: currency.symbol,
            format: currency.format,
            exchange_rate: currency.exchangeRate,
            is_enabled: currency.isEnabled,
        }
    }
}
