import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-download",
    template: `<svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        [attr.width]="width"
        [attr.height]="height"
        viewBox="0 0 466.04 442.09">
        <defs>
            <style>
                .cls-1 {
                    fill: #354c8c;
                    stroke-width: 0px;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="M371.53,226.95l-118.2,118.2c-.61.61-1.25,1.19-1.93,1.75-.19.15-.39.29-.58.44-.52.4-1.03.79-1.58,1.16-.18.11-.37.23-.55.34-.58.35-1.15.72-1.76,1.05-.14.08-.29.13-.43.21-.65.33-1.31.66-1.99.93-.11.05-.24.08-.35.13-.71.29-1.44.55-2.16.77-.16.05-.32.09-.47.13-.71.2-1.4.39-2.13.54-.35.06-.72.09-1.08.16-.51.08-1.05.17-1.58.24-.9.08-1.82.13-2.74.13s-1.84-.05-2.76-.13c-.53-.07-1.04-.16-1.58-.24-.35-.07-.72-.1-1.08-.16-.72-.15-1.42-.34-2.13-.54-.14-.04-.3-.08-.45-.13-.74-.22-1.46-.48-2.17-.77-.12-.05-.23-.08-.34-.13-.7-.27-1.36-.6-2-.93-.15-.08-.29-.13-.44-.21-.59-.33-1.17-.7-1.75-1.05-.18-.11-.38-.23-.55-.34-.54-.35-1.04-.76-1.55-1.14-.19-.17-.4-.3-.6-.46-.61-.5-1.17-1.03-1.74-1.56l-40.23-40.23-78.17-78.16c-10.71-10.7-10.71-28.05,0-38.76,5.35-5.35,12.37-8.03,19.38-8.03s14.05,2.68,19.4,8.03l71.35,71.35V27.42c0-15.15,12.27-27.42,27.41-27.42s27.41,12.27,27.41,27.42v232.12l71.35-71.35c5.36-5.35,12.37-8.03,19.38-8.03s14.03,2.68,19.39,8.03c10.7,10.71,10.7,28.06,0,38.76Z" />
        <path
            class="cls-1"
            d="M407.22,305.11h-86.49l-54.23,54.23-.06.05c-.89.85-1.82,1.69-2.77,2.47-.37.31-.76.6-1.16.9-.61.47-1.47,1.13-2.5,1.82-.37.26-.76.48-1.14.73-.82.52-1.73,1.06-2.81,1.64-.32.18-.66.34-1,.5-.82.42-1.89.94-3.11,1.45-.32.13-.63.26-.95.37-1.21.47-2.29.85-3.35,1.18-.37.11-.74.21-1.13.31-.94.27-2.03.56-3.29.81-.82.16-1.52.26-1.89.31l-.03.02-.19.03c-.56.1-1.42.23-2.42.34-1.56.15-3.14.23-4.69.23s-3.13-.08-4.68-.23c-1.03-.11-1.89-.26-2.45-.34l-.19-.03-.03-.02c-.37-.05-1.06-.15-1.85-.31-1.29-.24-2.42-.55-3.32-.81-.37-.1-.74-.19-1.11-.31-1.08-.32-2.16-.71-3.37-1.18-.31-.11-.61-.24-.94-.37-1.24-.52-2.34-1.05-3.13-1.45-.34-.16-.68-.32-1.02-.52-1.06-.56-1.97-1.13-2.77-1.63-.39-.24-.77-.47-1.16-.73-1-.68-1.84-1.31-2.47-1.81-.4-.29-.81-.6-1.21-.92-.85-.71-1.71-1.48-2.63-2.35-.21-.19-.47-.44-.74-.71l-53.68-53.68H58.84c-32.49,0-58.84,26.35-58.84,58.84v19.3c0,32.49,26.35,58.84,58.84,58.84h348.38c32.49,0,58.82-26.35,58.82-58.84v-19.3c0-32.49-26.33-58.84-58.82-58.84ZM402.44,389c-8.5,0-15.4-6.89-15.4-15.4s6.89-15.4,15.4-15.4,15.4,6.89,15.4,15.4-6.89,15.4-15.4,15.4Z" />
    </svg>`,
})
export class DownloadComponent {
    // Properties for dynamic width and height
    @Input() width = 12
    @Input() height = 12
}
