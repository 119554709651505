import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { Enums, EnumsSerializer } from "../../_models/enums"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { AssociativeArray, JsonObject } from "@app/_core/types"

@Injectable({
    providedIn: "root",
})
export class EnumsApiService {
    constructor(private httpClient: HttpClient, private enumsSerializer: EnumsSerializer) {}

    list(queryOptions: AssociativeArray<string> = {}): Observable<Enums> {
        const params = this.prepareHttpParams(queryOptions)
        return this.httpClient
            .get(environment.mainApiUrl + environment.mainApiEnums, { params })
            .pipe(map((response: JsonObject) => this.enumsSerializer.fromJson(response.data) as Enums))
    }

    protected prepareHttpParams(queryOptions: AssociativeArray<string>): HttpParams {
        let params = new HttpParams()
        for (const key in queryOptions) {
            if (Object.prototype.hasOwnProperty.call(queryOptions, key) && queryOptions[key] !== null) {
                params = params.append(key, queryOptions[key])
            }
        }
        return params
    }
}
