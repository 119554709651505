import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { JsonObject } from "../_core/types"

export class AuthUserCheck implements ApiResource {
    id: number = 0
    data: {
        appAction: string
        isKycRequired: number
        twoFactorAuth: {
            method: string
            requestId: number
        } | null
    }
    message: string
    status: string
    success: boolean
}

@Injectable({
    providedIn: "root",
})
export class AuthUserCheckSerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): AuthUserCheck {
        const record = new AuthUserCheck()
        record.id = json.id ?? 0
        record.data = {
            appAction: json.data.app_action,
            isKycRequired: json.data.is_kyc_required,
            twoFactorAuth: json.data.two_factor_auth
                ? {
                      method: json.data.two_factor_auth.method,
                      requestId: json.data.two_factor_auth.request_id,
                  }
                : null,
        }
        record.message = json.message
        record.status = json.status
        record.success = json.success
        return record
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: AuthUserCheck): any {
        return {
            id: record.id,
            data: {
                app_action: record.data.appAction,
                is_kyc_required: record.data.isKycRequired,
                two_factor_auth: {
                    method: record.data.twoFactorAuth.method,
                    request_id: record.data.twoFactorAuth.requestId,
                },
            },
        }
    }
}
