import { Component, Input } from "@angular/core"

export const enum poweredByType {
    payPanel = "payPanel",
    vPayment = "vPayment",
    myPayLink = "myPayLink",
}

export const enum size {
    small = "small",
    large = "large",
    medium = "medium",
}

@Component({
    selector: "app-powered-by-component",
    templateUrl: "./powered-by-component.component.html",
})
export class PoweredByComponentComponent {
    @Input() poweredBy: poweredByType = poweredByType.payPanel
    @Input() padding: boolean = true
    @Input() size: size = size.small
    @Input() svgSize: string = "120px"
    @Input() imgMargin: boolean = false
}
