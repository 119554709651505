import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-ToggleArrow",
    template: `
        <svg
            *ngIf="!isUp"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 212 116"
            style="enable-background:new 0 0 212 116;"
            xml:space="preserve">
            <g>
                <polygon points="5.8,107.5 105,8.3 204.2,107.5 	" />
                <path class="st0" d="M105,9.01L202.99,107H7.01L105,9.01 M105,7.59L4.59,108h200.82L105,7.59L105,7.59z" />
            </g>
        </svg>

        <svg
            *ngIf="isUp"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 212 116"
            style="enable-background:new 0 0 212 116;"
            xml:space="preserve">
            <g>
                <polygon points="5.8,8.5 204.2,8.5 105,107.7 	" />
                <path class="st0" d="M202.99,9L105,106.99L7.01,9H202.99 M205.41,8H4.59L105,108.41L205.41,8L205.41,8z" />
            </g>
        </svg>
    `,
})
export class ToggleArrowComponent {
    // Default to true for up arrow
    @Input() isUp: boolean = true
}
