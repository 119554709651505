import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ElectricityQuote, ElectricityQuoteSerializer } from "./electricity-quote"
import { GasQuote, GasQuoteSerializer } from "./gas-quote"
import { JsonObject } from "../_core/types"

export class CustomerUtilityQuote {
    public static TYPE_ELECTRICITY = 1
    public static TYPE_GAS = 2

    id = 0
    createdAt: Date
    updatedAt: Date

    validFrom: Date
    validTo: Date
    status: number
    type: number
    requestDetails:
        | {
              term: number
              postcode: string
              startDate: Date
              haveCurrentBill: boolean
              profile: number
              meterType: number
              mpanOne: string
              mpanTwo: string
              mpanThree: string
              mpanFour: string
              mpanFive: string
              mpanSix: string
              mpanSeven: string
              consumptionUnit: number
              consumptionDay: number
              consumptionNight: number
              consumptionWeekend: number
              uplift: number
          }
        | {
              term: number
              postcode: string
              startDate: Date
              type: number
              consumptionUnit: number
              consumption: number
              uplift: number
          }
    quoteDetails: ElectricityQuote | GasQuote
    customerId: number
    utilityPricingId: number
    hashCode: string
}

@Injectable({
    providedIn: "root",
})
export class CustomerUtilityQuoteSerializer implements ApiResourceSerializer {
    constructor(
        private electricityQuoteSerializer: ElectricityQuoteSerializer,
        private gasQuoteSerializer: GasQuoteSerializer,
    ) {}

    fromJson(json: JsonObject): CustomerUtilityQuote {
        const quote = new CustomerUtilityQuote()
        quote.id = json.id
        quote.createdAt = new Date(json.created_at)
        quote.updatedAt = new Date(json.updated_at)

        quote.validFrom = new Date(json.valid_from)
        quote.validTo = new Date(json.valid_to)
        quote.status = json.status
        quote.type = json.type
        if (quote.type === CustomerUtilityQuote.TYPE_ELECTRICITY) {
            quote.requestDetails =
                json.request_details !== undefined && json.request_details !== null
                    ? {
                          "term": json.request_details.term,
                          "postcode": json.request_details.postcode,
                          "startDate": new Date(json.request_details.start_date),
                          "haveCurrentBill": json.request_details.have_current_bill === "1",
                          "profile": json.request_details.profile,
                          "meterType": json.request_details.meter_type,
                          "mpanOne": json.request_details.mpan_one,
                          "mpanTwo": json.request_details.mpan_two,
                          "mpanThree": json.request_details.mpan_three,
                          "mpanFour": json.request_details.mpan_four,
                          "mpanFive": json.request_details.mpan_five,
                          "mpanSix": json.request_details.mpan_six,
                          "mpanSeven": json.request_details.mpan_seven,
                          "consumptionUnit": json.request_details.consumption_unit,
                          "consumptionDay": json.request_details.consumption_day,
                          "consumptionNight": json.request_details.consumption_night,
                          "consumptionWeekend": json.request_details.consumption_weekend,
                          "uplift": json.request_details.uplift,
                      }
                    : null
            quote.quoteDetails =
                json.quote_details !== undefined && json.quote_details !== null
                    ? this.electricityQuoteSerializer.fromJson(json.quote_details)
                    : null
        } else {
            quote.requestDetails =
                json.request_details !== undefined && json.request_details !== null
                    ? {
                          "term": json.request_details.term,
                          "postcode": json.request_details.postcode,
                          "startDate": new Date(json.request_details.start_date),
                          "type": json.request_details.type,
                          "consumptionUnit": json.request_details.consumption_unit,
                          "consumption": json.request_details.consumption,
                          "uplift": json.request_details.uplift,
                      }
                    : null
            quote.quoteDetails =
                json.quote_details !== undefined && json.quote_details !== null
                    ? this.gasQuoteSerializer.fromJson(json.quote_details)
                    : null
        }

        quote.customerId = json.customer_id
        quote.utilityPricingId = json.utility_pricing_id
        quote.hashCode = json.hash_code

        return quote
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(quote: CustomerUtilityQuote): any {
        return {
            id: quote.id,
        }
    }
}
