import { PartnersApiService } from "./api/partners.api.service"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, take } from "rxjs"
import { LocalStorageService } from "./local-storage.service"
import { AppError } from "@app/_core/app-error"
import { AlertService } from "./alert.service"
import { SwitchablePartner, SwitchablePartnerSerializer } from "@app/_models/switchable-partner"

@Injectable({
    providedIn: "root",
})
export class CurrentSwitchablePartnersService {
    public currentSwitchablePartners: Observable<SwitchablePartner[]>
    private currentSwitchablePartnersSubject: BehaviorSubject<SwitchablePartner[]>

    constructor(
        private partnersApiService: PartnersApiService,
        private switchablePartnerSerializer: SwitchablePartnerSerializer,
        private localStorageService: LocalStorageService,
        private alertService: AlertService,
    ) {
        this.currentSwitchablePartnersSubject = new BehaviorSubject<SwitchablePartner[]>(
            this.getCurrentSwitchablePartners(),
        )
        this.currentSwitchablePartners = this.currentSwitchablePartnersSubject.asObservable()
    }

    getCurrentSwitchablePartners(): SwitchablePartner[] {
        const currentSwitchablePartners = this.localStorageService.getData("currentSwitchablePartners")
        if (currentSwitchablePartners !== null) {
            return JSON.parse(currentSwitchablePartners).map(item => this.switchablePartnerSerializer.fromJson(item))
        }
        return null
    }

    public get currentSwitchablePartnersValue(): SwitchablePartner[] {
        return this.currentSwitchablePartnersSubject.value
    }

    setSwitchablePartners(switchablePartners: SwitchablePartner[]): void {
        this.currentSwitchablePartnersSubject.next(switchablePartners)
    }

    prepareSwitchablePartners(): Observable<SwitchablePartner[]> {
        return this.partnersApiService.getSwitchablePartners({ resource: "list" }, {})
    }

    public loadSwitchablePartners(): void {
        this.partnersApiService
            .getSwitchablePartners({ resource: "list" }, {})
            .pipe(take(1))
            .subscribe({
                next: (payload: SwitchablePartner[]) => {
                    console.log("Current switchable partners: ", payload)
                    const json = payload.map(item => this.switchablePartnerSerializer.toJson(item))
                    this.localStorageService.saveData("currentSwitchablePartners", JSON.stringify(json))
                    this.currentSwitchablePartnersSubject.next(payload)
                    return payload
                },
                error: (error: AppError) => {
                    this.alertService.userError(
                        error,
                        "We're sorry but the application having some technical difficulties. Please try to reload the page.",
                        false,
                        true,
                    )
                    throw error
                },
            })
    }
}
