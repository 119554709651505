import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-lock",
    template: ` <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 370.64 500"
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }">
        <defs>
            <style>
                .cls-1 {
                    fill: #c2c2c2;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="M329.94,181.38h-20.14v-56.9C309.8,55.83,253.97,0,185.32,0S60.82,55.83,60.82,124.48v56.9h-20.13c-22.47,0-40.69,18.22-40.69,40.7v237.23c0,22.47,18.22,40.69,40.69,40.69h289.25c22.47,0,40.69-18.22,40.69-40.69v-237.23c0-22.48-18.22-40.7-40.69-40.7ZM137.49,124.48c0-26.36,21.45-47.82,47.83-47.82s47.82,21.45,47.82,47.82v56.9h-95.65v-56.9Z" />
    </svg>`,
})
export class LockIconComponent {
    @Input() width: number = 14
    @Input() height: number = 14
}
