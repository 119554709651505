import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-question-white",
    template: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <defs>
            <style>
                .cls-11 {
                    fill: none;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.85px;
                }

                .cls-2 {
                    fill: #fff;
                    stroke-width: 0px;
                }
            </style>
        </defs>
        <circle class="cls-11" cx="15" cy="15" r="13.1" />
        <path
            class="cls-2"
            d="m13.87,17.94c.01-.72.09-1.3.25-1.72.15-.42.46-.89.93-1.4l1.2-1.23c.51-.58.77-1.2.77-1.86s-.17-1.14-.5-1.5c-.34-.36-.82-.54-1.46-.54s-1.12.17-1.5.49c-.38.33-.57.77-.57,1.33h-1.69c.01-.99.36-1.79,1.06-2.39.69-.61,1.59-.91,2.7-.91s2.05.31,2.69.93.96,1.47.96,2.54-.49,2.12-1.48,3.15l-1,.99c-.45.49-.67,1.2-.67,2.13h-1.69Zm-.07,2.91c0-.28.08-.51.25-.7.17-.19.42-.28.75-.28s.58.09.75.28c.17.19.26.42.26.7s-.09.51-.26.69c-.17.18-.42.27-.75.27s-.58-.09-.75-.27-.25-.41-.25-.69Z" />
    </svg>`,
})
export class QuestionWhiteComponent {}
