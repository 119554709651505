import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { Alert, AlertType } from "@app/shared/alert/alert"
import { Subject, Subscription } from "rxjs"
import { debounceTime } from "rxjs/operators"

@Component({
    selector: "app-alert-component",
    templateUrl: "./alert-component.component.html",
})
export class AlertComponentComponent implements OnInit, OnDestroy {
    @Input() alert: Alert
    @Output() handleRemoveAlert = new EventEmitter<Alert>()
    autoCloseAlertSubscription: Subscription

    private dueTime = 10000
    private autoCloseAlertSub = new Subject<Alert>()
    animationClass: string = ""

    ngOnInit(): void {
        this.autoCloseAlertSub.pipe(debounceTime(this.dueTime)).subscribe(() => {
            this.removeAlert()
        })

        this.autoCloseAlert()
    }

    ngOnDestroy(): void {
        if (this.autoCloseAlertSubscription !== undefined) {
            this.autoCloseAlertSubscription.unsubscribe()
        }
    }

    public autoCloseAlert(): void {
        if (this.alert && this.alert.autoClose === true) {
            this.autoCloseAlertSub.next(this.alert)
        }
    }

    removeAlert(): void {
        this.animationClass = "hide"
        // remove specified alert from array
        setTimeout(() => {
            this.handleRemoveAlert.emit(this.alert)
        }, 1000)
    }

    getAlertTitle(): string {
        if (!this.alert) {
            return this.alert.title
        }

        switch (this.alert.type) {
            case AlertType.Success:
                return "Success"
            case AlertType.Error:
                return "Error"
            case AlertType.Info:
                return "Info"
            case AlertType.Warning:
                return "Warning"
        }
    }

    getCssClass(): string {
        if (!this.alert) {
            return
        }

        // return css class based on alert type
        switch (this.alert.type) {
            case AlertType.Success:
                return "success"
            case AlertType.Error:
                return "danger"
            case AlertType.Info:
                return "info"
            case AlertType.Warning:
                return "warning"
        }
    }
}
