import { createReducer, on } from "@ngrx/store"
import {
    SetHideAppLoadingComponent,
    SetHideAppLoadingGlobal,
    SetHideAppLoadingHeader,
    SetShowAppLoadingComponent,
    SetShowAppLoadingGlobal,
    SetShowAppLoadingHeader,
} from "@app/store/actions/AppLoadingActions"

export enum AppLoadingType {
    global = "global",
    local = "local",
}

export interface AppLoadingInterface {
    loadingGlobal: boolean
    loadingGlobalCount: number
    loadingHeader: boolean
    loadingComponent: boolean
    color: string
    loadingText: string
}

const initialState: AppLoadingInterface = {
    loadingGlobal: false,
    loadingGlobalCount: 0,
    loadingHeader: false,
    loadingComponent: false,
    color: "#307aff",
    loadingText: null,
}

export const AppLoadingReducer = createReducer(
    initialState,

    on(SetShowAppLoadingGlobal, (state, { prop }) => {
        return {
            ...state,
            loadingGlobalCount: state.loadingGlobalCount + 1,
            loadingGlobal: true,
            loadingText: prop,
        }
    }),

    on(SetHideAppLoadingGlobal, state => {
        return {
            ...state,
            loadingGlobalCount: state.loadingGlobalCount - 1,
            loadingGlobal: false,
            loadingText: null,
        }
    }),

    on(SetShowAppLoadingHeader, state => {
        return {
            ...state,
            loadingHeader: true,
        }
    }),

    on(SetHideAppLoadingHeader, state => {
        return {
            ...state,
            loadingHeader: false,
        }
    }),

    on(SetShowAppLoadingComponent, state => {
        return {
            ...state,
            loadingComponent: true,
        }
    }),

    on(SetHideAppLoadingComponent, state => {
        return {
            ...state,
            loadingComponent: false,
        }
    }),
)
