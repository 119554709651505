import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-verofy-footer-logo",
    template: `<svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        viewBox="0 0 300 96.1">
        <defs>
            <style>
                .footerVerofyLogo {
                    stroke-width: 0;
                }
            </style>
        </defs>
        <path
            class="footerVerofyLogo"
            [ngStyle]="{ 'fill': color }"
            d="M280.7,9.82c0-5.79,3.86-9.82,9.65-9.82s9.65,4.03,9.65,9.82-3.86,9.82-9.65,9.82-9.65-4.03-9.65-9.82ZM290.35,17.82c4.65,0,7.61-3.24,7.61-8s-2.95-8-7.61-8-7.61,3.24-7.61,8,2.95,8,7.61,8ZM286.72,4.54h3.97c2.38,0,3.86,1.14,3.86,3.41,0,1.53-.96,2.81-2.44,3.35l1.76,2.1c.6.71,1.36,1.5,1.7,1.82h-2.38c-.2-.17-.57-.6-1.02-1.14l-2.04-2.5h-1.36v3.63h-2.04V4.54ZM290.58,9.88c1.25,0,1.93-.91,1.93-1.87,0-1.19-.57-1.87-1.82-1.87h-1.93v3.75h1.82Z" />
        <g>
            <path
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                d="M159.94,25.53c-14.1,0-25.53,11.43-25.53,25.53s11.43,25.53,25.53,25.53,25.53-11.43,25.53-25.53-11.43-25.53-25.53-25.53ZM159.94,66.84c-8.71,0-15.78-7.06-15.78-15.78s7.06-15.78,15.78-15.78,15.78,7.06,15.78,15.78-7.06,15.78-15.78,15.78Z" />
            <path
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                d="M217.17,9.75V0c-14.1,0-25.53,11.43-25.53,25.53h0v51.06h9.75v-41.31h15.78v-9.75h-15.78c0-8.71,7.06-15.78,15.78-15.78Z" />
            <path
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                d="M106.34,51.06h0v25.53h9.75v-25.53c0-8.71,7.06-15.78,15.78-15.78v-9.75c-14.1,0-25.53,11.43-25.53,25.53Z" />
            <path
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                d="M274.5,51.07h0v-25.53h-9.75v25.53c0,8.71-7.06,15.78-15.78,15.78s-15.78-7.06-15.78-15.78h0v-25.53h-9.75v25.53h0c0,14.1,11.43,25.53,25.53,25.53,5.95,0,11.42-2.03,15.76-5.45-.31,8.45-7.24,15.2-15.76,15.2-5.75,0-11.14-1.54-15.78-4.22v10.86c4.86,2.01,10.19,3.12,15.78,3.12,14.1,0,25.53-11.43,25.53-25.53h0v-19.51Z" />
            <path
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                d="M99.76,55.94c.3-1.58.46-3.21.46-4.88s-.16-3.3-.47-4.88c-2.28-11.77-12.63-20.66-25.07-20.66s-22.79,8.89-25.07,20.66c-.31,1.58-.47,3.21-.47,4.88s.16,3.3.46,4.88c2.28,11.77,12.63,20.66,25.07,20.66.27,0,.53,0,.8-.01,7.22-.14,14-2.12,19.86-5.51l-2.69-5.38-1.68-3.37c-4.68,2.82-10.15,4.47-16,4.52h-.05c-.07,0-.15,0-.23,0h0c-7.01,0-12.96-4.57-15.01-10.9h40.08ZM59.68,46.19c2.05-6.33,8-10.9,15.01-10.9s12.96,4.58,15.01,10.9h-30.02Z" />
            <polygon
                class="footerVerofyLogo"
                [ngStyle]="{ 'fill': color }"
                points="51.06 25.53 40.16 25.53 25.53 54.79 10.9 25.53 0 25.53 25.53 76.6 25.53 76.6 25.53 76.6 51.06 25.53" />
        </g>
    </svg>`,
})
export class VerofyFooterLogoComponent {
    @Input() width: number = 65
    @Input() height: number = 20
    @Input() color: string = "#2f7aff"
}
