import { BehaviorSubject, Observable } from "rxjs"
import { Injectable } from "@angular/core"

@Injectable({
    providedIn: "root",
})
export class BodyScrollService {
    private bodyScrollableSubject = new BehaviorSubject<boolean>(true) // Initial value is true

    constructor() {
        this.adjustBodyScrollable()
        const resizeObserver = new ResizeObserver(() => {
            this.adjustBodyScrollable()
        })
        resizeObserver.observe(document.body)
    }

    private adjustBodyScrollable(): void {
        const scrollable = document.body.scrollHeight > window.innerHeight
        if (scrollable !== this.bodyScrollableSubject.value) {
            this.bodyScrollableSubject.next(scrollable)
        }
    }

    isBodyScrollable(): Observable<boolean> {
        return this.bodyScrollableSubject.asObservable()
    }
}
