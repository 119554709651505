import { createReducer, on } from "@ngrx/store"
import {
    SetPageAnimation,
    SetPageInitialize,
    SetPageLoadingFrom,
    SetPageRestart,
    SetResetPageLoading,
} from "@app/store/actions/AppPageActions"

export enum PageLoadingFrom {
    header = "HEADER",
}

export interface AppPageInterface {
    isPageAnimation: boolean
    initializePage: boolean
    loadingFrom: PageLoadingFrom | null
}

const initialState: AppPageInterface = {
    initializePage: false,
    isPageAnimation: false,
    loadingFrom: null,
}

export const AppPageReducer = createReducer(
    initialState,

    on(SetPageInitialize, (state, { initialize }) => {
        return {
            ...state,
            initializePage: initialize,
        }
    }),

    on(SetPageAnimation, (state, { animation }) => {
        return {
            ...state,
            isPageAnimation: animation,
        }
    }),

    on(SetPageRestart, state => {
        return {
            ...state,
            isPageAnimation: false,
            initializePage: false,
        }
    }),

    on(SetPageLoadingFrom, (state, { loadingFrom }) => {
        return {
            ...state,
            loadingFrom,
        }
    }),

    on(SetResetPageLoading, state => {
        return {
            ...state,
            loadingFrom: null,
        }
    }),
)
