import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-my-pay-links",
    template: ` <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 12 8"
        style="enable-background:new 0 0 12 8;"
        xml:space="preserve">
        <circle class="cls-2" cx="166.89" cy="144.08" r="107.89" />
        <path
            class="cls-1"
            d="m174.35,168.52c-8.74,0-17.47-3.38-24.04-10.12-2.06-2.11-3.83-4.48-5.26-7.03-1.59-2.84-.58-6.43,2.26-8.02,2.83-1.59,6.43-.58,8.02,2.26.93,1.66,2.08,3.2,3.42,4.57,8.38,8.6,22.21,8.78,30.81.39l22.77-22.2c4.17-4.06,6.5-9.5,6.58-15.32.07-5.82-2.12-11.32-6.19-15.49-8.39-8.6-22.21-8.78-30.81-.39l-17.25,16.82c-2.33,2.27-6.05,2.22-8.33-.11-2.27-2.33-2.22-6.06.11-8.33l17.25-16.82c6.42-6.26,14.98-9.63,23.86-9.53,8.97.11,17.35,3.71,23.61,10.13,6.26,6.42,9.64,14.89,9.53,23.86-.11,8.97-3.71,17.35-10.13,23.61l-22.77,22.2c-6.52,6.35-14.98,9.52-23.43,9.52Z" />
        <path
            class="cls-1"
            d="m129.66,212.09c-8.74,0-17.47-3.38-24.04-10.12-6.26-6.42-9.64-14.89-9.53-23.86.11-8.97,3.71-17.35,10.13-23.61l22.77-22.2c13.26-12.92,34.55-12.65,47.47.6,2.91,2.99,5.21,6.44,6.85,10.28,1.27,2.99-.12,6.45-3.11,7.73-3,1.27-6.45-.12-7.73-3.11-1.06-2.49-2.56-4.73-4.44-6.67-4.06-4.17-9.5-6.5-15.32-6.58-5.79-.08-11.32,2.12-15.49,6.18l-22.77,22.2c-4.17,4.06-6.5,9.5-6.58,15.32-.07,5.82,2.12,11.32,6.18,15.49,8.39,8.6,22.21,8.78,30.81.39l16.87-16.44c2.33-2.27,6.06-2.22,8.33.11,2.27,2.33,2.22,6.06-.11,8.33l-16.87,16.44c-6.52,6.35-14.98,9.52-23.43,9.52Z" />
        <text class="cls-4" transform="translate(307.05 202.73)">
            <tspan class="cls-3" x="0" y="0">My</tspan>
            <tspan class="cls-7" x="198.27" y="0">P</tspan>
            <tspan class="cls-6" x="268.62" y="0">a</tspan>
            <tspan class="cls-5" x="342.41" y="0">ylinks</tspan>
        </text>
    </svg>`,
})
export class MyPayLinksComponent {}
