import { Component, Input } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-clip-icon",
    template: `<svg
        class="clipIcon"
        [ngStyle]="{ 'width': width + 'px', 'height': height + 'px' }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21.905 23.803">
        <g id="Group_116" data-name="Group 116" transform="translate(-1109.724 -778.425)">
            <path
                [attr.fill]="borderColor"
                data-name="Path 102"
                d="M1126.448,778.425a6.866,6.866,0,0,1,2.894,1.183,5.782,5.782,0,0,1,.949,8.3c-.191.229-.4.447-.61.659q-6.108,6.113-12.216,12.226a5.279,5.279,0,0,1-2.759,1.433h-1.058a4.855,4.855,0,0,1-2.853-1.569,4.412,4.412,0,0,1,.185-5.966q5.075-5.134,10.208-10.21a3.1,3.1,0,1,1,4.4,4.37c-2.474,2.512-4.979,4.993-7.475,7.483a.834.834,0,0,1-1.259.083c-.352-.365-.31-.786.15-1.246,2.416-2.42,4.847-4.826,7.241-7.268a2.482,2.482,0,0,0,.612-1.223,1.246,1.246,0,0,0-.753-1.337,1.446,1.446,0,0,0-1.552.089,2.465,2.465,0,0,0-.358.312q-4.975,4.971-9.946,9.948a2.835,2.835,0,0,0,.815,4.693,2.8,2.8,0,0,0,3.158-.641c.821-.8,1.623-1.618,2.434-2.428q5.03-5.029,10.057-10.063a4.16,4.16,0,0,0-1.44-6.952,4,4,0,0,0-4.423,1c-1.787,1.753-3.544,3.536-5.314,5.306q-2.3,2.3-4.6,4.6a.8.8,0,0,1-1.045.211.743.743,0,0,1-.363-.9,1.045,1.045,0,0,1,.269-.44q5.043-5.054,10.1-10.091a5.319,5.319,0,0,1,2.984-1.469,2.139,2.139,0,0,0,.295-.092Z"
                fill="#dfdfdf" />
        </g>
    </svg>`,
})
export class ClipIconComponent {
    @Input() width: number = 18
    @Input() height: number = 18
    @Input() borderColor: string = "#ced4da"
}
