import { map } from "rxjs/operators"
import { Observable } from "rxjs"
import { CRProductsServices, CRProductsServicesSerializer } from "../../../_models/cr-products-services"
import { ApiSubResourceService } from "./../api-sub-resource.service"
import { PaginationSerializer } from "./../../../_models/pagination"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../../environments/environment"
import { AssociativeArray } from "@app/_core/types"
import { Injectable, Injector } from "@angular/core"
import { CRProductsAndPacks, CRProductsAndPacksSerializer } from "@app/_models/cr-products-and-packs"

@Injectable({
    providedIn: "root",
})
export class CustomerRegistrationProductsServicesApiService extends ApiSubResourceService<CRProductsServices> {
    constructor(
        httpClient: HttpClient,
        requestSerializer: CRProductsServicesSerializer,
        protected paginationSerializer: PaginationSerializer,
        private injector: Injector,
    ) {
        super(
            httpClient,
            environment.customerRegistrationApi.url.slice(0, -1),
            environment.customerRegistrationApi.endpoints.products,
            "",
            requestSerializer,
            paginationSerializer,
        )
    }

    public getProductsWithPacks(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<CRProductsAndPacks> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const serializer = this.injector.get(CRProductsAndPacksSerializer)
        const url = `${this.url}/${environment.customerRegistrationApi.endpoints.productsWithPacks}`
        return (
            this.httpClient
                .get<CRProductsAndPacks>(url, { headers, params })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => serializer.fromJson(data.data)))
        )
    }

    public getProductsWithPacksTest(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<CRProductsAndPacks> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const url = `${this.url}/${environment.customerRegistrationApi.endpoints.productsWithPacks}`
        return (
            this.httpClient
                .get<CRProductsAndPacks>(url, { headers, params })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => data))
        )
    }

    public getDefaultProductsWithPacks(
        queryOptions: AssociativeArray<string> = {},
        customHeaders: AssociativeArray<string> = {},
    ): Observable<CRProductsAndPacks> {
        const params = this.prepareHttpParams(queryOptions)
        const headers = this.prepareHttpHeaders(customHeaders)
        const serializer = this.injector.get(CRProductsAndPacksSerializer)

        let url = environment.customerRegistrationApi.url.slice(0, -1)
        url += "/default-products-with-packs"

        return (
            this.httpClient
                .get<CRProductsAndPacks>(url, { headers, params })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .pipe(map((data: any) => serializer.fromJson(data.data)))
        )
    }
}
