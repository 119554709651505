import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRContactAddress, CRContactAddressSerializer } from "./cr-contact-address"

export class CROfficers {
    id: number
    companyName: string
    firstName: string
    fullName: string
    isDirector: boolean
    isShareholder: boolean
    isSelected: boolean
    isSDIShareholder: boolean
    lastName: string
    percentageShare: number
    phoneNumber: string
    email: string
    uniqueId: string
    dateOfBirth: string
    address: CRContactAddress
    previousAddress: CRContactAddress
}

@Injectable({
    providedIn: "root",
})
export class CROfficersSerializer implements ApiResourceSerializer {
    constructor(private cRContactAddressSerializer: CRContactAddressSerializer) {}

    fromJson(json: JsonObject): CROfficers {
        const record = new CROfficers()
        record.id = json.id
        record.companyName = json.company_name
        record.firstName = json.first_name
        record.fullName = json.full_name
        record.isDirector = json.is_director
        record.isShareholder = json.is_shareholder
        record.isSelected = json.is_selected
        record.isSDIShareholder = json.is_sdi_shareholder
        record.lastName = json.last_name
        record.percentageShare = json.percentage_share
        record.phoneNumber = json.phone_number
        record.email = json.email
        record.uniqueId = json.unique_id
        record.dateOfBirth = json.date_of_birth
        record.address = json.address ? this.cRContactAddressSerializer.fromJson(json.address) : null
        record.previousAddress = json.previous_address
            ? this.cRContactAddressSerializer.fromJson(json.previous_address)
            : null
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CROfficers): any {
        return {
            id: record.id,
            company_name: record.companyName,
            first_name: record.firstName,
            full_name: record.fullName,
            is_director: record.isDirector,
            is_shareholder: record.isShareholder,
            is_selected: record.isSelected,
            is_sdi_shareholder: record.isSDIShareholder,
            last_name: record.lastName,
            percentage_share: record.percentageShare,
            phone_number: record.phoneNumber,
            email: record.email,
            unique_id: record.uniqueId,
            date_of_birth: record.dateOfBirth,
            address: record.address ? this.cRContactAddressSerializer.toJson(record.address) : null,
            previous_address: record.previousAddress
                ? this.cRContactAddressSerializer.toJson(record.previousAddress)
                : null,
        }
    }
}
