import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { AlertService } from "@app/_services/alert.service"
import { Alert } from "./alert"
import { AlertComponentInstance } from "./alert-component-instance.enum"

@Component({
    selector: "app-alert",
    templateUrl: "./alertList.component.html",
})
export class AlertListComponent implements OnInit, OnDestroy {
    @Input() instance = AlertComponentInstance.main

    alerts: Alert[] = []
    onAlertSubscription: Subscription
    constructor(private alertService: AlertService) {}

    ngOnInit(): void {
        this.onAlertSubscription = this.alertService.onAlert(this.instance).subscribe(alert => {
            if (!alert.message) {
                // clear alerts when an empty alert is received
                this.alerts = []
                return
            }

            // add alert to array
            this.alerts.push(alert)
        })
    }

    ngOnDestroy(): void {
        // unsubscribe to avoid memory leaks
        if (this.onAlertSubscription !== undefined) {
            this.onAlertSubscription.unsubscribe()
        }
    }

    removeAlert(alert: Alert): void {
        // remove specified alert from array
        this.alerts = this.alerts.filter(x => x !== alert)
    }
}
